import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import validator from "validator";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DescriptionIcon from "@material-ui/icons/Description";
import ClearIcon from "@material-ui/icons/Clear";

/*#############################
  # Modal view dialog classes #
  #############################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyle);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const documentUploadEndpoint = {
  image: "IMAGE",
  file: "FILE",
};

const documentUploadEndpointMap = {};
documentUploadEndpointMap[ApiService.documentTypes.avatar] =
  documentUploadEndpoint.image;
documentUploadEndpointMap[ApiService.documentTypes.signature] =
  documentUploadEndpoint.image;
documentUploadEndpointMap[ApiService.documentTypes.userId] =
  documentUploadEndpoint.file;
documentUploadEndpointMap[ApiService.documentTypes.driversLicense] =
  documentUploadEndpoint.file;
documentUploadEndpointMap[ApiService.documentTypes.companyDocuments] =
  documentUploadEndpoint.file;

const UserDocuments = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
  const [userDocuments, setUserDocuments] = React.useState([]);
  const [formattedUserName, setFormattedUserName] = React.useState("");

  // Modal states
  const [showModal, setShowModal] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("upload")
  );

  // Dialog fields
  const [modalDocumentType, setModalDocumentType] = React.useState(
    ApiService.documentTypes.companyDocuments
  );
  const [modalDocumentUploadEndpoint, setModalDocumentUploadEndpoint] =
    React.useState(documentUploadEndpoint.file);
  const [modalEncryptDocument, setModalEncryptDocument] = React.useState([1]);
  const [modalResizeImage, setModalResizeImage] = React.useState([]);
  const [modalResizeWidth, setModalResizeWidth] = React.useState("");
  const [modalResizeHeight, setModalResizeHeight] = React.useState("");
  const [modalSelectedFile, setModalSelectedFile] = React.useState(undefined);
  const [refreshClicked, setRefreshClicked] = React.useState(false);

  const modalFileInput = React.createRef();

  const getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    if (queryParams.get("parentId")) {
      params.parentId = queryParams.get("parentId");
    }
    if (queryParams.get("parentType")) {
      params.parentType = queryParams.get("parentType");
    }
    const returnData = await ApiService.loginRequired(signal, false);
    if (
      (returnData.superuser ||
        params.parentType === ApiService.parentTypes.organization) &&
      params.userId
    ) {
      const userProfileData = await ApiService.readUser(params, signal);
      setFormattedUserName(
        ApiService.getFormattedUserName(returnData._id, userProfileData)
      );
      params.userId =
        typeof userProfileData.superuser !== "undefined"
          ? userProfileData._id
          : returnData._id;
    } else {
      params.userId = returnData._id;
    }
    params.superuser = returnData.superuser;
    return params;
  };

  const clearModalState = () => {
    setShowModal(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("upload"));
    setModalDocumentType(ApiService.documentTypes.companyDocuments);
    setModalDocumentUploadEndpoint(documentUploadEndpoint.file);
    setModalEncryptDocument([1]);
    setModalResizeImage([]);
    setModalResizeWidth("");
    setModalResizeHeight("");
    setModalSelectedFile(undefined);
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const handleReturnData = (data) => {
    return data.map((elem) => {
      elem.encryptedFormatted = elem.encrypted.toString();
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("download-document")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                handleDownloadDocument(elem);
              }}
            >
              <CloudDownloadIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("delete-document")}>
            <Button
              justIcon
              color="rose"
              simple
              className="remove"
              onClick={() => {
                handleDeleteDocument(elem);
              }}
            >
              <ClearIcon />
            </Button>
          </Tooltip>
        </div>
      );
      return elem;
    });
  };

  const handleDeleteDocument = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-document")}?`}
        onConfirm={() => {
          deleteDocument(elem);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{elem.type}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleDownloadDocument = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("download-document")}?`}
        onConfirm={() => {
          downloadDocument(elem);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{elem.type}</b>
        </p>
      </SweetAlert>
    );
  };

  const downloadDocument = async (doc) => {
    setAlert(null);
    try {
      const queryParams = await getQueryParams();
      const params = {
        userId: queryParams.userId,
        documentCriteria: ApiService.documentCriteria.documentId,
        documentFilter: doc._id,
      };
      if (queryParams.parentId) {
        params.parentId = queryParams.parentId;
      }
      if (queryParams.parentType) {
        params.parentType = queryParams.parentType;
      }
      const responseData = await ApiService.downloadUserDocument(params);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = doc.fileName;
      a.click();
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const deleteDocument = async (doc) => {
    setAlert(null);
    try {
      const queryParams = await getQueryParams();
      const params = {
        userId: queryParams.userId,
        documentFilter: doc._id,
        documentCriteria: ApiService.documentCriteria.documentId,
      };
      if (queryParams.parentId) {
        params.parentId = queryParams.parentId;
      }
      if (queryParams.parentType) {
        params.parentType = queryParams.parentType;
      }
      const { message } = await ApiService.deleteUserDocument(params);
      operationResult(true, message);
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const handleDocumentUpload = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const validateDocumentEndpoint =
      !(modalDocumentType in documentUploadEndpointMap) ||
      documentUploadEndpointMap[modalDocumentType] ===
        modalDocumentUploadEndpoint;
    const resizeImage = modalResizeImage.length > 0;
    const resizeWidth =
      modalResizeWidth.trim().length > 0 ? modalResizeWidth.trim() : undefined;
    const validResizeWidth =
      !resizeWidth ||
      (validator.isDecimal(resizeWidth) && parseInt(resizeWidth, 10) > 0);
    const resizeHeight =
      modalResizeHeight.trim().length > 0
        ? modalResizeHeight.trim()
        : undefined;
    const validResizeHeight =
      !resizeHeight ||
      (validator.isDecimal(resizeHeight) && parseInt(resizeHeight, 10) > 0);

    const checksPassed =
      modalSelectedFile &&
      validateDocumentEndpoint &&
      (!resizeImage || (validResizeWidth && validResizeHeight));
    if (!checksPassed) {
      const messageContent = [];
      if (!modalSelectedFile) {
        messageContent.push(t("upload-file-not-selected"));
      }
      if (!validateDocumentEndpoint) {
        messageContent.push(t("invalid-upload-endpoint"));
      }
      if (!validResizeWidth) {
        messageContent.push(t("invalid-image-resize-width"));
      }
      if (!validResizeHeight) {
        messageContent.push(t("invalid-image-resize-height"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }
    const queryParams = await getQueryParams();
    const params = {
      userId: queryParams.userId,
      file: modalSelectedFile,
      documentType: modalDocumentType,
      encryptDocument: (modalEncryptDocument.length > 0).toString(),
    };
    if (queryParams.parentId) {
      params.parentId = queryParams.parentId;
    }
    if (queryParams.parentType) {
      params.parentType = queryParams.parentType;
    }

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("uploading"));

    try {
      let responseData;
      if (modalDocumentUploadEndpoint === documentUploadEndpoint.image) {
        params.resizeImage = (modalResizeImage.length > 0).toString();
        params.scaleImage = params.resizeImage;
        if (modalResizeImage.length > 0) {
          if (resizeWidth) {
            params.imageWidth = resizeWidth;
          }
          if (resizeHeight) {
            params.imageHeight = resizeHeight;
          }
        }
        responseData = await ApiService.upsertUserImage(params);
      } else {
        responseData = await ApiService.upsertUserFile(params);
      }
      setModalProgress(100);
      return showSuccessModalMessage(responseData.message);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      if (queryParams.get("parentId")) {
        params.parentId = queryParams.get("parentId");
      }
      if (queryParams.get("parentType")) {
        params.parentType = queryParams.get("parentType");
      }
      const returnData = await ApiService.loginRequired(signal, false);
      if (
        (returnData.superuser ||
          params.parentType === ApiService.parentTypes.organization) &&
        params.userId
      ) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId =
          typeof userProfileData.superuser !== "undefined"
            ? userProfileData._id
            : returnData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;
      return params;
    };

    const getUserDocuments = async (signal = undefined) => {
      try {
        const queryParams = await getQueryParams(signal);
        const params = {
          userId: queryParams.userId,
        };
        if (queryParams.parentId) {
          params.parentId = queryParams.parentId;
        }
        if (queryParams.parentType) {
          params.parentType = queryParams.parentType;
        }
        const responseData = await ApiService.getUserDocuments(params, signal);
        setUserDocuments(responseData);
      } catch (e) {
        console.error(e);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal, false);
        await getUserDocuments(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [props.location.search, refreshClicked]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            modalFileInput.current.value = "";
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("document-upload")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form
          onSubmit={(event) => {
            handleDocumentUpload(event);
            modalFileInput.current.value = "";
          }}
        >
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12}>
                <div className="picture-container">
                  <div className="picture">
                    <CloudUploadIcon
                      style={{
                        width: "2.5em",
                        height: "2.5em",
                        color: roseColor[0],
                        paddingTop: "1em",
                      }}
                    />
                    <label
                      htmlFor="files"
                      className="btn"
                      style={{ display: "block" }}
                    >
                      {t("select-file")}
                    </label>
                    <input
                      type="file"
                      id="files"
                      ref={modalFileInput}
                      disabled={showModalProgress}
                      onChange={(e) => {
                        setModalSelectedFile(e.target.files[0]);
                      }}
                    />
                  </div>
                  <h6 className="description">
                    {modalSelectedFile && modalSelectedFile.name}
                  </h6>
                  <h6 className="description">
                    {modalSelectedFile && `Size: ${modalSelectedFile.size}`}
                  </h6>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("encrypt")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6} style={{ float: "left" }}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => {
                          const currentIndex = modalEncryptDocument.indexOf(1);
                          const newChecked = [...modalEncryptDocument];
                          if (currentIndex === -1) {
                            newChecked.push(1);
                          } else {
                            newChecked.splice(currentIndex, 1);
                          }
                          setModalEncryptDocument(newChecked);
                        }}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot,
                        }}
                        checked={modalEncryptDocument.length > 0}
                        disabled={showModalProgress}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot,
                    }}
                    label={t("encrypt")}
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("document-type")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="document-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalDocumentType}
                    onChange={(e) => {
                      const documentType = e.target.value;
                      setModalDocumentType(documentType);
                      if (documentType in documentUploadEndpointMap) {
                        setModalDocumentUploadEndpoint(
                          documentUploadEndpointMap[documentType]
                        );
                      }
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "documentType",
                      id: "document-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("document-type")}
                    </MenuItem>
                    {Object.values(ApiService.documentTypes).map(
                      (documentType) => {
                        return (
                          <MenuItem
                            key={documentType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={documentType}
                          >
                            {documentType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("upload-endpoint")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="upload-endpoint"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalDocumentUploadEndpoint}
                    onChange={(e) => {
                      setModalDocumentUploadEndpoint(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "uploadEndpoint",
                      id: "upload-endpoint",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("upload-endpoint")}
                    </MenuItem>
                    {Object.values(documentUploadEndpoint).map(
                      (uploadEndpoint) => {
                        return (
                          <MenuItem
                            key={uploadEndpoint}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={uploadEndpoint}
                          >
                            {uploadEndpoint}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            {modalDocumentUploadEndpoint === documentUploadEndpoint.image && (
              <React.Fragment>
                <GridContainer>
                  <GridItem xs={12} sm={3}>
                    <FormLabel
                      className={
                        formClasses.labelHorizontal +
                        " " +
                        formClasses.labelHorizontalRadioCheckbox
                      }
                      style={{ float: "left" }}
                    >
                      {t("resize-image")}
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6} style={{ float: "left" }}>
                    <div className={formClasses.checkboxAndRadio}>
                      <FormControlLabel
                        style={{ float: "left" }}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => {
                              const currentIndex = modalResizeImage.indexOf(1);
                              const newChecked = [...modalResizeImage];
                              if (currentIndex === -1) {
                                newChecked.push(1);
                              } else {
                                newChecked.splice(currentIndex, 1);
                              }
                              setModalResizeImage(newChecked);
                            }}
                            checkedIcon={
                              <Check className={formClasses.checkedIcon} />
                            }
                            icon={
                              <Check className={formClasses.uncheckedIcon} />
                            }
                            classes={{
                              checked: formClasses.checked,
                              root: formClasses.checkRoot,
                            }}
                            checked={modalResizeImage.length > 0}
                            disabled={showModalProgress}
                          />
                        }
                        classes={{
                          label: formClasses.label,
                          root: formClasses.labelRoot,
                        }}
                        label={t("resize-image")}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                {modalResizeImage.length > 0 && (
                  <React.Fragment>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel
                          className={formClasses.labelHorizontal}
                          style={{ float: "left" }}
                        >
                          {t("resize-width")}
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          id="resize-width"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            value: modalResizeWidth,
                            disabled: showModalProgress,
                            onChange: (e) => {
                              setModalResizeWidth(e.target.value);
                            },
                          }}
                          helpText={t("resize-width-0")}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel
                          className={formClasses.labelHorizontal}
                          style={{ float: "left" }}
                        >
                          {t("resize-height")}
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9}>
                        <CustomInput
                          id="resize-height"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            value: modalResizeHeight,
                            disabled: showModalProgress,
                            onChange: (e) => {
                              setModalResizeHeight(e.target.value);
                            },
                          }}
                          helpText={t("resize-height-0")}
                        />
                      </GridItem>
                    </GridContainer>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                modalFileInput.current.value = "";
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <DescriptionIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("documents")} {formattedUserName}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => handleRefresh()}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  round
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {t("add")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={handleReturnData(userDocuments)}
                filterable
                columns={[
                  {
                    Header: t("type"),
                    accessor: "type",
                  },
                  {
                    Header: t("encrypted"),
                    accessor: "encryptedFormatted",
                  },
                  {
                    Header: t("data-type"),
                    accessor: "dataType",
                  },
                  {
                    Header: t("file-name"),
                    accessor: "fileName",
                  },
                  {
                    Header: t("file-size"),
                    accessor: "fileSize",
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
};

UserDocuments.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default UserDocuments;
