import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useAlertStyles = makeStyles(sweetAlertStyles);

const AdminServicesSubscriptions = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
  const [serviceSubscriptions, setServiceSubscriptions] = React.useState([]);
  const [refreshClicked, setRefreshClicked] = React.useState(false);

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getServiceSubscriptions = async (signal = undefined) => {
      try {
        const responseData = await ApiService.getAllSubscriptions({}, signal);
        setServiceSubscriptions(
          responseData.map((elem) => {
            elem.activationDateFormatted = ApiService.formatDateTime(
              elem.activationDate
            );
            return elem;
          })
        );
      } catch (e) {
        console.error(e);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal, true, true);
        await getServiceSubscriptions(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [refreshClicked]);

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const handleExportData = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("export-data")}?`}
        onConfirm={() => {
          exportData();
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      ></SweetAlert>
    );
  };

  const exportData = async () => {
    try {
      const responseData = await ApiService.downloadAllSubscriptions({
        exportJson: "true",
      });
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = `${uuidv4()}.json`;
      a.click();
      setAlert(null);
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <SubscriptionsIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("subscriptions")}</h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => handleRefresh()}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => {
                    handleExportData();
                  }}
                  style={{ marginTop: "28px" }}
                >
                  {t("export")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={serviceSubscriptions}
                filterable
                columns={[
                  {
                    Header: t("parent"),
                    accessor: "parent.name",
                  },
                  {
                    Header: t("email-address-0"),
                    accessor: "parent.email",
                  },
                  {
                    Header: t("parent-type"),
                    accessor: "parentType",
                  },
                  {
                    Header: t("name"),
                    accessor: "name",
                  },
                  {
                    Header: t("type"),
                    accessor: "type",
                  },
                  {
                    Header: t("plan"),
                    accessor: "plan",
                  },
                  {
                    Header: t("pricing-plan"),
                    accessor: "pricingPlanName",
                  },
                  {
                    Header: t("payment-method"),
                    accessor: "paymentMethod",
                  },
                  {
                    Header: t("status"),
                    accessor: "status",
                  },
                  {
                    Header: t("activation-date"),
                    accessor: "activationDateFormatted",
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                  {
                    Header: t("price"),
                    accessor: "price",
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
};

AdminServicesSubscriptions.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default AdminServicesSubscriptions;
