import React from "react";
import PropTypes from "prop-types";
import ApiService from "api/ApiService.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbars from "components/Snackbar/Snackbar.js";

// core components
import Button from "components/CustomButtons/Button.js";

import defaultImage from "assets/img/document.jpg";
import documentImage from "assets/img/document-management.jpg";
import { useTranslation } from "react-i18next";

const UserDocumentUpload = (props) => {
  const { t } = useTranslation();
  const [activeInput, setActiveInput] = React.useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(defaultImage);
  const [uploadedDocument, setUploadedDocument] = React.useState({});
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const fileInput = React.createRef();

  const getDocumentMetadata = async (signal = undefined) => {
    const params = {
      userId: props.userId,
    };
    if (props.parentId) {
      params.parentId = props.parentId;
    }
    if (props.parentType) {
      params.parentType = props.parentType;
    }
    const responseData = await ApiService.getUserDocuments(params, signal);
    const documentData = responseData.find(
      (entry) => entry.type === props.documentType
    );
    return documentData;
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getDocumentMetadata = async (signal = undefined) => {
      const params = {
        userId: props.userId,
      };
      if (props.parentId) {
        params.parentId = props.parentId;
      }
      if (props.parentType) {
        params.parentType = props.parentType;
      }
      const responseData = await ApiService.getUserDocuments(params, signal);
      const documentData = responseData.find(
        (entry) => entry.type === props.documentType
      );
      return documentData;
    };

    const apiOperations = async () => {
      try {
        const documentMetadata = await getDocumentMetadata(signal);
        if (documentMetadata) {
          setUploadedDocument(documentMetadata);
          setImagePreviewUrl(documentImage);
          setActiveInput(true);
        }
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [props.documentType, props.parentId, props.parentType, props.userId]);

  const showErrorMessage = (messageContent) => {
    setSuccessMessage("");
    setErrorMessage(messageContent);
    setTimeout(() => {
      setErrorMessage("");
    }, ApiService.messageTimeout);
  };
  const showSuccessMessage = (messageContent) => {
    setErrorMessage("");
    setSuccessMessage(messageContent);
    setTimeout(() => {
      setSuccessMessage("");
    }, ApiService.messageTimeout);
  };
  const handleUploadDocument = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    try {
      const params = {
        userId: props.userId,
        documentType: props.documentType,
        encryptDocument: props.encrypt.toString(),
        file,
      };
      if (props.parentId) {
        params.parentId = props.parentId;
      }
      if (props.parentType) {
        params.parentType = props.parentType;
      }
      const { message } = await ApiService.upsertUserFile(params);
      const documentMetadata = await getDocumentMetadata();
      setUploadedDocument(documentMetadata);
      setImagePreviewUrl(documentImage);
      setActiveInput(true);
      showSuccessMessage(message);
    } catch (e) {
      showErrorMessage(e.message);
    }
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleDownload = async () => {
    try {
      const params = {
        userId: props.userId,
        documentCriteria: ApiService.documentCriteria.documentId,
        documentFilter: uploadedDocument._id,
      };
      if (props.parentId) {
        params.parentId = props.parentId;
      }
      if (props.parentType) {
        params.parentType = props.parentType;
      }
      const responseData = await ApiService.downloadUserDocument(params);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = uploadedDocument.fileName;
      a.click();
    } catch (e) {
      showErrorMessage(e.message);
    }
  };
  const handleDelete = async () => {
    try {
      const params = {
        userId: props.userId,
        documentCriteria: ApiService.documentCriteria.documentId,
        documentFilter: uploadedDocument._id,
      };
      if (props.parentId) {
        params.parentId = props.parentId;
      }
      if (props.parentType) {
        params.parentType = props.parentType;
      }
      const { message } = await ApiService.deleteUserDocument(params);
      fileInput.current.value = "";
      setActiveInput(false);
      setImagePreviewUrl(defaultImage);
      showSuccessMessage(message);
    } catch (e) {
      showErrorMessage(e.message);
    }
  };
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleUploadDocument} ref={fileInput} />
      <div className={"thumbnail"}>
        <img src={imagePreviewUrl} alt={t("user-document")} />
        {activeInput && <p>{uploadedDocument.fileName}</p>}
      </div>
      <div>
        {!activeInput ? (
          <Button simple color="primary" onClick={() => handleClick()}>
            {t("select-document")}
          </Button>
        ) : (
          <React.Fragment>
            <Button simple color="rose" onClick={() => handleDownload()}>
              {t("download")}
            </Button>
            {props.update && (
              <React.Fragment>
                <Button simple color="rose" onClick={() => handleClick()}>
                  {t("change")}
                </Button>
                <Button simple color="rose" onClick={() => handleDelete()}>
                  <i className="fas fa-times" /> {t("delete")}
                </Button>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
      <Snackbars
        place="bc"
        color="danger"
        icon={AddAlert}
        message={errorMessage}
        open={errorMessage.length > 0}
        closeNotification={() => setErrorMessage("")}
        close
      />
      <Snackbars
        place="bc"
        color="success"
        icon={AddAlert}
        message={successMessage}
        open={successMessage.length > 0}
        closeNotification={() => setSuccessMessage("")}
        close
      />
    </div>
  );
};

UserDocumentUpload.propTypes = {
  userId: PropTypes.string.isRequired,
  parentId: PropTypes.string,
  parentType: PropTypes.string,
  update: PropTypes.bool.isRequired,
  encrypt: PropTypes.bool.isRequired,
  documentType: PropTypes.string.isRequired,
};

export default UserDocumentUpload;
