import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import validator from "validator";
import Big from "big.js";
import bsv from "bsv";
import { v4 as uuidv4 } from "uuid";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";

import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import ClearIcon from "@material-ui/icons/Clear";
import VisibilityIcon from "@material-ui/icons/Visibility";

/*#############################
  # Modal view dialog classes #
  #############################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import Tooltip from "@material-ui/core/Tooltip";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyle);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const dialogDetailsInputFields = new Set(["deploymentConfig"]);

const UserKeys = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
  const [userKeys, setUserKeys] = React.useState([]);
  const [query, setQuery] = React.useState({});
  const [formattedUserName, setFormattedUserName] = React.useState("");

  // Modal states
  const [showModal, setShowModal] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("add")
  );

  // Dialog fields
  const [modalServiceType, setModalServiceType] = React.useState(
    ApiService.serviceTypes.ubn
  );
  const [modalKeyType, setModalKeyType] = React.useState(
    ApiService.keyTypes.master
  );
  const [modalKeyId, setModalKeyId] = React.useState("");
  const [modalDeploymentId, setModalDeploymentId] = React.useState("");
  const [modalPermissionType, setModalPermissionType] = React.useState(
    ApiService.keyPermissionTypes.public
  );
  const [modalPermissions, setModalPermissions] = React.useState("[]");

  const [modalAddress, setModalAddress] = React.useState("");
  const [modalPublicKey, setModalPublicKey] = React.useState("");
  const [modalPaymail, setModalPaymail] = React.useState("");
  const [modalPublicId, setModalPublicId] = React.useState("");
  const [modalParentKey, setModalParentKey] = React.useState("");
  const [modalConnectedParents, setModalConnectedParents] =
    React.useState("[]");
  const [modalFlags, setModalFlags] = React.useState("[]");
  const [modalIdentities, setModalIdentities] = React.useState("[]");
  const [modalProtected, setModalProtected] = React.useState([]);
  const [modalVisibleWithParent, setModalVisibleWithParent] = React.useState(
    []
  );
  const [modalHdKey, setModalHdKey] = React.useState("");
  const [modalPrivateKey, setModalPrivateKey] = React.useState("");
  const [modalTransactionId, setModalTransactionId] = React.useState("");
  const [modalDecryptionKey, setModalDecryptionKey] = React.useState("");
  const [modalPayload, setModalPayload] = React.useState("");
  const [modalPayloadSize, setModalPayloadSize] = React.useState("");
  const [modalFeePaid, setModalFeePaid] = React.useState("");
  const [modalWalletBalance, setModalWalletBalance] = React.useState("");

  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [formatDetails, setFormatDetails] = React.useState(true);
  const [detailsTitle, setDetailsTitle] = React.useState("");
  const [details, setDetails] = React.useState({});

  const [refreshClicked, setRefreshClicked] = React.useState(false);

  // pagination sorting
  const [count, setCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(ApiService.defaultPageSize);
  const [totalPages, setTotalPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [sortBy, setSortBy] = React.useState("createdAt:desc");

  const [filters, setFilters] = React.useState([]);
  const [filterClicked, setFilterClicked] = React.useState(false);
  const [filterParams, setFilterParams] = React.useState([]);

  const getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    const returnData = await ApiService.loginRequired(signal, false);
    if (returnData.superuser && params.userId) {
      const userProfileData = await ApiService.readUser(params, signal);
      setFormattedUserName(
        ApiService.getFormattedUserName(returnData._id, userProfileData)
      );
      params.userId = userProfileData._id;
    } else {
      params.userId = returnData._id;
    }
    params.superuser = returnData.superuser;
    return params;
  };

  const clearModalState = () => {
    setShowModal(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("add"));
    setModalServiceType(ApiService.serviceTypes.ubn);
    setModalKeyType(ApiService.keyTypes.master);
    setModalKeyId("");
    setModalDeploymentId("");
    setModalPermissionType(ApiService.keyPermissionTypes.public);
    setModalPermissions("[]");
    setModalAddress("");
    setModalPublicKey("");
    setModalPaymail("");
    setModalPublicId("");
    setModalParentKey("");
    setModalConnectedParents("[]");
    setModalFlags("[]");
    setModalIdentities("[]");
    setModalProtected([]);
    setModalVisibleWithParent([]);
    setModalHdKey("");
    setModalPrivateKey("");
    setModalTransactionId("");
    setModalDecryptionKey("");
    setModalPayload("");
    setModalPayloadSize("");
    setModalFeePaid("");
    setModalWalletBalance("");
    setShowDetailsModal(false);
    setFormatDetails(true);
    setDetailsTitle("");
    setDetails({});
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const handleReturnData = (data, queryParams) => {
    return data.map((elem) => {
      elem.protectedFormatted = elem.protected.toString();
      if (elem.permissionType) {
        if (elem.permissions) {
          elem.permissionsFormatted = `${
            elem.permissionType
          } - ${JSON.stringify(elem.permissions)}`;
        } else {
          elem.permissionsFormatted = elem.permissionType;
        }
      }
      if (elem.connectedParents) {
        elem.connectedParentsFormatted = JSON.stringify(elem.connectedParents);
      }
      if (elem.identities) {
        elem.identitiesFormatted = JSON.stringify(
          elem.identities.map((identity) => identity.paymail)
        );
      }
      elem.actions = (
        <div className="actions-right">
          {queryParams.superuser && (
            <React.Fragment>
              <Tooltip title={t("key-details")}>
                <Button
                  justIcon
                  round
                  simple
                  color="rose"
                  className="edit"
                  onClick={() => {
                    handleGetSingleKey(elem);
                  }}
                >
                  <VisibilityIcon />
                </Button>
              </Tooltip>
              <Tooltip title={t("deployment-config")}>
                <Button
                  justIcon
                  round
                  simple
                  color="rose"
                  className="edit"
                  onClick={() => {
                    handleGetDeploymentConfig(elem);
                  }}
                >
                  <SettingsIcon />
                </Button>
              </Tooltip>
            </React.Fragment>
          )}
          <Tooltip title={t("add-key")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                setModalServiceType(elem.service);
                setModalDeploymentId(elem.deploymentId);
                setModalParentKey(elem._id);
                setShowModal(true);
              }}
            >
              <LibraryAddIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("did-document")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                handleGetRawDID(elem);
              }}
            >
              <AssignmentIcon />
            </Button>
          </Tooltip>
          {(!elem.protected || queryParams.superuser) && (
            <React.Fragment>
              <Tooltip title={t("delete-single-key")}>
                <Button
                  justIcon
                  color="rose"
                  simple
                  className="remove"
                  onClick={() => {
                    handleDeleteSingleKey(elem);
                  }}
                >
                  <ClearIcon />
                </Button>
              </Tooltip>
              <Tooltip title={t("delete-key-tree")}>
                <Button
                  justIcon
                  simple
                  color="rose"
                  className="edit"
                  onClick={() => {
                    handleDeleteKeyTree(elem);
                  }}
                >
                  <DeleteSweepIcon />
                </Button>
              </Tooltip>
            </React.Fragment>
          )}
        </div>
      );
      return elem;
    });
  };

  const handleGetSingleKey = async (elem) => {
    const queryParams = await getQueryParams();
    const params = {
      keyCriteria: "byId",
      userId: queryParams.userId,
      keyFilter: elem._id,
      serviceType: elem.service,
      populateKey: true,
    };
    try {
      const responseData = await ApiService.getSingleUserKey(params);
      setFormatDetails(true);
      setDetailsTitle(t("key-details-0"));
      setDetails(responseData);
      setShowDetailsModal(true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleGetDeploymentConfig = async (elem) => {
    const queryParams = await getQueryParams();
    const params = {
      userId: queryParams.userId,
      keyId: elem._id,
    };
    try {
      const responseData = await ApiService.getDeploymentConfig(params);
      setFormatDetails(true);
      setDetailsTitle(t("deployment-config-0"));
      setDetails({
        deploymentId: elem.deploymentId,
        ...responseData,
      });
      setShowDetailsModal(true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleGetRawDID = async (elem) => {
    const queryParams = await getQueryParams();
    const params = {
      userId: queryParams.userId,
      keyId: elem._id,
    };
    try {
      const responseData = await ApiService.getRawDID(params);
      setFormatDetails(false);
      setDetailsTitle(t("did-document-0"));
      setDetails(responseData);
      setShowDetailsModal(true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteSingleKey = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-single-key")}?`}
        onConfirm={() => {
          deleteSingleKey(elem);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{elem._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const deleteSingleKey = async (key) => {
    setAlert(null);
    try {
      const queryParams = await getQueryParams();
      await ApiService.deleteSingleUserKey({
        userId: queryParams.userId,
        keyId: key._id,
      });
      operationResult(true, t("key-successfully-deleted"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const handleDeleteKeyTree = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-key-tree")}?`}
        onConfirm={() => {
          deleteKeyTree(elem);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{elem._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const deleteKeyTree = async (key) => {
    setAlert(null);
    try {
      const queryParams = await getQueryParams();
      await ApiService.deleteUserKeyTree({
        userId: queryParams.userId,
        keyId: key._id,
      });
      operationResult(true, t("key-tree-successfully-deleted"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const handleAddKey = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }
    const keyId = modalKeyId.trim().length > 0 ? modalKeyId.trim() : undefined;
    const deploymentId = modalDeploymentId.trim();
    const validDeploymentId = deploymentId.length > 0;
    const address =
      modalAddress.trim().length > 0 ? modalAddress.trim() : undefined;
    let validAddress = true;
    if (address) {
      try {
        if (!bsv.encoding.Base58Check.fromString(address).toHex()) {
          validAddress = false;
        }
      } catch (e) {
        validAddress = false;
      }
    }
    const publicKey =
      modalPublicKey.trim().length > 0 ? modalPublicKey.trim() : undefined;
    const validPublicKey =
      publicKey === undefined
        ? true
        : validator.isHexadecimal(publicKey) && publicKey.length === 66;
    const paymail =
      modalPaymail.trim().length > 0 ? modalPaymail.trim() : undefined;
    const validPaymail =
      paymail === undefined ? true : validator.isEmail(paymail);
    const publicId =
      modalPublicId.trim().length > 0 ? modalPublicId.trim() : undefined;
    const parentKey =
      modalParentKey.trim().length > 0 ? modalParentKey.trim() : undefined;
    let permissions;
    let validPermissions;
    try {
      permissions = JSON.parse(modalPermissions);
      validPermissions = Array.isArray(permissions);
    } catch (e) {
      validPermissions = false;
    }
    let connectedParents;
    let validConnectedParents;
    try {
      connectedParents = JSON.parse(modalConnectedParents);
      validConnectedParents = Array.isArray(connectedParents);
    } catch (e) {
      validConnectedParents = false;
    }
    let flags;
    let validFlags;
    try {
      flags = JSON.parse(modalFlags);
      validFlags = Array.isArray(flags);
    } catch (e) {
      validFlags = false;
    }
    let identities;
    let validIdentities;
    try {
      identities = JSON.parse(modalIdentities);
      validIdentities = Array.isArray(identities);
    } catch (e) {
      validIdentities = false;
    }
    const hdKey = modalHdKey.trim().length > 0 ? modalHdKey.trim() : undefined;
    const privateKey =
      modalPrivateKey.trim().length > 0 ? modalPrivateKey.trim() : undefined;
    const transactionId =
      modalTransactionId.trim().length > 0
        ? modalTransactionId.trim()
        : undefined;
    const decryptionKey =
      modalDecryptionKey.trim().length > 0
        ? modalDecryptionKey.trim()
        : undefined;
    const validDerivation = hdKey || (address && publicKey);
    const payload =
      modalPayload.trim().length > 0 ? modalPayload.trim() : undefined;
    const payloadSize =
      modalPayloadSize.trim().length > 0
        ? parseInt(modalPayloadSize.trim(), 10)
        : undefined;
    const validPayloadSize =
      payloadSize === undefined ? true : payloadSize >= 0;
    const feePaid =
      modalFeePaid.trim().length > 0 ? modalFeePaid.trim() : undefined;
    const validFeePaid =
      feePaid === undefined
        ? true
        : validator.isDecimal(feePaid) && new Big(feePaid).gte(0);
    const walletBalance =
      modalWalletBalance.trim().length > 0
        ? modalWalletBalance.trim()
        : undefined;
    const validWalletBalance =
      walletBalance === undefined
        ? true
        : validator.isDecimal(walletBalance) && new Big(walletBalance).gte(0);

    const checksPassed =
      validDeploymentId &&
      validAddress &&
      validPublicKey &&
      validPaymail &&
      validPermissions &&
      validConnectedParents &&
      validFlags &&
      validIdentities &&
      validDerivation &&
      validPayloadSize &&
      validFeePaid &&
      validWalletBalance;
    if (!checksPassed) {
      const messageContent = [];
      if (!validDeploymentId) {
        messageContent.push(t("invalid-deployment-id"));
      }
      if (!validAddress) {
        messageContent.push(t("invalid-address"));
      }
      if (!validPublicKey) {
        messageContent.push(t("invalid-public-key"));
      }
      if (!validPaymail) {
        messageContent.push(t("invalid-paymail"));
      }
      if (!validPermissions) {
        messageContent.push(t("invalid-permissions"));
      }
      if (!validConnectedParents) {
        messageContent.push(t("invalid-connected-parents"));
      }
      if (!validFlags) {
        messageContent.push(t("invalid-flags"));
      }
      if (!validIdentities) {
        messageContent.push(t("invalid-identities"));
      }
      if (!validDerivation) {
        messageContent.push(t("define-hd-key-or-address-and-public-key-tuple"));
      }
      if (!validPayloadSize) {
        messageContent.push(t("invalid-payload-size"));
      }
      if (!validFeePaid) {
        messageContent.push(t("invalid-fee-paid"));
      }
      if (!validWalletBalance) {
        messageContent.push(t("invalid-wallet-balance"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }
    const queryParams = await getQueryParams();
    const params = {
      userId: queryParams.userId,
      serviceType: modalServiceType,
      keyType: modalKeyType,
      permissionType: modalPermissionType,
      deploymentId,
      address,
      publicKey,
      paymail,
      publicId,
      parentKey,
      protected: modalProtected.length > 0,
      visibleWithParent: modalVisibleWithParent.length > 0,
      hdKey,
      privateKey,
      transactionId,
      decryptionKey,
      keyId,
    };
    if (permissions.length > 0) {
      params.permissions = permissions;
    }
    if (connectedParents.length > 0) {
      params.connectedParents = connectedParents;
    }
    if (flags.length > 0) {
      params.flags = flags;
    }
    if (identities.length > 0) {
      params.identities = identities;
    }
    if (payload) {
      params.payload = payload;
    }
    if (payloadSize) {
      params.payloadSize = payloadSize;
    }
    if (feePaid) {
      params.feePaid = feePaid;
    }
    if (walletBalance) {
      params.walletBalance = walletBalance;
    }

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("adding"));

    try {
      await ApiService.addUserKey(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("key-successfully-added"));
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const handleRefresh = () => {
    setFilters([]);
    setFilterParams([]);
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId = userProfileData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;
      return params;
    };

    const getUserKeys = async (signal = undefined) => {
      try {
        const queryParams = await getQueryParams(signal);
        const params = {
          userId: queryParams.userId,
          sortBy: sortBy,
          fromEntry: currentPage * pageSize,
          numberOfResults: pageSize,
        };

        if (filterParams.length > 0) {
          const filteredObj = {};
          filterParams.map((filter) => {
            const filteredField = filter.id.replace(/Formatted$/, "");
            if (ApiService.exactMatch.includes(filter.id)) {
              return (filteredObj[filteredField] =
                filter.id === "fileSize"
                  ? parseInt(filter.value)
                  : filter.value);
            } else {
              return (filteredObj[
                filteredField === "permissions"
                  ? "permissionType"
                  : filteredField
              ] = {
                $regex: `^${filter.value}`,
              });
            }
          });

          params.filters = JSON.stringify(filteredObj);
        }

        const responseData = await ApiService.getUserKeys(params, signal);
        setQuery(queryParams);
        setUserKeys(responseData);

        delete params.sortBy;
        delete params.fromEntry;
        delete params.numberOfResults;
        params.count = true;
        const { count: userKeysCount } = await ApiService.getUserKeys(
          params,
          signal
        );
        setCount(userKeysCount);
      } catch (e) {
        console.error(e);
      } finally {
        setFilterClicked(false);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await getUserKeys(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    apiOperations();
    return () => {
      abortController.abort();
    };
  }, [
    props.location.search,
    refreshClicked,
    currentPage,
    pageSize,
    sortBy,
    filterParams,
  ]);

  React.useEffect(() => {
    if (filterClicked && filters.length > 0) {
      setFilterParams(filters);
    }
  }, [filters, filterClicked]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && count > 0) {
      setTotalPages(Math.ceil(count / pageSize));
    }

    return () => {
      isMounted = false;
    };
  }, [count, pageSize]);

  const handlePageChange = async (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePageSizeChange = async (newPageSize, pageIndex) => {
    const total = Math.ceil(count / newPageSize);
    setTotalPages(total);

    let currentIndex;
    if (pageSize > newPageSize) {
      currentIndex = Math.ceil(pageIndex / (pageSize / newPageSize));
    } else {
      currentIndex = Math.ceil((pageSize / newPageSize) * pageIndex);
    }
    setCurrentPage(currentIndex);

    setPageSize(newPageSize);
  };

  const handleSortChange = (newSorted) => {
    setSortBy(
      `${newSorted[0].id.replace(/Formatted$/, "")}:${
        newSorted[0].desc === true ? "desc" : "asc"
      }`
    );
  };

  const handleFilterColumn = (filtered, column) => {
    setFilters(filtered);
  };

  const handleFilterClicked = () => {
    setFilterClicked(true);
  };

  const handleExportData = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("export-data")}?`}
        onConfirm={() => {
          exportData();
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      ></SweetAlert>
    );
  };

  const exportData = async () => {
    try {
      const queryParams = await getQueryParams();
      const fromEntry = currentPage * pageSize;
      const params = {
        userId: queryParams.userId,
        sortBy: sortBy,
        fromEntry,
        numberOfResults: pageSize,
        populateKey: "true",
        exportJson: "true",
      };

      if (filterParams.length > 0) {
        const filteredObj = {};
        filterParams.map((filter) => {
          const filteredField = filter.id.replace(/Formatted$/, "");
          if (ApiService.exactMatch.includes(filter.id)) {
            return (filteredObj[filteredField] =
              filter.id === "fileSize" ? parseInt(filter.value) : filter.value);
          } else {
            return (filteredObj[
              filteredField === "permissions" ? "permissionType" : filteredField
            ] = {
              $regex: `^${filter.value}`,
            });
          }
        });

        params.filters = JSON.stringify(filteredObj);
      }
      const responseData = await ApiService.downloadUserKeys(params);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = `${t("keys")}_${fromEntry}_${pageSize}.json`.toLowerCase();
      a.click();
      setAlert(null);
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showDetailsModal}
        transition={Transition}
        keepMounted
        scroll="body"
        fullWidth
        maxWidth="sm"
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowDetailsModal(false);
            setFormatDetails(true);
            setDetailsTitle("");
            setDetails({});
          }
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>{detailsTitle}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            {formatDetails &&
              Object.entries(details).map(([fieldName, fieldValue]) => {
                let data;
                if (typeof fieldValue === "object") {
                  data = (
                    <div style={{ whiteSpace: "pre-wrap" }}>
                      {JSON.stringify(fieldValue, undefined, 2)}
                    </div>
                  );
                } else {
                  if (dialogDetailsInputFields.has(fieldName)) {
                    data = (
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          value: fieldValue.toString(),
                        }}
                      />
                    );
                  } else {
                    data = (
                      <React.Fragment>{fieldValue.toString()}</React.Fragment>
                    );
                  }
                }
                return (
                  <React.Fragment key={uuidv4()}>
                    <GridItem
                      xs={12}
                      sm={6}
                      md={4}
                      style={{ fontWeight: "400" }}
                    >
                      {dialogDetailsInputFields.has(fieldName) ? (
                        <FormLabel
                          className={formClasses.labelHorizontal}
                          style={{ float: "left" }}
                        >
                          {fieldName}
                        </FormLabel>
                      ) : (
                        fieldName
                      )}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={6}
                      md={8}
                      className={classes.wordBreak}
                    >
                      {data}
                    </GridItem>
                  </React.Fragment>
                );
              })}
            {!formatDetails && (
              <div style={{ whiteSpace: "pre-wrap" }}>
                {JSON.stringify(details, undefined, 2)}
              </div>
            )}
          </GridContainer>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button
            onClick={() => {
              setShowDetailsModal(false);
              setFormatDetails(true);
              setDetailsTitle("");
              setDetails({});
            }}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>{t("add-key-0")}</h4>
        </DialogTitle>
        <form onSubmit={handleAddKey}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("service")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="service-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalServiceType}
                    onChange={(e) => {
                      setModalServiceType(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "serviceType",
                      id: "service-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("service")}
                    </MenuItem>
                    {Object.values(ApiService.serviceTypes).map(
                      (serviceType) => {
                        return (
                          <MenuItem
                            key={serviceType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={serviceType}
                          >
                            {serviceType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("type")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="key-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalKeyType}
                    onChange={(e) => {
                      setModalKeyType(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "keyType",
                      id: "key-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("type")}
                    </MenuItem>
                    {Object.values(ApiService.keyTypes).map((keyType) => {
                      return (
                        <MenuItem
                          key={keyType}
                          classes={{
                            root: formClasses.selectMenuItem,
                            selected:
                              formClasses.selectMenuItemSelectedMultiple,
                          }}
                          value={keyType}
                        >
                          {keyType}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            {query.superuser && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("protected")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={6} style={{ float: "left" }}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControlLabel
                      style={{ float: "left" }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => {
                            const currentIndex = modalProtected.indexOf(1);
                            const newChecked = [...modalProtected];
                            if (currentIndex === -1) {
                              newChecked.push(1);
                            } else {
                              newChecked.splice(currentIndex, 1);
                            }
                            setModalProtected(newChecked);
                          }}
                          checkedIcon={
                            <Check className={formClasses.checkedIcon} />
                          }
                          icon={<Check className={formClasses.uncheckedIcon} />}
                          classes={{
                            checked: formClasses.checked,
                            root: formClasses.checkRoot,
                          }}
                          checked={modalProtected.length > 0}
                          disabled={showModalProgress}
                        />
                      }
                      classes={{
                        label: formClasses.label,
                        root: formClasses.labelRoot,
                      }}
                      label={t("protected")}
                    />
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("visibility")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6} style={{ float: "left" }}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => {
                          const currentIndex =
                            modalVisibleWithParent.indexOf(1);
                          const newChecked = [...modalVisibleWithParent];
                          if (currentIndex === -1) {
                            newChecked.push(1);
                          } else {
                            newChecked.splice(currentIndex, 1);
                          }
                          setModalVisibleWithParent(newChecked);
                        }}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot,
                        }}
                        checked={modalVisibleWithParent.length > 0}
                        disabled={showModalProgress}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot,
                    }}
                    label={t("visible-with-parent")}
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("key-id")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="key-id"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalKeyId,
                    onChange: (e) => {
                      setModalKeyId(e.target.value);
                    },
                  }}
                  helpText={t("key-id")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("deployment-id")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="deployment-id"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalDeploymentId,
                    onChange: (e) => {
                      setModalDeploymentId(e.target.value);
                    },
                  }}
                  helpText={t("deployment-id")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("permission-type")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="permission-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalPermissionType}
                    onChange={(e) => {
                      setModalPermissionType(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "permissionType",
                      id: "permission-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("permission-type")}
                    </MenuItem>
                    {Object.values(ApiService.keyPermissionTypes).map(
                      (keyPermissionType) => {
                        return (
                          <MenuItem
                            key={keyPermissionType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={keyPermissionType}
                          >
                            {keyPermissionType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("permissions")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="permissions"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalPermissions,
                    onChange: (e) => {
                      setModalPermissions(e.target.value);
                    },
                  }}
                  helpText={t("permissions")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("address")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="address"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalAddress,
                    onChange: (e) => {
                      setModalAddress(e.target.value);
                    },
                  }}
                  helpText={t("address")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("public-key")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="public-key"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalPublicKey,
                    onChange: (e) => {
                      setModalPublicKey(e.target.value);
                    },
                  }}
                  helpText={t("public-key")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("paymail")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="paymail"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalPaymail,
                    onChange: (e) => {
                      setModalPaymail(e.target.value);
                    },
                  }}
                  helpText={t("paymail")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("public-id")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="publicId"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalPublicId,
                    onChange: (e) => {
                      setModalPublicId(e.target.value);
                    },
                  }}
                  helpText={t("public-id")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("parent-key")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="parent-key"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalParentKey,
                    onChange: (e) => {
                      setModalParentKey(e.target.value);
                    },
                  }}
                  helpText={t("parent-key-id")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("connected-parents")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="connected-parents"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalConnectedParents,
                    onChange: (e) => {
                      setModalConnectedParents(e.target.value);
                    },
                  }}
                  helpText={t("connected-parents")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("flags")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="flags"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalFlags,
                    onChange: (e) => {
                      setModalFlags(e.target.value);
                    },
                  }}
                  helpText={t("flags")}
                />
              </GridItem>
            </GridContainer>
            {query.superuser && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    style={{ float: "left" }}
                  >
                    {t("identities")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <CustomInput
                    id="identities"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: modalIdentities,
                      onChange: (e) => {
                        setModalIdentities(e.target.value);
                      },
                    }}
                    helpText={t("identities")}
                  />
                </GridItem>
              </GridContainer>
            )}
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("hd-key")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="hd-key"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalHdKey,
                    onChange: (e) => {
                      setModalHdKey(e.target.value);
                    },
                  }}
                  helpText={t("hd-key")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("private-key")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="private-key"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalPrivateKey,
                    onChange: (e) => {
                      setModalPrivateKey(e.target.value);
                    },
                  }}
                  helpText={t("private-key")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("transaction-id")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="transaction-id"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalTransactionId,
                    onChange: (e) => {
                      setModalTransactionId(e.target.value);
                    },
                  }}
                  helpText={t("transaction-id")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("decryption-key")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="decryption-key"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalDecryptionKey,
                    onChange: (e) => {
                      setModalDecryptionKey(e.target.value);
                    },
                  }}
                  helpText={t("decryption-key")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("payload")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="payload"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalPayload,
                    onChange: (e) => {
                      setModalPayload(e.target.value);
                    },
                  }}
                  helpText={t("payload")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("payload-size")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="payload-size"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalPayloadSize,
                    onChange: (e) => {
                      setModalPayloadSize(e.target.value);
                    },
                  }}
                  helpText={t("payload-size")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("fee-paid")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="fee-paid"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalFeePaid,
                    onChange: (e) => {
                      setModalFeePaid(e.target.value);
                    },
                  }}
                  helpText={t("fee-paid")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("wallet-balance")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="wallet-ballance"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalWalletBalance,
                    onChange: (e) => {
                      setModalWalletBalance(e.target.value);
                    },
                  }}
                  helpText={t("wallet-balance")}
                />
              </GridItem>
            </GridContainer>

            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <VpnKeyIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("keys")} {formattedUserName}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => handleRefresh()}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => {
                    handleExportData();
                  }}
                  style={{ marginTop: "28px" }}
                >
                  {t("export")}
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  round
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {t("add")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              {filters.length > 0 && (
                <React.Fragment>
                  <Button
                    color="primary"
                    round
                    className="remove"
                    onClick={() => {
                      handleFilterClicked();
                    }}
                  >
                    {t("filter")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    className="remove"
                    onClick={() => {
                      handleRefresh();
                    }}
                  >
                    {t("clear-filters")}
                  </Button>
                </React.Fragment>
              )}
              <ReactTable
                data={handleReturnData(userKeys, query)}
                sortable={true}
                multiSort={false}
                filterable={true}
                filtered={filters}
                columns={[
                  {
                    Header: t("service"),
                    accessor: "service",
                  },
                  {
                    Header: t("type"),
                    accessor: "type",
                  },
                  {
                    Header: t("deploy-id"),
                    accessor: "deploymentId",
                  },
                  {
                    Header: t("address"),
                    accessor: "address",
                  },
                  {
                    Header: t("public-key"),
                    accessor: "publicKey",
                  },
                  {
                    Header: t("paymail"),
                    accessor: "paymail",
                  },
                  {
                    Header: t("public-id"),
                    accessor: "publicId",
                  },
                  {
                    Header: t("key-id"),
                    accessor: "_id",
                  },
                  {
                    Header: t("parent-key"),
                    accessor: "parentKey",
                  },
                  {
                    Header: t("connected"),
                    accessor: "connectedParentsFormatted",
                    filterable: false,
                  },
                  {
                    Header: t("identities"),
                    accessor: "identitiesFormatted",
                    filterable: false,
                  },
                  {
                    Header: t("permissions"),
                    accessor: "permissionsFormatted",
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
                manual
                pages={totalPages}
                page={currentPage}
                pageSize={pageSize}
                onPageChange={(pageIndex) => handlePageChange(pageIndex)}
                onPageSizeChange={(pageSize, pageIndex) =>
                  handlePageSizeChange(pageSize, pageIndex)
                }
                onSortedChange={(newSorted, column, shiftKey) =>
                  handleSortChange(newSorted, column, shiftKey)
                }
                onFilteredChange={(filtered, column) =>
                  handleFilterColumn(filtered, column)
                }
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
};

UserKeys.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default UserKeys;
