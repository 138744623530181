import {
  cardTitle,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const userProfileStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "35px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400",
    },
  },
  cardCategory: {
    marginTop: "10px",
    color: grayColor[0] + " !important",
    textAlign: "center",
  },
  cardMargin: {
    marginTop: "30px",
  },
  cardMarginBottom: {
    marginBottom: "30px",
  },
  description: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  marginIn: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  marginBottom: {
    marginBottom: "10px",
  },
  updateProfileButton: {
    float: "right",
  },
};
export default userProfileStyles;
