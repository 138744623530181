import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";

import HistoryIcon from "@material-ui/icons/History";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";

/*#############################
  # Modal view dialog classes #
  #############################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const dialogDetailsInputFields = new Set(["value"]);

const UserAuditLogs = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
  const [userAuditLogs, setUserAuditLogs] = React.useState([]);
  const [formattedUserName, setFormattedUserName] = React.useState("");

  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [detailsTitle, setDetailsTitle] = React.useState("");
  const [details, setDetails] = React.useState({});

  const [refreshClicked, setRefreshClicked] = React.useState(false);

  // pagination sorting
  const [count, setCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(ApiService.defaultPageSize);
  const [totalPages, setTotalPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [sortBy, setSortBy] = React.useState("createdAt:desc");

  const [filters, setFilters] = React.useState([]);
  const [filterClicked, setFilterClicked] = React.useState(false);
  const [filterParams, setFilterParams] = React.useState([]);

  const getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    if (queryParams.get("parentId")) {
      params.parentId = queryParams.get("parentId");
    }
    if (queryParams.get("parentType")) {
      params.parentType = queryParams.get("parentType");
    }
    const returnData = await ApiService.loginRequired(signal, false);
    if (
      (returnData.superuser ||
        params.parentType === ApiService.parentTypes.organization) &&
      params.userId
    ) {
      const userProfileData = await ApiService.readUser(params, signal);
      setFormattedUserName(
        ApiService.getFormattedUserName(returnData._id, userProfileData)
      );
      params.userId =
        typeof userProfileData.superuser !== "undefined"
          ? userProfileData._id
          : returnData._id;
    } else {
      params.userId = returnData._id;
    }
    params.superuser = returnData.superuser;
    return params;
  };

  const handleReturnData = (data) => {
    return data.map((elem) => {
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("view-details")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                handleGetSingleAuditLog(elem);
              }}
            >
              <VisibilityIcon />
            </Button>
          </Tooltip>
        </div>
      );
      return elem;
    });
  };

  const handleGetSingleAuditLog = async (elem) => {
    const queryParams = await getQueryParams();
    const params = {
      userId: queryParams.userId,
      id: elem._id,
    };
    if (queryParams.parentId) {
      params.parentId = queryParams.parentId;
    }
    if (queryParams.parentType) {
      params.parentType = queryParams.parentType;
    }
    try {
      const responseData = await ApiService.getUserAuditLogs(params);
      if (responseData.length > 0) {
        setDetailsTitle(t("audit-log-details"));
        setDetails(responseData[0]);
        setShowDetailsModal(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const handleRefresh = () => {
    setFilters([]);
    setFilterParams([]);
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      if (queryParams.get("parentId")) {
        params.parentId = queryParams.get("parentId");
      }
      if (queryParams.get("parentType")) {
        params.parentType = queryParams.get("parentType");
      }
      const returnData = await ApiService.loginRequired(signal, false);
      if (
        (returnData.superuser ||
          params.parentType === ApiService.parentTypes.organization) &&
        params.userId
      ) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId =
          typeof userProfileData.superuser !== "undefined"
            ? userProfileData._id
            : returnData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;
      return params;
    };

    const getUserAuditLogs = async (signal = undefined) => {
      try {
        const queryParams = await getQueryParams(signal);
        const params = {
          userId: queryParams.userId,
          sortBy: sortBy,
          fromEntry: currentPage * pageSize,
          numberOfResults: pageSize,
        };

        if (filterParams.length > 0) {
          const filteredObj = {};
          filterParams.map((filter) => {
            const filteredField = filter.id.replace(/Formatted$/, "");
            if (ApiService.exactMatch.includes(filter.id)) {
              return (filteredObj[filteredField] =
                filter.id === "fileSize"
                  ? parseInt(filter.value)
                  : filter.value);
            } else {
              return (filteredObj[filteredField] = {
                $regex: `^${filter.value}`,
              });
            }
          });

          params.filters = JSON.stringify(filteredObj);
        }

        if (queryParams.parentId) {
          params.parentId = queryParams.parentId;
        }
        if (queryParams.parentType) {
          params.parentType = queryParams.parentType;
        }
        const responseData = await ApiService.getUserAuditLogs(params, signal);
        setUserAuditLogs(responseData);

        delete params.sortBy;
        delete params.fromEntry;
        delete params.numberOfResults;
        params.count = true;
        const { count: auditLogsCount } = await ApiService.getUserAuditLogs(
          params,
          signal
        );
        setCount(auditLogsCount);
      } catch (e) {
        console.error(e);
      } finally {
        setFilterClicked(false);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await getUserAuditLogs(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [
    props.location.search,
    refreshClicked,
    currentPage,
    pageSize,
    sortBy,
    filterParams,
  ]);

  React.useEffect(() => {
    if (filterClicked && filters.length > 0) {
      setFilterParams(filters);
    }
  }, [filters, filterClicked]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && count > 0) {
      setTotalPages(Math.ceil(count / pageSize));
    }

    return () => {
      isMounted = false;
    };
  }, [count, pageSize]);

  const handlePageChange = async (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePageSizeChange = async (newPageSize, pageIndex) => {
    const total = Math.ceil(count / newPageSize);
    setTotalPages(total);

    let currentIndex;
    if (pageSize > newPageSize) {
      currentIndex = Math.ceil(pageIndex / (pageSize / newPageSize));
    } else {
      currentIndex = Math.ceil((pageSize / newPageSize) * pageIndex);
    }
    setCurrentPage(currentIndex);

    setPageSize(newPageSize);
  };

  const handleSortChange = (newSorted) => {
    setSortBy(
      `${newSorted[0].id.replace(/Formatted$/, "")}:${
        newSorted[0].desc === true ? "desc" : "asc"
      }`
    );
  };

  const handleFilterColumn = (filtered, column) => {
    setFilters(filtered);
  };

  const handleFilterClicked = () => {
    setFilterClicked(true);
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const handleExportData = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("export-data")}?`}
        onConfirm={() => {
          exportData();
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      ></SweetAlert>
    );
  };

  const exportData = async () => {
    try {
      const queryParams = await getQueryParams();
      const fromEntry = currentPage * pageSize;
      const params = {
        userId: queryParams.userId,
        sortBy: sortBy,
        fromEntry,
        numberOfResults: pageSize,
        exportJson: "true",
      };
      if (queryParams.parentId) {
        params.parentId = queryParams.parentId;
      }
      if (queryParams.parentType) {
        params.parentType = queryParams.parentType;
      }

      if (filterParams.length > 0) {
        const filteredObj = {};
        filterParams.map((filter) => {
          const filteredField = filter.id.replace(/Formatted$/, "");
          if (ApiService.exactMatch.includes(filter.id)) {
            return (filteredObj[filteredField] =
              filter.id === "fileSize" ? parseInt(filter.value) : filter.value);
          } else {
            return (filteredObj[filteredField] = {
              $regex: `^${filter.value}`,
            });
          }
        });

        params.filters = JSON.stringify(filteredObj);
      }
      const responseData = await ApiService.downloadUserAuditLogs(params);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = `${t(
        "audit-logs-filename"
      )}_${fromEntry}_${pageSize}.json`.toLowerCase();
      a.click();
      setAlert(null);
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showDetailsModal}
        transition={Transition}
        keepMounted
        scroll="body"
        fullWidth
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowDetailsModal(false);
            setDetailsTitle("");
            setDetails({});
          }
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>{detailsTitle}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            {Object.entries(details).map(([fieldName, fieldValue]) => {
              let data;
              if (typeof fieldValue === "object") {
                data = (
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    {JSON.stringify(fieldValue, undefined, 2)}
                  </div>
                );
              } else {
                if (dialogDetailsInputFields.has(fieldName)) {
                  data = (
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: fieldValue.toString(),
                      }}
                    />
                  );
                } else {
                  data = (
                    <React.Fragment>{fieldValue.toString()}</React.Fragment>
                  );
                }
              }
              return (
                <React.Fragment key={uuidv4()}>
                  <GridItem xs={12} sm={6} md={4} style={{ fontWeight: "400" }}>
                    {dialogDetailsInputFields.has(fieldName) ? (
                      <FormLabel
                        className={formClasses.labelHorizontal}
                        style={{ float: "left" }}
                      >
                        {fieldName}
                      </FormLabel>
                    ) : (
                      fieldName
                    )}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={6}
                    md={8}
                    style={{ wordBreak: "break-all" }}
                  >
                    {data}
                  </GridItem>
                </React.Fragment>
              );
            })}
          </GridContainer>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button
            simple
            color="rose"
            onClick={() => {
              setShowDetailsModal(false);
              setDetailsTitle("");
              setDetails({});
            }}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <HistoryIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("audit-logs")} {formattedUserName}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => handleRefresh()}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => {
                    handleExportData();
                  }}
                  style={{ marginTop: "28px" }}
                >
                  {t("export")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              {filters.length > 0 && (
                <React.Fragment>
                  <Button
                    color="primary"
                    round
                    className="remove"
                    onClick={() => {
                      handleFilterClicked();
                    }}
                  >
                    {t("filter")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    className="remove"
                    onClick={() => {
                      handleRefresh();
                    }}
                  >
                    {t("clear-filters")}
                  </Button>
                </React.Fragment>
              )}
              <ReactTable
                data={handleReturnData(userAuditLogs)}
                sortable={true}
                multiSort={false}
                filterable={true}
                filtered={filters}
                columns={[
                  {
                    Header: t("audit-log-id"),
                    accessor: "_id",
                  },
                  {
                    Header: t("type"),
                    accessor: "type",
                  },
                  {
                    Header: t("source"),
                    accessor: "source",
                  },
                  {
                    Header: t("message"),
                    accessor: "message",
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
                manual
                pages={totalPages}
                page={currentPage}
                pageSize={pageSize}
                onPageChange={(pageIndex) => handlePageChange(pageIndex)}
                onPageSizeChange={(pageSize, pageIndex) =>
                  handlePageSizeChange(pageSize, pageIndex)
                }
                onSortedChange={(newSorted, column, shiftKey) =>
                  handleSortChange(newSorted, column, shiftKey)
                }
                onFilteredChange={(filtered, column) =>
                  handleFilterColumn(filtered, column)
                }
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
};

UserAuditLogs.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default UserAuditLogs;
