import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Tooltip from "@material-ui/core/Tooltip";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DescriptionIcon from "@material-ui/icons/Description";
import HttpsIcon from "@material-ui/icons/Https";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import CreditCard from "@material-ui/icons/CreditCard";
import FormLabel from "@material-ui/core/FormLabel";

/*#############################
  # Modal view dialog classes #
  #############################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";
import CardIcon from "components/Card/CardIcon.js";
import Muted from "components/Typography/Muted.js";
import UserImageUpload from "components/CustomUpload/UserImageUpload.js";
import UserDocumentUpload from "components/CustomUpload/UserDocumentUpload.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import isNumeric from "validator/lib/isNumeric";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbars from "components/Snackbar/Snackbar.js";
import SweetAlert from "react-bootstrap-sweetalert";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import EditIcon from "@material-ui/icons/Edit";
import NotificationsIcon from "@material-ui/icons/Notifications";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Datetime from "react-datetime";
import moment from "moment";
import {
  roseColor,
  primaryColor,
} from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useAlertStyles = makeStyles(sweetAlertStyles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyle);

const theme = createTheme({
  overrides: {
    MuiSwitch: {
      colorPrimary: {
        "&$checked": {
          // Controls checked color for the thumb
          color: primaryColor[0],
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: primaryColor[0],
        "$checked$checked + &": {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: primaryColor[0],
        },
      },
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const UserProfile = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const alertClasses = useAlertStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();

  const [loading, setLoading] = React.useState(true);
  const [toggle2FA, setToggle2FA] = React.useState(false);
  const [toggle2FAMessage, setToggle2FAMessage] = React.useState(
    t("generate-secret")
  );
  const [token2FA, setToken2FA] = React.useState("");
  const [confirm2FA, setConfirm2FA] = React.useState(false);
  const [qrCodeSecret2FA, setQrCodeSecret2FA] = React.useState(null);
  const [notifyLowCredits, setNotifyLowCredits] = React.useState(false);
  const [lowCreditsThreshold, setLowCreditsThreshold] = React.useState("0");
  const [currency, setCurrency] = React.useState("");

  const [name, setName] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [companyPosition, setCompanyPosition] = React.useState("");
  const [paymailHandle, setPaymailHandle] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [query, setQuery] = React.useState({});
  const [userProfile, setUserProfile] = React.useState({});
  const [roles, setRoles] = React.useState([]);
  const [checkboxes, setCheckboxes] = React.useState({});
  const [notifications, setNotifications] = React.useState({});
  const [notificationCheckboxes, setNotificationCheckboxes] = React.useState(
    {}
  );
  const [magicLinkLogin, setMagicLinkLogin] = React.useState([]);
  const [availableOrganizations, setAvailableOrganizations] = React.useState(
    []
  );

  // DID fields
  const [personIdentifier, setPersonIdentifier] = React.useState("");
  const [currentFamilyName, setCurrentFamilyName] = React.useState("");
  const [currentGivenName, setCurrentGivenName] = React.useState("");
  const [birthName, setBirthName] = React.useState("");
  const [dateOfBirth, setDateOfBirth] = React.useState("");
  const [placeOfBirth, setPlaceOfBirth] = React.useState("");
  const [currentAddress, setCurrentAddress] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [govID, setGovID] = React.useState("");
  const [tokenExpireTime, setTokenExpireTime] = React.useState("");
  const [city, setCity] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [vatRate, setVatRate] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");

  // Modal states
  const [showModal, setShowModal] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("add")
  );

  // Dialog fields
  const [modalName, setModalName] = React.useState("");
  const [modalValue, setModalValue] = React.useState("");

  // Error handling
  const [nameError, setNameError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [alert, setAlert] = React.useState(null);
  const [refreshClicked, setRefreshClicked] = React.useState(false);

  const [showDisable2FA, setShowDisable2FA] = React.useState(false);

  const genderEnum = [
    { label: "", value: "" },
    { label: t("male"), value: "male" },
    { label: t("female"), value: "female" },
    { label: t("not-known"), value: "notknown" },
    { label: t("not-applicable"), value: "notapplicable" },
  ];

  const clearProfileUpdate = () => {
    setName("");
    setEmail("");
    setCompanyName("");
    setCompanyPosition("");
    setPaymailHandle("");
    setMagicLinkLogin([]);
    setPassword("");
    setConfirmPassword("");
    setCurrency("");
    // DID fields
    setPersonIdentifier("");
    setCurrentFamilyName("");
    setCurrentGivenName("");
    setBirthName("");
    setDateOfBirth("");
    setPlaceOfBirth("");
    setCurrentAddress("");
    setGender("");
    setGovID("");
    setTokenExpireTime("");
    setCity("");
    setCountry("");
    setVatRate("");
    setPostalCode("");
    setPhoneNumber("");
  };

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    const abortController = new AbortController();
    const signal = abortController.signal;

    const handle2FAAction = async (toggleActive, userProfileData) => {
      if (userProfileData.enabled2FA) {
        if (toggleActive) {
          setToggle2FA(true);
          setToggle2FAMessage(t("enabled"));
          setQrCodeSecret2FA(null);
          setConfirm2FA(false);
          setToken2FA("");
        } else {
          setShowDisable2FA(true);
        }
      } else {
        if (toggleActive) {
          try {
            const { qrCode } = await ApiService.generate2FASecret({
              userId: query.userId,
            });
            setQrCodeSecret2FA(qrCode);
            setToggle2FA(true);
            setConfirm2FA(true);
          } catch (e) {
            return showErrorMessage(e.message);
          }
        } else {
          setQrCodeSecret2FA(null);
          setConfirm2FA(false);
          setToggle2FA(false);
        }
        setToggle2FAMessage(t("generate-secret"));
        setToken2FA("");
      }
    };

    const setUserProfileAndActions = async (
      userData,
      availableNotifications,
      superuser = false
    ) => {
      setUserProfile(userData);
      handle2FAAction(userData.enabled2FA, userData);
      if (superuser) {
        const allRoles = await ApiService.getRoles({
          sortBy: "name:asc",
        });
        const userRoles = {};
        userData.assignedRoles.forEach((role) => {
          userRoles[role.name] = role.type;
        });
        const checkboxes_ = {};
        // eslint-disable-next-line no-unused-vars
        for (const role_ of allRoles) {
          if (userRoles.hasOwnProperty(role_.name)) {
            role_.resolvingType = ApiService.resolvingTypes.native;
            checkboxes_[role_._id] = {
              name: role_.name,
              disabled: false,
              initChecked: true,
              checked: true,
            };
          } else {
            role_.resolvingType = ApiService.resolvingTypes.assignable;
            checkboxes_[role_._id] = {
              name: role_.name,
              disabled: false,
              initChecked: false,
              checked: false,
            };
          }
        }
        setCheckboxes(checkboxes_);
        setRoles(allRoles);
      }
      const availableServices = new Set([
        ApiService.serviceTypes.id,
        ...userData.services.map((service) => service.type),
      ]);
      const userDisabledNotifications = {};
      if (userData.disabledNotifications) {
        userData.disabledNotifications.forEach((disabledNotification) => {
          userDisabledNotifications[disabledNotification] = true;
        });
      }
      const notificationCheckboxes_ = {};
      const updateableNotifications = [];
      // eslint-disable-next-line no-unused-vars
      for (const notification of availableNotifications) {
        if (availableServices.has(notification.serviceType)) {
          if (userDisabledNotifications.hasOwnProperty(notification.name)) {
            notification.type = ApiService.notificationTypes.disabled;
            notificationCheckboxes_[notification.name] = {
              name: notification.name,
              disabled: false,
              initChecked: false,
              checked: false,
            };
          } else {
            notification.type = ApiService.notificationTypes.enabled;
            notificationCheckboxes_[notification.name] = {
              name: notification.name,
              disabled: false,
              initChecked: true,
              checked: true,
            };
          }
          updateableNotifications.push(notification);
        }
      }
      setNotificationCheckboxes(notificationCheckboxes_);
      setNotifications(updateableNotifications);
    };

    const getAvailableOrganizations = async (signal) => {
      let organizations = await ApiService.getAvailableOrganizations(signal);
      organizations = organizations.map((organization) => organization.name);
      organizations.sort();
      setAvailableOrganizations(organizations);
    };

    const apiOperations = async () => {
      try {
        const returnData = await ApiService.loginRequired(signal, false);
        if (returnData.superuser && ApiService.profileListOrganizations) {
          await getAvailableOrganizations(signal);
        }
        const availableNotifications =
          await ApiService.getAvailableNotifications(signal);
        let queryValue;
        if (returnData.superuser && params.userId) {
          const userProfileData = await ApiService.readUser(params, signal);
          queryValue = {
            superuser: returnData.superuser,
            ownAccount: returnData._id === params.userId,
            userId: userProfileData._id,
          };
          await setUserProfileAndActions(
            userProfileData,
            availableNotifications,
            returnData.superuser
          );
        } else {
          queryValue = {
            superuser: returnData.superuser,
            ownAccount: true,
            userId: returnData._id,
          };
          await setUserProfileAndActions(
            returnData,
            availableNotifications,
            returnData.superuser
          );
        }
        setQuery(queryValue);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    apiOperations();
    return () => {
      abortController.abort();
    };
  }, [props.location.search, query.userId, refreshClicked, t]);

  React.useEffect(() => {
    if (!ApiService.isObjectEmpty(userProfile)) {
      setName(userProfile.name);
      setEmail(userProfile.email);
      setPaymailHandle(
        userProfile.paymailHandles.length > 0
          ? userProfile.paymailHandles[0].handle
          : ""
      );
      setCompanyName(
        userProfile.organizations.length > 0
          ? userProfile.organizations[0].name
          : ""
      );
      setCompanyPosition(
        userProfile.organizations.length > 0 &&
          userProfile.organizations[0].positions.length > 0
          ? userProfile.organizations[0].positions[0].name
          : ""
      );
      setCurrency(userProfile.currency ? userProfile.currency : "");
      setPersonIdentifier(
        userProfile.personIdentifier ? userProfile.personIdentifier : ""
      );
      setCurrentFamilyName(
        userProfile.currentFamilyName ? userProfile.currentFamilyName : ""
      );
      setCurrentGivenName(
        userProfile.currentGivenName ? userProfile.currentGivenName : ""
      );
      setBirthName(userProfile.birthName ? userProfile.birthName : "");
      setDateOfBirth(userProfile.dateOfBirth ? userProfile.dateOfBirth : "");
      setPlaceOfBirth(userProfile.placeOfBirth ? userProfile.placeOfBirth : "");
      setCurrentAddress(
        userProfile.currentAddress ? userProfile.currentAddress : ""
      );
      setGender(userProfile.gender ? userProfile.gender : "");
      setGovID(userProfile.govID ? userProfile.govID : "");
      setTokenExpireTime(
        userProfile.tokenExpireTime ? userProfile.tokenExpireTime : ""
      );
      setCity(userProfile.city ? userProfile.city : "");
      setCountry(userProfile.country ? userProfile.country : "");
      setVatRate(userProfile.vatRate ? userProfile.vatRate : "");
      setPostalCode(userProfile.postalCode ? userProfile.postalCode : "");
      setPhoneNumber(userProfile.phoneNumber ? userProfile.phoneNumber : "");
      setMagicLinkLogin(userProfile.enabledMagicLinkLogin ? [1] : []);
      setNotifyLowCredits(!!userProfile.notifyLowCredits);
      setLowCreditsThreshold(
        userProfile.lowCreditsThreshold ? userProfile.lowCreditsThreshold : "0"
      );
    }
  }, [userProfile]);

  const clearModalState = () => {
    setShowModal(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("add"));
    setModalName("");
    setModalValue("");
  };

  const showErrorMessage = (messageContent) => {
    setSuccessMessage("");
    setErrorMessage(messageContent);
    setTimeout(() => {
      setNameError("");
      setPasswordError("");
      setConfirmPasswordError("");
      setErrorMessage("");
    }, ApiService.messageTimeout);
  };
  const showSuccessMessage = (messageContent) => {
    setErrorMessage("");
    setSuccessMessage(messageContent);
    setTimeout(() => {
      setSuccessMessage("");
    }, ApiService.messageTimeout);
  };
  const handleUpdateProfileSubmit = async (event) => {
    event.preventDefault();
    const requestData = {
      userId: userProfile._id,
      enabledMagicLinkLogin: magicLinkLogin.length > 0,
    };
    const messageContent = [];
    let checksPassed = true;
    if (name.length > 0 && name !== userProfile.name) {
      requestData.name = name;
    }
    const currentCompanyName =
      userProfile.organizations.length > 0
        ? userProfile.organizations[0].name
        : "";
    const currentCompanyPosition =
      userProfile.organizations.length > 0 &&
      userProfile.organizations[0].positions.length > 0
        ? userProfile.organizations[0].positions[0].name
        : "";
    if (companyName.length > 0 && companyName !== currentCompanyName) {
      requestData.companyName = companyName;
      if (currentCompanyPosition.length > 0) {
        requestData.companyPosition = currentCompanyPosition;
      }
    }
    if (
      companyPosition.length > 0 &&
      companyPosition !== currentCompanyPosition
    ) {
      requestData.companyPosition = companyPosition;
      if (!requestData.companyName) {
        requestData.companyName = currentCompanyName;
      }
    }
    if (email.length > 0 && email !== userProfile.email) {
      const validEmail = isEmail(email);
      if (validEmail) {
        requestData.email = email;
      } else {
        messageContent.push(t("email-address"));
        checksPassed = false;
      }
    }
    if (currency.length > 0 && currency !== userProfile.currency) {
      requestData.currency = currency;
    }
    // DID fields
    if (
      personIdentifier.length > 0 &&
      personIdentifier !== userProfile.personIdentifier
    ) {
      requestData.personIdentifier = personIdentifier;
    }
    if (
      currentFamilyName.length > 0 &&
      currentFamilyName !== userProfile.currentFamilyName
    ) {
      requestData.currentFamilyName = currentFamilyName;
    }
    if (
      currentGivenName.length > 0 &&
      currentGivenName !== userProfile.currentGivenName
    ) {
      requestData.currentGivenName = currentGivenName;
    }
    if (birthName.length > 0 && birthName !== userProfile.birthName) {
      requestData.birthName = birthName;
    }
    if (dateOfBirth.length > 0 && dateOfBirth !== userProfile.dateOfBirth) {
      requestData.dateOfBirth = dateOfBirth;
    }
    if (placeOfBirth.length > 0 && placeOfBirth !== userProfile.placeOfBirth) {
      requestData.placeOfBirth = placeOfBirth;
    }
    if (
      currentAddress.length > 0 &&
      currentAddress !== userProfile.currentAddress
    ) {
      requestData.currentAddress = currentAddress;
    }
    if (gender.length > 0 && gender !== userProfile.gender) {
      requestData.gender = gender;
    }
    if (govID.length > 0 && govID !== userProfile.govID) {
      requestData.govID = govID;
    }
    if (city.length > 0 && city !== userProfile.city) {
      requestData.city = city;
    }
    if (country.length > 0 && country !== userProfile.country) {
      requestData.country = country;
    }
    if (postalCode.length > 0 && postalCode !== userProfile.postalCode) {
      requestData.postalCode = postalCode;
    }
    if (phoneNumber.length > 0 && phoneNumber !== userProfile.phoneNumber) {
      requestData.phoneNumber = phoneNumber;
    }
    if (
      tokenExpireTime.trim().length > 0 &&
      tokenExpireTime !== userProfile.tokenExpireTime
    ) {
      requestData.tokenExpireTime = tokenExpireTime.trim();
    }
    const currentPaymailHandle =
      userProfile.paymailHandles.length > 0
        ? userProfile.paymailHandles[0].handle
        : "";
    if (paymailHandle.length > 0 && paymailHandle !== currentPaymailHandle) {
      requestData.paymailHandle = paymailHandle === " " ? "" : paymailHandle;
    }
    if (password.length > 0) {
      const validPasswordLength = password.length > 6;
      const passwordsMatch = password === confirmPassword;
      if (validPasswordLength && passwordsMatch) {
        requestData.password = password;
      } else {
        if (!validPasswordLength) {
          setPasswordError(t("minimum-password-length-is-7-0"));
          messageContent.push(t("minimum-password-length-is-7"));
        }
        if (!passwordsMatch) {
          setConfirmPasswordError(t("passwords-do-not-match-0"));
          messageContent.push(t("passwords-do-not-match"));
        }
        checksPassed = false;
      }
    }
    if (vatRate.length > 0 && vatRate !== userProfile.vatRate) {
      let validVatRate = isNumeric(vatRate);
      if (validVatRate) {
        const vatRateParsed = parseInt(vatRate, 10);
        if (vatRateParsed >= 0) {
          requestData.vatRate = vatRate;
        } else {
          validVatRate = false;
        }
      }
      if (!validVatRate) {
        messageContent.push(t("vat-rate"));
        checksPassed = false;
      }
    }
    if (!checksPassed) {
      showErrorMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`
      );
    }
    if (!checksPassed || Object.keys(requestData).length === 1) {
      return;
    }

    try {
      await ApiService.updateUser(requestData);
      showSuccessMessage(t("successfully-updated-profile-fields"));
      clearProfileUpdate();
    } catch (e) {
      if (e.message.includes("email")) {
        showErrorMessage(t("user-with-the-same-email-already-exists"));
      } else if (e.message.includes("name")) {
        showErrorMessage(t("user-with-the-same-name-already-exists"));
      } else {
        showErrorMessage(e.message);
      }
    }
    handleRefresh();
  };
  const handleLock = () => {
    props.history.push("/auth/lock-screen-page");
  };
  const handleSendValidationEmail = async (event) => {
    event.preventDefault();
    try {
      const responseData = await ApiService.sendUserValidateEmailLink({
        userId: userProfile._id,
      });
      showSuccessMessage(responseData.message);
    } catch (e) {
      showErrorMessage(e.message);
    }
  };
  const handleRequestDocumentsValidation = async (event) => {
    event.preventDefault();
    try {
      const responseData = await ApiService.requestUserDocumentsValidation({
        userId: userProfile._id,
        requestDocumentsValidation: true,
      });
      handleRefresh();
      showSuccessMessage(responseData.message);
    } catch (e) {
      showErrorMessage(e.message);
    }
  };
  const handleLogout = async () => {
    try {
      await ApiService.logoutUser({ userId: query.userId }, props.history);
    } catch (e) {
      console.error(e);
    }
  };
  const handleLogoutAll = async () => {
    try {
      const { message } = await ApiService.logoutUserAllSessions(
        { userId: query.userId, superuser: query.superuser },
        props.history
      );
      if (query.superuser && !query.ownAccount) {
        setAlert(
          <SweetAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title={t("confirmed")}
            onConfirm={() => {
              handleRefresh();
              setAlert(null);
            }}
            onCancel={() => setAlert(null)}
            confirmBtnCssClass={
              alertClasses.button + " " + alertClasses.success
            }
          >
            {message}
          </SweetAlert>
        );
      }
    } catch (e) {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("cancelled")}
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          {e.message}
        </SweetAlert>
      );
    }
  };
  const showLogoutAllSessions = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("are-you-sure")}
        onConfirm={handleLogoutAll}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        {t("log-out-from")} {userProfile.activeTokens} {t("active-sessions")}
      </SweetAlert>
    );
  };
  const deleteProfileRedirect = () => {
    setAlert(null);
    if (query.superuser && !query.ownAccount) {
      props.history.push("/admin/admin-users");
    } else {
      props.history.push("/");
    }
  };
  const deleteConfirmAlertNext = (result, resultMessage) => {
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("confirmed")}
          onConfirm={deleteProfileRedirect}
          onCancel={deleteProfileRedirect}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          {resultMessage}
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("cancelled")}
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          {resultMessage}
        </SweetAlert>
      );
    }
  };
  const showDeleteProfile = () => {
    setAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={t("delete-profile")}
        onConfirm={async (e) => {
          if (e === userProfile.name) {
            try {
              await ApiService.deleteUser({
                userId: query.userId,
                superuser: query.superuser && !query.ownAccount,
              });
              deleteConfirmAlertNext(
                true,
                t("profile-deletion-process-started")
              );
            } catch (e) {
              deleteConfirmAlertNext(
                false,
                t("error-deleting-profile-please-contact-the-support")
              );
            }
          } else {
            deleteConfirmAlertNext(false, t("names-do-not-match"));
          }
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
      >
        <p>
          {t("deleting-profile-will")} <b>{t("remove-all-of-your-data")}</b>.{" "}
          {t("user-deletion-process-message")}
        </p>
        <p>
          {t("to-delete-profile-confirm")} <b>{t("name")}</b> {t("below")}.
        </p>
      </SweetAlert>
    );
  };
  const handleDisable2FA = async () => {
    let responseData;
    try {
      responseData = await ApiService.disable2FA({
        userId: query.userId,
      });
      showSuccessMessage(responseData.message);
    } catch (e) {
      return showErrorMessage(e.message);
    }
    setToggle2FA(false);
    setToggle2FAMessage(t("generate-secret"));
    setQrCodeSecret2FA(null);
    setConfirm2FA(false);
    setToken2FA("");
    setAlert(null);
    setShowDisable2FA(false);
    showSuccessMessage(responseData.message);
    handleRefresh();
  };
  const handle2FAAction = async (toggleActive, userProfileData) => {
    if (userProfileData.enabled2FA) {
      if (toggleActive) {
        setToggle2FA(true);
        setToggle2FAMessage(t("enabled"));
        setQrCodeSecret2FA(null);
        setConfirm2FA(false);
        setToken2FA("");
      } else {
        setShowDisable2FA(true);
      }
    } else {
      if (toggleActive) {
        try {
          const { qrCode } = await ApiService.generate2FASecret({
            userId: query.userId,
          });
          setQrCodeSecret2FA(qrCode);
          setToggle2FA(true);
          setConfirm2FA(true);
        } catch (e) {
          return showErrorMessage(e.message);
        }
      } else {
        setQrCodeSecret2FA(null);
        setConfirm2FA(false);
        setToggle2FA(false);
      }
      setToggle2FAMessage(t("generate-secret"));
      setToken2FA("");
    }
  };
  const handleEnable2FASubmit = async (event) => {
    event.preventDefault();
    try {
      const responseData = await ApiService.enable2FA({
        userId: query.userId,
        token2FA,
      });
      handleRefresh();
      showSuccessMessage(responseData.message);
    } catch (e) {
      setToken2FA("");
      showErrorMessage(e.message);
    }
  };
  const handleLowCreditsNotificationAction = async (
    toggleActive,
    userProfileData
  ) => {
    if (userProfileData.notifyLowCredits) {
      if (toggleActive) {
        setNotifyLowCredits(true);
      } else {
        try {
          await ApiService.updateUser({
            userId: query.userId,
            notifyLowCredits: false,
          });
          setNotifyLowCredits(false);
          handleRefresh();
        } catch (e) {
          return showErrorMessage(e.message);
        }
      }
    } else {
      if (toggleActive) {
        try {
          await ApiService.updateUser({
            userId: query.userId,
            notifyLowCredits: true,
            lowCreditsThreshold,
          });
          setNotifyLowCredits(true);
          handleRefresh();
        } catch (e) {
          return showErrorMessage(e.message);
        }
      } else {
        setNotifyLowCredits(false);
      }
    }
  };
  const handleLowCreditsNotificationSubmit = async (event) => {
    event.preventDefault();
    try {
      await ApiService.updateUser({
        userId: query.userId,
        notifyLowCredits,
        lowCreditsThreshold,
      });
      showSuccessMessage(t("threshold-successfully-updated"));
    } catch (e) {
      showErrorMessage(e.message);
    }
  };
  const handleRolesData = (data) => {
    return data.map((elem) => {
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("assign-unassign")}>
            <Checkbox
              color="default"
              key={elem._id}
              checked={checkboxes[elem._id] && checkboxes[elem._id].checked}
              className={classes.positionAbsolute}
              tabIndex={-1}
              onClick={() => {
                const checkboxesUpdate = { ...checkboxes };
                checkboxesUpdate[elem._id] = {
                  ...checkboxes[elem._id],
                  checked: !checkboxes[elem._id].checked,
                };
                setCheckboxes(checkboxesUpdate);
              }}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
          </Tooltip>
        </div>
      );
      return elem;
    });
  };
  const handleNotifications = (data) => {
    return data.map((elem) => {
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("enable-disable")}>
            <Checkbox
              color="default"
              key={elem.name}
              checked={
                notificationCheckboxes[elem.name] &&
                notificationCheckboxes[elem.name].checked
              }
              className={classes.positionAbsolute}
              tabIndex={-1}
              onClick={() => {
                const notificationCheckboxesUpdate = {
                  ...notificationCheckboxes,
                };
                notificationCheckboxesUpdate[elem.name] = {
                  ...notificationCheckboxes[elem.name],
                  checked: !notificationCheckboxes[elem.name].checked,
                };
                setNotificationCheckboxes(notificationCheckboxesUpdate);
              }}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
          </Tooltip>
        </div>
      );
      return elem;
    });
  };
  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };
  const handleAddMetadata = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const name = modalName.trim();
    const validName = name.length > 0;
    const value = modalValue.trim();
    const validValue = value.length > 0;

    const checksPassed = validName && validValue;
    if (!checksPassed) {
      const messageContent = [];
      if (!validName) {
        messageContent.push(t("invalid-name"));
      }
      if (!validValue) {
        messageContent.push(t("invalid-value"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("adding"));

    try {
      await ApiService.updateUserMetadata({
        userId: userProfile._id,
        addMetadata: [
          {
            name,
            value,
          },
        ],
      });
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage("Metadata successfully added");
  };
  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };
  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };
  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };
  const filterUpdates = (inputCheckboxes) => {
    const addObjects = [];
    const deleteObjects = [];

    const objectIds = Object.keys(inputCheckboxes);
    // eslint-disable-next-line no-unused-vars
    for (const objectId of objectIds) {
      const entry = inputCheckboxes[objectId];
      if (!entry.disabled) {
        if (entry.initChecked && !entry.checked) {
          deleteObjects.push(entry.name);
        } else if (!entry.initChecked && entry.checked) {
          addObjects.push(entry.name);
        }
      }
    }

    return {
      addObjects,
      deleteObjects,
    };
  };
  const handleUpdateAssignedRoles = async () => {
    setAlert(null);
    try {
      const { addObjects, deleteObjects } = filterUpdates(checkboxes);
      if (addObjects.length > 0) {
        await ApiService.addUserRoles({
          parentId: userProfile._id,
          parentType: ApiService.parentTypes.user,
          roles: addObjects,
        });
      }
      if (deleteObjects.length > 0) {
        await ApiService.deleteUserRoles({
          parentId: userProfile._id,
          parentType: ApiService.parentTypes.user,
          roles: deleteObjects,
        });
      }
      operationResult(true, t("successfully-updated-roles"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };
  const handleUpdateRoles = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("update-roles")}
        onConfirm={() => {
          handleUpdateAssignedRoles();
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      />
    );
  };
  const handleUpdateDisabledNotifications = async () => {
    setAlert(null);
    try {
      const { addObjects, deleteObjects } = filterUpdates(
        notificationCheckboxes
      );
      if (addObjects.length > 0 || deleteObjects.length > 0) {
        await ApiService.updateUserNotifications({
          userId: userProfile._id,
          addDisableNotifications: deleteObjects,
          removeDisableNotifications: addObjects,
        });
      }
      operationResult(true, t("successfully-updated-notifications"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };
  const handleUpdateNotifications = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("update-notifications")}
        onConfirm={() => {
          handleUpdateDisabledNotifications();
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      />
    );
  };
  const redirectToView = (viewLink) => {
    const queryParameters = new URLSearchParams({
      userId: query.userId,
    }).toString();
    props.history.push(`${viewLink}?${queryParameters}`);
  };
  const handleReturnMetadata = (data) => {
    return data.map((elem) => {
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={"Delete metadata"}>
            <Button
              justIcon
              color="rose"
              simple
              className="remove"
              onClick={() => {
                handleDeleteMetadata(elem);
              }}
            >
              <ClearIcon />
            </Button>
          </Tooltip>
        </div>
      );
      return elem;
    });
  };
  const handleDeleteMetadata = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={"Delete metadata"}
        onConfirm={() => {
          deleteMetadata(elem);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{elem.name}</b>
        </p>
      </SweetAlert>
    );
  };
  const deleteMetadata = async (metadata) => {
    setAlert(null);
    try {
      await ApiService.updateUserMetadata({
        userId: userProfile._id,
        removeMetadata: [metadata.name],
      });
      operationResult(true, "Metadata successfully deleted");
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      {query.superuser && !query.ownAccount && (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
                <CardIcon color="turq">
                  <InsertLinkIcon style={{ color: roseColor[0] }} />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{t("view-links")}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer justifyContent="center">
                  <Button
                    color="rose"
                    round
                    simple
                    onClick={() => redirectToView("/admin/dashboard")}
                  >
                    {t("dashboard")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    onClick={() => redirectToView("/admin/admin-services")}
                  >
                    {t("services")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    onClick={() => redirectToView("/admin/user-organizations")}
                  >
                    {t("organizations")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    onClick={() => redirectToView("/admin/user-documents")}
                  >
                    {t("documents")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    onClick={() => redirectToView("/admin/user-subscriptions")}
                  >
                    {t("subscriptions")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    onClick={() => redirectToView("/admin/user-billings")}
                  >
                    {t("billings")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    onClick={() => redirectToView("/admin/user-lookups")}
                  >
                    {t("lookups")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    onClick={() => redirectToView("/admin/user-keys")}
                  >
                    {t("keys")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    onClick={() => redirectToView("/admin/user-ssi")}
                  >
                    {t("ssi")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    onClick={() =>
                      redirectToView("/admin/user-key-selling-rules")
                    }
                  >
                    {t("key-selling-rules")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    onClick={() =>
                      redirectToView("/admin/admin-key-transactions")
                    }
                  >
                    {t("key-transactions")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    onClick={() => redirectToView("/admin/admin-transactions")}
                  >
                    {t("transactions")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    onClick={() =>
                      redirectToView("/admin/admin-external-storage")
                    }
                  >
                    {t("external-storage")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    onClick={() => redirectToView("/admin/user-audit-logs")}
                  >
                    {t("audit-logs")}
                  </Button>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>{"Add metadata"}</h4>
        </DialogTitle>
        <form onSubmit={handleAddMetadata}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("name")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="metadata-name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalName,
                    onChange: (e) => {
                      setModalName(e.target.value);
                    },
                  }}
                  helpText={t("name")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("value")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="metadata-value"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalValue,
                    onChange: (e) => {
                      setModalValue(e.target.value);
                    },
                  }}
                  helpText={t("value")}
                />
              </GridItem>
            </GridContainer>

            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} lg={8}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <PermIdentity style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("edit-profile")}</h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => {
                    handleRefresh();
                  }}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleUpdateProfileSubmit}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={t("name")}
                      id="name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setName(e.target.value);
                        },
                        value: name,
                      }}
                      error={nameError.length > 0}
                      helperText={nameError}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    {ApiService.profileListOrganizations ? (
                      <FormControl
                        fullWidth
                        className={formClasses.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="company-name"
                          className={formClasses.selectLabel}
                        >
                          {t("company-name")}
                        </InputLabel>
                        <Select
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                          MenuProps={{ className: formClasses.selectMenu }}
                          classes={{ select: formClasses.select }}
                          inputProps={{
                            name: "company-name",
                            id: "company-name",
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: formClasses.selectMenuItem,
                            }}
                          >
                            {t("company-name")}
                          </MenuItem>
                          {query.superuser ? (
                            Object.values(availableOrganizations).map(
                              (organizationName) => {
                                return (
                                  <MenuItem
                                    key={organizationName}
                                    classes={{
                                      root: formClasses.selectMenuItem,
                                      selected:
                                        formClasses.selectMenuItemSelectedMultiple,
                                    }}
                                    value={organizationName}
                                  >
                                    {organizationName}
                                  </MenuItem>
                                );
                              }
                            )
                          ) : (
                            <MenuItem
                              key={companyName}
                              classes={{
                                root: formClasses.selectMenuItem,
                                selected:
                                  formClasses.selectMenuItemSelectedMultiple,
                              }}
                              value={companyName}
                            >
                              {companyName}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    ) : (
                      <CustomInput
                        labelText={t("company-name")}
                        id="company-name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => {
                            setCompanyName(e.target.value);
                          },
                          value: companyName,
                        }}
                      />
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={t("job-title")}
                      id="company-position"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setCompanyPosition(e.target.value);
                        },
                        value: companyPosition,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={query.superuser ? 6 : 12}>
                    <CustomInput
                      labelText={t("paymail-handle")}
                      id="paymail-handle"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setPaymailHandle(e.target.value);
                        },
                        value: paymailHandle,
                      }}
                    />
                  </GridItem>
                  {query.superuser && (
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText={t("email")}
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => {
                            setEmail(e.target.value);
                          },
                          value: email,
                        }}
                      />
                    </GridItem>
                  )}
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={t("currency")}
                      id="currency"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setCurrency(e.target.value);
                        },
                        value: currency,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={t("person-identifier")}
                      id="personIdentifier"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setPersonIdentifier(e.target.value);
                        },
                        value: personIdentifier,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={t("current-family-name")}
                      id="currentFamilyName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setCurrentFamilyName(e.target.value);
                        },
                        value: currentFamilyName,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={t("current-given-name")}
                      id="currentGivenName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setCurrentGivenName(e.target.value);
                        },
                        value: currentGivenName,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText={t("birth-name")}
                      id="birthName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setBirthName(e.target.value);
                        },
                        value: birthName,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={formClasses.checkboxAndRadio}>
                      <FormControl fullWidth style={{ paddingTop: "15px" }}>
                        <Datetime
                          timeFormat={false}
                          inputProps={{ placeholder: t("date-of-birth") }}
                          value={
                            dateOfBirth !== null && dateOfBirth.length > 0
                              ? moment(dateOfBirth).format("DD-MM-YYYY")
                              : null
                          }
                          onChange={(e) =>
                            setDateOfBirth(moment(e).toISOString())
                          }
                        />
                      </FormControl>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText={t("place-of-birth")}
                      id="placeOfBirth"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setPlaceOfBirth(e.target.value);
                        },
                        value: placeOfBirth,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText={t("current-address")}
                      id="currentAddress"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setCurrentAddress(e.target.value);
                        },
                        value: currentAddress,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText={t("city")}
                      id="city"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setCity(e.target.value);
                        },
                        value: city,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText={t("postal-code")}
                      id="postalCode"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setPostalCode(e.target.value);
                        },
                        value: postalCode,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={query.superuser ? 4 : 8}>
                    <CustomInput
                      labelText={t("country")}
                      id="country"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setCountry(e.target.value);
                        },
                        value: country,
                      }}
                    />
                  </GridItem>
                  {query.superuser && (
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText={t("vat-rate")}
                        id="vat-rate"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => {
                            setVatRate(e.target.value);
                          },
                          value: vatRate,
                        }}
                      />
                    </GridItem>
                  )}
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText={t("phone-number")}
                      id="phoneNumber"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setPhoneNumber(e.target.value);
                        },
                        value: phoneNumber,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <FormControl
                      fullWidth
                      className={formClasses.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="gender"
                        className={formClasses.selectLabel}
                      >
                        {t("select-gender")}
                      </InputLabel>
                      <Select
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        MenuProps={{ className: formClasses.selectMenu }}
                        classes={{ select: formClasses.select }}
                        inputProps={{
                          name: "gender",
                          id: "gender",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: formClasses.selectMenuItem,
                          }}
                        >
                          {t("gender")}
                        </MenuItem>
                        {genderEnum.map((genderType) => {
                          return (
                            <MenuItem
                              key={genderType.value}
                              classes={{
                                root: formClasses.selectMenuItem,
                                selected:
                                  formClasses.selectMenuItemSelectedMultiple,
                              }}
                              value={genderType.value}
                            >
                              {genderType.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8}>
                    <CustomInput
                      labelText={t("gov-id")}
                      id="govID"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setGovID(e.target.value);
                        },
                        value: govID,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} style={{ float: "left" }}>
                    <div className={formClasses.checkboxAndRadio}>
                      <FormControlLabel
                        style={{ float: "left" }}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => {
                              const currentIndex = magicLinkLogin.indexOf(1);
                              const newChecked = [...magicLinkLogin];
                              if (currentIndex === -1) {
                                newChecked.push(1);
                              } else {
                                newChecked.splice(currentIndex, 1);
                              }
                              setMagicLinkLogin(newChecked);
                            }}
                            checkedIcon={
                              <Check className={formClasses.checkedIcon} />
                            }
                            icon={
                              <Check className={formClasses.uncheckedIcon} />
                            }
                            classes={{
                              checked: formClasses.checked,
                              root: formClasses.checkRoot,
                            }}
                            checked={magicLinkLogin.length > 0}
                          />
                        }
                        classes={{
                          label: formClasses.label,
                          root: formClasses.labelRoot,
                        }}
                        label={t("magic-link-login")}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={t("password")}
                      id="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        autoComplete: "off",
                        onChange: (e) => {
                          setPassword(e.target.value);
                        },
                        value: password,
                      }}
                      error={passwordError.length > 0}
                      helperText={passwordError}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={t("confirm-password")}
                      id="confirm-password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        autoComplete: "off",
                        onChange: (e) => {
                          setConfirmPassword(e.target.value);
                        },
                        value: confirmPassword,
                      }}
                      error={confirmPasswordError.length > 0}
                      helperText={confirmPasswordError}
                    />
                  </GridItem>
                </GridContainer>
                {query.superuser && (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText={t("token-expire-time")}
                        id="tokenExpireTime"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => {
                            setTokenExpireTime(e.target.value);
                          },
                          value: tokenExpireTime,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                )}
                <Button
                  color="primary"
                  type="submit"
                  className={classes.updateProfileButton}
                >
                  {t("edit")}
                </Button>
              </form>
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={4}>
          <Card profile>
            <CardBody profile>
              <UserImageUpload
                userId={userProfile._id}
                update={true}
                encrypt={true}
                resize={true}
                extractImageText={false}
                resizeWidth={400}
                resizeHeight={400}
                documentType={ApiService.documentTypes.avatar}
                avatar={true}
              />
              <h6 className={classes.cardCategory}>{userProfile.name}</h6>
              {userProfile.organizations.length > 0 && (
                <h6 className={classes.cardCategory}>
                  {userProfile.organizations[0].positions.length > 0
                    ? [
                        userProfile.organizations[0].positions[0].name,
                        userProfile.organizations[0].name,
                      ]
                        .filter((entry) => entry.length > 0)
                        .join(t("at"))
                    : userProfile.organizations[0].name}
                </h6>
              )}
              <h4 className={classes.cardTitle}>{userProfile.email}</h4>

              <GridContainer
                className={`${classes.description} ${classes.cardMargin}`}
              >
                <GridItem xs={12} sm={4} className={classes.alignLeft}>
                  {t("paymail")}
                </GridItem>
                <GridItem xs={12} sm={8} className={classes.alignRight}>
                  {userProfile.paymail}
                </GridItem>
              </GridContainer>

              {userProfile.paymailHandles.length > 0 && (
                <GridContainer
                  className={`${classes.description} ${classes.cardMargin}`}
                >
                  <GridItem xs={12} sm={4} className={classes.alignLeft}>
                    {t("paymail-handle")}
                  </GridItem>
                  <GridItem xs={12} sm={8} className={classes.alignRight}>
                    {`${userProfile.paymailHandles[0].handle} (${userProfile.paymailHandles[0].type})`}
                  </GridItem>
                </GridContainer>
              )}

              {userProfile.organizations.length > 0 && (
                <React.Fragment>
                  <GridContainer
                    className={`${classes.description} ${classes.cardMargin}`}
                  >
                    <GridItem xs={12} sm={4} className={classes.alignLeft}>
                      {t("organization-paymail")}
                    </GridItem>
                    <GridItem xs={12} sm={8} className={classes.alignRight}>
                      {userProfile.organizations[0].paymail}
                    </GridItem>
                  </GridContainer>
                  <GridContainer
                    className={`${classes.description} ${classes.cardMargin}`}
                  >
                    <GridItem xs={12} sm={4} className={classes.alignLeft}>
                      {t("user-organization-paymail")}
                    </GridItem>
                    <GridItem xs={12} sm={8} className={classes.alignRight}>
                      {userProfile.organizations[0].userPaymail}
                    </GridItem>
                  </GridContainer>
                </React.Fragment>
              )}

              <GridContainer
                className={`${classes.description} ${classes.cardMargin}`}
              >
                <GridItem xs={12} sm={4} className={classes.alignLeft}>
                  {t("created-at")}
                </GridItem>
                <GridItem xs={12} sm={8} className={classes.alignRight}>
                  {ApiService.formatDateTime(userProfile.createdAt)}
                </GridItem>
              </GridContainer>

              <GridContainer
                className={`${classes.description} ${classes.cardMarginBottom} ${classes.marginIn}`}
              >
                <GridItem xs={12} sm={4} className={classes.alignLeft}>
                  {t("updated-at")}
                </GridItem>
                <GridItem xs={12} sm={8} className={classes.alignRight}>
                  {ApiService.formatDateTime(userProfile.updatedAt)}
                </GridItem>
              </GridContainer>

              <GridContainer
                className={`${classes.description} ${classes.cardMargin}`}
              >
                <GridItem xs={12} sm={4} className={classes.alignLeft}>
                  {t("credits")}
                </GridItem>
                <GridItem xs={12} sm={8} className={classes.alignRight}>
                  {userProfile.credits}
                </GridItem>
              </GridContainer>

              {userProfile.trialCredits && (
                <GridContainer className={`${classes.description}`}>
                  <GridItem xs={12} sm={4} className={classes.alignLeft}>
                    {t("trial-credits")}
                  </GridItem>
                  <GridItem xs={12} sm={8} className={classes.alignRight}>
                    {userProfile.trialCredits}
                  </GridItem>
                </GridContainer>
              )}

              {userProfile.trial && (
                <GridContainer
                  className={`${classes.description} ${classes.cardMargin}`}
                >
                  <GridItem xs={12} sm={4} className={classes.alignLeft}>
                    {t("trial-account")}
                  </GridItem>
                  <GridItem xs={12} sm={8} className={classes.alignRight}>
                    {userProfile.trial ? t("true") : t("false")}
                  </GridItem>
                </GridContainer>
              )}

              <GridContainer
                className={`${classes.description} ${classes.cardMarginBottom} ${classes.marginIn}`}
              >
                <GridItem xs={12} sm={4} className={classes.alignLeft}>
                  {t("validated-email-0")}
                </GridItem>
                <GridItem xs={12} sm={8} className={classes.alignRight}>
                  {userProfile.validatedEmail ? t("true") : t("false")}
                </GridItem>
              </GridContainer>

              {ApiService.validateUserDocuments &&
                userProfile.validatedDocuments && (
                  <GridContainer
                    className={`${classes.description} ${classes.cardMarginBottom} ${classes.marginIn}`}
                  >
                    <GridItem xs={12} sm={4} className={classes.alignLeft}>
                      {t("validated-documents-0")}
                    </GridItem>
                    <GridItem xs={12} sm={8} className={classes.alignRight}>
                      {userProfile.validatedDocuments ? t("true") : t("false")}
                    </GridItem>
                  </GridContainer>
                )}
              {ApiService.validateUserDocuments &&
                !userProfile.validatedDocuments && (
                  <GridContainer
                    className={`${classes.description} ${classes.cardMarginBottom} ${classes.marginIn}`}
                  >
                    <GridItem xs={12} sm={4} className={classes.alignLeft}>
                      {t("requested-validation")}
                    </GridItem>
                    <GridItem xs={12} sm={8} className={classes.alignRight}>
                      {userProfile.requestDocumentsValidation
                        ? t("true")
                        : t("false")}
                    </GridItem>
                  </GridContainer>
                )}

              {query.ownAccount && (
                <Button color="rose" round onClick={handleLock}>
                  {t("lock")}
                </Button>
              )}
              {!userProfile.validatedEmail && (
                <Button color="rose" round onClick={handleSendValidationEmail}>
                  {t("resend-validation-link")}
                </Button>
              )}
              {ApiService.validateUserDocuments &&
                userProfile.validatedEmail &&
                !userProfile.validatedDocuments &&
                !userProfile.requestDocumentsValidation && (
                  <Button
                    color="rose"
                    round
                    onClick={handleRequestDocumentsValidation}
                  >
                    {t("request-documents-validation")}
                  </Button>
                )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <NavPills
            color="info"
            alignCenter
            tabs={[
              query.superuser
                ? {
                    tabButton: t("assigned-roles"),
                    tabIcon: GroupWorkIcon,
                    tabContent: (
                      <Card>
                        <CardHeader
                          color="turq"
                          icon
                          style={{ display: "inline-flex" }}
                        >
                          <CardIcon color="turq">
                            <GroupWorkIcon style={{ color: roseColor[0] }} />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>
                            {t("assigned-roles")}
                          </h4>
                          <div style={{ marginLeft: "auto" }}>
                            <Button
                              simple
                              color="rose"
                              className="remove"
                              onClick={() => handleRefresh()}
                              style={{ marginTop: "28px" }}
                            >
                              {t("refresh")}
                            </Button>
                            <Button
                              color="primary"
                              style={{ marginTop: "20px" }}
                              round
                              onClick={() => {
                                handleUpdateRoles();
                              }}
                            >
                              {t("update")}
                            </Button>
                          </div>
                        </CardHeader>
                        <CardBody>
                          <ReactTable
                            data={handleRolesData(roles)}
                            filterable
                            columns={[
                              {
                                Header: t("name"),
                                accessor: "name",
                              },
                              {
                                Header: t("type"),
                                accessor: "type",
                              },
                              {
                                Header: t("status"),
                                accessor: "resolvingType",
                              },
                              {
                                Header: t("actions"),
                                accessor: "actions",
                                sortable: false,
                                filterable: false,
                              },
                            ]}
                            defaultPageSize={5}
                            showPaginationTop={false}
                            showPaginationBottom
                            className="-striped -highlight"
                          />
                        </CardBody>
                      </Card>
                    ),
                  }
                : {
                    tabButton: t("assigned-roles"),
                    tabIcon: GroupWorkIcon,
                    tabContent: (
                      <Card>
                        <CardHeader
                          color="turq"
                          icon
                          style={{ display: "inline-flex" }}
                        >
                          <CardIcon color="turq">
                            <GroupWorkIcon style={{ color: roseColor[0] }} />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>
                            {t("assigned-roles")}
                          </h4>
                          <div style={{ marginLeft: "auto" }}>
                            <Button
                              simple
                              color="rose"
                              className="remove"
                              onClick={() => handleRefresh()}
                              style={{ marginTop: "28px" }}
                            >
                              {t("refresh")}
                            </Button>
                          </div>
                        </CardHeader>
                        <CardBody>
                          <ReactTable
                            data={userProfile.assignedRoles}
                            filterable
                            columns={[
                              {
                                Header: t("name"),
                                accessor: "name",
                              },
                              {
                                Header: t("type"),
                                accessor: "type",
                              },
                            ]}
                            defaultPageSize={5}
                            showPaginationTop={false}
                            showPaginationBottom
                            className="-striped -highlight"
                          />
                        </CardBody>
                      </Card>
                    ),
                  },
              {
                tabButton: t("capabilities"),
                tabIcon: VerifiedUserIcon,
                tabContent: (
                  <Card>
                    <CardHeader
                      color="turq"
                      icon
                      style={{ display: "inline-flex" }}
                    >
                      <CardIcon color="turq">
                        <VerifiedUserIcon style={{ color: roseColor[0] }} />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>
                        {t("capabilities")}
                      </h4>
                      <div style={{ marginLeft: "auto" }}>
                        <Button
                          simple
                          color="rose"
                          className="remove"
                          onClick={() => handleRefresh()}
                          style={{ marginTop: "28px" }}
                        >
                          {t("refresh")}
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <ReactTable
                        data={userProfile.capabilities}
                        filterable
                        columns={[
                          {
                            Header: t("name"),
                            accessor: "name",
                          },
                          {
                            Header: t("type"),
                            accessor: "type",
                          },
                        ]}
                        defaultPageSize={5}
                        showPaginationTop={false}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    </CardBody>
                  </Card>
                ),
              },
              {
                tabButton: t("notifications"),
                tabIcon: NotificationsIcon,
                tabContent: (
                  <Card>
                    <CardHeader
                      color="turq"
                      icon
                      style={{ display: "inline-flex" }}
                    >
                      <CardIcon color="turq">
                        <GroupWorkIcon style={{ color: roseColor[0] }} />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>
                        {t("notifications")}
                      </h4>
                      <div style={{ marginLeft: "auto" }}>
                        <Button
                          simple
                          color="rose"
                          className="remove"
                          onClick={() => handleRefresh()}
                          style={{ marginTop: "28px" }}
                        >
                          {t("refresh")}
                        </Button>
                        <Button
                          color="primary"
                          style={{ marginTop: "20px" }}
                          round
                          onClick={() => {
                            handleUpdateNotifications();
                          }}
                        >
                          {t("update")}
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <ReactTable
                        data={handleNotifications(notifications)}
                        filterable
                        columns={[
                          {
                            Header: t("name"),
                            accessor: "name",
                          },
                          {
                            Header: t("service"),
                            accessor: "serviceType",
                          },
                          {
                            Header: t("status"),
                            accessor: "type",
                          },
                          {
                            Header: t("description"),
                            accessor: "description",
                          },
                          {
                            Header: t("actions"),
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={5}
                        showPaginationTop={false}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    </CardBody>
                  </Card>
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem
          xs={12}
          sm={ApiService.validateUserDocuments ? 4 : 12}
          md={ApiService.validateUserDocuments ? 4 : 12}
        >
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <EditIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("signature")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer justifyContent="center">
                <UserImageUpload
                  userId={userProfile._id}
                  update={true}
                  encrypt={true}
                  resize={true}
                  extractImageText={true}
                  resizeWidth={300}
                  resizeHeight={150}
                  documentType={ApiService.documentTypes.signature}
                  avatar={false}
                />
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        {ApiService.validateUserDocuments && (
          <React.Fragment>
            <GridItem xs={12} sm={4} md={4}>
              <Card>
                <CardHeader color="turq" icon>
                  <CardIcon color="turq">
                    <DescriptionIcon style={{ color: roseColor[0] }} />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    {t("user-id-document")}
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer justifyContent="center">
                    <UserDocumentUpload
                      userId={userProfile._id}
                      update={
                        query.superuser || !userProfile.validatedDocuments
                      }
                      encrypt={true}
                      documentType={ApiService.documentTypes.userId}
                    />
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card>
                <CardHeader color="turq" icon>
                  <CardIcon color="turq">
                    <DescriptionIcon style={{ color: roseColor[0] }} />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    {t("company-documents")}
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer justifyContent="center">
                    <UserDocumentUpload
                      userId={userProfile._id}
                      update={
                        query.superuser || !userProfile.validatedDocuments
                      }
                      encrypt={true}
                      documentType={ApiService.documentTypes.companyDocuments}
                    />
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </React.Fragment>
        )}
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <AvTimerIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("sessions")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer
                className={`${classes.description} ${classes.marginIn} ${classes.cardMarginBottom}`}
              >
                <GridItem xs={6} sm={6} md={8}>
                  <h5>{t("active-sessions-0")}</h5>
                </GridItem>
                <GridItem xs={6} sm={6} md={4} style={{ textAlign: "right" }}>
                  <h3 style={{ margin: 0 }}>{userProfile.activeTokens}</h3>
                </GridItem>
              </GridContainer>
              <Button
                color="rose"
                className={classes.updateProfileButton}
                onClick={showLogoutAllSessions}
              >
                {t("log-out-all")}
              </Button>
              {query.ownAccount && (
                <Button
                  color="rose"
                  simple
                  className={classes.updateProfileButton}
                  onClick={handleLogout}
                >
                  {t("log-out")}
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <CreditCard style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("low-credits-email-notifications")}
              </h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleLowCreditsNotificationSubmit}>
                <GridContainer
                  className={`${classes.description} ${classes.marginIn} ${classes.cardMarginBottom}`}
                >
                  <GridItem xs={12}>
                    <ThemeProvider theme={theme}>
                      <div className={classes.block}>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              checked={notifyLowCredits}
                              onChange={(event) => {
                                handleLowCreditsNotificationAction(
                                  event.target.checked,
                                  userProfile
                                );
                              }}
                              value="notifyLowCredits"
                              classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                thumb: classes.switchIcon,
                                track: classes.switchBar,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                          }}
                          label={
                            notifyLowCredits ? t("enabled") : t("disabled")
                          }
                        />
                        {notifyLowCredits && (
                          <GridContainer
                            className={`${classes.description} ${classes.marginIn} ${classes.cardMarginBottom}`}
                          >
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              style={{ textAlign: "right" }}
                            >
                              <CustomInput
                                labelText={t("low-credits-threshold")}
                                id="low-credits-threshold"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) => {
                                    setLowCreditsThreshold(e.target.value);
                                  },
                                  value: lowCreditsThreshold,
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        )}
                      </div>
                    </ThemeProvider>
                  </GridItem>
                </GridContainer>
                {notifyLowCredits && (
                  <Button
                    color="primary"
                    type="submit"
                    className={classes.updateProfileButton}
                  >
                    {t("update")}
                  </Button>
                )}
              </form>
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <HttpsIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("two-factor-authentication")}
              </h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleEnable2FASubmit}>
                <GridContainer
                  className={`${classes.description} ${classes.marginIn} ${classes.cardMarginBottom}`}
                >
                  <GridItem xs={12}>
                    <ThemeProvider theme={theme}>
                      <div className={classes.block}>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              checked={toggle2FA}
                              onChange={(event) => {
                                handle2FAAction(
                                  event.target.checked,
                                  userProfile
                                );
                              }}
                              value="toggle2FA"
                              classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                thumb: classes.switchIcon,
                                track: classes.switchBar,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                          }}
                          label={toggle2FAMessage}
                        />
                        {confirm2FA && (
                          <GridContainer
                            className={`${classes.description} ${classes.marginIn} ${classes.cardMarginBottom}`}
                          >
                            <GridItem xs={12} sm={12} md={6}>
                              <div className={classes.imgContainer}>
                                <img
                                  src={qrCodeSecret2FA}
                                  alt={t("2fa-qr-code-secret")}
                                  className={classes.img}
                                />
                              </div>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              style={{ textAlign: "right" }}
                            >
                              <CustomInput
                                labelText={t("2fa-confirmation-token")}
                                id="2FA-confirmation-token"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) => {
                                    setToken2FA(e.target.value);
                                  },
                                  value: token2FA,
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        )}
                      </div>
                    </ThemeProvider>
                  </GridItem>
                </GridContainer>
                {confirm2FA && (
                  <Button
                    color="primary"
                    type="submit"
                    className={classes.updateProfileButton}
                  >
                    {t("enable")}
                  </Button>
                )}
              </form>
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <LibraryBooksIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{"Metadata"}</h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  round
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {t("add")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={handleReturnMetadata(userProfile.metadata || [])}
                filterable
                columns={[
                  {
                    Header: t("name"),
                    accessor: "name",
                  },
                  {
                    Header: t("value"),
                    accessor: "value",
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={5}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <DeleteOutlineIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("delete-profile-0")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer
                className={`${classes.description} ${classes.marginIn} ${classes.cardMarginBottom}`}
              >
                <GridItem xs={12}>
                  <h5>{t("permanently-delete-profile")}</h5>
                </GridItem>
              </GridContainer>
              <Button
                color="rose"
                simple
                className={classes.updateProfileButton}
                onClick={showDeleteProfile}
              >
                {t("delete")}
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbars
        place="bc"
        color="danger"
        icon={AddAlert}
        message={errorMessage}
        open={errorMessage.length > 0}
        closeNotification={() => setErrorMessage("")}
        close
      />
      <Snackbars
        place="bc"
        color="success"
        icon={AddAlert}
        message={successMessage}
        open={successMessage.length > 0}
        closeNotification={() => setSuccessMessage("")}
        close
      />
      {alert}
      {showDisable2FA && (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("are-you-sure")}
          onConfirm={handleDisable2FA}
          onCancel={() => setShowDisable2FA(false)}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
          cancelBtnCssClass={
            alertClasses.button +
            " " +
            alertClasses.simple +
            " " +
            alertClasses.github
          }
          confirmBtnText={t("confirm")}
          cancelBtnText={t("cancel")}
          showCancel
        >
          {t("disable-two-factor-authentication")}
        </SweetAlert>
      )}
    </div>
  );
};

UserProfile.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default UserProfile;
