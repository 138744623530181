import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import validator from "validator";
import Big from "big.js";
import { v4 as uuidv4 } from "uuid";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";

import NotesIcon from "@material-ui/icons/Notes";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";

/*#############################
  # Modal view dialog classes #
  #############################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyle);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const defaultCurrency = "EUR";
const dialogDetailsInputFields = new Set([]);

const UserKeySellingRules = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
  const [userKeySellingRules, setUserKeySellingRules] = React.useState([]);
  const [formattedUserName, setFormattedUserName] = React.useState("");

  // Modal states
  const [showModal, setShowModal] = React.useState(false);
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("add")
  );

  // Dialog fields
  const [modalKeySellingRuleId, setModalKeySellingRuleId] = React.useState("");
  const [modalInitialKeySellingRuleId, setModalInitialKeySellingRuleId] =
    React.useState("");
  const [modalDeploymentId, setModalDeploymentId] = React.useState("");
  const [modalServiceType, setModalServiceType] = React.useState(
    ApiService.serviceTypes.ubn
  );
  const [modalPrice, setModalPrice] = React.useState("0");
  const [modalCurrency, setModalCurrency] = React.useState(defaultCurrency);
  const [modalKeyFilter, setModalKeyFilter] = React.useState("{}");
  const [modalPaymail, setModalPaymail] = React.useState("");
  const [modalProtected, setModalProtected] = React.useState([]);
  const [modalFlags, setModalFlags] = React.useState("[]");
  const [modalWhitelist, setModalWhitelist] = React.useState("[]");
  const [modalBlacklist, setModalBlacklist] = React.useState("[]");
  const [modalDescription, setModalDescription] = React.useState("");

  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [detailsTitle, setDetailsTitle] = React.useState("");
  const [details, setDetails] = React.useState({});

  const [refreshClicked, setRefreshClicked] = React.useState(false);

  // pagination sorting
  const [count, setCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(ApiService.defaultPageSize);
  const [totalPages, setTotalPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [sortBy, setSortBy] = React.useState("createdAt:desc");

  const [filters, setFilters] = React.useState([]);
  const [filterClicked, setFilterClicked] = React.useState(false);
  const [filterParams, setFilterParams] = React.useState([]);

  const getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    const returnData = await ApiService.loginRequired(signal, false);
    if (returnData.superuser && params.userId) {
      const userProfileData = await ApiService.readUser(params, signal);
      setFormattedUserName(
        ApiService.getFormattedUserName(returnData._id, userProfileData)
      );
      params.userId =
        typeof userProfileData.superuser !== "undefined"
          ? userProfileData._id
          : returnData._id;
    } else {
      params.userId = returnData._id;
    }
    params.superuser = returnData.superuser;
    return params;
  };

  const clearModalState = () => {
    setShowModal(false);
    setShowEditModal(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("add"));
    setModalKeySellingRuleId("");
    setModalInitialKeySellingRuleId("");
    setModalDeploymentId("");
    setModalServiceType(ApiService.serviceTypes.ubn);
    setModalPrice("0");
    setModalCurrency(defaultCurrency);
    setModalKeyFilter("{}");
    setModalPaymail("");
    setModalProtected([]);
    setModalFlags("[]");
    setModalWhitelist("[]");
    setModalBlacklist("[]");
    setModalDescription("");
    setShowDetailsModal(false);
    setDetailsTitle("");
    setDetails({});
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const handleReturnData = (data) => {
    return data.map((elem) => {
      elem.protectedFormatted = elem.protected.toString();
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("view-details")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                handleGetSingleKeySellingRule(elem);
              }}
            >
              <VisibilityIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("edit-key-selling-rule")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                setModalKeySellingRuleId(elem._id);
                setModalInitialKeySellingRuleId(elem._id);
                setModalPaymail(elem.paymail ? elem.paymail : "");
                setModalProtected(elem.protected ? [1] : []);
                setModalPrice(elem.price);
                setModalCurrency(elem.currency);
                setModalDescription(elem.description ? elem.description : "");
                setModalKeyFilter(JSON.stringify(elem.keyFilter));
                setModalWhitelist(JSON.stringify(elem.whitelist));
                setModalBlacklist(JSON.stringify(elem.blacklist));
                setModalFlags(
                  elem.flags
                    ? JSON.stringify(
                        elem.flags.map((entry) => {
                          return {
                            name: entry.name,
                            value: entry.value,
                          };
                        })
                      )
                    : "[]"
                );
                setConfirmModalButtonText(t("edit"));
                setShowEditModal(true);
              }}
            >
              <EditIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("delete-key-selling-rule")}>
            <Button
              justIcon
              color="rose"
              simple
              className="remove"
              onClick={() => {
                handleDeleteUserKeySellingRule(elem);
              }}
            >
              <ClearIcon />
            </Button>
          </Tooltip>
        </div>
      );
      return elem;
    });
  };

  const handleDeleteUserKeySellingRule = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-key-selling-rule")}?`}
        onConfirm={() => {
          deleteSingleKeySellingRule(elem);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${elem._id}`}</b>
        </p>
      </SweetAlert>
    );
  };

  const deleteSingleKeySellingRule = async (keySellingRule) => {
    setAlert(null);
    try {
      const queryParams = await getQueryParams();
      const params = {
        userId: queryParams.userId,
        keySellingRuleId: keySellingRule._id,
      };
      await ApiService.deleteKeySellingRule(params);
      operationResult(true, t("key-selling-rule-successfully-deleted"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const handleGetSingleKeySellingRule = async (elem) => {
    const queryParams = await getQueryParams();
    const params = {
      userId: queryParams.userId,
      keySellingRuleId: elem._id,
    };
    try {
      const responseData = await ApiService.getUserKeySellingRules(params);
      if (responseData.length === 0) {
        throw new Error(t("unable-to-find-user-key-selling-rule"));
      }
      setDetailsTitle(t("key-selling-rule-details"));
      setDetails(responseData[0]);
      setShowDetailsModal(true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddKeySellingRule = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }
    const keySellingRuleId =
      modalKeySellingRuleId.trim().length > 0
        ? modalKeySellingRuleId.trim()
        : undefined;
    const deploymentId = modalDeploymentId.trim();
    const validDeploymentId = deploymentId.length > 0;
    const price = modalPrice.trim();
    const validPrice = validator.isDecimal(price) && new Big(price).gte(0);
    const currency = modalCurrency.trim();
    const paymail =
      modalPaymail.trim().length > 0 ? modalPaymail.trim() : undefined;
    const validPaymail =
      paymail === undefined ? true : validator.isEmail(paymail);
    const description =
      modalDescription.trim().length > 0 ? modalDescription.trim() : undefined;
    let keyFilter;
    let validKeyFilter = true;
    try {
      keyFilter = JSON.parse(modalKeyFilter);
    } catch (e) {
      validKeyFilter = false;
    }
    let whitelist;
    let validWhitelist;
    try {
      whitelist = JSON.parse(modalWhitelist);
      validWhitelist = Array.isArray(whitelist);
    } catch (e) {
      validWhitelist = false;
    }
    let blacklist;
    let validBlacklist;
    try {
      blacklist = JSON.parse(modalBlacklist);
      validBlacklist = Array.isArray(blacklist);
    } catch (e) {
      validBlacklist = false;
    }
    let flags;
    let validFlags;
    try {
      flags = JSON.parse(modalFlags);
      validFlags = Array.isArray(flags);
    } catch (e) {
      validFlags = false;
    }
    const checksPassed =
      validDeploymentId &&
      validPrice &&
      validPaymail &&
      validKeyFilter &&
      validWhitelist &&
      validBlacklist &&
      validFlags;
    if (!checksPassed) {
      const messageContent = [];
      if (!validDeploymentId) {
        messageContent.push(t("invalid-deployment-id"));
      }
      if (!validPrice) {
        messageContent.push(t("invalid-price"));
      }
      if (!validPaymail) {
        messageContent.push(t("invalid-paymail"));
      }
      if (!validKeyFilter) {
        messageContent.push(t("invalid-key-filter"));
      }
      if (!validWhitelist) {
        messageContent.push(t("invalid-whitelist"));
      }
      if (!validBlacklist) {
        messageContent.push(t("invalid-blacklist"));
      }
      if (!validFlags) {
        messageContent.push(t("invalid-flags"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }
    const queryParams = await getQueryParams();
    const params = {
      userId: queryParams.userId,
      paymail,
      keySellingRuleId,
      protected: modalProtected.length > 0,
      serviceType: modalServiceType,
      deploymentId,
      whitelist,
      blacklist,
      price,
      currency,
      keyFilter,
      description,
      flags,
    };

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("adding"));

    try {
      await ApiService.addKeySellingRule(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("key-selling-rule-successfully-added"));
  };

  const handleEditKeySellingRule = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }
    const patchKeySellingRuleId =
      modalKeySellingRuleId.trim().length > 0 &&
      modalKeySellingRuleId.trim() !== modalInitialKeySellingRuleId
        ? modalKeySellingRuleId.trim()
        : undefined;
    const price = modalPrice.trim();
    const validPrice = validator.isDecimal(price) && new Big(price).gte(0);
    const currency = modalCurrency.trim();
    const paymail =
      modalPaymail.trim().length > 0 ? modalPaymail.trim() : undefined;
    const validPaymail =
      paymail === undefined ? true : validator.isEmail(paymail);
    const description =
      modalDescription.trim().length > 0 ? modalDescription.trim() : undefined;
    let keyFilter;
    let validKeyFilter = true;
    try {
      keyFilter = JSON.parse(modalKeyFilter);
    } catch (e) {
      validKeyFilter = false;
    }
    let whitelist;
    let validWhitelist;
    try {
      whitelist = JSON.parse(modalWhitelist);
      validWhitelist = Array.isArray(whitelist);
    } catch (e) {
      validWhitelist = false;
    }
    let blacklist;
    let validBlacklist;
    try {
      blacklist = JSON.parse(modalBlacklist);
      validBlacklist = Array.isArray(blacklist);
    } catch (e) {
      validBlacklist = false;
    }
    let flags;
    let validFlags;
    try {
      flags = JSON.parse(modalFlags);
      validFlags = Array.isArray(flags);
    } catch (e) {
      validFlags = false;
    }
    const checksPassed =
      validPrice &&
      validPaymail &&
      validKeyFilter &&
      validWhitelist &&
      validBlacklist &&
      validFlags;
    if (!checksPassed) {
      const messageContent = [];
      if (!validPrice) {
        messageContent.push(t("invalid-price"));
      }
      if (!validPaymail) {
        messageContent.push(t("invalid-paymail"));
      }
      if (!validKeyFilter) {
        messageContent.push(t("invalid-key-filter"));
      }
      if (!validWhitelist) {
        messageContent.push(t("invalid-whitelist"));
      }
      if (!validBlacklist) {
        messageContent.push(t("invalid-blacklist"));
      }
      if (!validFlags) {
        messageContent.push(t("invalid-flags"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }
    const queryParams = await getQueryParams();
    const params = {
      userId: queryParams.userId,
      keySellingRuleId: modalInitialKeySellingRuleId,
      patchKeySellingRuleId,
      paymail,
      protected: modalProtected.length > 0,
      whitelist,
      blacklist,
      price,
      currency,
      keyFilter,
      description,
      flags,
    };

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("processing"));

    try {
      await ApiService.updateKeySellingRule(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("key-selling-rule-successfully-updated"));
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const handleRefresh = () => {
    setFilters([]);
    setFilterParams([]);
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId =
          typeof userProfileData.superuser !== "undefined"
            ? userProfileData._id
            : returnData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;
      return params;
    };

    const getUserKeySellingRules = async (signal = undefined) => {
      try {
        const queryParams = await getQueryParams(signal);
        const params = {
          userId: queryParams.userId,
          sortBy: sortBy,
          fromEntry: currentPage * pageSize,
          numberOfResults: pageSize,
        };

        if (filterParams.length > 0) {
          const filteredObj = {};
          filterParams.map((filter) => {
            const filteredField = filter.id.replace(/Formatted$/, "");
            if (ApiService.exactMatch.includes(filter.id)) {
              return (filteredObj[filteredField] =
                filter.id === "fileSize"
                  ? parseInt(filter.value)
                  : filter.value);
            } else {
              return (filteredObj[filteredField] = {
                $regex: `^${filter.value}`,
              });
            }
          });
          params.filters = JSON.stringify(filteredObj);
        }
        const responseData = await ApiService.getUserKeySellingRules(
          params,
          signal
        );
        setUserKeySellingRules(responseData);

        delete params.sortBy;
        delete params.fromEntry;
        delete params.numberOfResults;
        params.count = true;
        const { count: userKeySellingRulesCount } =
          await ApiService.getUserKeySellingRules(params, signal);
        setCount(userKeySellingRulesCount);
      } catch (e) {
        console.error(e);
      } finally {
        setFilterClicked(false);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await getUserKeySellingRules(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [
    props.location.search,
    refreshClicked,
    currentPage,
    pageSize,
    sortBy,
    filterParams,
  ]);

  React.useEffect(() => {
    if (filterClicked && filters.length > 0) {
      setFilterParams(filters);
    }
  }, [filters, filterClicked]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && count > 0) {
      setTotalPages(Math.ceil(count / pageSize));
    }

    return () => {
      isMounted = false;
    };
  }, [count, pageSize]);

  const handlePageChange = async (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePageSizeChange = async (newPageSize, pageIndex) => {
    const total = Math.ceil(count / newPageSize);
    setTotalPages(total);

    let currentIndex;
    if (pageSize > newPageSize) {
      currentIndex = Math.ceil(pageIndex / (pageSize / newPageSize));
    } else {
      currentIndex = Math.ceil((pageSize / newPageSize) * pageIndex);
    }
    setCurrentPage(currentIndex);

    setPageSize(newPageSize);
  };

  const handleSortChange = (newSorted) => {
    setSortBy(
      `${newSorted[0].id.replace(/Formatted$/, "")}:${
        newSorted[0].desc === true ? "desc" : "asc"
      }`
    );
  };

  const handleFilterColumn = (filtered, column) => {
    setFilters(filtered);
  };

  const handleFilterClicked = () => {
    setFilterClicked(true);
  };

  const handleExportData = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("export-data")}?`}
        onConfirm={() => {
          exportData();
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      ></SweetAlert>
    );
  };

  const exportData = async () => {
    try {
      const queryParams = await getQueryParams();
      const fromEntry = currentPage * pageSize;
      const params = {
        userId: queryParams.userId,
        sortBy: sortBy,
        fromEntry,
        numberOfResults: pageSize,
        exportJson: "true",
      };

      if (filterParams.length > 0) {
        const filteredObj = {};
        filterParams.map((filter) => {
          const filteredField = filter.id.replace(/Formatted$/, "");
          if (ApiService.exactMatch.includes(filter.id)) {
            return (filteredObj[filteredField] =
              filter.id === "fileSize" ? parseInt(filter.value) : filter.value);
          } else {
            return (filteredObj[filteredField] = {
              $regex: `^${filter.value}`,
            });
          }
        });

        params.filters = JSON.stringify(filteredObj);
      }
      const responseData = await ApiService.downloadUserKeySellingRules(params);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = `${t("rules")}_${fromEntry}_${pageSize}.json`.toLowerCase();
      a.click();
      setAlert(null);
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showDetailsModal}
        transition={Transition}
        keepMounted
        scroll="body"
        fullWidth
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowDetailsModal(false);
            setDetailsTitle("");
            setDetails({});
          }
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>{detailsTitle}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            {Object.entries(details).map(([fieldName, fieldValue]) => {
              let data;
              if (typeof fieldValue === "object") {
                data = (
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    {JSON.stringify(fieldValue, undefined, 2)}
                  </div>
                );
              } else {
                if (dialogDetailsInputFields.has(fieldName)) {
                  data = (
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: fieldValue.toString(),
                      }}
                    />
                  );
                } else {
                  data = (
                    <React.Fragment>{fieldValue.toString()}</React.Fragment>
                  );
                }
              }
              return (
                <React.Fragment key={uuidv4()}>
                  <GridItem xs={12} sm={6} md={4} style={{ fontWeight: "400" }}>
                    {dialogDetailsInputFields.has(fieldName) ? (
                      <FormLabel
                        className={formClasses.labelHorizontal}
                        style={{ float: "left" }}
                      >
                        {fieldName}
                      </FormLabel>
                    ) : (
                      fieldName
                    )}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={6}
                    md={8}
                    style={{ wordBreak: "break-all" }}
                  >
                    {data}
                  </GridItem>
                </React.Fragment>
              );
            })}
          </GridContainer>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button
            simple
            color="rose"
            onClick={() => {
              setShowDetailsModal(false);
              setDetailsTitle("");
              setDetails({});
            }}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("add-key-selling-rule")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleAddKeySellingRule}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("key-selling-rule-id")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="key-selling-rule-id"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalKeySellingRuleId,
                    onChange: (e) => {
                      setModalKeySellingRuleId(e.target.value);
                    },
                  }}
                  helpText={t("key-selling-rule-id")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("deployment-id")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="deployment-id"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalDeploymentId,
                    onChange: (e) => {
                      setModalDeploymentId(e.target.value);
                    },
                  }}
                  helpText={t("deployment-id")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("service")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="service-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalServiceType}
                    onChange={(e) => {
                      setModalServiceType(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "serviceType",
                      id: "service-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("service")}
                    </MenuItem>
                    {Object.values(ApiService.serviceTypes).map(
                      (serviceType) => {
                        return (
                          <MenuItem
                            key={serviceType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={serviceType}
                          >
                            {serviceType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("protected")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6} style={{ float: "left" }}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => {
                          const currentIndex = modalProtected.indexOf(1);
                          const newChecked = [...modalProtected];
                          if (currentIndex === -1) {
                            newChecked.push(1);
                          } else {
                            newChecked.splice(currentIndex, 1);
                          }
                          setModalProtected(newChecked);
                        }}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot,
                        }}
                        checked={modalProtected.length > 0}
                        disabled={showModalProgress}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot,
                    }}
                    label={t("protected")}
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("price")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="price"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalPrice,
                    onChange: (e) => {
                      setModalPrice(e.target.value);
                    },
                  }}
                  helpText={t("price")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("currency")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="currency"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalCurrency,
                    onChange: (e) => {
                      setModalCurrency(e.target.value);
                    },
                  }}
                  helpText={t("currency")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("paymail")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="paymail"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalPaymail,
                    onChange: (e) => {
                      setModalPaymail(e.target.value);
                    },
                  }}
                  helpText={t("paymail")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("description")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="description"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalDescription,
                    onChange: (e) => {
                      setModalDescription(e.target.value);
                    },
                  }}
                  helpText={t("description")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("key-filter")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="key-filter"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalKeyFilter,
                    onChange: (e) => {
                      setModalKeyFilter(e.target.value);
                    },
                  }}
                  helpText={t("key-filter")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("whitelist")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="whitelist"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalWhitelist,
                    onChange: (e) => {
                      setModalWhitelist(e.target.value);
                    },
                  }}
                  helpText={t("whitelist")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("blacklist")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="flags"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalBlacklist,
                    onChange: (e) => {
                      setModalBlacklist(e.target.value);
                    },
                  }}
                  helpText={t("blacklist")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("flags")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="flags"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalFlags,
                    onChange: (e) => {
                      setModalFlags(e.target.value);
                    },
                  }}
                  helpText={t("flags")}
                />
              </GridItem>
            </GridContainer>

            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showEditModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("edit-key-selling-rule")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleEditKeySellingRule}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("key-selling-rule-id")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="key-selling-rule-id"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalKeySellingRuleId,
                    onChange: (e) => {
                      setModalKeySellingRuleId(e.target.value);
                    },
                  }}
                  helpText={t("key-selling-rule-id")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("protected")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6} style={{ float: "left" }}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => {
                          const currentIndex = modalProtected.indexOf(1);
                          const newChecked = [...modalProtected];
                          if (currentIndex === -1) {
                            newChecked.push(1);
                          } else {
                            newChecked.splice(currentIndex, 1);
                          }
                          setModalProtected(newChecked);
                        }}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot,
                        }}
                        checked={modalProtected.length > 0}
                        disabled={showModalProgress}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot,
                    }}
                    label={t("protected")}
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("price")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="price"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalPrice,
                    onChange: (e) => {
                      setModalPrice(e.target.value);
                    },
                  }}
                  helpText={t("price")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("currency")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="currency"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalCurrency,
                    onChange: (e) => {
                      setModalCurrency(e.target.value);
                    },
                  }}
                  helpText={t("currency")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("paymail")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="paymail"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalPaymail,
                    onChange: (e) => {
                      setModalPaymail(e.target.value);
                    },
                  }}
                  helpText={t("paymail")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("description")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="description"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalDescription,
                    onChange: (e) => {
                      setModalDescription(e.target.value);
                    },
                  }}
                  helpText={t("description")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("key-filter")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="key-filter"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalKeyFilter,
                    onChange: (e) => {
                      setModalKeyFilter(e.target.value);
                    },
                  }}
                  helpText={t("key-filter")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("whitelist")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="whitelist"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalWhitelist,
                    onChange: (e) => {
                      setModalWhitelist(e.target.value);
                    },
                  }}
                  helpText={t("whitelist")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("blacklist")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="flags"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalBlacklist,
                    onChange: (e) => {
                      setModalBlacklist(e.target.value);
                    },
                  }}
                  helpText={t("blacklist")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("flags")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="flags"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalFlags,
                    onChange: (e) => {
                      setModalFlags(e.target.value);
                    },
                  }}
                  helpText={t("flags")}
                />
              </GridItem>
            </GridContainer>

            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <NotesIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("key-selling-rules")} {formattedUserName}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => handleRefresh()}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => {
                    handleExportData();
                  }}
                  style={{ marginTop: "28px" }}
                >
                  {t("export")}
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  round
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {t("add")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              {filters.length > 0 && (
                <React.Fragment>
                  <Button
                    color="primary"
                    round
                    className="remove"
                    onClick={() => {
                      handleFilterClicked();
                    }}
                  >
                    {t("filter")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    className="remove"
                    onClick={() => {
                      handleRefresh();
                    }}
                  >
                    {t("clear-filters")}
                  </Button>
                </React.Fragment>
              )}
              <ReactTable
                data={handleReturnData(userKeySellingRules)}
                sortable={true}
                multiSort={false}
                filterable={true}
                filtered={filters}
                columns={[
                  {
                    Header: t("key-selling-rule-id"),
                    accessor: "_id",
                  },
                  {
                    Header: t("service"),
                    accessor: "service",
                  },
                  {
                    Header: t("deploy-id"),
                    accessor: "deploymentId",
                  },
                  {
                    Header: t("price"),
                    accessor: "price",
                  },
                  {
                    Header: t("currency"),
                    accessor: "currency",
                  },
                  {
                    Header: t("paymail"),
                    accessor: "paymail",
                  },
                  {
                    Header: t("protected"),
                    accessor: "protectedFormatted",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
                manual
                pages={totalPages}
                page={currentPage}
                pageSize={pageSize}
                onPageChange={(pageIndex) => handlePageChange(pageIndex)}
                onPageSizeChange={(pageSize, pageIndex) =>
                  handlePageSizeChange(pageSize, pageIndex)
                }
                onSortedChange={(newSorted, column, shiftKey) =>
                  handleSortChange(newSorted, column, shiftKey)
                }
                onFilteredChange={(filtered, column) =>
                  handleFilterColumn(filtered, column)
                }
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
};

UserKeySellingRules.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default UserKeySellingRules;
