import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import Business from "@material-ui/icons/Business";
import Tooltip from "@material-ui/core/Tooltip";

import { makeStyles } from "@material-ui/core/styles";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "35px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);

const UserOrganizations = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState({});
  const [formattedUserName, setFormattedUserName] = React.useState("");
  const [refreshClicked, setRefreshClicked] = React.useState(false);

  const handleReturnData = (data, userId) => {
    return data.map((elem) => {
      const queryParameters = new URLSearchParams({
        userId,
        parentId: elem.organization,
        parentType: ApiService.parentTypes.organization,
      }).toString();
      elem.actions = (
        <div className="actions-right">
          {elem.role === ApiService.organizationRoleTypes.admin && (
            <Tooltip title={t("organization-profile")}>
              <Link to={`/admin/organization-profile?${queryParameters}`}>
                <Button justIcon round simple color="rose" className="like">
                  <MoreVertIcon />
                </Button>
              </Link>
            </Tooltip>
          )}
        </div>
      );
      return elem;
    });
  };

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getUserOrganizations = async (signal = undefined) => {
      try {
        const returnData = await ApiService.loginRequired(signal, false);
        const queryParams = new URLSearchParams(props.location.search);
        const params = {};
        if (queryParams.get("userId")) {
          params.userId = queryParams.get("userId");
        }
        const responseData = await ApiService.readUser(params);
        if (returnData.superuser && params.userId) {
          setFormattedUserName(
            ApiService.getFormattedUserName(returnData._id, responseData)
          );
        }
        setUser(responseData);
      } catch (e) {
        console.error(e);
      }
    };

    const apiOperations = async () => {
      try {
        await getUserOrganizations(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    apiOperations();
    return () => {
      abortController.abort();
    };
  }, [props.location.search, refreshClicked]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <Business style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("organizations")} {formattedUserName}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => {
                    handleRefresh();
                  }}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={
                  !ApiService.isObjectEmpty(user)
                    ? handleReturnData(user.organizations, user._id)
                    : []
                }
                filterable
                columns={[
                  {
                    Header: t("id"),
                    accessor: "organization",
                  },
                  {
                    Header: t("type"),
                    accessor: "type",
                  },
                  {
                    Header: t("name"),
                    accessor: "name",
                  },
                  {
                    Header: t("role"),
                    accessor: "role",
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

UserOrganizations.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default UserOrganizations;
