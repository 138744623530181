import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardFooter from "components/Card/CardFooter.js";

import defaultImage from "assets/img/user.jpg";

import styles from "assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

const LockScreenPage = (props) => {
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = React.useState(defaultImage);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [requireToken, setRequireToken] = React.useState(false);
  const [token2FA, setToken2FA] = React.useState("");

  const [passwordError, setPasswordError] = React.useState("");
  const [token2FAError, setToken2FAError] = React.useState("");

  const getDocumentMetadata = async (signal = undefined, userId) => {
    const responseData = await ApiService.getUserDocuments({ userId }, signal);
    const documentData = responseData.find(
      (entry) => entry.type === ApiService.documentTypes.avatar
    );
    return documentData;
  };

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const apiOperations = async () => {
      try {
        const userProfile = await ApiService.loginRequired(signal, false);
        const documentData = await getDocumentMetadata(signal, userProfile._id);
        if (documentData) {
          const userAvatar = await ApiService.readUserDocument(
            {
              userId: userProfile._id,
              documentCriteria: ApiService.documentCriteria.documentType,
              documentFilter: ApiService.documentTypes.avatar,
            },
            signal
          );
          setImageUrl(`data:image/png;base64, ${userAvatar.contents}`);
        }
        setName(userProfile.name);
        setEmail(userProfile.email);
        const params = {};
        await ApiService.logoutUser(params, props.history, false, signal);
      } catch (e) {
        props.history.push("/");
      }
    };
    apiOperations();
    return () => {
      abortController.abort();
    };
  }, [props.history]);

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const showErrorMessage = (messageContent) => {
    if (!requireToken) {
      if (messageContent === t("invalid-2fa-token")) {
        setRequireToken(true);
        setPasswordError("");
        setToken2FAError("");
        return setToken2FA("");
      }
      setPasswordError(messageContent);
      setToken2FAError("");
    } else {
      setToken2FA("");
      setPasswordError("");
      setToken2FAError(messageContent);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await ApiService.loginUser({ email, password, token2FA });
      props.history.push("/");
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Card
          profile
          className={classes.customCardClass + " " + classes[cardAnimaton]}
        >
          <CardAvatar profile className={classes.cardAvatar}>
            <span>
              <img src={imageUrl} alt={t("user-avatar")} />
            </span>
          </CardAvatar>
          <CardBody profile>
            <h4 className={classes.cardTitle}>{name}</h4>
            <CustomInput
              labelText={t("enter-password")}
              id="company-disabled"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "password",
                autoComplete: "off",
                required: true,
                onChange: (e) => {
                  setPassword(e.target.value);
                },
                value: password,
              }}
              error={passwordError.length > 0}
              helperText={passwordError}
            />
            {requireToken && (
              <CustomInput
                labelText={t("enter-2fa-token")}
                id="token2FA"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "password",
                  autoComplete: "off",
                  required: true,
                  onChange: (e) => {
                    setToken2FA(e.target.value);
                  },
                  value: token2FA,
                }}
                error={token2FAError.length > 0}
                helperText={token2FAError}
              />
            )}
          </CardBody>
          <CardFooter className={classes.justifyContentCenter}>
            <Button
              type="submit"
              color="primary"
              round
              disabled={email.length === 0}
            >
              {t("unlock")}
            </Button>
          </CardFooter>
        </Card>
      </form>
    </div>
  );
};

LockScreenPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default LockScreenPage;
