import React from "react";
import PropTypes from "prop-types";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import { useStripe } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";

const useAlertStyles = makeStyles(sweetAlertStyles);

export default function StripeCheckout(props) {
  const { t } = useTranslation();
  const alertClasses = useAlertStyles();
  const stripe = useStripe();

  React.useEffect(() => {
    let isMounted = true;

    const apiOperations = async () => {
      try {
        const { error } = await stripe.redirectToCheckout({
          sessionId: props.paymentSessionId,
        });
        if (error) {
          console.error(error);
        }
        props.handleRefresh();
      } catch (e) {
        console.error(e);
      }
    };

    isMounted && !!stripe && !props.showDialog && apiOperations();
    return () => {
      isMounted = false;
    };
  }, [stripe, props]);

  return (
    !!stripe &&
    props.showDialog && (
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("credit-card-payment")}
        onConfirm={async () => {
          const { error } = await stripe.redirectToCheckout({
            sessionId: props.paymentSessionId,
          });
          if (error) {
            console.error(error);
          }
          props.handleRefresh();
        }}
        onCancel={() => {
          props.handleRefresh();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      ></SweetAlert>
    )
  );
}

StripeCheckout.propTypes = {
  paymentSessionId: PropTypes.string.isRequired,
  showDialog: PropTypes.bool.isRequired,
  handleRefresh: PropTypes.func.isRequired,
};
