import React from "react";
import PropTypes from "prop-types";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Loading from "components/Loading/Loading.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);
const useAlertStyles = makeStyles(sweetAlertStyles);

const AdminSystemConfiguration = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [initialConfig, setInitialConfig] = React.useState({});
  const [systemConfig, setSystemConfig] = React.useState({});
  const [alert, setAlert] = React.useState(null);

  const [refreshClicked, setRefreshClicked] = React.useState(false);

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getSystemConfig = async (signal = undefined) => {
      try {
        const responseData = await ApiService.readSystemConfiguration(
          {},
          signal
        );
        setInitialConfig({ ...responseData });
        setSystemConfig(responseData);
      } catch (e) {
        console.error(e);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal, true, true);
        await getSystemConfig(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [refreshClicked, t]);

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const operationResult = (result, resultMessage, refresh = true) => {
    const handleUpdateResult = async () => {
      if (refresh) {
        handleRefresh();
      }
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const handleUpdateSystemConfiguration = async (event) => {
    event.preventDefault();
    const requestData = {};
    const fieldNames = Object.keys(initialConfig);
    // eslint-disable-next-line no-unused-vars
    for (const fieldName of fieldNames) {
      const value = systemConfig[fieldName];
      if (typeof value !== undefined && value !== initialConfig[fieldName]) {
        requestData[fieldName] = value;
      }
    }
    try {
      const responseData = await ApiService.updateSystemConfiguration({
        config: requestData,
      });
      operationResult(true, responseData.message);
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const reloadStaticConfig = async () => {
    setAlert(null);
    try {
      await ApiService.reloadSystemConfiguration({});
      operationResult(
        true,
        t("successfully-reloaded-system-static-configuration")
      );
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const handleReloadStaticConfig = () => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("reload-system-static-config")}
        onConfirm={() => {
          reloadStaticConfig();
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      />
    );
  };

  const shutdownSystemService = async () => {
    setAlert(null);
    try {
      await ApiService.shutdownSystemService({});
    } catch (e) {
      if (e.message.toLowerCase().startsWith("failed to fetch")) {
        operationResult(true, t("restart-in-progress"));
      } else {
        operationResult(false, e.message);
      }
    }
  };

  const handleShutdownSystemService = () => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("restart-system-service-0")}
        onConfirm={() => {
          shutdownSystemService();
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      />
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <GridContainer
        justifyContent="space-between"
        alignItems="center"
        direction="row"
      >
        <GridItem xs={12} style={{ textAlign: "right" }}>
          <Button
            simple
            color="rose"
            className="remove"
            onClick={() => {
              handleRefresh();
            }}
            style={{ marginTop: "28px" }}
          >
            {t("refresh")}
          </Button>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <h3>{t("system-configuration")}</h3>
              <br />
              <form onSubmit={handleUpdateSystemConfiguration}>
                {Object.keys(systemConfig)
                  .sort()
                  .map((fieldName) => {
                    return (
                      <GridContainer key={fieldName}>
                        <GridItem xs={12}>
                          <CustomInput
                            labelText={fieldName}
                            id={fieldName}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              onChange: (e) => {
                                const newConfig = { ...systemConfig };
                                newConfig[fieldName] = e.target.value;
                                setSystemConfig(newConfig);
                              },
                              value: systemConfig[fieldName],
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                    );
                  })}
                <Button
                  color="primary"
                  type="submit"
                  className={classes.updateProfileButton}
                >
                  {t("edit")}
                </Button>
              </form>
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={6}>
          <Card>
            <CardBody>
              <h3>{t("reload-static-config")}</h3>
              <br />
              <GridContainer
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <GridItem xs={12} style={{ textAlign: "right" }}>
                  <Button
                    color="primary"
                    style={{ marginTop: "20px" }}
                    round
                    onClick={() => {
                      handleReloadStaticConfig();
                    }}
                  >
                    {t("execute")}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={6}>
          <Card>
            <CardBody>
              <h3>{t("restart-system-service")}</h3>
              <br />
              <GridContainer
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <GridItem xs={12} style={{ textAlign: "right" }}>
                  <Button
                    color="primary"
                    style={{ marginTop: "20px" }}
                    round
                    onClick={() => {
                      handleShutdownSystemService();
                    }}
                  >
                    {t("execute")}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
};

AdminSystemConfiguration.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default AdminSystemConfiguration;
