import React from "react";
import validator from "validator";

import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";

// @material-ui/icons
import Home from "@material-ui/icons/Home";
import Business from "@material-ui/icons/Business";
import AccountBalance from "@material-ui/icons/AccountBalance";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";

const useStyles = makeStyles(styles);

export default function PricingPage() {
  const { t } = useTranslation();
  const classes = useStyles();

  const [pricingPlans, setPricingPlans] = React.useState([]);
  const [activePricingPlans, setActivePricingPlans] = React.useState([]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const apiOperations = async () => {
      try {
        const params = {
          paymentMethod: ApiService.serviceSubscriptionPaymentMethods.invoice,
          sortBy: "plan:asc|name:asc",
        };
        const pricingPlans = await ApiService.getPricingPlans(params, signal);
        try {
          const userProfile = await ApiService.readUser({}, signal);
          const activeSubs = userProfile.subscriptions.filter(
            (subscription) =>
              subscription.status ===
                ApiService.serviceSubscriptionStatuses.active ||
              subscription.status ===
                ApiService.serviceSubscriptionStatuses.cancelled
          );
          const activePricingPlans = pricingPlans
            .filter((pricingPlan) =>
              pricingPlan.subscriptions.every((subscription) =>
                activeSubs.find(
                  (s) =>
                    s.type === subscription.type &&
                    s.plan === subscription.plan &&
                    s.price === subscription.price
                )
              )
            )
            .sort(
              (first, second) =>
                second.subscriptions.length - first.subscriptions.length
            );
          setActivePricingPlans(activePricingPlans);
        } catch (e) {
          console.error(e);
        }
        setPricingPlans(pricingPlans);
      } catch (e) {
        console.error(e);
      }
    };
    apiOperations();
    return () => {
      abortController.abort();
    };
  }, []);

  const getPricingIcon = (index, active) => {
    const icons = [
      <Business
        key={0}
        className={active ? classes.iconRose : classes.iconWhite}
      />,
      <Home
        key={1}
        className={active ? classes.iconRose : classes.iconWhite}
      />,
      <AccountBalance
        key={2}
        className={active ? classes.iconRose : classes.iconWhite}
      />,
      <AccountBalanceWalletIcon
        key={3}
        className={active ? classes.iconRose : classes.iconWhite}
      />,
    ];
    return icons[index % icons.length];
  };

  const renderPricingPlan = (pricingPlan, index, active) => {
    if (active) {
      return (
        <GridItem key={index} xs={12} sm={12} md={3}>
          <Card pricing raised>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>{pricingPlan.plan}</h6>
              <div className={classes.icon}>
                {getPricingIcon(index, active)}
              </div>
              <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                {pricingPlan.plan ===
                ApiService.serviceSubscriptionPlans.operation
                  ? t("pay-as-you-go")
                  : validator.isDecimal(pricingPlan.price)
                  ? `$${pricingPlan.price}`
                  : pricingPlan.price}
              </h3>
              <p className={classes.cardDescription}>
                {pricingPlan.shortDescription}
              </p>
              <Button round color="rose" disabled={true}>
                {t("active")}
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      );
    } else {
      return (
        <GridItem key={index} xs={12} sm={12} md={3}>
          <Card pricing plain>
            <CardBody pricing plain>
              <h6 className={classes.cardCategory}>{pricingPlan.plan}</h6>
              <div className={classes.icon}>
                {getPricingIcon(index, active)}
              </div>
              <h3
                className={`${classes.cardTitleWhite} ${classes.marginTop30}`}
              >
                {pricingPlan.plan ===
                ApiService.serviceSubscriptionPlans.operation
                  ? t("pay-as-you-go")
                  : validator.isDecimal(pricingPlan.price)
                  ? `$${pricingPlan.price}`
                  : pricingPlan.price}
              </h3>
              <p className={classes.cardCategory}>
                {pricingPlan.shortDescription}
              </p>
              <Link to={"/admin/user-subscriptions"}>
                <Button round color="white">
                  {t("choose-plan")}
                </Button>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      );
    }
  };

  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={6}>
          <h2 className={classes.title}>{t("pick-the-best-plan-for-you")}</h2>
          <h5 className={classes.description}>
            {t(
              "you-have-free-unlimited-updates-and-premium-support-on-each-package"
            )}
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer justifyContent="center">
        {pricingPlans.map((pricingPlan, index) =>
          renderPricingPlan(
            pricingPlan,
            index,
            activePricingPlans.find((a) => a._id === pricingPlan._id)
          )
        )}
      </GridContainer>
    </div>
  );
}
