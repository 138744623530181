import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import DescriptionIcon from "@material-ui/icons/Description";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import LinkIcon from "@material-ui/icons/Link";
import AppsIcon from "@material-ui/icons/Apps";
import Tooltip from "@material-ui/core/Tooltip";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import Loading from "components/Loading/Loading.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import stylesChart from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";
import moment from "moment";
import ChartistGraph from "react-chartist";
import Timeline from "@material-ui/icons/Timeline";
//import "./ibmStyle.css";

const Chartist = require("chartist");

const useStyles = makeStyles(styles);
const useStylesChart = makeStyles(stylesChart);

// Chart configuration
const chartDelays = 80,
  chartDurations = 500,
  chartAddMaxCount = 50,
  chartHeight = "200px",
  chartRange = "month",
  chartProductNames = ["UNISOT ID", "UNISOT SDC"],
  chartProductColors = ["#024266", "#CFAF4E"];
//chartProductColors = [primaryColor[0], roseColor[0]];
const Dashboard = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [documentsCount, setDocumentsCount] = React.useState(0);
  const [keysCount, setKeysCount] = React.useState(0);
  const [formattedUserName, setFormattedUserName] = React.useState("");
  const [services, setServices] = React.useState([]);

  const [refreshClicked, setRefreshClicked] = React.useState(false);
  const [query, setQuery] = React.useState({});

  const [activityChartRange, setActivityChartRange] =
    React.useState(chartRange);
  const [elkStatistics, setElkStatistics] = React.useState({});
  const [lineChart, setLineChart] = React.useState({});

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  const handleReturnData = (data, query) => {
    const dataTable = [];
    // eslint-disable-next-line no-unused-vars
    for (const entry of data) {
      // eslint-disable-next-line no-unused-vars
      for (const endpoint of entry.endpoints) {
        if (!endpoint.blocked) {
          const endpointUri = ApiService.getRedirectUri(
            endpoint.uri,
            entry.type,
            endpoint.name,
            query.userId,
            query.superuser
          );
          dataTable.push({
            name: endpoint.name,
            type: entry.type,
            blockedFormatted: endpoint.blocked.toString(),
            uri: endpoint.uri,
            actions: (
              <div className="actions-right">
                <Tooltip title={t("endpoint-uri")}>
                  <a href={endpointUri} style={{ textDecoration: "none" }}>
                    <Button justIcon round simple color="rose" className="like">
                      <LinkIcon />
                    </Button>
                  </a>
                </Tooltip>
              </div>
            ),
          });
        }
      }
    }
    return dataTable;
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId = userProfileData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;
      return params;
    };

    const getDocumentsCount = async (signal = undefined, userId) => {
      try {
        const responseData = await ApiService.getUserDocuments(
          {
            userId,
          },
          signal
        );
        setDocumentsCount(responseData.length);
      } catch (e) {
        console.error(e);
      }
    };

    const getKeysCount = async (signal = undefined, userId) => {
      try {
        const { count } = await ApiService.getUserKeys(
          {
            userId,
            count: true,
          },
          signal
        );
        setKeysCount(count);
      } catch (e) {
        console.error(e);
      }
    };

    const getUserServices = async (signal = undefined, userId) => {
      try {
        const responseData = await ApiService.getUserServices(
          {
            userId,
          },
          signal
        );
        setServices(responseData);
      } catch (e) {
        console.error(e);
      }
    };

    const handleRefresh = async () => {
      const queryParams = await getQueryParams(signal);
      setQuery(queryParams);
      await getDocumentsCount(signal, queryParams.userId);
      await getKeysCount(signal, queryParams.userId);
      await getUserServices(signal, queryParams.userId);
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await handleRefresh();
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [props.location.search, refreshClicked]);

  React.useEffect(() => {
    let isMounted = true;

    /*const getParams = (userID, chartRange, products) => {
      const aggObj = {};
      // eslint-disable-next-line no-unused-vars
      for (const product of products) {
        aggObj[product] = {
          filter: {
            term: {
              "product.keyword": product,
            },
          },
          aggs: {
            [`${product}_agg`]: {
              date_histogram: {
                field: "@timestamp",
                calendar_interval: `${
                  chartRange === "week" || chartRange === "month"
                    ? "day"
                    : "month"
                }`,
                min_doc_count: 0,
                order: {
                  _key: "asc",
                },
                extended_bounds: {
                  min: `now-${
                    chartRange === "week"
                      ? "1w"
                      : chartRange === "month"
                      ? "1M"
                      : "1y"
                  }`,
                  max: "now",
                },
              },
            },
          },
        };
      }
      return {
        index: "api",
        params: { size: 0 },
        search: {
          query: {
            bool: {
              filter: [
                {
                  range: {
                    "@timestamp": {
                      from: `now-${
                        chartRange === "week"
                          ? "1w"
                          : chartRange === "month"
                          ? "1M"
                          : "1y"
                      }`,
                      to: "now",
                    },
                  },
                },
                {
                  match_phrase: {
                    "user._id.keyword": userID,
                  },
                },
              ],
            },
          },
          aggs: aggObj,
          track_total_hits: true,
        },
      };
    };*/

    const apiOperations = async () => {
      try {
        /*const params = getParams(
          query.userId,
          activityChartRange,
          chartProductNames
        );*/
        const statistics = await ApiService.elkSearch({
          chartRange: activityChartRange,
        });
        setElkStatistics(statistics.aggregations);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted &&
      ApiService.queryElk &&
      !ApiService.isObjectEmpty(query) &&
      apiOperations();
    return () => {
      isMounted = false;
    };
  }, [query, activityChartRange]);

  React.useEffect(() => {
    if (ApiService.isObjectEmpty(elkStatistics)) {
      return;
    }
    const labels = [];
    const series = [];
    let maxCount = 0;

    let index = 0;
    // eslint-disable-next-line no-unused-vars
    for (const product of chartProductNames) {
      const buckets = elkStatistics[product][`${product}_agg`].buckets;
      series.push([]);
      // eslint-disable-next-line no-unused-vars
      for (const bucket of buckets) {
        if (index === 0) {
          labels.push(moment(bucket.key_as_string).format("YYYY-MM-DD"));
        }
        series[index].push(bucket.doc_count);
        if (bucket.doc_count > maxCount) {
          maxCount = bucket.doc_count;
        }
      }
      index += 1;
    }

    const straightLinesChart = {
      data: {
        labels,
        series,
      },
      options: {
        lineSmooth: Chartist.Interpolation.cardinal({
          tension: 0,
        }),
        low: 0,
        high: maxCount + chartAddMaxCount,
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        classNames: {
          point: "ct-point ct-white",
          line: "ct-line ct-white",
        },
        height: chartHeight,
      },
      animation: {
        draw: function (data) {
          if (data.type === "line" || data.type === "area") {
            data.element.animate({
              d: {
                begin: 600,
                dur: 700,
                from: data.path
                  .clone()
                  .scale(1, 0)
                  .translate(0, data.chartRect.height())
                  .stringify(),
                to: data.path.clone().stringify(),
                easing: Chartist.Svg.Easing.easeOutQuint,
              },
            });
          } else if (data.type === "point") {
            data.element.animate({
              opacity: {
                begin: (data.index + 1) * chartDelays,
                dur: chartDurations,
                from: 0,
                to: 1,
                easing: "ease",
              },
            });
          }
        },
      },
    };
    setLineChart(straightLinesChart);
  }, [elkStatistics]);

  const classes = useStyles();
  const chartClasses = useStylesChart();
  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <Card>
            <CardHeader stats icon>
              <CardIcon color="turq">
                <DescriptionIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <p className={classes.cardCategory}>{t("documents")}</p>
              <h3 className={classes.cardTitle}>{documentsCount}</h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <Card>
            <CardHeader stats icon>
              <CardIcon color="turq">
                <VpnKeyIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <p className={classes.cardCategory}>{t("keys")}</p>
              <h3 className={classes.cardTitle}>{keysCount}</h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      {!ApiService.isObjectEmpty(lineChart) && (
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader icon style={{ display: "inline-flex" }}>
                <CardIcon color="turq">
                  <Timeline style={{ color: roseColor[0] }} />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {t("activity")}{" "}
                  <small>
                    - {t(`last-${activityChartRange}`).toLowerCase()}
                  </small>
                </h4>
                <div style={{ marginLeft: "auto" }}>
                  <Button
                    simple
                    color="rose"
                    className="remove"
                    onClick={() => setActivityChartRange("week")}
                    style={{ marginTop: "28px" }}
                  >
                    {t("last-week")}
                  </Button>
                  <Button
                    simple
                    color="rose"
                    className="remove"
                    onClick={() => setActivityChartRange("month")}
                    style={{ marginTop: "28px" }}
                  >
                    {t("last-month")}
                  </Button>
                  <Button
                    simple
                    color="rose"
                    className="remove"
                    onClick={() => setActivityChartRange("year")}
                    style={{ marginTop: "28px" }}
                  >
                    {t("last-year")}
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <ChartistGraph
                  data={lineChart.data}
                  type="Line"
                  options={lineChart.options}
                  listener={lineChart.animation}
                />
              </CardBody>
              <CardFooter stats className={chartClasses.cardFooter}>
                <h6 className={chartClasses.legendTitle}>{t("legend")}</h6>
                {chartProductNames.map((entry, index) => {
                  return (
                    <React.Fragment key={index}>
                      <i
                        className={"fas fa-circle "}
                        style={{ color: chartProductColors[index] }}
                      />
                      {entry}
                    </React.Fragment>
                  );
                })}
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      )}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <AppsIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("service-endpoints")} {formattedUserName}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => handleRefresh()}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={handleReturnData(services, query)}
                filterable
                columns={[
                  {
                    Header: t("name"),
                    accessor: "name",
                  },
                  {
                    Header: t("type"),
                    accessor: "type",
                  },
                  {
                    Header: t("blocked"),
                    accessor: "blockedFormatted",
                  },
                  {
                    Header: t("uri-0"),
                    accessor: "uri",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>
                        <a style={{ color: roseColor[0] }} href={row.value}>
                          {row.value}
                        </a>
                      </div>
                    ),
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={5}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

Dashboard.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default Dashboard;
