import React from "react";
import PropTypes from "prop-types";

// react component for creating dynamic tables
import ReactTable from "react-table";
import { v4 as uuidv4 } from "uuid";
import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import MoreVertIcon from "@material-ui/icons/MoreVert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import modalStyles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import { Tooltip } from "@material-ui/core";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStylesDialog = makeStyles(modalStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "35px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);

const JobInspector = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const classesDialog = useStylesDialog();

  const [loading, setLoading] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const [userJobs, setUserJobs] = React.useState([]);
  const [activeJobId, setActiveJobId] = React.useState("");
  const [details, setDetails] = React.useState([]);
  const [formattedUserName, setFormattedUserName] = React.useState("");
  const [refreshClicked, setRefreshClicked] = React.useState(false);

  // pagination
  const [count, setCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(ApiService.defaultPageSize);
  const [totalPages, setTotalPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(0);

  const [sortBy, setSortBy] = React.useState("createdAt:desc");

  const [filters, setFilters] = React.useState([]);
  const [filterClicked, setFilterClicked] = React.useState(false);
  const [filterParams, setFilterParams] = React.useState([]);

  const handleRefresh = () => {
    setFilters([]);
    setFilterParams([]);
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  const handlePageChange = async (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePageSizeChange = async (newPageSize, pageIndex) => {
    const total = Math.ceil(count / newPageSize);
    setTotalPages(total);

    let currentIndex;
    if (pageSize > newPageSize) {
      currentIndex = Math.ceil(pageIndex / (pageSize / newPageSize));
    } else {
      currentIndex = Math.ceil((pageSize / newPageSize) * pageIndex);
    }
    setCurrentPage(currentIndex);

    setPageSize(newPageSize);
  };

  const handleReturnData = (data) => {
    return data.map((elem) => {
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("job-details")}>
            <Button
              justIcon
              round
              simple
              onClick={() => {
                setActiveJobId(elem._id);
                setDetails(elem.logs);
                setModal(true);
              }}
              color="rose"
              className="like"
            >
              <MoreVertIcon />
            </Button>
          </Tooltip>
        </div>
      );
      return elem;
    });
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getUserJobs = async (signal = undefined, userId) => {
      try {
        const params = {
          userId,
          sortBy: sortBy,
          fromEntry: currentPage * pageSize,
          numberOfResults: pageSize,
        };

        if (filterParams.length > 0) {
          const filteredObj = {};
          filterParams.map((filter) => {
            const filteredField = filter.id.replace(/Formatted$/, "");
            if (ApiService.exactMatch.includes(filter.id)) {
              return (filteredObj[filteredField] =
                filter.id === "fileSize"
                  ? parseInt(filter.value)
                  : filter.value);
            } else {
              return (filteredObj[filteredField] = {
                $regex: `^${filter.value}`,
              });
            }
          });

          params.filters = JSON.stringify(filteredObj);
        }

        const returnData = await ApiService.getUserJobs(params, signal);
        setUserJobs(returnData);

        delete params.sortBy;
        delete params.fromEntry;
        delete params.numberOfResults;
        const { count: jobsCount } = await ApiService.getUserJobsCount(
          params,
          signal
        );
        setCount(jobsCount);
      } catch (e) {
        console.error(e);
      } finally {
        setFilterClicked(false);
      }
    };

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };

      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId = userProfileData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;
      return params;
    };

    const handleRefresh = async (signal = undefined) => {
      const queryParams = await getQueryParams(signal);
      await getUserJobs(signal, queryParams.userId);
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await handleRefresh(signal, false);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [
    props.location.search,
    refreshClicked,
    currentPage,
    pageSize,
    sortBy,
    filterParams,
  ]);

  React.useEffect(() => {
    if (filterClicked && filters.length > 0) {
      setFilterParams(filters);
    }
  }, [filters, filterClicked]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && count > 0) {
      setTotalPages(Math.ceil(count / pageSize));
    }
    return () => {
      isMounted = false;
    };
  }, [count, pageSize]);

  const handleSortChange = (newSorted, column, shiftKey) => {
    setSortBy(
      `${newSorted[0].id.replace(/Formatted$/, "")}:${
        newSorted[0].desc === true ? "desc" : "asc"
      }`
    );
  };

  const handleFilterColumn = (filtered, column) => {
    setFilters(filtered);
  };

  const handleFilterClicked = () => {
    setFilterClicked(true);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
            <CardIcon color="turq">
              <Assignment style={{ color: roseColor[0] }} />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {t("jobs")} {formattedUserName}
            </h4>
            <div style={{ marginLeft: "auto" }}>
              <Button
                color="rose"
                round
                simple
                className="remove"
                onClick={() => {
                  handleRefresh();
                }}
                style={{ marginTop: "28px" }}
              >
                {t("refresh")}
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {filters.length > 0 && (
              <React.Fragment>
                <Button
                  color="primary"
                  round
                  className="remove"
                  onClick={() => {
                    handleFilterClicked();
                  }}
                >
                  {t("filter")}
                </Button>
                <Button
                  color="rose"
                  round
                  simple
                  className="remove"
                  onClick={() => {
                    handleRefresh();
                  }}
                >
                  {t("clear-filters")}
                </Button>
              </React.Fragment>
            )}
            <ReactTable
              data={handleReturnData(userJobs)}
              sortable={true}
              multiSort={false}
              filterable={true}
              filtered={filters}
              manual
              columns={[
                {
                  Header: t("job"),
                  accessor: "_id",
                  sortable: false,
                },
                {
                  Header: t("host"),
                  accessor: "host",
                },
                {
                  Header: t("action"),
                  accessor: "action",
                },
                {
                  Header: t("status"),
                  accessor: "status",
                },
                {
                  Header: t("progress"),
                  accessor: "progress",
                  filterable: false,
                  headerStyle: { textAlign: "right" },
                  Cell: (row) => (
                    <div style={{ textAlign: "right" }}>{row.value}</div>
                  ),
                },
                {
                  Header: t("runtime"),
                  accessor: "runtime",
                  headerStyle: { textAlign: "right" },
                  Cell: (row) => (
                    <div style={{ textAlign: "right" }}>{row.value}</div>
                  ),
                  filterable: false,
                },
                {
                  Header: t("created-at"),
                  accessor: "createdAt",
                  headerStyle: { textAlign: "right" },
                  Cell: (row) => (
                    <div style={{ textAlign: "right" }}>
                      {ApiService.formatDateTime(row.value)}
                    </div>
                  ),
                  filterable: false,
                },
                {
                  Header: t("updated-at"),
                  accessor: "updatedAt",
                  headerStyle: { textAlign: "right" },
                  Cell: (row) => (
                    <div style={{ textAlign: "right" }}>
                      {ApiService.formatDateTime(row.value)}
                    </div>
                  ),
                  filterable: false,
                },
                {
                  Header: t("details"),
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                },
              ]}
              defaultPageSize={ApiService.defaultPageSize}
              showPaginationTop={false}
              showPaginationBottom
              className="-striped -highlight"
              pages={totalPages}
              page={currentPage}
              pageSize={pageSize}
              onPageChange={(pageIndex) => handlePageChange(pageIndex)} // Called when the page index is changed by the user
              onPageSizeChange={(pageSize, pageIndex) =>
                handlePageSizeChange(pageSize, pageIndex)
              }
              onSortedChange={(newSorted, column, shiftKey) =>
                handleSortChange(newSorted, column, shiftKey)
              }
              onFilteredChange={(filtered, column) =>
                handleFilterColumn(filtered, column)
              }
            />
          </CardBody>
        </Card>
      </GridItem>
      <Dialog
        classes={{
          root: classesDialog.center,
          paper: classesDialog.modal,
        }}
        open={modal}
        transition={Transition}
        keepMounted
        scroll="body"
        fullWidth={true}
        maxWidth="md"
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classesDialog.modalHeader}
        >
          <Button
            justIcon
            className={classesDialog.modalCloseButton}
            key="close"
            aria-label="Close"
            color="rose"
            round
            simple
            onClick={() => setModal(false)}
          >
            <Close className={classesDialog.modalClose} />
          </Button>
          <h4 className={classesDialog.modalTitle}>
            {t("job-details-0")} <b>{activeJobId}</b> {formattedUserName}
          </h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classesDialog.modalBody}
        >
          {details.map((element) => {
            const dataDetails = JSON.parse(element.details);
            return (
              <React.Fragment key={uuidv4()}>
                <GridContainer
                  className={classesDialog.cardMargin}
                  key={uuidv4()}
                  style={{ fontWeight: "600" }}
                >
                  <GridItem xs={12} sm={5} md={3}>
                    {t("status-0")}
                  </GridItem>
                  <GridItem xs={12} sm={7} md={9}>
                    {element.status}
                  </GridItem>
                </GridContainer>
                <GridContainer key={uuidv4()}>
                  <GridItem xs={12} sm={5} md={3} style={{ fontWeight: "400" }}>
                    {t("runtime-0")}
                  </GridItem>
                  <GridItem xs={12} sm={7} md={9}>
                    {element.runtime}
                  </GridItem>
                </GridContainer>
                <GridContainer
                  style={{
                    borderBottom: "1px solid #c4c4c4",
                    paddingBottom: "15px",
                  }}
                >
                  {Object.keys(dataDetails).map(function (key) {
                    const data =
                      typeof dataDetails[key] === "object"
                        ? JSON.stringify(dataDetails[key])
                        : dataDetails[key].toString();
                    return (
                      <React.Fragment key={uuidv4()}>
                        <GridItem
                          xs={12}
                          sm={5}
                          md={3}
                          style={{ fontWeight: "400" }}
                        >
                          {key}
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={7}
                          md={9}
                          className={classesDialog.wordBreak}
                        >
                          <React.Fragment>{data}</React.Fragment>
                        </GridItem>
                      </React.Fragment>
                    );
                  })}
                </GridContainer>
              </React.Fragment>
            );
          })}
        </DialogContent>
        <DialogActions
          className={
            classesDialog.modalFooter + " " + classesDialog.modalFooterCenter
          }
        >
          <Button onClick={() => setModal(false)} color="rose" simple>
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
};

JobInspector.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default JobInspector;
