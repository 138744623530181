import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import validator from "validator";
import { v4 as uuidv4 } from "uuid";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";

import ListIcon from "@material-ui/icons/List";
import ClearIcon from "@material-ui/icons/Clear";
import LinkIcon from "@material-ui/icons/Link";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InfoIcon from "@material-ui/icons/Info";
import AttachMoney from "@material-ui/icons/AttachMoney";

/*#############################
  # Modal view dialog classes #
  #############################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.js";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyle);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AdminDeployments = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
  const [deployments, setDeployments] = React.useState([]);

  // Modal states
  const [showModal, setShowModal] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("add")
  );

  // Dialog fields
  const [modalDeploymentId, setModalDeploymentId] = React.useState("");
  const [modalDeploymentServiceType, setModalDeploymentServiceType] =
    React.useState(ApiService.serviceTypes.ubn);
  const [modalDeploymentName, setModalDeploymentName] = React.useState("");
  const [modalDeploymentUri, setModalDeploymentUri] = React.useState("");
  const [modalBackendUri, setModalBackendUri] = React.useState("");
  const [modalPaymail, setModalPaymail] = React.useState("");
  const [modalCheckFundingUri, setModalCheckFundingUri] = React.useState([]);
  const [modalAutoTopUp, setModalAutoTopUp] = React.useState([]);
  const [modalTopUpAmount, setModalTopUpAmount] = React.useState("");
  const [modalTopUpThreshold, setModalTopUpThreshold] = React.useState("");

  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [formatDetails, setFormatDetails] = React.useState(true);
  const [detailsTitle, setDetailsTitle] = React.useState("");
  const [details, setDetails] = React.useState({});
  const [refreshClicked, setRefreshClicked] = React.useState(false);

  const clearModalState = () => {
    setShowModal(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("add"));
    setModalDeploymentId("");
    setModalDeploymentServiceType(ApiService.serviceTypes.ubn);
    setModalDeploymentName("");
    setModalDeploymentUri("");
    setModalBackendUri("");
    setModalPaymail("");
    setModalCheckFundingUri([]);
    setModalAutoTopUp([]);
    setModalTopUpAmount("");
    setModalTopUpThreshold("");
    setShowDetailsModal(false);
    setFormatDetails(true);
    setDetailsTitle("");
    setDetails({});
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const handleGetSingleDeployment = async (elem) => {
    const params = {
      deploymentId: elem._id,
    };
    try {
      const responseData = await ApiService.getSingleDeployment(params);
      setFormatDetails(true);
      setDetailsTitle(t("deployment-details"));
      setDetails(responseData);
      setShowDetailsModal(true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleFundDeployment = (elem) => {
    const address =
      elem.walletAddress && elem.fundingAddresses.includes(elem.walletAddress)
        ? elem.walletAddress
        : elem.fundingAddresses[0];
    setAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={t("fund-deployment")}
        onConfirm={async (e) => {
          if (!validator.isDecimal(e)) {
            return operationResult(false, t("invalid-top-up-amount"));
          }
          try {
            const { message } = await ApiService.topUpDeployment({
              deploymentId: elem._id,
              serviceType: elem.service,
              address,
              topUpAmount: e,
            });
            operationResult(true, message);
          } catch (e) {
            operationResult(
              false,
              `${t("error-funding-deployment")}: ${e.message}`
            );
          }
        }}
        onCancel={() => cancelDialog()}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
      >
        <p>{t("set-top-up-amount-for-deployment-and-address")}</p>
        <p>
          <b>{`${elem._id} (${address})`}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleReturnData = (data) => {
    return data.map((elem) => {
      const deploymentUri = ApiService.getRedirectUri(
        elem.uri,
        elem.service,
        elem.name
      );
      elem.autoTopUpFormatted =
        typeof elem.autoTopUp === "undefined"
          ? "false"
          : elem.autoTopUp.toString();
      elem.walletBalanceFormatted =
        typeof elem.walletBalance === "undefined"
          ? ""
          : elem.walletBalance.toString();
      elem.actions = (
        <div className="actions-right">
          {Array.isArray(elem.fundingAddresses) &&
            elem.fundingAddresses.length > 0 && (
              <React.Fragment>
                <a
                  href={
                    ApiService.populatePath(
                      ApiService.blockchainAddressInfoURL,
                      {
                        address:
                          elem.walletAddress &&
                          elem.fundingAddresses.includes(elem.walletAddress)
                            ? elem.walletAddress
                            : elem.fundingAddresses[0],
                      }
                    ).populatedPath
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Tooltip title={t("funding-address-uri")}>
                    <Button
                      justIcon
                      round
                      simple
                      color="rose"
                      className="remove"
                    >
                      <InfoIcon />
                    </Button>
                  </Tooltip>
                </a>
                <Tooltip title={t("add-funds-manually")}>
                  <Button
                    justIcon
                    round
                    simple
                    color="rose"
                    className="edit"
                    onClick={() => {
                      handleFundDeployment(elem);
                    }}
                  >
                    <AttachMoney />
                  </Button>
                </Tooltip>
              </React.Fragment>
            )}
          <Tooltip title={t("view-details")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                handleGetSingleDeployment(elem);
              }}
            >
              <VisibilityIcon />
            </Button>
          </Tooltip>
          <a
            href={deploymentUri}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Tooltip title={t("deployment-uri")}>
              <Button justIcon round simple color="rose" className="like">
                <LinkIcon />
              </Button>
            </Tooltip>
          </a>
          <Tooltip title={t("edit-deployment")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                setModalDeploymentId(elem._id);
                setModalDeploymentServiceType(elem.service);
                setModalDeploymentName(elem.name);
                setModalDeploymentUri(elem.uri);
                setModalBackendUri(elem.backendUri ? elem.backendUri : "");
                setModalPaymail(elem.paymail ? elem.paymail : "");
                setModalCheckFundingUri(elem.checkFundingUri ? [1] : []);
                setModalAutoTopUp(elem.autoTopUp ? [1] : []);
                setModalTopUpAmount(elem.topUpAmount ? elem.topUpAmount : "");
                setModalTopUpThreshold(
                  elem.topUpThreshold ? elem.topUpThreshold : ""
                );
                setShowModal(true);
              }}
            >
              <EditIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("delete-deployment")}>
            <Button
              justIcon
              color="rose"
              simple
              className="remove"
              onClick={() => {
                handleDeleteDeployment(elem);
              }}
            >
              <ClearIcon />
            </Button>
          </Tooltip>
        </div>
      );
      return elem;
    });
  };

  const handleDeleteDeployment = (deployment) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-deployment")}?`}
        onConfirm={() => {
          deleteDeployment(deployment);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${deployment.name} (${deployment.uri})`}</b>
        </p>
      </SweetAlert>
    );
  };

  const deleteDeployment = async (deployment) => {
    setAlert(null);
    try {
      await ApiService.deleteDeployment({
        serviceType: deployment.service,
        deploymentId: deployment._id,
      });
      operationResult(true, t("deployment-successfully-deleted"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const handleAddDeployment = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const deploymentId = modalDeploymentId.trim();
    const deploymentName = modalDeploymentName.trim();
    const deploymentUri = modalDeploymentUri.trim();
    const backendUri = modalBackendUri.trim();
    const paymail =
      modalPaymail.trim().length > 0 ? modalPaymail.trim() : undefined;
    const checkFundingUri = modalCheckFundingUri.length > 0;
    const autoTopUp = modalAutoTopUp.length > 0;
    const topUpAmount =
      modalTopUpAmount.trim().length > 0 ? modalTopUpAmount.trim() : undefined;
    const topUpThreshold =
      modalTopUpThreshold.trim().length > 0
        ? modalTopUpThreshold.trim()
        : undefined;
    const validPaymail = paymail ? validator.isEmail(paymail) : true;
    const validDeploymentName = modalDeploymentName.length > 0;
    const validDeploymentUri =
      deploymentUri.includes("localhost") || validator.isURL(deploymentUri);
    const validBackendUri =
      backendUri.length === 0 ||
      backendUri.includes("localhost") ||
      validator.isURL(backendUri);
    const validTopUpAmount = topUpAmount
      ? validator.isDecimal(topUpAmount)
      : true;
    const validTopUpThreshold = topUpThreshold
      ? validator.isDecimal(topUpThreshold)
      : true;

    const checksPassed =
      validDeploymentName &&
      validDeploymentUri &&
      validBackendUri &&
      validPaymail &&
      validTopUpAmount &&
      validTopUpThreshold;
    if (!checksPassed) {
      const messageContent = [];
      if (!validDeploymentName) {
        messageContent.push(t("invalid-deployment-name"));
      }
      if (!validDeploymentUri) {
        messageContent.push(t("invalid-deployment-uri"));
      }
      if (!validBackendUri) {
        messageContent.push(t("invalid-backend-uri"));
      }
      if (!validPaymail) {
        messageContent.push(t("invalid-paymail"));
      }
      if (!validTopUpAmount) {
        messageContent.push(t("invalid-top-up-amount-0"));
      }
      if (!validTopUpThreshold) {
        messageContent.push(t("invalid-top-up-threshold"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }

    const params = {
      serviceType: modalDeploymentServiceType,
      deploymentName,
      deploymentUri,
      backendUri,
      checkFundingUri,
      autoTopUp,
    };
    if (paymail) {
      params.paymail = paymail;
    }
    if (deploymentId.length > 0) {
      params.deploymentId = deploymentId;
    }
    if (topUpAmount) {
      params.topUpAmount = topUpAmount;
    }
    if (topUpThreshold) {
      params.topUpThreshold = topUpThreshold;
    }
    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("adding"));

    try {
      await ApiService.upsertDeployment(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("deployment-added-successfully"));
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getDeployments = async (signal = undefined) => {
      try {
        const params = {};
        const responseData = await ApiService.getDeployments(params, signal);
        setDeployments(responseData);
      } catch (e) {
        console.error(e);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal, true, true);
        await getDeployments(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [refreshClicked]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showDetailsModal}
        transition={Transition}
        keepMounted
        scroll="body"
        fullWidth
        maxWidth="sm"
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowDetailsModal(false);
            setFormatDetails(true);
            setDetailsTitle("");
            setDetails({});
          }
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>{detailsTitle}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            {formatDetails &&
              Object.entries(details).map(([fieldName, fieldValue]) => {
                let data;
                if (typeof fieldValue === "object") {
                  data = (
                    <div style={{ whiteSpace: "pre-wrap" }}>
                      {JSON.stringify(fieldValue, undefined, 2)}
                    </div>
                  );
                } else {
                  data = (
                    <React.Fragment>{fieldValue.toString()}</React.Fragment>
                  );
                }
                return (
                  <React.Fragment key={uuidv4()}>
                    <GridItem
                      xs={12}
                      sm={6}
                      md={4}
                      style={{ fontWeight: "400" }}
                    >
                      {fieldName}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={6}
                      md={8}
                      className={classes.wordBreak}
                    >
                      {data}
                    </GridItem>
                  </React.Fragment>
                );
              })}
            {!formatDetails && (
              <div style={{ whiteSpace: "pre-wrap" }}>
                {JSON.stringify(details, undefined, 2)}
              </div>
            )}
          </GridContainer>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button
            onClick={() => {
              setShowDetailsModal(false);
              setFormatDetails(true);
              setDetailsTitle("");
              setDetails({});
            }}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>{t("add-deployment")}</h4>
        </DialogTitle>
        <form onSubmit={handleAddDeployment}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("deployment-id")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="deployment-id"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalDeploymentId,
                    onChange: (e) => {
                      setModalDeploymentId(e.target.value);
                    },
                  }}
                  helpText={t("deployment-id")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("service")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="service-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalDeploymentServiceType}
                    onChange={(e) => {
                      setModalDeploymentServiceType(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "serviceType",
                      id: "service-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("service")}
                    </MenuItem>
                    {Object.values(ApiService.serviceTypes).map(
                      (serviceType) => {
                        return (
                          <MenuItem
                            key={serviceType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={serviceType}
                          >
                            {serviceType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("name")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="deployment-name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalDeploymentName,
                    onChange: (e) => {
                      setModalDeploymentName(e.target.value);
                    },
                  }}
                  helpText={t("deployment-name")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  URI
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="deployment-uri"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalDeploymentUri,
                    onChange: (e) => {
                      setModalDeploymentUri(e.target.value);
                    },
                  }}
                  helpText={t("deployment-uri")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("backend-uri")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="backend-uri"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalBackendUri,
                    onChange: (e) => {
                      setModalBackendUri(e.target.value);
                    },
                  }}
                  helpText={t("backend-uri")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("paymail")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="paymail"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalPaymail,
                    onChange: (e) => {
                      setModalPaymail(e.target.value);
                    },
                  }}
                  helpText={t("paymail")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("check-funding-uri")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6} style={{ float: "left" }}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => {
                          const currentIndex = modalCheckFundingUri.indexOf(1);
                          const newChecked = [...modalCheckFundingUri];
                          if (currentIndex === -1) {
                            newChecked.push(1);
                          } else {
                            newChecked.splice(currentIndex, 1);
                          }
                          setModalCheckFundingUri(newChecked);
                        }}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot,
                        }}
                        checked={modalCheckFundingUri.length > 0}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot,
                    }}
                    label={t("check-funding-uri")}
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("auto-top-up")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6} style={{ float: "left" }}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => {
                          const currentIndex = modalAutoTopUp.indexOf(1);
                          const newChecked = [...modalAutoTopUp];
                          if (currentIndex === -1) {
                            newChecked.push(1);
                          } else {
                            newChecked.splice(currentIndex, 1);
                          }
                          setModalAutoTopUp(newChecked);
                        }}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot,
                        }}
                        checked={modalAutoTopUp.length > 0}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot,
                    }}
                    label={t("auto-top-up")}
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("top-up-amount")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="top-up-amount"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalTopUpAmount,
                    onChange: (e) => {
                      setModalTopUpAmount(e.target.value);
                    },
                  }}
                  helpText={t("top-up-amount")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("top-up-threshold")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="top-up-threshold"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalTopUpThreshold,
                    onChange: (e) => {
                      setModalTopUpThreshold(e.target.value);
                    },
                  }}
                  helpText={t("top-up-threshold")}
                />
              </GridItem>
            </GridContainer>
            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <ListIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("deployments")}</h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => handleRefresh()}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  round
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {t("add")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={handleReturnData(deployments)}
                filterable
                columns={[
                  {
                    Header: t("deployment-id"),
                    accessor: "_id",
                  },
                  {
                    Header: t("service"),
                    accessor: "service",
                  },
                  {
                    Header: t("name"),
                    accessor: "name",
                  },
                  {
                    Header: t("paymail"),
                    accessor: "paymail",
                  },
                  {
                    Header: t("balance"),
                    accessor: "walletBalanceFormatted",
                  },
                  {
                    Header: t("auto-top-up"),
                    accessor: "autoTopUpFormatted",
                  },
                  {
                    Header: t("top-up-status"),
                    accessor: "topUpStatus",
                  },
                  {
                    Header: "URI",
                    accessor: "uri",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>
                        <a
                          href={row.value}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: roseColor[0] }}
                        >
                          {row.value}
                        </a>
                      </div>
                    ),
                  },
                  {
                    Header: t("backend-uri"),
                    accessor: "backendUri",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
};

AdminDeployments.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default AdminDeployments;
