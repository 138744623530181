import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Code from "@material-ui/icons/Code";
import Face from "@material-ui/icons/Face";
import Business from "@material-ui/icons/Business";
import People from "@material-ui/icons/People";
import Email from "@material-ui/icons/Email";

import Check from "@material-ui/icons/Check";
import CloudQueueIcon from "@material-ui/icons/CloudQueue";
import DataUsage from "@material-ui/icons/DataUsage";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbars from "components/Snackbar/Snackbar.js";
import ReCAPTCHA from "react-google-recaptcha";

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import extendedStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles(styles);
const useExtendedStyles = makeStyles(extendedStyles);

const RegisterPage = (props) => {
  const { t } = useTranslation();
  const [name, setName] = React.useState("");
  const [availableOrganizations, setAvailableOrganizations] = React.useState(
    []
  );
  const [selectedOrganization, setSelectedOrganization] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [companyPosition, setCompanyPosition] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [checked, setChecked] = React.useState([]);
  const [recaptchaEnabled, setRecaptchaEnabled] = React.useState(false);
  const [recaptchaSiteKey, setRecaptchaSiteKey] = React.useState("");
  const [recaptchaValue, setRecaptchaValue] = React.useState("");

  // Error handling
  const [nameError, setNameError] = React.useState("");
  const [companyNameError, setCompanyNameError] = React.useState("");
  const [companyPositionError, setCompanyPositionError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");
  const [message, setMessage] = React.useState("");

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const apiOperations = async () => {
      try {
        if (ApiService.registerListOrganizations) {
          let organizations = await ApiService.getAvailableOrganizations(
            signal
          );
          organizations = organizations.map(
            (organization) => organization.name
          );
          organizations.sort();
          setAvailableOrganizations(organizations);
        }
        const { captchaEnabled, captchaSiteKey } =
          await ApiService.getPublicSystemConfig({}, signal);
        setRecaptchaEnabled(captchaEnabled);
        setRecaptchaSiteKey(captchaSiteKey);
      } catch (e) {
        console.error(e);
      }
    };
    apiOperations();
    return () => {
      abortController.abort();
    };
  }, []);

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const showErrorMessage = (messageContent) => {
    setMessage(messageContent);
    setTimeout(() => {
      setNameError("");
      setCompanyNameError("");
      setCompanyPositionError("");
      setEmailError("");
      setPasswordError("");
      setConfirmPasswordError("");
      setMessage("");
    }, ApiService.messageTimeout);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validName = name.length > 0;
    const companyNameSet = companyName.length > 0;
    const companyPositionSet = companyPosition.length > 0;
    const validEmail = isEmail(email);
    const validPasswordLength = password.length > 6;
    const passwordsMatch = password === confirmPassword;
    const recaptchaValid =
      !recaptchaEnabled || (recaptchaEnabled && !!recaptchaValue);
    const checkedAgreement = checked.length > 0;
    const checksPassed =
      validName &&
      validEmail &&
      validPasswordLength &&
      passwordsMatch &&
      recaptchaValid &&
      checkedAgreement &&
      ((companyNameSet && companyPositionSet) ||
        (!companyNameSet && !companyPositionSet));
    if (!checksPassed) {
      const messageContent = [];
      if (!validName) {
        setNameError(t("name-length-should-be-greater-than-1-0"));
        messageContent.push(t("name-length-should-be-greater-than-1"));
      }
      if (companyNameSet && !companyPositionSet) {
        setCompanyPositionError(t("invalid-job-title"));
        messageContent.push(t("invalid-job-title-0"));
      }
      if (companyPositionSet && !companyNameSet) {
        setCompanyNameError(t("invalid-company-name"));
        messageContent.push(t("invalid-company-name-0"));
      }
      if (!validEmail) {
        setEmailError(t("invalid-email-address"));
        messageContent.push(t("email-address"));
      }
      if (!validPasswordLength) {
        setPasswordError(t("minimum-password-length-is-7-0"));
        messageContent.push(t("minimum-password-length-is-7"));
      }
      if (!passwordsMatch) {
        setConfirmPasswordError(t("passwords-do-not-match-0"));
        messageContent.push(t("passwords-do-not-match"));
      }
      if (!recaptchaValid) {
        messageContent.push(t("invalid-captcha"));
      }
      if (!checkedAgreement) {
        messageContent.push(t("agree-to-the-terms-and-conditions"));
      }
      showErrorMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`
      );
      return;
    }

    try {
      await ApiService.createUser({
        name,
        email,
        password,
        companyName,
        companyPosition,
        recaptchaValue,
        services: ApiService.defaultRegistrationServices,
        roles: ApiService.defaultRegistrationRoles,
      });
      props.history.push("/");
    } catch (e) {
      if (e.message.includes("email")) {
        showErrorMessage(t("user-with-the-same-email-already-exists"));
      } else if (e.message.includes("name")) {
        showErrorMessage(t("user-with-the-same-name-already-exists"));
      } else {
        showErrorMessage(e.message);
      }
    }
  };

  const classes = useStyles();
  const extendedClasses = useExtendedStyles();

  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>{t("register")}</h2>
            <CardBody>
              <GridContainer justifyContent="center">
                <GridItem xs={12} sm={12} md={5}>
                  <InfoArea
                    title={t("enterprise-blockchain")}
                    description={t("enterprise-blockchain-description")}
                    icon={Code}
                    iconColor="rose"
                  />
                  <InfoArea
                    title={t("scalable-marketplace")}
                    description={t("scalable-marketplace-description")}
                    icon={CloudQueueIcon}
                    iconColor="rose"
                  />
                  <InfoArea
                    title={t("big-data-and-machine-learning")}
                    description={t("big-data-and-machine-learning-description")}
                    icon={DataUsage}
                    iconColor="rose"
                  />
                </GridItem>
                <GridItem xs={12} sm={8} md={5}>
                  {ApiService.socialMediaLogin && (
                    <div className={classes.center}>
                      <Button justIcon round color="twitter">
                        <i className="fab fa-twitter" />
                      </Button>
                      {` `}
                      <Button justIcon round color="dribbble">
                        <i className="fab fa-dribbble" />
                      </Button>
                      {` `}
                      <Button justIcon round color="facebook">
                        <i className="fab fa-facebook-f" />
                      </Button>
                      {` `}
                      <h4 className={classes.socialTitle}>
                        {t("or-be-classical")}
                      </h4>
                    </div>
                  )}
                  <form onSubmit={handleSubmit} className={classes.form}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        autoFocus: true,
                        placeholder: `${t("name")}...`,
                        required: true,
                        onChange: (e) => {
                          setName(e.target.value);
                        },
                      }}
                      error={nameError.length > 0}
                      helperText={nameError}
                    />
                    {ApiService.registerListOrganizations ? (
                      <FormControl
                        fullWidth
                        className={classes.customFormControlClasses}
                      >
                        <InputLabel
                          htmlFor="organization"
                          className={extendedClasses.selectLabel}
                        >
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Business className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        </InputLabel>
                        <Select
                          value={selectedOrganization}
                          onChange={(e) => {
                            const selected = e.target.value;
                            setCompanyName(selected);
                            setSelectedOrganization(selected);
                          }}
                          MenuProps={{ className: extendedClasses.selectMenu }}
                          classes={{ select: extendedClasses.select }}
                          inputProps={{
                            name: "organization",
                            id: "organization",
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: extendedClasses.selectMenuItem,
                            }}
                          >
                            {t("company-name")}
                          </MenuItem>
                          {Object.values(availableOrganizations).map(
                            (organizationName) => {
                              return (
                                <MenuItem
                                  key={organizationName}
                                  classes={{
                                    root: extendedClasses.selectMenuItem,
                                    selected:
                                      extendedClasses.selectMenuItemSelectedMultiple,
                                  }}
                                  value={organizationName}
                                >
                                  {organizationName}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    ) : (
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Business
                                className={classes.inputAdornmentIcon}
                              />
                            </InputAdornment>
                          ),
                          placeholder: `${t("company-name")}...`,
                          required: false,
                          onChange: (e) => {
                            setCompanyName(e.target.value);
                          },
                        }}
                        error={companyNameError.length > 0}
                        helperText={companyNameError}
                      />
                    )}
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <People className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: `${t("job-title")}...`,
                        required: false,
                        onChange: (e) => {
                          setCompanyPosition(e.target.value);
                        },
                      }}
                      error={companyPositionError.length > 0}
                      helperText={companyPositionError}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: `${t("email")}...`,
                        type: "email",
                        required: true,
                        onChange: (e) => {
                          setEmail(e.target.value);
                        },
                      }}
                      error={emailError.length > 0}
                      helperText={emailError}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        required: true,
                        type: "password",
                        placeholder: `${t("password")}...`,
                        autoComplete: "off",
                        onChange: (e) => {
                          setPassword(e.target.value);
                        },
                      }}
                      error={passwordError.length > 0}
                      helperText={passwordError}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Icon className={classes.inputAdornmentIcon}>
                              repeat
                            </Icon>
                          </InputAdornment>
                        ),
                        required: true,
                        type: "password",
                        placeholder: `${t("confirm-password")}...`,
                        autoComplete: "off",
                        onChange: (e) => {
                          setConfirmPassword(e.target.value);
                        },
                      }}
                      error={confirmPasswordError.length > 0}
                      helperText={confirmPasswordError}
                    />
                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel,
                      }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => handleToggle(1)}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      label={
                        <span>
                          {t("i-agree-to-the")}{" "}
                          <a
                            style={{ color: primaryColor[0] }}
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://unisot.com/privacy-policy/"
                          >
                            {t("terms-and-conditions")}
                          </a>
                          .
                        </span>
                      }
                    />
                    {recaptchaEnabled && (
                      <ReCAPTCHA
                        sitekey={recaptchaSiteKey}
                        onChange={(value) => {
                          setRecaptchaValue(value);
                        }}
                      />
                    )}
                    <div className={classes.center}>
                      <Button round color="primary" type="submit">
                        {t("get-started")}
                      </Button>
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbars
        place="bc"
        color="success"
        icon={AddAlert}
        message={message}
        open={message.length > 0}
        closeNotification={() => setMessage("")}
        close
      />
    </div>
  );
};

RegisterPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default RegisterPage;
