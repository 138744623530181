import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import LinkIcon from "@material-ui/icons/Link";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import MoneyIcon from "@material-ui/icons/Money";
import BuildIcon from "@material-ui/icons/Build";
import PaymentIcon from "@material-ui/icons/Payment";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);

const AdminPricingLists = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [loading, setLoading] = React.useState(true);
  const [pricingCurrency, setPricingCurrency] = React.useState("");
  const [pricingMultiplier, setPricingMultiplier] = React.useState("1");
  const [minerType, setMinerType] = React.useState("");
  const [satoshisPerByte, setSatoshisPerByte] = React.useState("");

  const [links, setLinks] = React.useState([]);
  const [currencyExchangeRate, setCurrencyExchangeRate] = React.useState([]);
  const [bsvExchangeRate, setBSVExchangeRate] = React.useState([]);
  const [refreshClicked, setRefreshClicked] = React.useState(false);

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getPricingLists = async (signal = undefined) => {
      try {
        let responseData;
        if (refreshClicked) {
          responseData = await ApiService.updatePricingLists({}, signal);
        } else {
          responseData = await ApiService.getPricingLists({}, signal);
        }
        setPricingCurrency(responseData.pricingCurrency);
        setPricingMultiplier(responseData.pricingMultiplier.toString());
        setMinerType(responseData.minerType.toUpperCase());
        setSatoshisPerByte(
          `${responseData.satoshisPerByte.standard.toString()} S / ${responseData.satoshisPerByte.data.toString()} D`
        );
        setLinks([
          {
            name: t("fiat-currency-exchange-rate"),
            link: responseData.currencyExchangeRateLink,
          },
          {
            name: t("bsv-exchange-rate"),
            link: responseData.bsvExchangeRateLink,
          },
          {
            name: t("taal-fee-qoute"),
            link: responseData.taalFeeQuoteLink,
          },
          {
            name: t("hdd-prices"),
            link: responseData.hddPricesLink,
          },
          {
            name: t("s3-prices"),
            link: responseData.s3PricesLink,
          },
          {
            name: t("google-storage-prices"),
            link: responseData.googleStoragePricesLink,
          },
        ]);
        const currencies = Object.keys(responseData.currencyExchangeRate);
        const bsvCurrencies = Object.keys(responseData.bsvExchangeRate);
        currencies.sort();
        bsvCurrencies.sort();
        setCurrencyExchangeRate(
          currencies.map((currency) => {
            return {
              name: currency,
              exchangeRate: responseData.currencyExchangeRate[currency],
            };
          })
        );
        setBSVExchangeRate(
          bsvCurrencies.map((currency) => {
            return {
              name: currency,
              exchangeRate: responseData.bsvExchangeRate[currency],
            };
          })
        );
      } catch (e) {
        console.error(e);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal, true, true);
        await getPricingLists(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [refreshClicked, t]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="turq" stats icon>
              <CardIcon color="turq">
                <EuroSymbolIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <p className={classes.cardCategory}>{t("pricing-currency")}</p>
              <h3 className={classes.cardTitle}>{pricingCurrency}</h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="turq" stats icon>
              <CardIcon color="turq">
                <AccountBalanceWalletIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <p className={classes.cardCategory}>{t("pricing-multiplier")}</p>
              <h3 className={classes.cardTitle}>{pricingMultiplier}</h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="turq" stats icon>
              <CardIcon color="turq">
                <BuildIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <p className={classes.cardCategory}>{t("miner")}</p>
              <h3 className={classes.cardTitle}>{minerType}</h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="turq" stats icon>
              <CardIcon color="turq">
                <PaymentIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <p className={classes.cardCategory}>{t("mining-fee")}</p>
              <h3 className={classes.cardTitle}>{satoshisPerByte}</h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <LinkIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("api-links")}</h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => handleRefresh()}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={links}
                filterable
                columns={[
                  {
                    Header: t("name"),
                    accessor: "name",
                  },
                  {
                    Header: t("link"),
                    accessor: "link",
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>
                        <a
                          href={row.value}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: roseColor[0] }}
                        >
                          {row.value}
                        </a>
                      </div>
                    ),
                  },
                ]}
                defaultPageSize={6}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={6}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <EuroSymbolIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("fiat-currency-exchange-rate-0")}
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={currencyExchangeRate}
                filterable
                columns={[
                  {
                    Header: t("name"),
                    accessor: "name",
                  },
                  {
                    Header: t("exchange-rate"),
                    accessor: "exchangeRate",
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={6}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <MoneyIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("bsv-currency-exchange-rate")}
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={bsvExchangeRate}
                filterable
                columns={[
                  {
                    Header: t("name"),
                    accessor: "name",
                  },
                  {
                    Header: t("exchange-rate"),
                    accessor: "exchangeRate",
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

AdminPricingLists.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default AdminPricingLists;
