import {
  warningCardHeader,
  grayCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  greenCardHeader,
  turqCardHeader,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";
const cardIconStyle = {
  cardIcon: {
    "&$warningCardHeader,&$grayCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader,&$greenCardHeader,&$turqCardHeader":
      {
        borderRadius: "3px",
        backgroundColor: grayColor[0],
        padding: "15px",
        marginTop: "15px",
        marginRight: "15px",
        float: "left",
      },
  },
  warningCardHeader,
  grayCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  greenCardHeader,
  turqCardHeader,
};

export default cardIconStyle;
