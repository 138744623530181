import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbars from "components/Snackbar/Snackbar.js";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles(styles);

const LoginPage = (props) => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [requireToken, setRequireToken] = React.useState(false);
  const [token2FA, setToken2FA] = React.useState("");
  const [adLoginEnabled, setADLoginEnabled] = React.useState(false);
  const [openidLoginEnabled, setOpenidLoginEnabled] = React.useState(false);

  const [emailError, setEmailError] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const getQueryParams = () => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      deploymentId: queryParams.get("deploymentId"),
      redirectPath: queryParams.get("redirectPath"),
    };
    return params;
  };

  const showSuccessMessage = (messageContent) => {
    setEmailError("");
    setMessage("");
    setSuccessMessage(messageContent);
    setTimeout(() => {
      setSuccessMessage("");
    }, ApiService.messageTimeout);
  };

  const showErrorMessage = (messageContent) => {
    setSuccessMessage("");
    if (!requireToken) {
      if (messageContent === t("invalid-2fa-token")) {
        setRequireToken(true);
        return setToken2FA("");
      }
    } else {
      setToken2FA("");
    }
    setMessage(messageContent);
    setTimeout(() => {
      setEmailError("");
      setMessage("");
    }, ApiService.messageTimeout);
  };

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    const validEmail = isEmail(email);
    if (!validEmail) {
      setEmailError(t("invalid-email-address"));
      showErrorMessage(t("invalid-information-provided-email-address"));
      return;
    }
    try {
      const responseData = await ApiService.sendUserLoginMagicLink({
        email,
      });
      showSuccessMessage(responseData.message);
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  const handleCredentialsSubmit = async (event) => {
    event.preventDefault();
    const validEmail = isEmail(email);
    if (!validEmail) {
      setEmailError(t("invalid-email-address"));
      showErrorMessage(t("invalid-information-provided-email-address"));
      return;
    }
    try {
      const responseData = await ApiService.loginUser({
        email,
        password,
        token2FA,
      });
      const queryParams = getQueryParams();
      const response = ApiService.loginRedirectUri({
        endpoints: responseData.user.endpoints,
        ...queryParams,
      });
      if (response.local) {
        props.history.push(response.path);
      } else {
        window.location.replace(response.path);
      }
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  const handleADSubmit = async (event) => {
    event.preventDefault();
    window.location.replace(ApiService.adLoginURL);
  };

  const handleOpenidSubmit = async (event) => {
    event.preventDefault();
    window.location.replace(ApiService.openidLoginURL);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;
    const apiOperations = async () => {
      try {
        const { adEnabled, openidEnabled } =
          await ApiService.getPublicSystemConfig({}, signal);
        setADLoginEnabled(adEnabled);
        setOpenidLoginEnabled(openidEnabled);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, []);

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem
          xs={12}
          sm={adLoginEnabled && openidLoginEnabled ? 7 : 6}
          md={adLoginEnabled && openidLoginEnabled ? 5 : 4}
        >
          <Card login className={classes[cardAnimaton]}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="white"
              style={{ marginBottom: "0px" }}
            >
              <h3 className={classes.cardTitle}>{t("my-account")}</h3>
              {!ApiService.showRegisterPage && (
                <h6>{t("only-for-registered-customers")}</h6>
              )}
              {ApiService.socialMediaLogin && (
                <div className={classes.socialLine}>
                  {[
                    "fab fa-facebook-square",
                    "fab fa-twitter",
                    "fab fa-google-plus",
                  ].map((prop, key) => {
                    return (
                      <Button
                        color="rose"
                        justIcon
                        round
                        simple
                        key={key}
                        className={classes.customButtonClass}
                      >
                        <i className={prop} />
                      </Button>
                    );
                  })}
                </div>
              )}
            </CardHeader>
            <NavPills
              color="warning"
              alignCenter
              active={0}
              tabs={[
                {
                  tabButton: t("email"),
                  tabContent: (
                    <form onSubmit={handleEmailSubmit}>
                      <CardBody>
                        <CustomInput
                          labelText={`${t("email")}...`}
                          id="email-magic-link"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            type: "email",
                            required: true,
                            autoFocus: true,
                            onChange: (e) => {
                              setEmail(e.target.value);
                            },
                            value: email,
                          }}
                          error={emailError.length > 0}
                          helperText={emailError}
                        />
                      </CardBody>
                      <CardFooter className={classes.justifyContentCenter}>
                        <Button
                          id="login-email"
                          color="primary"
                          size="lg"
                          block
                          type="submit"
                        >
                          {t("send-link")}
                        </Button>
                      </CardFooter>
                    </form>
                  ),
                },
                {
                  tabButton: t("credentials"),
                  tabContent: (
                    <form onSubmit={handleCredentialsSubmit}>
                      <CardBody>
                        <CustomInput
                          labelText={`${t("email")}...`}
                          id="email-credentials"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            type: "email",
                            required: true,
                            autoFocus: true,
                            onChange: (e) => {
                              setEmail(e.target.value);
                            },
                            value: email,
                          }}
                          error={emailError.length > 0}
                          helperText={emailError}
                        />
                        <CustomInput
                          labelText={t("password")}
                          id="password-credentials"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputAdornmentIcon}>
                                  lock_outline
                                </Icon>
                              </InputAdornment>
                            ),
                            type: "password",
                            autoComplete: "off",
                            required: true,
                            onChange: (e) => {
                              setPassword(e.target.value);
                            },
                            value: password,
                          }}
                        />
                        {requireToken && (
                          <CustomInput
                            labelText={t("2fa-token")}
                            id="token2FA-credentials"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Icon className={classes.inputAdornmentIcon}>
                                    lock_outline
                                  </Icon>
                                </InputAdornment>
                              ),
                              type: "password",
                              autoComplete: "off",
                              required: true,
                              onChange: (e) => {
                                setToken2FA(e.target.value);
                              },
                              value: token2FA,
                            }}
                          />
                        )}
                        <Link
                          to="/auth/reset-password-email"
                          style={{ color: primaryColor[0] }}
                        >
                          {t("forgot-your-password")}
                        </Link>
                      </CardBody>
                      <CardFooter className={classes.justifyContentCenter}>
                        <Button
                          id="login-credentials"
                          color="primary"
                          size="lg"
                          block
                          type="submit"
                        >
                          {t("log-in")}
                        </Button>
                      </CardFooter>
                    </form>
                  ),
                },
                adLoginEnabled
                  ? {
                      tabButton: t("active-directory"),
                      tabContent: (
                        <form onSubmit={handleADSubmit}>
                          <CardFooter className={classes.justifyContentCenter}>
                            <Button
                              id="login-ad"
                              color="primary"
                              size="lg"
                              block
                              type="submit"
                            >
                              {t("active-directory-login")}
                            </Button>
                          </CardFooter>
                        </form>
                      ),
                    }
                  : undefined,
                openidLoginEnabled
                  ? {
                      tabButton: t("openid"),
                      tabContent: (
                        <form onSubmit={handleOpenidSubmit}>
                          <CardFooter className={classes.justifyContentCenter}>
                            <Button
                              id="login-ad"
                              color="primary"
                              size="lg"
                              block
                              type="submit"
                            >
                              {t("openid-login")}
                            </Button>
                          </CardFooter>
                        </form>
                      ),
                    }
                  : undefined,
              ].filter((x) => x !== undefined)}
            />
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbars
        place="bc"
        color="danger"
        icon={AddAlert}
        message={message}
        open={message.length > 0}
        closeNotification={() => setMessage("")}
        close
      />
      <Snackbars
        place="bc"
        color="success"
        icon={AddAlert}
        message={successMessage}
        open={successMessage.length > 0}
        closeNotification={() => setSuccessMessage("")}
        close
      />
    </div>
  );
};

LoginPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default LoginPage;
