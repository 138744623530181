import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";

import SecurityIcon from "@material-ui/icons/Security";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import FileCopyIcon from "@material-ui/icons/FileCopy";

/*#############################
  # Modal view dialog classes #
  #############################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyle);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AdminRoles = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
  const [roles, setRoles] = React.useState([]);

  // Modal states
  const [showModal, setShowModal] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("add")
  );

  // Dialog fields
  const [modalRoleId, setModalRoleId] = React.useState(undefined);
  const [modalName, setModalName] = React.useState("");
  const [modalType, setModalType] = React.useState(ApiService.roleTypes.user);
  const [modalDescription, setModalDescription] = React.useState("");
  const [modalFlags, setModalFlags] = React.useState("[]");

  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [detailsTitle, setDetailsTitle] = React.useState("");
  const [details, setDetails] = React.useState({});
  const [refreshClicked, setRefreshClicked] = React.useState(false);

  const clearModalState = () => {
    setShowModal(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("add"));
    setModalRoleId(undefined);
    setModalName("");
    setModalType(ApiService.roleTypes.user);
    setModalDescription("");
    setModalFlags("[]");
    setShowDetailsModal(false);
    setDetailsTitle("");
    setDetails({});
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const handleReturnData = (data) => {
    return data.map((elem) => {
      elem.protectedFormatted = elem.protected.toString();
      const queryParameters = new URLSearchParams({
        roleId: elem._id,
      }).toString();
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("role-configuration")}>
            <Link to={`/admin/admin-role-configuration?${queryParameters}`}>
              <Button justIcon round simple color="rose" className="like">
                <MoreVertIcon />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip title={t("clone-role")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                handleCloneRole(elem);
              }}
            >
              <FileCopyIcon />
            </Button>
          </Tooltip>
          {!elem.protected && (
            <React.Fragment>
              <Tooltip title={t("edit-role-0")}>
                <Button
                  justIcon
                  round
                  simple
                  color="rose"
                  className="edit"
                  onClick={() => {
                    setModalRoleId(elem._id);
                    setModalName(elem.name);
                    setModalType(elem.type);
                    setModalDescription(elem.description);
                    setModalFlags(
                      elem.flags
                        ? JSON.stringify(
                            elem.flags.map((entry) => {
                              return {
                                name: entry.name,
                                value: entry.value,
                              };
                            })
                          )
                        : "[]"
                    );
                    setShowModal(true);
                  }}
                >
                  <EditIcon />
                </Button>
              </Tooltip>
              <Tooltip title={t("delete-role")}>
                <Button
                  justIcon
                  color="rose"
                  simple
                  className="remove"
                  onClick={() => {
                    handleDeleteRole(elem);
                  }}
                >
                  <ClearIcon />
                </Button>
              </Tooltip>
            </React.Fragment>
          )}
        </div>
      );
      return elem;
    });
  };

  const handleDeleteRole = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-role")}?`}
        onConfirm={() => {
          deleteRole(elem);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${elem.name} (${elem.type})`}</b>
        </p>
      </SweetAlert>
    );
  };

  const deleteRole = async (role) => {
    setAlert(null);
    try {
      const params = {
        type: role.type,
        name: role.name,
      };
      await ApiService.deleteRole(params);
      operationResult(true, t("role-successfully-deleted"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const handleCloneRole = (elem) => {
    setAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={t("clone-role")}
        onConfirm={async (e) => {
          try {
            const clonedName = e.trim();
            const validClonedName =
              clonedName.length > 0 && !!clonedName.match(/^[a-z_-]+$/);
            if (validClonedName) {
              const params = {
                name: elem.name,
                type: elem.type,
                clonedName,
              };
              await ApiService.cloneRole(params);
              operationResult(true, t("role-successfuly-cloned"));
            } else {
              operationResult(false, t("invalid-new-role-name"));
            }
          } catch (e) {
            operationResult(false, e.message);
          }
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
      >
        <p>
          <b>{`${elem.name} (${elem.type})`}</b>
        </p>
        <p></p>
        <p>{t("please-input-new-role-name-below")}</p>
      </SweetAlert>
    );
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const handleAddRole = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const updateOperation = !!modalRoleId;
    const name = modalName.trim();
    const validName = name.length > 0 && !!name.match(/^[a-z_-]+$/);
    const description = modalDescription.trim();
    const validDescription = description.length > 0;
    let flags;
    let validFlags;
    try {
      flags = JSON.parse(modalFlags);
      validFlags = Array.isArray(flags);
    } catch (e) {
      validFlags = false;
    }

    const checksPassed = validName && validDescription && validFlags;
    if (!checksPassed) {
      const messageContent = [];
      if (!validName) {
        messageContent.push(t("invalid-name"));
      }
      if (!validDescription) {
        messageContent.push(t("invalid-description"));
      }
      if (!validFlags) {
        messageContent.push(t("invalid-flags"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }
    const params = {
      name,
      type: modalType,
      description,
      flags,
    };

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("adding"));

    try {
      if (updateOperation) {
        await ApiService.updateSingleRole({
          ...params,
          roleId: modalRoleId,
        });
      } else {
        await ApiService.upsertRole(params);
      }
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("role-successfully-added"));
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getRoles = async (signal = undefined) => {
      try {
        const params = {
          sortBy: "name:asc",
        };
        const responseData = await ApiService.getRoles(params, signal);
        setRoles(responseData);
      } catch (e) {
        console.error(e);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal, true, true);
        await getRoles(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [refreshClicked]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showDetailsModal}
        transition={Transition}
        keepMounted
        scroll="body"
        fullWidth
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowDetailsModal(false);
            setDetailsTitle("");
            setDetails({});
          }
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>{detailsTitle}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            {Object.entries(details).map(([fieldName, fieldValue]) => {
              let data;
              if (typeof fieldValue === "object") {
                data = (
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    {JSON.stringify(fieldValue, undefined, 2)}
                  </div>
                );
              } else {
                data = <React.Fragment>{fieldValue.toString()}</React.Fragment>;
              }
              return (
                <React.Fragment key={uuidv4()}>
                  <GridItem xs={12} sm={6} md={4} style={{ fontWeight: "400" }}>
                    {fieldName}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={6}
                    md={8}
                    style={{ wordBreak: "break-all" }}
                  >
                    {data}
                  </GridItem>
                </React.Fragment>
              );
            })}
          </GridContainer>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button
            simple
            color="rose"
            onClick={() => {
              setShowDetailsModal(false);
              setDetailsTitle("");
              setDetails({});
            }}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>{t("add-role")}</h4>
        </DialogTitle>
        <form onSubmit={handleAddRole}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("type")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="role-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalType}
                    onChange={(e) => {
                      setModalType(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "roleType",
                      id: "role-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("type")}
                    </MenuItem>
                    {Object.values(ApiService.roleTypes).map((roleType) => {
                      return (
                        <MenuItem
                          key={roleType}
                          classes={{
                            root: formClasses.selectMenuItem,
                            selected:
                              formClasses.selectMenuItemSelectedMultiple,
                          }}
                          value={roleType}
                        >
                          {roleType}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("name")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="role-name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalName,
                    onChange: (e) => {
                      setModalName(e.target.value);
                    },
                  }}
                  helpText={t("name")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("description")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="role-description"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalDescription,
                    onChange: (e) => {
                      setModalDescription(e.target.value);
                    },
                  }}
                  helpText={t("description")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("flags")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="flags"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalFlags,
                    onChange: (e) => {
                      setModalFlags(e.target.value);
                    },
                  }}
                  helpText={t("flags")}
                />
              </GridItem>
            </GridContainer>

            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <SecurityIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("roles")}</h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => handleRefresh()}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  round
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {t("add")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={handleReturnData(roles)}
                filterable
                columns={[
                  {
                    Header: t("name"),
                    accessor: "name",
                  },
                  {
                    Header: t("type"),
                    accessor: "type",
                  },
                  {
                    Header: t("protected"),
                    accessor: "protectedFormatted",
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
};

AdminRoles.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default AdminRoles;
