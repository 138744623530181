import React from "react";
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";

import HttpsIcon from "@material-ui/icons/Https";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbars from "components/Snackbar/Snackbar.js";

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

const useStyles = makeStyles(styles);

const ResetPasswordEmail = () => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState("");
  const [showHome, setShowHome] = React.useState(false);

  // Error handling
  const [emailError, setEmailError] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  const showErrorMessage = (messageContent) => {
    setSuccessMessage("");
    setMessage(messageContent);
    setTimeout(() => {
      setEmailError("");
      setMessage("");
    }, ApiService.messageTimeout);
  };

  const showSuccessMessage = (messageContent) => {
    setEmailError("");
    setMessage("");
    setSuccessMessage(messageContent);
    setShowHome(true);
    setTimeout(() => {
      setSuccessMessage("");
    }, ApiService.messageTimeout);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validEmail = isEmail(email);
    if (!validEmail) {
      setEmailError(t("invalid-email-address"));
      showErrorMessage(
        `${t("invalid-information-provided")}: ${t("email-address")}.`
      );
      return;
    }

    try {
      const responseData = await ApiService.sendUserResetPasswordEmailLink({
        email,
      });
      showSuccessMessage(responseData.message);
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  const classes = useStyles();
  const showAdditionalInformation = true;

  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>{t("reset-password")}</h2>
            <CardBody>
              <GridContainer justifyContent="center">
                {showAdditionalInformation && (
                  <GridItem xs={12} sm={12} md={5}>
                    <InfoArea
                      title={t("forgot-your-password-0")}
                      description={t("forgot-your-password-description")}
                      icon={HttpsIcon}
                      iconColor="primary"
                    />
                  </GridItem>
                )}
                <GridItem xs={12} sm={8} md={5}>
                  <form onSubmit={handleSubmit} className={classes.form}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: `${t("email")}...`,
                        type: "email",
                        required: true,
                        onChange: (e) => {
                          setEmail(e.target.value);
                        },
                      }}
                      error={emailError.length > 0}
                      helperText={emailError}
                    />
                    <div className={classes.center}>
                      {!showHome && (
                        <Button round color="primary" type="submit">
                          {t("send-link")}
                        </Button>
                      )}
                      {showHome && (
                        <Link to="/">
                          <Button round color="primary">
                            {t("return-to-home")}
                          </Button>
                        </Link>
                      )}
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbars
        place="bc"
        color="danger"
        icon={AddAlert}
        message={message}
        open={message.length > 0}
        closeNotification={() => setMessage("")}
        close
      />
      <Snackbars
        place="bc"
        color="success"
        icon={AddAlert}
        message={successMessage}
        open={successMessage.length > 0}
        closeNotification={() => setSuccessMessage("")}
        close
      />
    </div>
  );
};

export default ResetPasswordEmail;
