import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import moment from "moment";
import Big from "big.js";
import { v4 as uuidv4 } from "uuid";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";
import Datetime from "react-datetime";
import ChartistGraph from "react-chartist";
import Timeline from "@material-ui/icons/Timeline";
import CardFooter from "components/Card/CardFooter.js";

import CreditCard from "@material-ui/icons/CreditCard";
import BuildIcon from "@material-ui/icons/Build";
import DataUsage from "@material-ui/icons/DataUsage";
import VisibilityIcon from "@material-ui/icons/Visibility";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";
import Tooltip from "@material-ui/core/Tooltip";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import stylesChart from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const Chartist = require("chartist");

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useStylesChart = makeStyles(stylesChart);

const chartDelays = 80,
  chartDurations = 500,
  chartAddMaxCount = 5,
  chartHeight = "200px",
  chartCategoryColors = ["#024266"],
  chartDayRange = [
    { intervalDays: 31, bucketDays: 1 },
    { intervalDays: 366, bucketDays: 7 },
    { intervalDays: 366000, bucketDays: 31 },
  ],
  roundNumberOfDecimals = 2;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const dialogDetailsInputFields = new Set([]);

const validServiceTypes = new Set([ApiService.serviceTypes.ubn]);
const excludeKeyTypes = [
  ApiService.keyTypes.id,
  ApiService.keyTypes.master,
  ApiService.keyTypes.funding,
];
const defaultTimeIntervalDays = 6;

const AdminKeyUserTransactions = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();
  const chartClasses = useStylesChart();
  const selectAllEnum = t("all");
  const selectAllDeployment = { id: selectAllEnum, name: selectAllEnum };

  const [loading, setLoading] = React.useState(true);
  const [formattedUserName, setFormattedUserName] = React.useState("");

  // Dropdown selectors
  const [availableServiceTypes, setAvailableServiceTypes] = React.useState([
    selectAllEnum,
  ]);
  const [availableServiceDeployments, setAvailableServiceDeployments] =
    React.useState([selectAllDeployment]);
  const [serviceDeploymentMapping, setServiceDeploymentMapping] =
    React.useState({
      selectAllEnum: [selectAllDeployment],
    });
  const [query, setQuery] = React.useState({});

  // Dropdown selected values
  const [selectedServiceType, setSelectedServiceType] =
    React.useState(selectAllEnum);
  const [selectedDeploymentId, setSelectedDeploymentId] =
    React.useState(selectAllEnum);

  // Date period selectors
  const [dateFrom, setDateFrom] = React.useState(
    moment().utc().subtract(defaultTimeIntervalDays, "days").startOf("day")
  );
  const [dateTo, setDateTo] = React.useState(
    moment().utc().add(1, "days").startOf("day")
  );

  // Submitt button clicked
  const [submitClicked, setSubmitClicked] = React.useState(true);

  // Statistics results
  const [transactions, setTransactions] = React.useState([]);
  const [totalTransactions, setTotalTransactions] = React.useState("0");
  const [totalFeePaid, setTotalFeePaid] = React.useState("0");
  const [totalCreditsPaid, setTotalCreditsPaid] = React.useState("0");
  const [totalPayloadSize, setTotalPayloadSize] = React.useState("0");

  const [lineChart, setLineChart] = React.useState({});

  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [formatDetails, setFormatDetails] = React.useState(true);
  const [detailsTitle, setDetailsTitle] = React.useState("");
  const [details, setDetails] = React.useState({});

  const handleGetTransactionDetails = async (elem) => {
    const params = {
      userId: query.userId,
      keyCriteria: "byId",
      keyFilter: elem._id,
      serviceType: elem.service,
      populateKey: true,
    };
    try {
      const responseData = await ApiService.getSingleUserKey(params);
      setFormatDetails(true);
      setDetailsTitle(t("transaction-details"));
      setDetails(responseData);
      setShowDetailsModal(true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleReturnData = (data) => {
    return data.map((elem) => {
      elem.createdAt = ApiService.formatDateTime(elem.createdAt);
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("transaction-details")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                handleGetTransactionDetails(elem);
              }}
            >
              <VisibilityIcon />
            </Button>
          </Tooltip>
        </div>
      );
      return elem;
    });
  };

  const handleSubmit = () => {
    setSubmitClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      if (queryParams.get("parentId")) {
        params.parentId = queryParams.get("parentId");
      }
      if (queryParams.get("parentType")) {
        params.parentType = queryParams.get("parentType");
      }
      const returnData = await ApiService.loginRequired(signal, false);
      let endpoints;
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        endpoints = userProfileData.endpoints;
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId =
          typeof userProfileData.superuser !== "undefined"
            ? userProfileData._id
            : returnData._id;
      } else {
        params.userId = returnData._id;
        endpoints = returnData.endpoints;
      }
      const serviceDeployments = {};
      const deployments = [];
      // eslint-disable-next-line no-unused-vars
      for (const endpoint of endpoints) {
        const service = endpoint.service;
        if (validServiceTypes.has(service)) {
          const deployment = {
            id: endpoint.deploymentId,
            name: endpoint.name,
          };
          deployments.push(deployment);
          if (serviceDeployments.hasOwnProperty(service)) {
            serviceDeployments[service].push(deployment);
          } else {
            serviceDeployments[service] = [deployment];
          }
        }
      }
      serviceDeployments[selectAllEnum] = deployments;
      params.serviceDeployments = serviceDeployments;
      params.superuser = returnData.superuser;
      return params;
    };

    const setInitialServicesAndDeployments = (serviceDeployments) => {
      const availableServices = Object.keys(serviceDeployments);
      availableServices.sort();
      setAvailableServiceTypes(availableServices);
      setServiceDeploymentMapping(serviceDeployments);
      setAvailableServiceDeployments(serviceDeployments[selectedServiceType]);
    };

    const getStatistics = async (userId, deploymentIds, signal = undefined) => {
      const dateFromFormatted = dateFrom.format("YYYY-MM-DD");
      const dateToFormatted = dateTo.format("YYYY-MM-DD");
      const params = {
        userId,
        filter: [{ type: { $nin: excludeKeyTypes } }],
        aggregate: [
          {
            $project: {
              createdAt: 1,
              deploymentId: {
                $convert: {
                  input: "$deploymentId",
                  to: "string",
                },
              },
              creditsPaid: {
                $convert: {
                  input: "$creditsPaid",
                  to: "decimal",
                  onError: { $toDecimal: 0 },
                  onNull: { $toDecimal: 0 },
                },
              },
              payloadSize: {
                $convert: {
                  input: "$payloadSize",
                  to: "decimal",
                  onError: { $toDecimal: 0 },
                  onNull: { $toDecimal: 0 },
                },
              },
              feePaid: {
                $convert: {
                  input: "$feePaid",
                  to: "decimal",
                  onError: { $toDecimal: 0 },
                  onNull: { $toDecimal: 0 },
                },
              },
            },
          },
          {
            $match: {
              $expr: {
                $and: [
                  {
                    $gte: [
                      "$createdAt",
                      {
                        $dateFromString: {
                          dateString: dateFromFormatted,
                          format: "%Y-%m-%d",
                        },
                      },
                    ],
                  },
                  {
                    $lte: [
                      "$createdAt",
                      {
                        $dateFromString: {
                          dateString: dateToFormatted,
                          format: "%Y-%m-%d",
                        },
                      },
                    ],
                  },
                  {
                    $in: ["$deploymentId", deploymentIds],
                  },
                ],
              },
            },
          },
          {
            $group: {
              _id: {
                dateYMD: {
                  $dateFromParts: {
                    year: { $year: "$createdAt" },
                    month: { $month: "$createdAt" },
                    day: { $dayOfMonth: "$createdAt" },
                  },
                },
              },
              creditsPaid: { $sum: "$creditsPaid" },
              payloadSize: { $sum: "$payloadSize" },
              feePaid: { $sum: "$feePaid" },
              count: { $sum: 1 },
            },
          },
          {
            $sort: { "_id.dateYMD": 1 },
          },
          {
            $project: {
              _id: 0,
              creditsPaid: 1,
              payloadSize: 1,
              feePaid: 1,
              count: 1,
              date: {
                $dateToString: { date: "$_id.dateYMD", format: "%Y-%m-%d" },
              },
            },
          },
        ],
      };
      const results = await ApiService.calculateKeysStatistics(params, signal);

      // Calculate totals and prepare calculations lookup
      let creditsPaid = new Big(0),
        payloadSize = new Big(0),
        feePaid = new Big(0),
        count = 0;
      const existingEntries = {};
      // eslint-disable-next-line no-unused-vars
      for (const entry of results) {
        creditsPaid = creditsPaid.plus(
          new Big(entry.creditsPaid["$numberDecimal"])
        );
        payloadSize = payloadSize.plus(
          new Big(entry.payloadSize["$numberDecimal"])
        );
        feePaid = feePaid.plus(new Big(entry.feePaid["$numberDecimal"]));
        count = count + entry.count;
        existingEntries[entry.date] = entry.count;
      }

      // Determine graph bucket size in days
      let startDate = moment.utc(dateFromFormatted);
      const endDate = moment.utc(dateToFormatted);
      const intervalSize = endDate.diff(startDate, "days");
      let bucketSize = 1;
      // eslint-disable-next-line no-unused-vars
      for (const { intervalDays, bucketDays } of chartDayRange) {
        if (intervalSize <= intervalDays) {
          bucketSize = bucketDays;
          break;
        }
      }

      // Populate graph values
      const series = [[]];
      const labels = [];
      let maxCount = 0,
        bucketDateLabel = startDate.format("YYYY-MM-DD"),
        bucketDateSize = bucketSize,
        bucketCount = 0;
      while (startDate.isBefore(endDate)) {
        const startDateFormatted = startDate.format("YYYY-MM-DD");
        bucketCount += existingEntries.hasOwnProperty(startDateFormatted)
          ? existingEntries[startDateFormatted]
          : 0;
        bucketDateSize -= 1;
        startDate.add(1, "days");
        if (bucketDateSize === 0) {
          labels.push(bucketDateLabel);
          series[0].push(bucketCount);
          if (bucketCount > maxCount) {
            maxCount = bucketCount;
          }
          bucketDateLabel = startDate.format("YYYY-MM-DD");
          bucketDateSize = bucketSize;
          bucketCount = 0;
        }
      }
      if (bucketDateSize < bucketSize) {
        labels.push(bucketDateLabel);
        series[0].push(bucketCount);
        if (bucketCount > maxCount) {
          maxCount = bucketCount;
        }
      }

      // Return statistics
      const statistics = {
        creditsPaid: creditsPaid.round(roundNumberOfDecimals).toString(),
        payloadSize: payloadSize.round(roundNumberOfDecimals).toString(),
        feePaid: feePaid.round(roundNumberOfDecimals).toString(),
        count: count.toString(),
        series,
        labels,
        maxCount,
      };
      return statistics;
    };

    const populateLineChart = (statistics) => {
      return {
        data: {
          labels: statistics.labels,
          series: statistics.series,
        },
        options: {
          lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: statistics.maxCount + chartAddMaxCount,
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
          classNames: {
            point: "ct-point ct-white",
            line: "ct-line ct-white",
          },
          height: chartHeight,
        },
        animation: {
          draw: function (data) {
            if (data.type === "line" || data.type === "area") {
              data.element.animate({
                d: {
                  begin: 600,
                  dur: 700,
                  from: data.path
                    .clone()
                    .scale(1, 0)
                    .translate(0, data.chartRect.height())
                    .stringify(),
                  to: data.path.clone().stringify(),
                  easing: Chartist.Svg.Easing.easeOutQuint,
                },
              });
            } else if (data.type === "point") {
              data.element.animate({
                opacity: {
                  begin: (data.index + 1) * chartDelays,
                  dur: chartDurations,
                  from: 0,
                  to: 1,
                  easing: "ease",
                },
              });
            }
          },
        },
      };
    };

    const getTransactions = async (signal = undefined) => {
      try {
        // Parse query params and user profile
        const queryParams = await getQueryParams(signal);
        let deploymentIds;
        if (selectedDeploymentId === selectAllEnum) {
          deploymentIds = queryParams.serviceDeployments[
            selectedServiceType
          ].map((entry) => entry.id);
        } else {
          deploymentIds = [selectedDeploymentId];
        }

        // Set initial dropdown menus
        setQuery(queryParams);
        setInitialServicesAndDeployments(queryParams.serviceDeployments);

        // Check query and populate transactions
        if (dateFrom && dateTo && dateTo.isAfter(dateFrom)) {
          const params = {
            userId: queryParams.userId,
            sortBy: "createdAt:desc",
          };
          const filteredObj = {
            createdAt: {
              $gte: dateFrom.toISOString(),
              $lte: dateTo.toISOString(),
            },
            type: { $nin: excludeKeyTypes },
            deploymentId: { $in: deploymentIds },
          };
          params.filters = JSON.stringify(filteredObj);

          const responseData = await ApiService.getUserKeys(params, signal);
          const statistics = await getStatistics(
            queryParams.userId,
            deploymentIds,
            signal
          );
          setTransactions(responseData);
          setTotalTransactions(statistics.count);
          setTotalFeePaid(statistics.feePaid);
          setTotalCreditsPaid(statistics.creditsPaid);
          setTotalPayloadSize(statistics.payloadSize);
          setLineChart(populateLineChart(statistics));
        }
      } catch (e) {
        console.error(e);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal, true, true);
        await getTransactions();
        setLoading(false);
        setSubmitClicked(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && submitClicked && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [
    props.location.search,
    dateFrom,
    dateTo,
    selectAllEnum,
    selectedDeploymentId,
    selectedServiceType,
    submitClicked,
  ]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showDetailsModal}
        transition={Transition}
        keepMounted
        scroll="body"
        fullWidth
        maxWidth="sm"
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowDetailsModal(false);
            setFormatDetails(true);
            setDetailsTitle("");
            setDetails({});
          }
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>{detailsTitle}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            {formatDetails &&
              Object.entries(details).map(([fieldName, fieldValue]) => {
                let data;
                if (typeof fieldValue === "object") {
                  data = (
                    <div style={{ whiteSpace: "pre-wrap" }}>
                      {JSON.stringify(fieldValue, undefined, 2)}
                    </div>
                  );
                } else {
                  if (dialogDetailsInputFields.has(fieldName)) {
                    data = (
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          value: fieldValue.toString(),
                        }}
                      />
                    );
                  } else {
                    data = (
                      <React.Fragment>{fieldValue.toString()}</React.Fragment>
                    );
                  }
                }
                return (
                  <React.Fragment key={uuidv4()}>
                    <GridItem
                      xs={12}
                      sm={6}
                      md={4}
                      style={{ fontWeight: "400" }}
                    >
                      {dialogDetailsInputFields.has(fieldName) ? (
                        <FormLabel
                          className={formClasses.labelHorizontal}
                          style={{ float: "left" }}
                        >
                          {fieldName}
                        </FormLabel>
                      ) : (
                        fieldName
                      )}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={6}
                      md={8}
                      className={classes.wordBreak}
                    >
                      {data}
                    </GridItem>
                  </React.Fragment>
                );
              })}
            {!formatDetails && (
              <div style={{ whiteSpace: "pre-wrap" }}>
                {JSON.stringify(details, undefined, 2)}
              </div>
            )}
          </GridContainer>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button
            onClick={() => {
              setShowDetailsModal(false);
              setFormatDetails(true);
              setDetailsTitle("");
              setDetails({});
            }}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <GridContainer>
        <GridItem xs={12}>
          <legend>
            {t("filters")} {formattedUserName}
          </legend>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={3}>
          <FormControl fullWidth className={formClasses.selectFormControl}>
            <InputLabel
              htmlFor="service-type"
              className={formClasses.selectLabel}
            >
              {t("service-type")}
            </InputLabel>
            <Select
              value={selectedServiceType}
              onChange={(e) => {
                setSelectedServiceType(e.target.value);
                setAvailableServiceDeployments(
                  serviceDeploymentMapping[e.target.value]
                );
              }}
              MenuProps={{
                className: formClasses.selectMenu,
              }}
              classes={{
                select: formClasses.select,
              }}
              inputProps={{
                name: "serviceType",
                id: "service-type",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: formClasses.selectMenuItem,
                }}
              >
                {t("service-type")}
              </MenuItem>
              {availableServiceTypes.map((entry) => {
                return (
                  <MenuItem
                    key={entry}
                    classes={{
                      root: formClasses.selectMenuItem,
                      selected: formClasses.selectMenuItemSelectedMultiple,
                    }}
                    value={entry}
                  >
                    {entry}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={3}>
          <FormControl fullWidth className={formClasses.selectFormControl}>
            <InputLabel
              htmlFor="deployment-id"
              className={formClasses.selectLabel}
            >
              {t("deployment-name-0")}
            </InputLabel>
            <Select
              value={selectedDeploymentId}
              onChange={(e) => {
                setSelectedDeploymentId(e.target.value);
              }}
              MenuProps={{
                className: formClasses.selectMenu,
              }}
              classes={{
                select: formClasses.select,
              }}
              inputProps={{
                name: "deploymentId",
                id: "deployment-id",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: formClasses.selectMenuItem,
                }}
              >
                {t("deployment-name-0")}
              </MenuItem>
              <MenuItem
                classes={{
                  root: formClasses.selectMenuItem,
                  selected: formClasses.selectMenuItemSelectedMultiple,
                }}
                value={selectAllEnum}
              >
                {selectAllEnum}
              </MenuItem>
              {availableServiceDeployments.map((entry) => {
                return (
                  <MenuItem
                    key={entry.id}
                    classes={{
                      root: formClasses.selectMenuItem,
                      selected: formClasses.selectMenuItemSelectedMultiple,
                    }}
                    value={entry.id}
                  >
                    {entry.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={2} style={{ marginTop: "25px" }}>
          <FormControl fullWidth>
            <Datetime
              dateFormat={"YYYY-MM-DD"}
              timeFormat={false}
              inputProps={{
                placeholder: t("date-from"),
              }}
              value={dateFrom}
              onChange={(e) => {
                if (dateTo) {
                  setDateFrom(e.isBefore(dateTo) ? e : null);
                } else {
                  setDateFrom(e);
                }
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={2} style={{ marginTop: "25px" }}>
          <FormControl fullWidth>
            <Datetime
              dateFormat={"YYYY-MM-DD"}
              timeFormat={false}
              inputProps={{
                placeholder: t("date-to"),
              }}
              value={dateTo}
              onChange={(e) => {
                if (dateFrom) {
                  setDateTo(e.isAfter(dateFrom) ? e : null);
                } else {
                  setDateTo(e);
                }
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={2}>
          <Button
            simple
            color="rose"
            className="remove"
            onClick={() => handleSubmit()}
            style={{ marginTop: "28px" }}
          >
            {t("submit")}
          </Button>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="turq" stats icon>
              <CardIcon color="turq">
                <Timeline style={{ color: roseColor[0] }} />
              </CardIcon>
              <p className={classes.cardCategory}>{t("total-transactions")}</p>
              <h3 className={classes.cardTitle}>{totalTransactions}</h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="turq" stats icon>
              <CardIcon color="turq">
                <CreditCard style={{ color: roseColor[0] }} />
              </CardIcon>
              <p className={classes.cardCategory}>{t("total-credits-paid")}</p>
              <h3 className={classes.cardTitle}>{totalCreditsPaid}</h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="turq" stats icon>
              <CardIcon color="turq">
                <BuildIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <p className={classes.cardCategory}>{t("total-fee-paid")}</p>
              <h3 className={classes.cardTitle}>{totalFeePaid}</h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="turq" stats icon>
              <CardIcon color="turq">
                <DataUsage style={{ color: roseColor[0] }} />
              </CardIcon>
              <p className={classes.cardCategory}>{t("total-payload-size")}</p>
              <h3 className={classes.cardTitle}>{totalPayloadSize}</h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      {!ApiService.isObjectEmpty(lineChart) && (
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader icon style={{ display: "inline-flex" }}>
                <CardIcon color="turq">
                  <Timeline style={{ color: roseColor[0] }} />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {t("transactions-by-day")}
                </h4>
              </CardHeader>
              <CardBody>
                <ChartistGraph
                  data={lineChart.data}
                  type="Line"
                  options={lineChart.options}
                  listener={lineChart.animation}
                />
              </CardBody>
              <CardFooter stats className={chartClasses.cardFooter}>
                <h6 className={chartClasses.legendTitle}>{t("legend")}</h6>
                {[t("count")].map((entry, index) => {
                  return (
                    <React.Fragment key={index}>
                      <i
                        className={"fas fa-circle "}
                        style={{ color: chartCategoryColors[index] }}
                      />
                      {entry}
                    </React.Fragment>
                  );
                })}
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      )}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <Timeline style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("transaction-details")}
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={handleReturnData(transactions)}
                filterable
                columns={[
                  {
                    Header: t("created-at-0"),
                    accessor: "createdAt",
                  },
                  {
                    Header: t("type"),
                    accessor: "type",
                  },
                  {
                    Header: t("deploy-id"),
                    accessor: "deploymentId",
                  },
                  {
                    Header: t("transaction-id"),
                    accessor: "_id",
                  },
                  {
                    Header: t("payload-size"),
                    accessor: "payloadSize",
                  },
                  {
                    Header: t("fee-paid"),
                    accessor: "feePaid",
                  },
                  {
                    Header: t("credits-paid"),
                    accessor: "creditsPaid",
                  },
                  {
                    Header: t("wallet-balance"),
                    accessor: "walletBalance",
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

AdminKeyUserTransactions.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default AdminKeyUserTransactions;
