import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbars from "components/Snackbar/Snackbar.js";

const useStyles = makeStyles(styles);

const MagicLinkLogin = (props) => {
  const { t } = useTranslation();
  const [requireToken, setRequireToken] = React.useState(false);
  const [token2FA, setToken2FA] = React.useState("");

  const [token2FAError, setToken2FAError] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const showErrorMessage = (messageContent) => {
    if (!requireToken) {
      if (messageContent === t("invalid-2fa-token")) {
        setRequireToken(true);
        setMessage("");
        setToken2FAError("");
        return setToken2FA("");
      } else {
        setToken2FAError("");
        setMessage(messageContent);
      }
    } else {
      setToken2FA("");
      setToken2FAError(messageContent);
    }
    setTimeout(() => {
      setToken2FAError("");
      setMessage("");
    }, ApiService.messageTimeout);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
      magicLinkSecret: queryParams.get("magicLinkSecret"),
      token2FA,
    };

    try {
      const responseData = await ApiService.magicLinkLogin(params);
      const response = ApiService.loginRedirectUri({
        endpoints: responseData.user.endpoints,
        deploymentId: queryParams.get("deploymentId"),
        redirectPath: queryParams.get("redirectPath"),
      });
      if (response.local) {
        props.history.push(response.path);
      } else {
        window.location.replace(response.path);
      }
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={handleSubmit}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="white"
              >
                <h3 className={classes.cardTitle}>{t("magic-link-login")}</h3>
              </CardHeader>
              <CardBody>
                {requireToken && (
                  <CustomInput
                    labelText={t("2fa-token")}
                    id="token2FA-credentials"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      required: true,
                      type: "password",
                      placeholder: `${t("2fa-token")}...`,
                      autoComplete: "off",
                      onChange: (e) => {
                        setToken2FA(e.target.value);
                      },
                      value: token2FA,
                    }}
                    error={token2FAError.length > 0}
                    helperText={token2FAError}
                  />
                )}
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="primary" size="lg" block type="submit">
                  {t("login")}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
      <Snackbars
        place="bc"
        color="danger"
        icon={AddAlert}
        message={message}
        open={message.length > 0}
        closeNotification={() => setMessage("")}
        close
      />
    </div>
  );
};

MagicLinkLogin.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default MagicLinkLogin;
