import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import validator from "validator";
import Big from "big.js";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";
import StripeCheckout from "components/Checkout/StripeCheckout";

import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ReplayIcon from "@material-ui/icons/Replay";
import CreditCard from "@material-ui/icons/CreditCard";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckIcon from "@material-ui/icons/Check";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import Tooltip from "@material-ui/core/Tooltip";
/*#############################
  # Modal view dialog classes #
  #############################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyle);
const useAlertStyles = makeStyles(sweetAlertStyles);

const stripePromise = loadStripe(ApiService.stripePublicKey);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const dialogDetailsInputFields = new Set([]);

const UserServicesBillings = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
  const [userServiceBillings, setUserServiceBillings] = React.useState([]);
  const [formattedUserName, setFormattedUserName] = React.useState("");
  const [userCredits, setUserCredits] = React.useState({});
  const [editEnabled, setEditEnabled] = React.useState(false);

  // Modal states
  const [showModal, setShowModal] = React.useState(false);
  const [showModalProvider, setShowModalProvider] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("add")
  );

  // pagination sorting
  const [count, setCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(ApiService.defaultPageSize);
  const [totalPages, setTotalPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [sortBy, setSortBy] = React.useState("createdAt:desc");

  const [filters, setFilters] = React.useState([]);
  const [filterClicked, setFilterClicked] = React.useState(false);
  const [filterParams, setFilterParams] = React.useState([]);

  // Dialog fields
  const [modalServiceBillingId, setModalServiceBillingId] = React.useState("");
  const [
    modalServiceBillingPaymentMethod,
    setModalServiceBillingPaymentMethod,
  ] = React.useState(ApiService.serviceSubscriptionPaymentMethods.invoice);
  const [modalServiceBillingStatus, setModalServiceBillingStatus] =
    React.useState(ApiService.billingStatuses.pending);
  const [modalServiceBillingAmount, setModalServiceBillingAmount] =
    React.useState("0");
  const [modalServiceBillingInvoiceDate, setModalServiceBillingInvoiceDate] =
    React.useState(moment().utc().startOf("day"));
  const [modalServiceBillingStartDate, setModalServiceBillingStartDate] =
    React.useState(moment().utc().startOf("day"));
  const [modalServiceBillingEndDate, setModalServiceBillingEndDate] =
    React.useState(moment().utc().startOf("day"));
  const [modalServiceBillingDescription, setModalServiceBillingDescription] =
    React.useState("");
  const [modalProviderPaymentMethod, setModalProviderPaymentMethod] =
    React.useState(ApiService.serviceSubscriptionPaymentMethods.creditCard);
  const [modalProviderPaymentAmount, setModalProviderPaymentAmount] =
    React.useState("");
  const [modalProviderExistingBilling, setModalProviderExistingBilling] =
    React.useState("");

  const [superUser, setSuperUser] = React.useState(false);
  const [refreshClicked, setRefreshClicked] = React.useState(false);
  const [paymentSession, setPaymentSession] = React.useState({});

  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [formatDetails, setFormatDetails] = React.useState(true);
  const [detailsTitle, setDetailsTitle] = React.useState("");
  const [details, setDetails] = React.useState({});

  const getUserCredits = (userProfileData) => {
    return {
      credits: userProfileData.credits,
      topUpEnabled: !!userProfileData.services.find(
        (entry) => entry.type === ApiService.serviceTypes.topUp
      ),
    };
  };

  const getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    if (queryParams.get("parentId")) {
      params.parentId = queryParams.get("parentId");
    }
    if (queryParams.get("parentType")) {
      params.parentType = queryParams.get("parentType");
    }
    const returnData = await ApiService.loginRequired(signal, false);
    if (
      (returnData.superuser ||
        params.parentType === ApiService.parentTypes.organization) &&
      params.userId
    ) {
      const userProfileData = await ApiService.readUser(params, signal);
      setFormattedUserName(
        ApiService.getFormattedUserName(returnData._id, userProfileData)
      );
      setUserCredits(getUserCredits(userProfileData));
      params.userId =
        typeof userProfileData.superuser !== "undefined"
          ? userProfileData._id
          : returnData._id;
    } else {
      params.userId = returnData._id;
      setUserCredits(getUserCredits(returnData));
    }
    params.superuser = returnData.superuser;
    const serviceId = queryParams.get("serviceId");
    const subscriptionId = queryParams.get("subscriptionId");
    if (serviceId) {
      params.serviceId = serviceId;
    }
    if (subscriptionId) {
      params.subscriptionId = subscriptionId;
    }
    params.editEnabled =
      params.superuser && params.serviceId && params.subscriptionId;
    setEditEnabled(params.editEnabled);
    return params;
  };

  const clearModalState = () => {
    setShowModal(false);
    setShowModalProvider(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("add"));
    setModalServiceBillingId("");
    setModalProviderPaymentMethod(
      ApiService.serviceSubscriptionPaymentMethods.creditCard
    );
    setModalProviderPaymentAmount("");
    setModalProviderExistingBilling("");
    setModalServiceBillingPaymentMethod(
      ApiService.serviceSubscriptionPaymentMethods.invoice
    );
    setModalServiceBillingStatus(ApiService.billingStatuses.pending);
    setModalServiceBillingAmount("0");
    setModalServiceBillingInvoiceDate(moment().utc().startOf("day"));
    setModalServiceBillingStartDate(moment().utc().startOf("day"));
    setModalServiceBillingEndDate(moment().utc().startOf("day"));
    setModalServiceBillingDescription("");
    setShowDetailsModal(false);
    setFormatDetails(true);
    setDetailsTitle("");
    setDetails({});
  };

  const handleProviderPaymentMethod = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }
    const queryParams = await getQueryParams();

    const paymentMethod = modalProviderPaymentMethod.trim();
    const paymentProvider =
      paymentMethod === ApiService.serviceSubscriptionPaymentMethods.creditCard
        ? ApiService.paymentProvider.stripe
        : ApiService.paymentProvider.handCash;
    const amount = modalProviderPaymentAmount.trim();
    const validBillingAmount =
      validator.isDecimal(amount) && new Big(amount).gt(0);
    const existingBilling = !!modalProviderExistingBilling;
    const checksPassed = validBillingAmount;
    if (!checksPassed) {
      const messageContent = [];
      if (!validBillingAmount) {
        messageContent.push(t("invalid-amount"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }
    const params = {
      userId: queryParams.userId,
      paymentProvider,
    };
    if (queryParams.parentId) {
      params.parentId = queryParams.parentId;
    }
    if (queryParams.parentType) {
      params.parentType = queryParams.parentType;
    }
    if (existingBilling) {
      params.billingId = modalProviderExistingBilling;
    } else {
      params.amount = amount;
    }

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("processing"));

    try {
      let session;
      if (existingBilling) {
        session = await ApiService.getBillingPaymentSession(params);
      } else {
        session = await ApiService.startBillingTopUpPaymentSessionId(params);
      }
      if (session.paymentRequestUrl) {
        window.location.replace(session.paymentRequestUrl);
      }
      setPaymentSession({
        session,
        showDialog: false,
      });
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("processing"));
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const handleReturnData = (data, superuser) => {
    return data.map((elem) => {
      elem.startDateFormatted = ApiService.formatDateTime(elem.startDate);
      elem.endDateFormatted = ApiService.formatDateTime(elem.endDate);
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("download-document")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                handleDownloadDocument(elem);
              }}
            >
              <CloudDownloadIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("send-email")}>
            <Button
              justIcon
              simple
              color="rose"
              className="remove"
              onClick={() => {
                handleSendEmail(elem);
              }}
            >
              <MailOutlineIcon />
            </Button>
          </Tooltip>
          {elem.status === ApiService.billingStatuses.pending && (
            <Tooltip title={t("payment-gateway-payment")}>
              <Button
                justIcon
                round
                simple
                color="rose"
                className="edit"
                onClick={() => {
                  setModalProviderPaymentAmount(elem.amount);
                  setModalProviderExistingBilling(elem._id);
                  setConfirmModalButtonText(t("pay"));
                  setShowModalProvider(true);
                }}
              >
                <CreditCard />
              </Button>
            </Tooltip>
          )}
          {superuser && (
            <React.Fragment>
              {elem.status !== ApiService.billingStatuses.paid && (
                <Tooltip title={t("mark-paid")}>
                  <Button
                    justIcon
                    round
                    simple
                    color="rose"
                    className="remove"
                    onClick={() => {
                      handleUpdateUserServiceBillingStatus(
                        elem,
                        ApiService.billingStatuses.paid
                      );
                    }}
                  >
                    <CheckIcon />
                  </Button>
                </Tooltip>
              )}
              {elem.status !== ApiService.billingStatuses.pending && (
                <Tooltip title={t("mark-pending")}>
                  <Button
                    justIcon
                    round
                    simple
                    color="rose"
                    className="remove"
                    onClick={() => {
                      handleUpdateUserServiceBillingStatus(
                        elem,
                        ApiService.billingStatuses.pending
                      );
                    }}
                  >
                    <MoneyOffIcon />
                  </Button>
                </Tooltip>
              )}
              {elem.status !== ApiService.billingStatuses.refunded && (
                <Tooltip title={t("mark-refunded")}>
                  <Button
                    justIcon
                    round
                    simple
                    color="rose"
                    className="edit"
                    onClick={() => {
                      handleUpdateUserServiceBillingStatus(
                        elem,
                        ApiService.billingStatuses.refunded
                      );
                    }}
                  >
                    <ReplayIcon />
                  </Button>
                </Tooltip>
              )}
              {elem.status !== ApiService.billingStatuses.cancelled && (
                <Tooltip title={t("mark-cancelled")}>
                  <Button
                    justIcon
                    round
                    simple
                    color="rose"
                    className="edit"
                    onClick={() => {
                      handleUpdateUserServiceBillingStatus(
                        elem,
                        ApiService.billingStatuses.cancelled
                      );
                    }}
                  >
                    <CancelIcon />
                  </Button>
                </Tooltip>
              )}
              <Tooltip title={t("billing-details")}>
                <Button
                  justIcon
                  round
                  simple
                  color="rose"
                  className="edit"
                  onClick={() => {
                    handleGetSingleBilling(elem);
                  }}
                >
                  <VisibilityIcon />
                </Button>
              </Tooltip>
              <Tooltip title={t("delete-billing")}>
                <Button
                  justIcon
                  color="rose"
                  simple
                  className="remove"
                  onClick={() => {
                    handleDeleteUserServiceBilling(elem);
                  }}
                >
                  <ClearIcon />
                </Button>
              </Tooltip>
            </React.Fragment>
          )}
        </div>
      );
      return elem;
    });
  };

  const handleSendEmail = (billing) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("send-email")}?`}
        onConfirm={() => {
          sendEmail(billing);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${billing._id}`}</b>
        </p>
      </SweetAlert>
    );
  };

  const sendEmail = async (billing) => {
    setAlert(null);
    try {
      const queryParams = await getQueryParams();
      const params = {
        userId: queryParams.userId,
        billingId: billing._id,
      };
      if (queryParams.parentId) {
        params.parentId = queryParams.parentId;
      }
      if (queryParams.parentType) {
        params.parentType = queryParams.parentType;
      }
      await ApiService.sendEmailBillingDocument(params);
      operationResult(true, t("email-successfully-sent"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const handleDeleteUserServiceBilling = (billing) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-billing")}?`}
        onConfirm={() => {
          deleteUserServiceBilling(billing);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${billing._id}`}</b>
        </p>
      </SweetAlert>
    );
  };

  const deleteUserServiceBilling = async (billing) => {
    setAlert(null);
    try {
      const queryParams = await getQueryParams();
      const params = {
        userId: queryParams.userId,
        billingId: billing._id,
      };
      if (queryParams.parentId) {
        params.parentId = queryParams.parentId;
      }
      if (queryParams.parentType) {
        params.parentType = queryParams.parentType;
      }
      await ApiService.deleteUserBilling(params);
      operationResult(true, t("billing-successfully-deleted"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const updateUserServiceBillingStatus = async (billing, status) => {
    setAlert(null);
    try {
      const queryParams = await getQueryParams();
      const params = {
        userId: queryParams.userId,
        billingId: billing._id,
        status,
      };
      if (queryParams.parentId) {
        params.parentId = queryParams.parentId;
      }
      if (queryParams.parentType) {
        params.parentType = queryParams.parentType;
      }
      await ApiService.updateUserBillingStatus(params);
      operationResult(true, t("billing-successfully-updated"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const handleUpdateUserServiceBillingStatus = (billing, status) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("update-billing-status")}
        onConfirm={() => {
          updateUserServiceBillingStatus(billing, status);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${status}`}</b>
        </p>
      </SweetAlert>
    );
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const handleAddServiceBilling = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }
    const queryParams = await getQueryParams();
    if (!queryParams.editEnabled) {
      clearModalState();
    }

    const billingId = modalServiceBillingId.trim();
    const billingDescription =
      modalServiceBillingDescription.trim().length > 0
        ? modalServiceBillingDescription.trim()
        : undefined;
    const billingAmount = modalServiceBillingAmount.trim();
    const validBillingAmount =
      validator.isDecimal(billingAmount) && new Big(billingAmount).gte(0);

    const checksPassed = validBillingAmount;
    if (!checksPassed) {
      const messageContent = [];
      if (!validBillingAmount) {
        messageContent.push(t("invalid-amount"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }

    const params = {
      userId: queryParams.userId,
      serviceId: queryParams.serviceId,
      subscriptionId: queryParams.subscriptionId,
      paymentMethod: modalServiceBillingPaymentMethod,
      status: modalServiceBillingStatus,
      amount: billingAmount,
      invoiceDate: modalServiceBillingInvoiceDate,
      startDate: modalServiceBillingStartDate,
      endDate: modalServiceBillingEndDate,
    };
    if (billingId !== "") {
      params.id = modalServiceBillingId;
    }
    if (queryParams.parentId) {
      params.parentId = queryParams.parentId;
    }
    if (queryParams.parentType) {
      params.parentType = queryParams.parentType;
    }
    if (billingDescription) {
      params.description = billingDescription;
    }

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("adding"));

    try {
      await ApiService.addUserBilling(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("billing-successfully-added"));
  };

  const handleDownloadDocument = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("download-document")}?`}
        onConfirm={() => {
          downloadDocument(elem);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{elem.type}</b>
        </p>
      </SweetAlert>
    );
  };

  const downloadDocument = async (doc) => {
    setAlert(null);
    try {
      const queryParams = await getQueryParams();
      const params = {
        userId: queryParams.userId,
        billingId: doc._id,
        creatorName: "UNISOT ID",
        documentTitle: "UNISOT ID Invoice",
        reportTitle: "Invoice",
      };
      if (queryParams.parentId) {
        params.parentId = queryParams.parentId;
      }
      if (queryParams.parentType) {
        params.parentType = queryParams.parentType;
      }
      const responseData = await ApiService.downloadBillingDocument(params);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = "invoice.pdf";
      a.click();
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const handleRefresh = () => {
    setFilters([]);
    setFilterParams([]);
    setRefreshClicked((prevCheck) => !prevCheck);
    setPaymentSession({});
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getUserCredits = (userProfileData) => {
      return {
        credits: userProfileData.credits,
        topUpEnabled: !!userProfileData.services.find(
          (entry) => entry.type === ApiService.serviceTypes.topUp
        ),
      };
    };

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      if (queryParams.get("parentId")) {
        params.parentId = queryParams.get("parentId");
      }
      if (queryParams.get("parentType")) {
        params.parentType = queryParams.get("parentType");
      }
      const returnData = await ApiService.loginRequired(signal, false);
      if (
        (returnData.superuser ||
          params.parentType === ApiService.parentTypes.organization) &&
        params.userId
      ) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        setUserCredits(getUserCredits(userProfileData));
        params.userId =
          typeof userProfileData.superuser !== "undefined"
            ? userProfileData._id
            : returnData._id;
      } else {
        params.userId = returnData._id;
        setUserCredits(getUserCredits(returnData));
      }
      params.superuser = returnData.superuser;
      const serviceId = queryParams.get("serviceId");
      const subscriptionId = queryParams.get("subscriptionId");
      if (serviceId) {
        params.serviceId = serviceId;
      }
      if (subscriptionId) {
        params.subscriptionId = subscriptionId;
      }
      params.editEnabled =
        params.superuser && params.serviceId && params.subscriptionId;
      setEditEnabled(params.editEnabled);
      return params;
    };

    const getUserServiceBillings = async (signal = undefined) => {
      try {
        const queryParams = await getQueryParams(signal);
        const params = {
          userId: queryParams.userId,
          excludePendingTopUps: "true",
          sortBy: sortBy,
          fromEntry: currentPage * pageSize,
          numberOfResults: pageSize,
        };

        if (filterParams.length > 0) {
          const filteredObj = {};
          filterParams.map((filter) => {
            const filteredField = filter.id.replace(/Formatted$/, "");
            if (ApiService.exactMatch.includes(filter.id)) {
              return (filteredObj[filteredField] =
                filter.id === "fileSize"
                  ? parseInt(filter.value)
                  : filter.value);
            } else {
              return (filteredObj[filteredField] = {
                $regex: `^${filter.value}`,
              });
            }
          });

          params.filters = JSON.stringify(filteredObj);
        }

        if (queryParams.parentId) {
          params.parentId = queryParams.parentId;
        }
        if (queryParams.parentType) {
          params.parentType = queryParams.parentType;
        }
        if (queryParams.serviceId) {
          params.serviceId = queryParams.serviceId;
        }
        if (queryParams.subscriptionId) {
          params.subscriptionId = queryParams.subscriptionId;
        }
        const responseData = await ApiService.getUserBillings(params, signal);
        setSuperUser(queryParams.superuser);
        setUserServiceBillings(responseData);

        delete params.sortBy;
        delete params.fromEntry;
        delete params.numberOfResults;
        params.count = true;
        const { count: userBillingsCount } = await ApiService.getUserBillings(
          params,
          signal
        );
        setCount(userBillingsCount);
      } catch (e) {
        console.error(e);
      } finally {
        setFilterClicked(false);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await getUserServiceBillings(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [
    props.location.search,
    refreshClicked,
    currentPage,
    pageSize,
    sortBy,
    filterParams,
  ]);

  React.useEffect(() => {
    if (filterClicked && filters.length > 0) {
      setFilterParams(filters);
    }
  }, [filters, filterClicked]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && count > 0) {
      setTotalPages(Math.ceil(count / pageSize));
    }

    return () => {
      isMounted = false;
    };
  }, [count, pageSize]);

  const handlePageChange = async (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePageSizeChange = async (newPageSize, pageIndex) => {
    const total = Math.ceil(count / newPageSize);
    setTotalPages(total);

    let currentIndex;
    if (pageSize > newPageSize) {
      currentIndex = Math.ceil(pageIndex / (pageSize / newPageSize));
    } else {
      currentIndex = Math.ceil((pageSize / newPageSize) * pageIndex);
    }
    setCurrentPage(currentIndex);

    setPageSize(newPageSize);
  };

  const handleSortChange = (newSorted) => {
    setSortBy(
      `${newSorted[0].id.replace(/Formatted$/, "")}:${
        newSorted[0].desc === true ? "desc" : "asc"
      }`
    );
  };

  const handleFilterColumn = (filtered, column) => {
    setFilters(filtered);
  };

  const handleFilterClicked = () => {
    setFilterClicked(true);
  };

  const handleGetSingleBilling = async (billing) => {
    const queryParams = await getQueryParams();
    const params = {
      userId: queryParams.userId,
      billingId: billing._id,
    };
    if (queryParams.parentId) {
      params.parentId = queryParams.parentId;
    }
    if (queryParams.parentType) {
      params.parentType = queryParams.parentType;
    }
    try {
      const responseData = await ApiService.getSingleUserBilling(params);
      setFormatDetails(true);
      setDetailsTitle(t("billing-details"));
      setDetails(responseData);
      setShowDetailsModal(true);
    } catch (e) {
      console.error(e);
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showDetailsModal}
        transition={Transition}
        keepMounted
        scroll="body"
        fullWidth
        maxWidth="sm"
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowDetailsModal(false);
            setFormatDetails(true);
            setDetailsTitle("");
            setDetails({});
          }
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>{detailsTitle}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            {formatDetails &&
              Object.entries(details).map(([fieldName, fieldValue]) => {
                let data;
                if (typeof fieldValue === "object") {
                  data = (
                    <div style={{ whiteSpace: "pre-wrap" }}>
                      {JSON.stringify(fieldValue, undefined, 2)}
                    </div>
                  );
                } else {
                  if (dialogDetailsInputFields.has(fieldName)) {
                    data = (
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          value: fieldValue.toString(),
                        }}
                      />
                    );
                  } else {
                    data = (
                      <React.Fragment>{fieldValue.toString()}</React.Fragment>
                    );
                  }
                }
                return (
                  <React.Fragment key={uuidv4()}>
                    <GridItem
                      xs={12}
                      sm={6}
                      md={4}
                      style={{ fontWeight: "400" }}
                    >
                      {dialogDetailsInputFields.has(fieldName) ? (
                        <FormLabel
                          className={formClasses.labelHorizontal}
                          style={{ float: "left" }}
                        >
                          {fieldName}
                        </FormLabel>
                      ) : (
                        fieldName
                      )}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={6}
                      md={8}
                      className={classes.wordBreak}
                    >
                      {data}
                    </GridItem>
                  </React.Fragment>
                );
              })}
            {!formatDetails && (
              <div style={{ whiteSpace: "pre-wrap" }}>
                {JSON.stringify(details, undefined, 2)}
              </div>
            )}
          </GridContainer>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button
            onClick={() => {
              setShowDetailsModal(false);
              setFormatDetails(true);
              setDetailsTitle("");
              setDetails({});
            }}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModalProvider}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("payment-gateway-payment")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleProviderPaymentMethod}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("payment-method")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="billing-payment-method"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalProviderPaymentMethod}
                    onChange={(e) => {
                      setModalProviderPaymentMethod(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "billingPaymentMethod",
                      id: "billingPaymentMethod",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("payment-method")}
                    </MenuItem>
                    {Object.values(ApiService.serviceSubscriptionPaymentMethods)
                      .filter(
                        (entry) =>
                          entry !==
                          ApiService.serviceSubscriptionPaymentMethods.invoice
                      )
                      .map((paymentMethod) => {
                        return (
                          <MenuItem
                            key={paymentMethod}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={paymentMethod}
                          >
                            {paymentMethod}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("amount")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="billing-amount"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalProviderPaymentAmount,
                    disabled: !!modalProviderExistingBilling,
                    onChange: (e) => {
                      setModalProviderPaymentAmount(e.target.value);
                    },
                  }}
                  helpText={t("billing-amount")}
                />
              </GridItem>
            </GridContainer>
            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("add-billing")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleAddServiceBilling}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("id")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="billing-id"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalServiceBillingId,
                    onChange: (e) => {
                      setModalServiceBillingId(e.target.value);
                    },
                  }}
                  helpText={t("id")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("payment-method")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="billing-payment-method"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalServiceBillingPaymentMethod}
                    onChange={(e) => {
                      setModalServiceBillingPaymentMethod(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "billingPaymentMethod",
                      id: "billingPaymentMethod",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("payment-method")}
                    </MenuItem>
                    {Object.values(
                      ApiService.serviceSubscriptionPaymentMethods
                    ).map((paymentMethod) => {
                      return (
                        <MenuItem
                          key={paymentMethod}
                          classes={{
                            root: formClasses.selectMenuItem,
                            selected:
                              formClasses.selectMenuItemSelectedMultiple,
                          }}
                          value={paymentMethod}
                        >
                          {paymentMethod}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("status")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="billing-status"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalServiceBillingStatus}
                    onChange={(e) => {
                      setModalServiceBillingStatus(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "billingStatus",
                      id: "billingStatus",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("status")}
                    </MenuItem>
                    {Object.values(ApiService.billingStatuses).map(
                      (billingStatus) => {
                        return (
                          <MenuItem
                            key={billingStatus}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={billingStatus}
                          >
                            {billingStatus}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("invoice-date")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControl fullWidth>
                    <Datetime
                      dateFormat={"YYYY-MM-DD"}
                      timeFormat={false}
                      inputProps={{
                        placeholder: t("select-date"),
                        disabled: showModalProgress,
                      }}
                      value={modalServiceBillingInvoiceDate}
                      onChange={(e) => {
                        setModalServiceBillingInvoiceDate(e);
                      }}
                    />
                  </FormControl>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("start-date")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControl fullWidth>
                    <Datetime
                      dateFormat={"YYYY-MM-DD"}
                      timeFormat={false}
                      inputProps={{
                        placeholder: t("select-date"),
                        disabled: showModalProgress,
                      }}
                      value={modalServiceBillingStartDate}
                      onChange={(e) => {
                        setModalServiceBillingStartDate(e);
                      }}
                    />
                  </FormControl>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("end-date")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControl fullWidth>
                    <Datetime
                      dateFormat={"YYYY-MM-DD"}
                      timeFormat={false}
                      inputProps={{
                        placeholder: t("select-date"),
                        disabled: showModalProgress,
                      }}
                      value={modalServiceBillingEndDate}
                      onChange={(e) => {
                        setModalServiceBillingEndDate(e);
                      }}
                    />
                  </FormControl>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("amount")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="billing-amount"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalServiceBillingAmount,
                    onChange: (e) => {
                      setModalServiceBillingAmount(e.target.value);
                    },
                  }}
                  helpText={t("billing-amount")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("description")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="billing-description"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalServiceBillingDescription,
                    onChange: (e) => {
                      setModalServiceBillingDescription(e.target.value);
                    },
                  }}
                  helpText={t("billing-description")}
                />
              </GridItem>
            </GridContainer>
            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="turq" stats icon>
              <CardIcon color="turq">
                <CreditCard style={{ color: roseColor[0] }} />
              </CardIcon>
              <p className={classes.cardCategory}>{t("credits")}</p>
              <h3 className={classes.cardTitle}>{userCredits.credits}</h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <AccountBalanceIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("billings")} {formattedUserName}
              </h4>
              {!paymentSession.session && (
                <div style={{ marginLeft: "auto" }}>
                  {userCredits.topUpEnabled && (
                    <Button
                      color="primary"
                      style={{ marginTop: "20px" }}
                      round
                      onClick={() => {
                        setConfirmModalButtonText(t("pay"));
                        setShowModalProvider(true);
                      }}
                    >
                      {t("top-up")}
                    </Button>
                  )}
                  <Button
                    simple
                    color="rose"
                    className="remove"
                    onClick={() => handleRefresh()}
                    style={{ marginTop: "28px" }}
                  >
                    {t("refresh")}
                  </Button>
                  {editEnabled && (
                    <Button
                      color="primary"
                      style={{ marginTop: "20px" }}
                      round
                      onClick={() => {
                        setShowModal(true);
                      }}
                    >
                      {t("add")}
                    </Button>
                  )}
                </div>
              )}
            </CardHeader>
            <CardBody>
              {filters.length > 0 && (
                <React.Fragment>
                  <Button
                    color="primary"
                    round
                    className="remove"
                    onClick={() => {
                      handleFilterClicked();
                    }}
                  >
                    {t("filter")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    className="remove"
                    onClick={() => {
                      handleRefresh();
                    }}
                  >
                    {t("clear-filters")}
                  </Button>
                </React.Fragment>
              )}
              {paymentSession.session &&
                paymentSession.session.paymentProvider ===
                  ApiService.paymentProvider.stripe && (
                  <Elements stripe={stripePromise}>
                    <StripeCheckout
                      paymentSessionId={paymentSession.session.paymentSessionId}
                      showDialog={paymentSession.showDialog}
                      handleRefresh={handleRefresh}
                    />
                  </Elements>
                )}
              <ReactTable
                data={handleReturnData(userServiceBillings, superUser)}
                sortable={true}
                multiSort={false}
                filterable={true}
                filtered={filters}
                columns={[
                  {
                    Header: t("id"),
                    accessor: "_id",
                  },
                  {
                    Header: t("description"),
                    accessor: "description",
                  },
                  {
                    Header: t("payment-method"),
                    accessor: "paymentMethod",
                  },
                  {
                    Header: t("status"),
                    accessor: "status",
                  },
                  {
                    Header: t("amount"),
                    accessor: "amount",
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                  {
                    Header: t("start-date"),
                    accessor: "startDateFormatted",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                    filterable: false,
                  },
                  {
                    Header: t("end-date"),
                    accessor: "endDateFormatted",
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                    filterable: false,
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
                manual
                pages={totalPages}
                page={currentPage}
                pageSize={pageSize}
                onPageChange={(pageIndex) => handlePageChange(pageIndex)}
                onPageSizeChange={(pageSize, pageIndex) =>
                  handlePageSizeChange(pageSize, pageIndex)
                }
                onSortedChange={(newSorted, column, shiftKey) =>
                  handleSortChange(newSorted, column, shiftKey)
                }
                onFilteredChange={(filtered, column) =>
                  handleFilterColumn(filtered, column)
                }
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
};

UserServicesBillings.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default UserServicesBillings;
