import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import Table from "components/Table/Table.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";
import CardFooter from "components/Card/CardFooter.js";

import ComputerIcon from "@material-ui/icons/Computer";
import ListIcon from "@material-ui/icons/List";
import Business from "@material-ui/icons/Business";
import DataUsage from "@material-ui/icons/DataUsage";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);

const AdminDeploymentMachine = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [loading, setLoading] = React.useState(true);
  const [healthCheck, setHealthCheck] = React.useState(false);

  // System deployment results
  const [deployment, setDeployment] = React.useState({});
  const [machine, setMachine] = React.useState({});
  const [healthCheckResults, setHealthCheckResults] = React.useState({});

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal, true, true);
        const responseData = await ApiService.getSystemOverview(signal);
        const queryParams = new URLSearchParams(props.location.search);
        const deploymentName = queryParams.get("deployment");
        const machineName = queryParams.get("machine");
        const queryDeployment = responseData.find(
          (entry) => entry.deployment === deploymentName
        );
        if (!queryDeployment) {
          return;
        }
        const queryMachine = queryDeployment.machines.find(
          (entry) => entry.name === machineName
        );
        if (!queryMachine) {
          return;
        }
        setDeployment(queryDeployment);
        setMachine(queryMachine);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && ApiService.isObjectEmpty(deployment) && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [deployment, machine, props.location.search]);

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const checkMachineServices = async (signal) => {
      const checks = {
        valid: 0,
        total: 0,
      };
      // eslint-disable-next-line no-unused-vars
      for (const service of machine.services) {
        if (service.enabled && !!service.healthCheck) {
          try {
            await ApiService.healthCheck(service.healthCheck, signal);
            checks[service.name] = t("healthy");
            checks.valid += 1;
          } catch (err) {
            checks[service.name] = t("unhealthy");
            console.error(err);
          }
          checks.total += 1;
        }
      }
      return checks;
    };

    const apiOperations = async () => {
      try {
        const checks = await checkMachineServices(signal);
        setHealthCheck(false);
        setHealthCheckResults(checks);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && healthCheck && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [healthCheck, machine, healthCheckResults, t]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      {!ApiService.isObjectEmpty(deployment) &&
        !ApiService.isObjectEmpty(machine) && (
          <React.Fragment>
            <GridContainer>
              <GridItem xs={3}>
                <Card>
                  <CardHeader color="turq" stats icon>
                    <CardIcon color="turq">
                      <ComputerIcon style={{ color: roseColor[0] }} />
                    </CardIcon>
                    <p className={classes.cardCategory}>{t("machine")}</p>
                    <h3 className={classes.cardTitle}>{machine.name}</h3>
                  </CardHeader>
                  <CardFooter stats></CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={3}>
                <Card>
                  <CardHeader color="turq" stats icon>
                    <CardIcon color="turq">
                      <Business style={{ color: roseColor[0] }} />
                    </CardIcon>
                    <p className={classes.cardCategory}>{t("deployment")}</p>
                    <h3 className={classes.cardTitle}>
                      {deployment.deployment}
                    </h3>
                  </CardHeader>
                  <CardFooter stats></CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={3}>
                <Card>
                  <CardHeader color="turq" stats icon>
                    <CardIcon color="turq">
                      <DataUsage style={{ color: roseColor[0] }} />
                    </CardIcon>
                    <p className={classes.cardCategory}>{t("provider")}</p>
                    <h3 className={classes.cardTitle}>{deployment.provider}</h3>
                  </CardHeader>
                  <CardFooter stats></CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={3}>
                <Card>
                  <CardHeader color="turq" stats icon>
                    <CardIcon color="turq">
                      <ListIcon style={{ color: roseColor[0] }} />
                    </CardIcon>
                    <p className={classes.cardCategory}>{t("type")}</p>
                    <h3 className={classes.cardTitle}>{deployment.type}</h3>
                  </CardHeader>
                  <CardFooter stats></CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4}>
                <Card>
                  <CardBody>
                    <h3>{t("machine")}</h3>
                    <br />
                    <Table
                      tableData={[
                        [t("name"), machine.name],
                        [t("cluster"), machine.cluster],
                        [t("description"), machine.description],
                        [t("region"), machine.region],
                        [t("zone"), machine.zone],
                        [
                          t("total-checks"),
                          machine.services.filter(
                            (service) =>
                              service.enabled && !!service.healthCheck
                          ).length,
                        ],
                        [
                          t("total-services"),
                          machine.services.filter((service) => service.enabled)
                            .length,
                        ],
                      ]}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={4}>
                <Card>
                  <CardBody>
                    <h3>{t("specs")}</h3>
                    <br />
                    <Table
                      tableData={[
                        [t("os"), machine.system.OS],
                        [t("cpu"), machine.system.CPU],
                        [t("ram"), machine.system.RAM],
                        ...machine.system.disks.map((entry) => [
                          entry.description,
                          `${entry.capacity} ${entry.type}`,
                        ]),
                        ...machine.system.ip.map((entry) => [
                          t("ip"),
                          `${entry.address} (${entry.type})`,
                        ]),
                        ...machine.system.ip.map((entry) => [
                          t("dns"),
                          entry.dns,
                        ]),
                      ]}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={4}>
                <Card>
                  <CardBody>
                    <h3>{t("roles")}</h3>
                    <br />
                    <Table
                      tableData={machine.roles
                        .filter((entry) => entry.enabled)
                        .map((entry) => [entry.name])}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardHeader>
                    <GridContainer
                      justifyContent="space-between"
                      alignItems="center"
                      direction="row"
                    >
                      <GridItem xs={3}>
                        <h3>
                          {t("services")}{" "}
                          {!ApiService.isObjectEmpty(healthCheckResults) &&
                            `(${healthCheckResults.valid} / ${healthCheckResults.total})`}
                        </h3>
                      </GridItem>
                      <GridItem xs={9} style={{ textAlign: "right" }}>
                        <Button
                          simple
                          color="rose"
                          className="remove"
                          disabled={healthCheck}
                          onClick={() => {
                            setHealthCheck(true);
                          }}
                          style={{ marginTop: "28px" }}
                        >
                          {t("health-check")}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </CardHeader>
                  <CardBody>
                    <ReactTable
                      data={machine.services
                        .filter((entry) => entry.enabled)
                        .map((entry) => {
                          return {
                            ...entry,
                            healthCheckResult: entry.healthCheck
                              ? healthCheckResults[entry.name]
                                ? healthCheckResults[entry.name]
                                : "-"
                              : "-",
                          };
                        })}
                      filterable
                      columns={[
                        {
                          Header: t("type"),
                          accessor: "type",
                        },
                        {
                          Header: t("name"),
                          accessor: "name",
                        },
                        {
                          Header: t("description"),
                          accessor: "description",
                        },
                        {
                          Header: t("health-check"),
                          accessor: "healthCheckResult",
                        },
                        {
                          Header: t("uri-0"),
                          accessor: "uri",
                          sortable: false,
                          filterable: false,
                          headerStyle: { textAlign: "right" },
                          Cell: (row) => (
                            <div style={{ textAlign: "right" }}>
                              <a
                                style={{ color: roseColor[0] }}
                                href={row.value}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {row.value}
                              </a>
                            </div>
                          ),
                        },
                        {
                          Header: t("backend-uri"),
                          accessor: "backendUri",
                          sortable: false,
                          filterable: false,
                          headerStyle: { textAlign: "right" },
                          Cell: (row) => (
                            <div style={{ textAlign: "right" }}>
                              {row.value ? (
                                <a
                                  style={{ color: roseColor[0] }}
                                  href={row.value}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {row.value}
                                </a>
                              ) : (
                                "-"
                              )}
                            </div>
                          ),
                        },
                        {
                          Header: `${t("health-check")} ${t("uri-0")}`,
                          accessor: "healthCheck",
                          sortable: false,
                          filterable: false,
                          headerStyle: { textAlign: "right" },
                          Cell: (row) => (
                            <div style={{ textAlign: "right" }}>
                              {row.value ? (
                                <a
                                  style={{ color: roseColor[0] }}
                                  href={row.value}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {row.value}
                                </a>
                              ) : (
                                "-"
                              )}
                            </div>
                          ),
                        },
                      ]}
                      defaultPageSize={ApiService.defaultPageSize}
                      showPaginationTop={false}
                      showPaginationBottom
                      className="-striped -highlight"
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </React.Fragment>
        )}
    </div>
  );
};

AdminDeploymentMachine.propTypes = {
  location: PropTypes.object.isRequired,
};

export default AdminDeploymentMachine;
