import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import validator from "validator";
import Big from "big.js";
import moment from "moment";

import { Link } from "react-router-dom";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import Tooltip from "@material-ui/core/Tooltip";
/*#############################
  # Modal view dialog classes #
  #############################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyle);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const UserServicesSubscriptions = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
  const [userServiceSubscriptions, setUserServiceSubscriptions] =
    React.useState([]);
  const [formattedUserName, setFormattedUserName] = React.useState("");
  const [editEnabled, setEditEnabled] = React.useState(false);

  // Modal states
  const [showModal, setShowModal] = React.useState(false);
  const [showModalPricingPlan, setShowModalPricingPlan] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("add")
  );

  const [refreshClicked, setRefreshClicked] = React.useState(false);
  const [query, setQuery] = React.useState({});

  // Dialog fields
  const [modalServiceEditId, setModalServiceEditId] = React.useState("");
  const [modalServiceSubscriptionName, setModalServiceSubscriptionName] =
    React.useState("");
  const [modalServiceSubscriptionPlan, setModalServiceSubscriptionPlan] =
    React.useState(ApiService.serviceSubscriptionPlans.operation);
  const [
    modalServiceSubscriptionPaymentMethod,
    setModalServiceSubscriptionPaymentMethod,
  ] = React.useState(ApiService.serviceSubscriptionPaymentMethods.invoice);
  const [modalServiceSubscriptionStatus, setModalServiceSubscriptionStatus] =
    React.useState(ApiService.serviceSubscriptionStatuses.active);
  const [
    modalServiceSubscriptionActivationDate,
    setModalServiceSubscriptionActivationDate,
  ] = React.useState(moment().utc().startOf("day"));
  const [modalServiceSubscriptionPrice, setModalServiceSubscriptionPrice] =
    React.useState("0");
  const [modalRequestedPricingPlan, setModalRequestedPricingPlan] =
    React.useState(false);
  const [modalRequestPricingPlan, setModalRequestPricingPlan] = React.useState(
    []
  );
  const [modalPricingPlan, setModalPricingPlan] = React.useState("");
  const [pricingPlans, setPricingPlans] = React.useState([]);

  const getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    if (queryParams.get("parentId")) {
      params.parentId = queryParams.get("parentId");
    }
    if (queryParams.get("parentType")) {
      params.parentType = queryParams.get("parentType");
    }
    const returnData = await ApiService.loginRequired(signal, false);
    if (
      (returnData.superuser ||
        params.parentType === ApiService.parentTypes.organization) &&
      params.userId
    ) {
      const userProfileData = await ApiService.readUser(params, signal);
      setFormattedUserName(
        ApiService.getFormattedUserName(returnData._id, userProfileData)
      );
      params.userId =
        typeof userProfileData.superuser !== "undefined"
          ? userProfileData._id
          : returnData._id;
      params.requestPricingPlan = userProfileData.requestPricingPlan;
      params.pricingPlanName = userProfileData.pricingPlanName;
    } else {
      params.userId = returnData._id;
      params.requestPricingPlan = returnData.requestPricingPlan;
      params.pricingPlanName = returnData.pricingPlanName;
    }
    params.superuser = returnData.superuser;
    const serviceId = queryParams.get("serviceId");
    if (serviceId) {
      params.serviceId = serviceId;
    }
    params.editEnabled = params.superuser && params.serviceId;
    setEditEnabled(params.editEnabled);
    return params;
  };

  const clearModalState = () => {
    setShowModal(false);
    setShowModalPricingPlan(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("add"));
    setModalServiceEditId("");
    setModalServiceSubscriptionName("");
    setModalServiceSubscriptionPlan(
      ApiService.serviceSubscriptionPlans.operation
    );
    setModalServiceSubscriptionPaymentMethod(
      ApiService.serviceSubscriptionPaymentMethods.invoice
    );
    setModalServiceSubscriptionStatus(
      ApiService.serviceSubscriptionStatuses.active
    );
    setModalServiceSubscriptionActivationDate(moment().utc().startOf("day"));
    setModalServiceSubscriptionPrice("0");
    setModalRequestedPricingPlan(false);
    setModalRequestPricingPlan([]);
    setModalPricingPlan("");
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const handleReturnData = (
    data,
    userId,
    superuser,
    parentId = undefined,
    parentType = undefined
  ) => {
    return data.map((elem) => {
      const params = {
        userId,
        serviceId: elem.serviceId,
        subscriptionId: elem._id,
      };
      if (parentId) {
        params.parentId = parentId;
      }
      if (parentType) {
        params.parentType = parentType;
      }
      const queryParameters = new URLSearchParams(params).toString();
      elem.activationDateFormatted = ApiService.formatDateTime(
        elem.activationDate
      );
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("billing-details")}>
            <Link to={`/admin/user-billings?${queryParameters}`}>
              <Button justIcon round simple color="rose" className="like">
                <MoreVertIcon />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip title={t("cancel-subscription")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                handleCancelUserServiceSubscription(elem);
              }}
            >
              <CancelIcon />
            </Button>
          </Tooltip>
          {superuser && (
            <React.Fragment>
              <Tooltip title={t("edit-subscription-plan")}>
                <Button
                  justIcon
                  round
                  simple
                  color="rose"
                  className="edit"
                  onClick={() => {
                    setModalServiceEditId(elem.serviceId);
                    setModalServiceSubscriptionName(elem.name);
                    setModalServiceSubscriptionPlan(elem.plan);
                    setModalServiceSubscriptionPaymentMethod(
                      elem.paymentMethod
                    );
                    setModalServiceSubscriptionStatus(elem.status);
                    setModalServiceSubscriptionActivationDate(
                      moment(elem.activationDate)
                    );
                    setModalServiceSubscriptionPrice(elem.price);
                    setShowModal(true);
                  }}
                >
                  <EditIcon />
                </Button>
              </Tooltip>
              <Tooltip title={t("remove-subscription")}>
                <Button
                  justIcon
                  color="rose"
                  simple
                  className="remove"
                  onClick={() => {
                    handleDeleteUserServiceSubscription(elem);
                  }}
                >
                  <ClearIcon />
                </Button>
              </Tooltip>
            </React.Fragment>
          )}
        </div>
      );
      return elem;
    });
  };

  const handleCancelUserServiceSubscription = (subscription) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("cancel-subscription")}?`}
        onConfirm={() => {
          cancelUserServiceSubscription(subscription);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${subscription.name}`}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleDeleteUserServiceSubscription = (subscription) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-subscription")}?`}
        onConfirm={() => {
          deleteUserServiceSubscription(subscription);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${subscription.name}`}</b>
        </p>
      </SweetAlert>
    );
  };

  const cancelUserServiceSubscription = async (subscription) => {
    setAlert(null);
    try {
      const queryParams = await getQueryParams();
      const params = {
        userId: queryParams.userId,
        serviceId: subscription.serviceId,
        subscriptionId: subscription._id,
      };
      if (queryParams.parentId) {
        params.parentId = queryParams.parentId;
      }
      if (queryParams.parentType) {
        params.parentType = queryParams.parentType;
      }
      await ApiService.cancelUserSubscription(params);
      operationResult(true, t("subscription-successfully-cancelled"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const deleteUserServiceSubscription = async (subscription) => {
    setAlert(null);
    try {
      const queryParams = await getQueryParams();
      const params = {
        userId: queryParams.userId,
        serviceId: subscription.serviceId,
        subscriptionId: subscription._id,
      };
      if (queryParams.parentId) {
        params.parentId = queryParams.parentId;
      }
      if (queryParams.parentType) {
        params.parentType = queryParams.parentType;
      }
      await ApiService.deleteUserSubscription(params);
      operationResult(true, t("subscription-successfully-deleted"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const handleAddServiceSubscription = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }
    const queryParams = await getQueryParams();

    const subscriptionName =
      modalServiceSubscriptionName.trim().length > 0
        ? modalServiceSubscriptionName.trim()
        : undefined;
    const subscriptionPrice = modalServiceSubscriptionPrice.trim();
    const validSubscriptionPrice =
      validator.isDecimal(subscriptionPrice) &&
      new Big(subscriptionPrice).gte(0);

    const checksPassed = validSubscriptionPrice;
    if (!checksPassed) {
      const messageContent = [];
      if (!validSubscriptionPrice) {
        messageContent.push(t("invalid-price"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }

    const params = {
      userId: queryParams.userId,
      serviceId: modalServiceEditId || queryParams.serviceId,
      plan: modalServiceSubscriptionPlan,
      paymentMethod: modalServiceSubscriptionPaymentMethod,
      price: subscriptionPrice,
      status: modalServiceSubscriptionStatus,
      activationDate: modalServiceSubscriptionActivationDate,
    };
    if (queryParams.parentId) {
      params.parentId = queryParams.parentId;
    }
    if (queryParams.parentType) {
      params.parentType = queryParams.parentType;
    }
    if (subscriptionName) {
      params.name = subscriptionName;
    }
    if (modalPricingPlan.length > 0) {
      params.pricingPlanName = modalPricingPlan;
    }

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("adding"));

    try {
      await ApiService.upsertUserSubscription(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("subscription-successfully-added"));
  };

  const handleRequestPricingPlanUpdate = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const queryParams = await getQueryParams();
    const requestPricingPlan = modalRequestPricingPlan.length > 0;
    const pricingPlanName = modalPricingPlan.trim();
    const params = {
      userId: queryParams.userId,
      requestPricingPlan,
    };
    if (pricingPlanName.length > 0) {
      params.name = pricingPlanName;
    }
    if (queryParams.parentId) {
      params.parentId = queryParams.parentId;
    }
    if (queryParams.parentType) {
      params.parentType = queryParams.parentType;
    }

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("sending"));

    try {
      await ApiService.requestPricingPlanUpdate(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(
      t("request-for-pricing-plan-successfully-sent")
    );
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;

    const abortController = new AbortController();
    const signal = abortController.signal;

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      if (queryParams.get("parentId")) {
        params.parentId = queryParams.get("parentId");
      }
      if (queryParams.get("parentType")) {
        params.parentType = queryParams.get("parentType");
      }
      const returnData = await ApiService.loginRequired(signal, false);
      if (
        (returnData.superuser ||
          params.parentType === ApiService.parentTypes.organization) &&
        params.userId
      ) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId =
          typeof userProfileData.superuser !== "undefined"
            ? userProfileData._id
            : returnData._id;
        params.requestPricingPlan = userProfileData.requestPricingPlan;
        params.pricingPlanName = userProfileData.pricingPlanName;
      } else {
        params.userId = returnData._id;
        params.requestPricingPlan = returnData.requestPricingPlan;
        params.pricingPlanName = returnData.pricingPlanName;
      }
      params.superuser = returnData.superuser;
      const serviceId = queryParams.get("serviceId");
      if (serviceId) {
        params.serviceId = serviceId;
      }
      params.editEnabled = params.superuser && params.serviceId;
      setEditEnabled(params.editEnabled);
      return params;
    };

    const getUserServiceSubscriptions = async (signal = undefined) => {
      try {
        // Fetch actual pricing plans
        const responsePricingPlans = await ApiService.getPricingPlans(
          {
            sortBy: "name:asc",
          },
          signal
        );
        setPricingPlans(
          responsePricingPlans.map((pricingPlan) => pricingPlan.name)
        );

        // Fetch service subscriptions
        const queryParams = await getQueryParams(signal);
        const params = {
          userId: queryParams.userId,
        };
        if (queryParams.parentId) {
          params.parentId = queryParams.parentId;
        }
        if (queryParams.parentType) {
          params.parentType = queryParams.parentType;
        }
        if (queryParams.serviceId) {
          params.serviceId = queryParams.serviceId;
        }
        const responseData = await ApiService.getUserSubscriptions(
          params,
          signal
        );
        setQuery(queryParams);
        setUserServiceSubscriptions(responseData);
      } catch (e) {
        console.error(e);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await getUserServiceSubscriptions(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [props.location.search, refreshClicked]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModalPricingPlan}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("request-pricing-plan-update")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleRequestPricingPlanUpdate}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            {modalRequestedPricingPlan && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    style={{ float: "left" }}
                  >
                    {t("status")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <CustomInput
                    id="pricing-plan-request"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      disabled: true,
                      value: "PENDING",
                    }}
                    helpText={t("pricing-plan-request-status")}
                  />
                </GridItem>
              </GridContainer>
            )}
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("request")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9} style={{ float: "left" }}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => {
                          const currentIndex =
                            modalRequestPricingPlan.indexOf(1);
                          const newChecked = [...modalRequestPricingPlan];
                          if (currentIndex === -1) {
                            newChecked.push(1);
                          } else {
                            newChecked.splice(currentIndex, 1);
                          }
                          setModalRequestPricingPlan(newChecked);
                        }}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot,
                        }}
                        checked={modalRequestPricingPlan.length > 0}
                        disabled={showModalProgress}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot,
                    }}
                    label={t("request-pricing-plan-update-0")}
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("pricing-plan")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="pricing-plan"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalPricingPlan}
                    onChange={(e) => {
                      setModalPricingPlan(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "pricingPlan",
                      id: "pricingPlan",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("pricing-plan")}
                    </MenuItem>
                    {pricingPlans.map((pricingPlan) => {
                      return (
                        <MenuItem
                          key={pricingPlan}
                          classes={{
                            root: formClasses.selectMenuItem,
                            selected:
                              formClasses.selectMenuItemSelectedMultiple,
                          }}
                          value={pricingPlan}
                        >
                          {pricingPlan}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("add-subscription")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleAddServiceSubscription}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("name")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="subscription-name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalServiceSubscriptionName,
                    onChange: (e) => {
                      setModalServiceSubscriptionName(e.target.value);
                    },
                  }}
                  helpText={t("subscription-name")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("activation-date")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControl fullWidth>
                    <Datetime
                      dateFormat={"YYYY-MM-DD"}
                      timeFormat={false}
                      inputProps={{
                        placeholder: t("select-date"),
                        disabled: showModalProgress,
                      }}
                      value={modalServiceSubscriptionActivationDate}
                      onChange={(e) => {
                        setModalServiceSubscriptionActivationDate(e);
                      }}
                    />
                  </FormControl>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("plan")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="subscription-plan"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalServiceSubscriptionPlan}
                    onChange={(e) => {
                      setModalServiceSubscriptionPlan(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "subscriptionPlan",
                      id: "subscriptionPlan",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("plan")}
                    </MenuItem>
                    {Object.values(ApiService.serviceSubscriptionPlans).map(
                      (subscriptionPlan) => {
                        return (
                          <MenuItem
                            key={subscriptionPlan}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={subscriptionPlan}
                          >
                            {subscriptionPlan}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("pricing-plan")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="pricing-plan"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalPricingPlan}
                    onChange={(e) => {
                      setModalPricingPlan(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "pricingPlan",
                      id: "pricingPlan",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("pricing-plan")}
                    </MenuItem>
                    {pricingPlans.map((pricingPlan) => {
                      return (
                        <MenuItem
                          key={pricingPlan}
                          classes={{
                            root: formClasses.selectMenuItem,
                            selected:
                              formClasses.selectMenuItemSelectedMultiple,
                          }}
                          value={pricingPlan}
                        >
                          {pricingPlan}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("payment-method")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="subscription-payment-method"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalServiceSubscriptionPaymentMethod}
                    onChange={(e) => {
                      setModalServiceSubscriptionPaymentMethod(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "subscriptionPaymentMethod",
                      id: "subscriptionPaymentMethod",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("payment-method")}
                    </MenuItem>
                    {Object.values(
                      ApiService.serviceSubscriptionPaymentMethods
                    ).map((paymentMethod) => {
                      return (
                        <MenuItem
                          key={paymentMethod}
                          classes={{
                            root: formClasses.selectMenuItem,
                            selected:
                              formClasses.selectMenuItemSelectedMultiple,
                          }}
                          value={paymentMethod}
                        >
                          {paymentMethod}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("status")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="subscription-status"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalServiceSubscriptionStatus}
                    onChange={(e) => {
                      setModalServiceSubscriptionStatus(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "subscriptionStatus",
                      id: "subscriptionStatus",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("status")}
                    </MenuItem>
                    {Object.values(ApiService.serviceSubscriptionStatuses).map(
                      (subscriptionStatus) => {
                        return (
                          <MenuItem
                            key={subscriptionStatus}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={subscriptionStatus}
                          >
                            {subscriptionStatus}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("price")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="subscription-price"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalServiceSubscriptionPrice,
                    onChange: (e) => {
                      setModalServiceSubscriptionPrice(e.target.value);
                    },
                  }}
                  helpText={t("subscription-price")}
                />
              </GridItem>
            </GridContainer>
            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <SubscriptionsIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("subscriptions")} {formattedUserName}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => handleRefresh()}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
                <Button
                  color="primary"
                  round
                  style={{ marginTop: "20px" }}
                  onClick={() => {
                    getQueryParams().then((params) => {
                      if (params.requestPricingPlan) {
                        setModalRequestedPricingPlan(true);
                        setModalRequestPricingPlan([1]);
                        setModalPricingPlan(
                          params.pricingPlanName ? params.pricingPlanName : ""
                        );
                      } else {
                        setModalRequestedPricingPlan(false);
                        setModalRequestPricingPlan([]);
                        setModalPricingPlan("");
                      }
                      setConfirmModalButtonText(t("send-request"));
                      setShowModalPricingPlan(true);
                    });
                  }}
                >
                  {t("update-plan")}
                </Button>
                {editEnabled && (
                  <Button
                    style={{ marginTop: "20px" }}
                    color="primary"
                    round
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {t("add")}
                  </Button>
                )}
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={handleReturnData(
                  userServiceSubscriptions,
                  query.userId,
                  query.superuser,
                  query.parentId,
                  query.parentType
                )}
                filterable
                columns={[
                  {
                    Header: t("name"),
                    accessor: "name",
                  },
                  {
                    Header: t("type"),
                    accessor: "type",
                  },
                  {
                    Header: t("plan"),
                    accessor: "plan",
                  },
                  {
                    Header: t("pricing-plan"),
                    accessor: "pricingPlanName",
                  },
                  {
                    Header: t("payment-method"),
                    accessor: "paymentMethod",
                  },
                  {
                    Header: t("status"),
                    accessor: "status",
                  },
                  {
                    Header: t("activation-date"),
                    accessor: "activationDateFormatted",
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                  {
                    Header: t("price"),
                    accessor: "price",
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
};

UserServicesSubscriptions.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default UserServicesSubscriptions;
