import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ClearIcon from "@material-ui/icons/Clear";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import EditIcon from "@material-ui/icons/Edit";

/*#############################
  # Modal view dialog classes #
  #############################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyle);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AdminPricingPlans = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
  const [pricingPlans, setPricingPlans] = React.useState([]);
  const [nameDisabled, setNameDisabled] = React.useState(false);

  // Modal states
  const [showModal, setShowModal] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("add")
  );
  const [refreshClicked, setRefreshClicked] = React.useState(false);

  // Dialog fields
  const [modalPricingPlanName, setModalPricingPlanName] = React.useState("");
  const [modalPricingPlanDisplayName, setModalPricingPlanDisplayName] =
    React.useState("");
  const [
    modalPricingPlanShortDescription,
    setModalPricingPlanShortDescription,
  ] = React.useState("");
  const [modalPricingPlanDescription, setModalPricingPlanDescription] =
    React.useState("");
  const [modalPricingPlanSubscription, setModalPricingPlanSubscription] =
    React.useState(ApiService.serviceSubscriptionPlans.monthly);
  const [modalPricingPlanPaymentMethod, setModalPricingPlanPaymentMethod] =
    React.useState(ApiService.serviceSubscriptionPaymentMethods.invoice);
  const [modalPricingPlanSubscriptions, setModalPricingPlanSubscriptions] =
    React.useState([]);

  const clearModalState = () => {
    setShowModal(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("add"));
    setModalPricingPlanName("");
    setModalPricingPlanDisplayName("");
    setModalPricingPlanShortDescription("");
    setModalPricingPlanDescription("");
    setModalPricingPlanSubscription(
      ApiService.serviceSubscriptionPlans.monthly
    );
    setModalPricingPlanPaymentMethod(
      ApiService.serviceSubscriptionPaymentMethods.invoice
    );
    setModalPricingPlanSubscriptions([]);
    setNameDisabled(false);
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const handleReturnData = (data) => {
    return data.map((elem) => {
      const queryParameters = new URLSearchParams({
        pricingPlanId: elem._id,
      }).toString();
      elem.numberOfSubscriptions = elem.subscriptions.length;
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("subscriptions")}>
            <Link to={`/admin/admin-pricing-subscriptions?${queryParameters}`}>
              <Button justIcon round simple color="rose" className="like">
                <SubscriptionsIcon />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip title={t("edit-pricing-plan")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                setNameDisabled(true);
                setModalPricingPlanName(elem.name);
                setModalPricingPlanDisplayName(
                  elem.displayName ? elem.displayName : ""
                );
                setModalPricingPlanShortDescription(elem.shortDescription);
                setModalPricingPlanDescription(elem.description);
                setModalPricingPlanSubscription(elem.plan);
                setModalPricingPlanPaymentMethod(elem.paymentMethod);
                setModalPricingPlanSubscriptions(elem.subscriptions);
                setShowModal(true);
              }}
            >
              <EditIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("delete-pricing-plan")}>
            <Button
              justIcon
              simple
              className="remove"
              color="rose"
              onClick={() => {
                handleDeletePricingPlan(elem);
              }}
            >
              <ClearIcon />
            </Button>
          </Tooltip>
        </div>
      );
      return elem;
    });
  };

  const handleDeletePricingPlan = (pricingPlan) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-pricing-plan")}?`}
        onConfirm={() => {
          deletePricingPlan(pricingPlan);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${pricingPlan.name}`}</b>
        </p>
      </SweetAlert>
    );
  };

  const deletePricingPlan = async (pricingPlan) => {
    setAlert(null);
    try {
      await ApiService.deletePricingPlan({ name: pricingPlan.name });
      operationResult(true, t("pricing-plan-successfully-deleted"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const handleAddPricingPlan = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const name = modalPricingPlanName.trim();
    const validName = name.length > 0;
    const displayName = modalPricingPlanDisplayName.trim();
    const shortDescription = modalPricingPlanShortDescription.trim();
    const validShortDescription = shortDescription.length > 0;
    const description = modalPricingPlanDescription.trim();
    const validDescription = description.length > 0;

    const checksPassed = validName && validShortDescription && validDescription;
    if (!checksPassed) {
      const messageContent = [];
      if (!validName) {
        messageContent.push(t("invalid-name"));
      }
      if (!validShortDescription) {
        messageContent.push(t("invalid-short-description"));
      }
      if (!validDescription) {
        messageContent.push(t("invalid-description"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }

    const params = {
      name,
      shortDescription,
      description,
      plan: modalPricingPlanSubscription,
      paymentMethod: modalPricingPlanPaymentMethod,
      subscriptions: modalPricingPlanSubscriptions,
    };
    if (displayName.length > 0) {
      params.displayName = displayName;
    }

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("adding"));

    try {
      await ApiService.upsertPricingPlan(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("pricing-plan-successfully-added"));
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getPricingPlans = async (signal = undefined) => {
      try {
        const responseData = await ApiService.getPricingPlans({}, signal);
        setPricingPlans(responseData);
      } catch (e) {
        console.error(e);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal, true, true);
        await getPricingPlans(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [refreshClicked]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>{t("add-pricing-plan")}</h4>
        </DialogTitle>
        <form onSubmit={handleAddPricingPlan}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("name")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="pricing-plan-name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalPricingPlanName,
                    disabled: nameDisabled,
                    onChange: (e) => {
                      setModalPricingPlanName(e.target.value);
                    },
                  }}
                  helpText={t("pricing-plan-name")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("display-name")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="pricing-plan-display-name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalPricingPlanDisplayName,
                    onChange: (e) => {
                      setModalPricingPlanDisplayName(e.target.value);
                    },
                  }}
                  helpText={t("pricing-plan-name")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("plan")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="pricing-plan-subscription"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalPricingPlanSubscription}
                    onChange={(e) => {
                      setModalPricingPlanSubscription(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "pricingPlanSubscription",
                      id: "pricingPlanSubscription",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("plan")}
                    </MenuItem>
                    {Object.values(ApiService.serviceSubscriptionPlans).map(
                      (subscriptionPlan) => {
                        return (
                          <MenuItem
                            key={subscriptionPlan}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={subscriptionPlan}
                          >
                            {subscriptionPlan}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("payment-method")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="pricing-plan-payment-method"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalPricingPlanPaymentMethod}
                    onChange={(e) => {
                      setModalPricingPlanPaymentMethod(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "pricingPlanPaymentMethod",
                      id: "pricingPlanPaymentMethod",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("payment-method")}
                    </MenuItem>
                    {Object.values(
                      ApiService.serviceSubscriptionPaymentMethods
                    ).map((paymentMethod) => {
                      return (
                        <MenuItem
                          key={paymentMethod}
                          classes={{
                            root: formClasses.selectMenuItem,
                            selected:
                              formClasses.selectMenuItemSelectedMultiple,
                          }}
                          value={paymentMethod}
                        >
                          {paymentMethod}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("short-description")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="pricing-plan-short-description"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalPricingPlanShortDescription,
                    onChange: (e) => {
                      setModalPricingPlanShortDescription(e.target.value);
                    },
                  }}
                  helpText={t("pricing-plan-short-description")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("description")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="pricing-plan-description"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalPricingPlanDescription,
                    multiline: true,
                    onChange: (e) => {
                      setModalPricingPlanDescription(e.target.value);
                    },
                  }}
                  helpText={t("pricing-plan-description")}
                />
              </GridItem>
            </GridContainer>
            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <LocalOfferIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("pricing-plans")}</h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => handleRefresh()}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  round
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {t("add")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={handleReturnData(pricingPlans)}
                filterable
                columns={[
                  {
                    Header: t("name"),
                    accessor: "name",
                  },
                  {
                    Header: t("display-name"),
                    accessor: "displayName",
                  },
                  {
                    Header: t("short-description"),
                    accessor: "shortDescription",
                  },
                  {
                    Header: t("plan"),
                    accessor: "plan",
                  },
                  {
                    Header: t("payment-method"),
                    accessor: "paymentMethod",
                  },
                  {
                    Header: t("price"),
                    accessor: "price",
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                  {
                    Header: t("subscriptions"),
                    accessor: "numberOfSubscriptions",
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
};

AdminPricingPlans.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default AdminPricingPlans;
