import i18n from "i18next";
import ApiService from "api/ApiService";
import Dashboard from "views/Dashboard/Dashboard.js";
import RedirectPage from "views/Pages/RedirectPage";
import ErrorPage from "views/Pages/ErrorPage.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import PricingPage from "views/Pages/PricingPage.js";
import JobInspector from "views/Pages/JobInspector.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import UserProfile from "views/Pages/UserProfile.js";
import OrganizationProfile from "views/Pages/OrganizationProfile.js";
import UserOrganizations from "views/Pages/UserOrganizations.js";
import UserDocuments from "views/Pages/UserDocuments.js";
import UserLookups from "views/Pages/UserLookups.js";
import UserSSI from "views/Pages/UserSSI.js";
import UserAuditLogs from "views/Pages/UserAuditLogs";
import UserKeys from "views/Pages/UserKeys.js";
import UserKeySellingRules from "views/Pages/UserKeySellingRules.js";
import ValidateEmail from "views/Pages/ValidateEmail.js";
import ResetPasswordEmail from "views/Pages/ResetPasswordEmail.js";
import ResetPassword from "views/Pages/ResetPassword.js";
import MagicLinkLogin from "views/Pages/MagicLinkLogin.js";
import AdminUsers from "views/Pages/AdminUsers.js";
import AdminOrganizations from "views/Pages/AdminOrganizations.js";
import AdminServicesSubscriptions from "views/Pages/AdminServicesSubscriptions.js";
import AdminUserServices from "views/Pages/AdminUserServices.js";
import AdminUserServicesEndpoints from "views/Pages/AdminUserServicesEndpoints.js";
import AdminDeployments from "views/Pages/AdminDeployments.js";
import AdminCapabilities from "views/Pages/AdminCapabilities.js";
import AdminRoles from "views/Pages/AdminRoles.js";
import AdminRoleConfiguration from "views/Pages/AdminRoleConfiguration.js";
import AdminPricingPlans from "views/Pages/AdminPricingPlans.js";
import AdminPricingLists from "views/Pages/AdminPricingLists.js";
import AdminSystemConfiguration from "views/Pages/AdminSystemConfiguration.js";
import AdminPricingSubscriptions from "views/Pages/AdminPricingSubscriptions.js";
import AdminUserKeyTransactions from "views/Pages/AdminUserKeyTransactions.js";
import AdminUserTransactions from "views/Pages/AdminUserTransactions.js";
import AdminUserExternalStorage from "views/Pages/AdminUserExternalStorage.js";
import AdminSystemOverview from "views/Pages/AdminSystemOverview.js";
import AdminDeploymentMachine from "views/Pages/AdminDeploymentMachine.js";
import UserServicesSubscriptions from "views/Pages/UserServicesSubscriptions.js";
import UserServicesBillings from "views/Pages/UserServicesBillings.js";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import ForwardIcon from "@material-ui/icons/Forward";
import Image from "@material-ui/icons/Image";
import DescriptionIcon from "@material-ui/icons/Description";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import AccountBox from "@material-ui/icons/AccountBox";
import Business from "@material-ui/icons/Business";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import ListIcon from "@material-ui/icons/List";
import Assignment from "@material-ui/icons/Assignment";
import HistoryIcon from "@material-ui/icons/History";

let currentLanguage;
let renderedRoutes;

const getNavigationRoutes = () => {
  if (i18n.language !== currentLanguage) {
    currentLanguage = i18n.language;
    renderedRoutes = [
      {
        hidden: true,
        collapse: true,
        admin: true,
        name: i18n.t("admin"),
        rtlName: i18n.t("admin"),
        icon: AccountBox,
        state: "adminCollapse",
        views: [
          {
            path: "/admin-users",
            name: i18n.t("users"),
            rtlName: i18n.t("users"),
            mini: "US",
            rtlMini: "US",
            component: AdminUsers,
            layout: "/admin",
          },
          {
            path: "/admin-organizations",
            name: i18n.t("organizations"),
            rtlName: i18n.t("organizations"),
            mini: "OR",
            rtlMini: "OR",
            component: AdminOrganizations,
            layout: "/admin",
          },
          {
            path: "/admin-subscriptions",
            name: i18n.t("subscriptions"),
            rtlName: i18n.t("subscriptions"),
            mini: "SU",
            rtlMini: "SU",
            component: AdminServicesSubscriptions,
            layout: "/admin",
          },
          {
            path: "/admin-services",
            name: i18n.t("services"),
            rtlName: i18n.t("services"),
            mini: "SR",
            rtlMini: "SR",
            component: AdminUserServices,
            layout: "/admin",
          },
          {
            hidden: true,
            path: "/admin-key-transactions",
            name: i18n.t("key-transactions"),
            rtlName: i18n.t("key-transactions"),
            mini: "KT",
            rtlMini: "KT",
            component: AdminUserKeyTransactions,
            layout: "/admin",
          },
          {
            hidden: true,
            path: "/admin-transactions",
            name: i18n.t("transactions"),
            rtlName: i18n.t("transactions"),
            mini: "TR",
            rtlMini: "TR",
            component: AdminUserTransactions,
            layout: "/admin",
          },
          {
            hidden: true,
            path: "/admin-external-storage",
            name: i18n.t("external-storage"),
            rtlName: i18n.t("external-storage"),
            mini: "ES",
            rtlMini: "ES",
            component: AdminUserExternalStorage,
            layout: "/admin",
          },
          {
            hidden: true,
            path: "/admin-endpoints",
            name: i18n.t("service-endpoints"),
            rtlName: i18n.t("service-endpoints"),
            mini: "SE",
            rtlMini: "SE",
            component: AdminUserServicesEndpoints,
            layout: "/admin",
          },
          {
            path: "/admin-deployments",
            name: i18n.t("deployments"),
            rtlName: i18n.t("deployments"),
            mini: "DE",
            rtlMini: "DE",
            component: AdminDeployments,
            layout: "/admin",
          },
          {
            path: "/admin-capabilities",
            name: i18n.t("capabilities"),
            rtlName: i18n.t("capabilities"),
            mini: "CP",
            rtlMini: "CP",
            component: AdminCapabilities,
            layout: "/admin",
          },
          {
            path: "/admin-roles",
            name: i18n.t("roles"),
            rtlName: i18n.t("roles"),
            mini: "RL",
            rtlMini: "RL",
            component: AdminRoles,
            layout: "/admin",
          },
          {
            path: "/admin-pricing-plans",
            name: i18n.t("pricing-plans"),
            rtlName: i18n.t("pricing-plans"),
            mini: "PP",
            rtlMini: "PP",
            component: AdminPricingPlans,
            layout: "/admin",
          },
          {
            path: "/admin-pricing-lists",
            name: i18n.t("pricing-lists"),
            rtlName: i18n.t("pricing-lists"),
            mini: "PL",
            rtlMini: "PL",
            component: AdminPricingLists,
            layout: "/admin",
          },
          {
            path: "/admin-system-overview",
            name: i18n.t("system-overview"),
            rtlName: i18n.t("system-overview"),
            mini: "SO",
            rtlMini: "SO",
            component: AdminSystemOverview,
            layout: "/admin",
          },
          {
            path: "/admin-system-configuration",
            name: i18n.t("system-configuration"),
            rtlName: i18n.t("system-configuration"),
            mini: "SC",
            rtlMini: "SC",
            component: AdminSystemConfiguration,
            layout: "/admin",
          },
          {
            hidden: true,
            path: "/admin-deployment-machine",
            name: i18n.t("deployment-machine"),
            rtlName: i18n.t("deployment-machine"),
            mini: "DM",
            rtlMini: "DM",
            component: AdminDeploymentMachine,
            layout: "/admin",
          },
          {
            hidden: true,
            path: "/admin-pricing-subscriptions",
            name: i18n.t("pricing-plan-subscriptions"),
            rtlName: i18n.t("pricing-plan-subscriptions"),
            mini: "PS",
            rtlMini: "PS",
            component: AdminPricingSubscriptions,
            layout: "/admin",
          },
        ],
      },
      {
        path: "/dashboard",
        name: i18n.t("dashboard"),
        rtlName: i18n.t("dashboard"),
        icon: DashboardIcon,
        component: Dashboard,
        layout: "/admin",
        capability: ApiService.capabilities.idDashboardView,
      },
      {
        path: "/user-organizations",
        name: i18n.t("organizations"),
        rtlName: i18n.t("organizations"),
        icon: Business,
        component: UserOrganizations,
        layout: "/admin",
        capability: ApiService.capabilities.idOrganizationsView,
      },
      {
        path: "/user-documents",
        name: i18n.t("documents"),
        rtlName: i18n.t("documents"),
        icon: DescriptionIcon,
        component: UserDocuments,
        layout: "/admin",
        capability: ApiService.capabilities.idDocumentsView,
      },
      {
        path: "/user-subscriptions",
        name: i18n.t("subscriptions"),
        rtlName: i18n.t("subscriptions"),
        icon: SubscriptionsIcon,
        component: UserServicesSubscriptions,
        layout: "/admin",
        capability: ApiService.capabilities.idSubscriptionsView,
      },
      {
        path: "/user-billings",
        name: i18n.t("billings"),
        rtlName: i18n.t("billings"),
        icon: AccountBalanceIcon,
        component: UserServicesBillings,
        layout: "/admin",
        capability: ApiService.capabilities.idBillingsView,
      },
      {
        path: "/user-lookups",
        name: i18n.t("lookups"),
        rtlName: i18n.t("lookups"),
        icon: FindInPageIcon,
        component: UserLookups,
        layout: "/admin",
        capability: ApiService.capabilities.idLookupsView,
      },
      {
        hidden: false,
        collapse: true,
        name: i18n.t("keys"),
        rtlName: i18n.t("keys"),
        icon: VpnKeyIcon,
        state: "pageCollapse",
        capability: ApiService.capabilities.idKeysView,
        views: [
          {
            path: "/user-keys",
            name: i18n.t("keys"),
            rtlName: i18n.t("keys"),
            mini: "K",
            rtlMini: "K",
            component: UserKeys,
            layout: "/admin",
            capability: ApiService.capabilities.idKeysView,
          },
          {
            path: "/user-key-selling-rules",
            name: i18n.t("key-selling-rules"),
            rtlName: i18n.t("key-selling-rules"),
            mini: "KSR",
            rtlMini: "KSR",
            component: UserKeySellingRules,
            layout: "/admin",
            capability: ApiService.capabilities.idKeySellingRulesView,
          },
        ],
      },
      {
        path: "/user-ssi",
        name: i18n.t("ssi"),
        rtlName: i18n.t("ssi"),
        icon: ListIcon,
        component: UserSSI,
        layout: "/admin",
        capability: ApiService.capabilities.idSSIView,
      },
      {
        path: "/job-inspector",
        name: i18n.t("jobs"),
        rtlName: i18n.t("jobs"),
        icon: Assignment,
        component: JobInspector,
        layout: "/admin",
        capability: ApiService.capabilities.idJobsView,
      },
      {
        path: "/user-audit-logs",
        name: i18n.t("audit-logs"),
        rtlName: i18n.t("audit-logs"),
        icon: HistoryIcon,
        component: UserAuditLogs,
        layout: "/admin",
        capability: ApiService.capabilities.idAuditLogsView,
      },
      {
        hidden: true,
        path: "/pricing-page",
        name: i18n.t("pricing"),
        rtlName: i18n.t("pricing"),
        mini: "P",
        rtlMini: "P",
        component: PricingPage,
        layout: "/auth",
        capability: ApiService.capabilities.idPricingView,
      },
      {
        hidden: true,
        collapse: true,
        name: i18n.t("pages"),
        rtlName: i18n.t("pages"),
        icon: Image,
        state: "pageCollapse",
        views: [
          {
            path: "/login-page",
            name: i18n.t("login-page"),
            rtlName: i18n.t("login-page"),
            mini: "L",
            rtlMini: "L",
            component: LoginPage,
            layout: "/auth",
          },
          {
            path: "/register-page",
            name: i18n.t("register-page"),
            rtlName: i18n.t("register-page"),
            mini: "R",
            rtlMini: "R",
            component: RegisterPage,
            layout: "/auth",
            hide: !ApiService.showRegisterPage,
          },
          {
            path: "/lock-screen-page",
            name: i18n.t("lock-screen-page"),
            rtlName: i18n.t("lock-screen-page"),
            mini: "LS",
            rtlMini: "LS",
            component: LockScreenPage,
            layout: "/auth",
          },
          {
            path: "/error-page",
            name: i18n.t("error-page"),
            rtlName: i18n.t("error-page"),
            mini: "E",
            rtlMini: "E",
            component: ErrorPage,
            layout: "/auth",
          },
          {
            path: "/validate-email/",
            name: i18n.t("validate-email"),
            rtlName: i18n.t("validate-email"),
            mini: "VE",
            rtlMini: "VE",
            component: ValidateEmail,
            layout: "/auth",
          },
          {
            path: "/reset-password-email",
            name: i18n.t("reset-password"),
            rtlName: i18n.t("reset-password"),
            mini: "RP",
            rtlMini: "RP",
            component: ResetPasswordEmail,
            layout: "/auth",
          },
          {
            path: "/reset-password/",
            name: i18n.t("reset-password"),
            rtlName: i18n.t("reset-password"),
            mini: "RP",
            rtlMini: "RP",
            component: ResetPassword,
            layout: "/auth",
          },
          {
            path: "/magic-link-login",
            name: i18n.t("magic-link-login"),
            rtlName: i18n.t("magic-link-login"),
            mini: "EL",
            rtlMini: "EL",
            component: MagicLinkLogin,
            layout: "/auth",
          },
          {
            path: "/redirect-page",
            name: i18n.t("redirect-page"),
            rtlName: i18n.t("redirect-page"),
            icon: ForwardIcon,
            component: RedirectPage,
            layout: "/auth",
          },
          {
            path: "/user-profile",
            name: i18n.t("profile"),
            rtlName: i18n.t("profile"),
            mini: "UP",
            rtlMini: "UP",
            component: UserProfile,
            layout: "/admin",
          },
          {
            path: "/organization-profile",
            name: i18n.t("organization-profile"),
            rtlName: i18n.t("organization-profile"),
            mini: "OP",
            rtlMini: "OP",
            component: OrganizationProfile,
            layout: "/admin",
          },
          {
            path: "/admin-role-configuration",
            name: i18n.t("role-configuration-0"),
            rtlName: i18n.t("role-configuration-0"),
            mini: "RC",
            rtlMini: "RC",
            component: AdminRoleConfiguration,
            layout: "/admin",
          },
        ],
      },
    ];
  }
  return renderedRoutes;
};

export default getNavigationRoutes;
