import React from "react";
import ReactTable from "react-table";
import isDecimal from "validator/lib/isDecimal";
import PropTypes from "prop-types";
import moment from "moment";

import { Link } from "react-router-dom";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";
import Datetime from "react-datetime";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import BlockIcon from "@material-ui/icons/Block";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AppsIcon from "@material-ui/icons/Apps";
import Business from "@material-ui/icons/Business";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import EmailIcon from "@material-ui/icons/Email";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CreditCard from "@material-ui/icons/CreditCard";

import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";

/*#############################
  # Modal view dialog classes #
  #############################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";

import Muted from "components/Typography/Muted.js";
import { Tooltip } from "@material-ui/core";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "35px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyle);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AdminOrganizations = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [organizations, setOrganizations] = React.useState([]);
  const [alert, setAlert] = React.useState(null);

  // Modal states
  const [showModalPricingPlan, setShowModalPricingPlan] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("add")
  );

  // Dialog fields
  const [modalOrganizationId, setModalOrganizationId] = React.useState("");
  const [modalParentOrg, setModalParentOrg] = React.useState("");
  const [modalType, setModalType] = React.useState(
    ApiService.organizationTypes.company
  );
  const [modalName, setModalName] = React.useState("");
  const [modalEmail, setModalEmail] = React.useState("");
  const [modalUsersLimit, setModalUsersLimit] = React.useState(0);
  const [modalValidatedEmail, setModalValidatedEmail] = React.useState([1]);
  const [modalValidatedDocuments, setModalValidatedDocuments] = React.useState([
    1,
  ]);
  const [modalChargeOrganization, setModalChargeOrganization] = React.useState([
    1,
  ]);
  const [modalNumber, setModalNumber] = React.useState("");
  const [modalPricingPlan, setModalPricingPlan] = React.useState("");
  const [modalPricingOrganization, setModalPricingOrganization] =
    React.useState({});
  const [modalPricingActivationDate, setModalPricingActivationDate] =
    React.useState(moment().utc().startOf("day"));
  const [pricingPlans, setPricingPlans] = React.useState([]);
  const [refreshClicked, setRefreshClicked] = React.useState(false);
  const [userId, setUserId] = React.useState(undefined);

  const clearModalState = () => {
    setShowModal(false);
    setShowModalPricingPlan(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("add"));
    setModalOrganizationId("");
    setModalParentOrg("");
    setModalType(ApiService.organizationTypes.company);
    setModalName("");
    setModalNumber("");
    setModalEmail("");
    setModalUsersLimit(0);
    setModalValidatedEmail([1]);
    setModalValidatedDocuments([1]);
    setModalChargeOrganization([1]);
    setModalPricingPlan("");
    setModalPricingOrganization({});
    setModalPricingActivationDate(moment().utc().startOf("day"));
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const handleAddOrganization = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }
    const organizationId =
      modalOrganizationId.trim().length > 0
        ? modalOrganizationId.trim()
        : undefined;
    const parentOrg =
      modalParentOrg.trim().length > 0 ? modalParentOrg.trim() : undefined;
    const name = modalName.trim();
    const number = modalNumber.trim();
    const email = modalEmail.trim();
    const usersLimit = parseInt(modalUsersLimit, 10);
    const validatedEmail = modalValidatedEmail.length > 0;
    const validatedDocuments = modalValidatedDocuments.length > 0;
    const chargeOrganization = modalChargeOrganization.length > 0;

    const validName = name.length > 0;
    const validEmail = isEmail(email);
    const validUsersLimit = usersLimit >= 0;

    const checksPassed = validName && validEmail;
    if (!checksPassed) {
      const messageContent = [];
      if (!validName) {
        messageContent.push(t("name-length-should-be-greater-than-1"));
      }
      if (!validEmail) {
        messageContent.push(t("email-address"));
      }
      if (!validUsersLimit) {
        messageContent.push(t("users-limit"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }
    const params = {
      organizationId,
      parentOrg,
      type: modalType,
      name,
      email,
      usersLimit,
      validatedEmail,
      validatedDocuments,
      chargeOrganization,
    };
    if (number.length > 0) {
      params.number = number;
    }

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("adding"));

    try {
      await ApiService.createOrganizationSuperuser(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("organization-successfully-added"));
  };

  const handleReturnData = (data, userId) => {
    return data.map((elem) => {
      const queryParameters = new URLSearchParams({
        userId,
        parentId: elem._id,
        parentType: ApiService.parentTypes.organization,
      }).toString();
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("organization-profile")}>
            <Link to={`/admin/organization-profile?${queryParameters}`}>
              <Button justIcon round simple color="rose" className="like">
                <MoreVertIcon />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip title={t("services")}>
            <Link to={`/admin/admin-services?${queryParameters}`}>
              <Button justIcon round simple color="rose" className="like">
                <AppsIcon />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip title={t("update-organization-credits")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                handleUpdateOrganizationCredits(elem);
              }}
            >
              <CreditCard />
            </Button>
          </Tooltip>
          <Tooltip title={t("update-pricing-plan")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                setModalPricingOrganization(elem);
                if (elem.requestPricingPlan) {
                  setModalPricingPlan(
                    elem.pricingPlanName ? elem.pricingPlanName : ""
                  );
                }
                setConfirmModalButtonText("Activate");
                setShowModalPricingPlan(true);
              }}
            >
              <LocalOfferIcon />
            </Button>
          </Tooltip>
          {!elem.validatedDocuments ? (
            <Tooltip title={t("validate-documents")}>
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  handleValidateDocuments(elem);
                }}
                color="rose"
                className="remove"
              >
                <AssignmentTurnedInIcon />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={t("invalidate-documents")}>
              <Button
                justIcon
                simple
                color="rose"
                className="remove"
                onClick={() => {
                  handleInvalidateDocuments(elem);
                }}
              >
                <AssignmentLateIcon />
              </Button>
            </Tooltip>
          )}
          {!elem.validatedEmail ? (
            <Tooltip title={t("validate-email")}>
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  handleValidateEmail(elem);
                }}
                color="rose"
                className="remove"
              >
                <EmailIcon />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={t("invalidate-email")}>
              <Button
                justIcon
                simple
                color="rose"
                className="remove"
                onClick={() => {
                  handleInvalidateEmail(elem);
                }}
              >
                <MailOutlineIcon />
              </Button>
            </Tooltip>
          )}
          {!elem.blocked ? (
            <Tooltip title={t("block-organization")}>
              <Button
                justIcon
                simple
                color="rose"
                className="edit"
                onClick={() => {
                  handleBlockOrganization(elem);
                }}
              >
                <BlockIcon />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={t("unblock-organization")}>
              <Button
                justIcon
                round
                simple
                color="rose"
                className="remove"
                onClick={() => {
                  handleUnblockOrganization(elem);
                }}
              >
                <CheckCircleIcon />
              </Button>
            </Tooltip>
          )}
        </div>
      );
      return elem;
    });
  };

  const handleValidateDocuments = (elem) => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("validate-organization-documents")}
        onConfirm={() => {
          updateOrganizationDocumentValidation(elem, true);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${elem.name} (${elem._id})`}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleInvalidateDocuments = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("invalidate-organization-documents")}
        onConfirm={() => {
          updateOrganizationDocumentValidation(elem, false);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${elem.name} (${elem._id})`}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleValidateEmail = (elem) => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("validate-organization-email")}
        onConfirm={() => {
          updateOrganizationEmailValidation(elem, true);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${elem.name} (${elem._id})`}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleInvalidateEmail = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("invalidate-organization-email")}
        onConfirm={() => {
          updateOrganizationEmailValidation(elem, false);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${elem.name} (${elem._id})`}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleBlockOrganization = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("block-organization")}?`}
        onConfirm={() => {
          updateOrganizationBlockedFlag(elem, true);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${elem.name} (${elem._id})`}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleUnblockOrganization = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("unblock-organization")}?`}
        onConfirm={() => {
          updateOrganizationBlockedFlag(elem, false);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${elem.name} (${elem._id})`}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleDenyPricingPlanUpdate = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("deny-pricing-plan-update")}
        onConfirm={() => {
          denyPricingPlanUpdate();
        }}
        onCancel={() => {
          cancelDialog();
          clearModalState();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${modalPricingPlan}`}</b>
          {t("for-organization")}
          <b>{`${modalPricingOrganization.name} (${modalPricingOrganization._id})`}</b>
        </p>
      </SweetAlert>
    );
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const updateOrganizationDocumentValidation = async (
    organization,
    validatedDocuments
  ) => {
    setAlert(null);
    try {
      await ApiService.updateUserDocumentsValidation({
        parentId: organization._id,
        parentType: ApiService.parentTypes.organization,
        validatedDocuments,
      });
      operationResult(
        true,
        t("organization-documents-validation-updated-successfully")
      );
    } catch (e) {
      operationResult(
        false,
        t("error-while-updating-organization-documents-validation")
      );
    }
  };

  const updateOrganizationEmailValidation = async (
    organization,
    validatedEmail
  ) => {
    setAlert(null);
    try {
      await ApiService.updateUserEmailValidation({
        parentId: organization._id,
        parentType: ApiService.parentTypes.organization,
        validatedEmail,
      });
      operationResult(
        true,
        t("organization-email-validation-updated-successfully")
      );
    } catch (e) {
      operationResult(
        false,
        t("error-while-updating-organization-email-validation")
      );
    }
  };

  const updateOrganizationBlockedFlag = async (organization, blocked) => {
    setAlert(null);
    try {
      await ApiService.updateUserBlockedFlag({
        parentId: organization._id,
        parentType: ApiService.parentTypes.organization,
        blocked,
      });
      operationResult(true, t("organization-block-flag-updated-successfully"));
    } catch (e) {
      operationResult(false, t("error-while-updating-organization-block-flag"));
    }
  };

  const denyPricingPlanUpdate = async () => {
    setAlert(null);
    try {
      await ApiService.requestPricingPlanUpdate({
        parentId: modalPricingOrganization._id,
        parentType: ApiService.parentTypes.organization,
        requestPricingPlan: false,
      });
      operationResult(
        true,
        t("successfully-denied-pricing-plan-update-request")
      );
    } catch (e) {
      operationResult(
        false,
        t("error-while-denying-pricing-plan-update-request")
      );
    }
    clearModalState();
  };

  const handleUpdateOrganizationCredits = (elem) => {
    setAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={t("update-organization-credits")}
        onConfirm={async (e) => {
          if (isDecimal(e)) {
            try {
              await ApiService.updateUserCredits({
                parentId: elem._id,
                parentType: ApiService.parentTypes.organization,
                credits: e,
              });
              operationResult(
                true,
                t("successfully-updated-organization-credits-amount")
              );
            } catch (e) {
              operationResult(
                false,
                `${t("error-updating-organization-credits")}: ${e.message}`
              );
            }
          } else {
            operationResult(false, t("invalid-credits-amount"));
          }
        }}
        onCancel={() => cancelDialog()}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
      >
        <p>{t("insert-available-amount-of-credits-for-organization")}</p>
        <p>
          <b>{`${elem.name} (${elem._id})`}</b>
        </p>
        <p>
          {t("current-credits-amount")} <b>{elem.credits}</b>
        </p>
      </SweetAlert>
    );
  };

  const handlePricingPlanUpdate = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const validPricingPlanName = pricingPlans.find(
      (pricingPlan) => pricingPlan === modalPricingPlan
    );
    const validPricingPlanActivationDate =
      modalPricingActivationDate.isSameOrAfter(moment().utc().startOf("day"));

    const checksPassed = validPricingPlanName && validPricingPlanActivationDate;
    if (!checksPassed) {
      const messageContent = [];
      if (!validPricingPlanName) {
        messageContent.push(t("invalid-name"));
      }
      if (!validPricingPlanActivationDate) {
        messageContent.push(t("invalid-activation-date"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }

    const params = {
      parentId: modalPricingOrganization._id,
      parentType: ApiService.parentTypes.organization,
      name: modalPricingPlan,
      activationDate: modalPricingActivationDate,
    };

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("activating"));

    try {
      await ApiService.activatePricingPlan(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("pricing-plan-successfully-activated"));
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getAllOrganizations = async (signal = undefined) => {
      try {
        const userProfile = await ApiService.loginRequired(signal, true, true);
        const responseData = await ApiService.getAvailableOrganizations(signal);
        const filteredData = responseData.filter((organizationData) => {
          organizationData.validatedEmailFormatted =
            organizationData.validatedEmail.toString();
          organizationData.validatedDocumentsFormatted =
            organizationData.validatedDocuments.toString();
          organizationData.blockedFormatted =
            organizationData.blocked.toString();
          organizationData.requestDocumentsValidationFormatted =
            organizationData.requestDocumentsValidation.toString();
          organizationData.requestPricingPlanFormatted =
            organizationData.requestPricingPlan.toString();
          organizationData.usersLimitFormatted =
            organizationData.usersLimit === 0
              ? t("unlimited")
              : organizationData.usersLimit.toString();
          organizationData.chargeOrganizationFormatted =
            typeof organizationData.chargeOrganization !== "undefined"
              ? organizationData.chargeOrganization.toString()
              : "false";
          setUserId(userProfile._id);
          return true;
        });
        const responsePricingPlans = await ApiService.getPricingPlans(
          {
            sortBy: "name:asc",
          },
          signal
        );
        setPricingPlans(
          responsePricingPlans.map((pricingPlan) => pricingPlan.name)
        );
        setOrganizations(filteredData);
      } catch (e) {
        console.error(e);
      }
    };

    const apiOperations = async () => {
      try {
        await getAllOrganizations(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [refreshClicked, t]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModalPricingPlan}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("activate-pricing-plan")}
          </h4>
        </DialogTitle>
        <form onSubmit={handlePricingPlanUpdate}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("organization")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="pricing-plan-organization"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    disabled: true,
                    value: `${modalPricingOrganization.name} (${modalPricingOrganization._id})`,
                  }}
                  helpText={t("pricing-plan-organization")}
                />
              </GridItem>
            </GridContainer>
            {modalPricingOrganization.requestPricingPlan && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    style={{ float: "left" }}
                  >
                    {t("requested-plan")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <CustomInput
                    id="pricing-plan-organization"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      disabled: true,
                      value: `${modalPricingOrganization.pricingPlanName}`,
                    }}
                    helpText={t("requested-pricing-plan")}
                  />
                </GridItem>
              </GridContainer>
            )}
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("pricing-plan")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="pricing-plan"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalPricingPlan}
                    onChange={(e) => {
                      setModalPricingPlan(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "pricingPlan",
                      id: "pricingPlan",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("pricing-plan")}
                    </MenuItem>
                    {pricingPlans.map((pricingPlan) => {
                      return (
                        <MenuItem
                          key={pricingPlan}
                          classes={{
                            root: formClasses.selectMenuItem,
                            selected:
                              formClasses.selectMenuItemSelectedMultiple,
                          }}
                          value={pricingPlan}
                        >
                          {pricingPlan}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("activation-date")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControl fullWidth>
                    <Datetime
                      dateFormat={"YYYY-MM-DD"}
                      timeFormat={false}
                      inputProps={{
                        placeholder: t("select-date"),
                        disabled: showModalProgress,
                      }}
                      value={modalPricingActivationDate}
                      onChange={(e) => {
                        setModalPricingActivationDate(e);
                      }}
                    />
                  </FormControl>
                </div>
              </GridItem>
            </GridContainer>
            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            {modalPricingOrganization.requestPricingPlan && (
              <Button
                onClick={() => {
                  setShowModalPricingPlan(false);
                  handleDenyPricingPlanUpdate();
                }}
                disabled={confirmModal}
                color="rose"
              >
                {t("deny")}
              </Button>
            )}
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>{t("add-organization")}</h4>
        </DialogTitle>
        <form onSubmit={handleAddOrganization}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("organization-id")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="organization-id"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalOrganizationId,
                    onChange: (e) => {
                      setModalOrganizationId(e.target.value);
                    },
                  }}
                  helpText={t("organization-id")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("parent-organization")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="parent-organization"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalParentOrg,
                    onChange: (e) => {
                      setModalParentOrg(e.target.value);
                    },
                  }}
                  helpText={t("parent-organization")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("organization-type")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="organization-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalType}
                    onChange={(e) => {
                      setModalType(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "organizationType",
                      id: "organization-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("organization-type")}
                    </MenuItem>
                    {Object.values(ApiService.organizationTypes).map(
                      (organizationType) => {
                        return (
                          <MenuItem
                            key={organizationType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={organizationType}
                          >
                            {organizationType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("name")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="organization-name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalName,
                    required: true,
                    onChange: (e) => {
                      setModalName(e.target.value);
                    },
                  }}
                  helpText={t("organization-name")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("number")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="organization-number"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalNumber,
                    required: true,
                    onChange: (e) => {
                      setModalNumber(e.target.value);
                    },
                  }}
                  helpText={t("organization-number")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("email")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="organization-email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "email",
                    value: modalEmail,
                    required: true,
                    onChange: (e) => {
                      setModalEmail(e.target.value);
                    },
                  }}
                  helpText={t("organization-email")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("users-limit-0")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="users-limit"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                    value: modalUsersLimit,
                    required: true,
                    onChange: (e) => {
                      setModalUsersLimit(e.target.value);
                    },
                  }}
                  helpText={t("organization-users-limit")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("validated-email")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6} style={{ float: "left" }}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => {
                          const currentIndex = modalValidatedEmail.indexOf(1);
                          const newChecked = [...modalValidatedEmail];
                          if (currentIndex === -1) {
                            newChecked.push(1);
                          } else {
                            newChecked.splice(currentIndex, 1);
                          }
                          setModalValidatedEmail(newChecked);
                        }}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot,
                        }}
                        checked={modalValidatedEmail.length > 0}
                        disabled={showModalProgress}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot,
                    }}
                    label={t("validated-email")}
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("validated-documents")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6} style={{ float: "left" }}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => {
                          const currentIndex =
                            modalValidatedDocuments.indexOf(1);
                          const newChecked = [...modalValidatedDocuments];
                          if (currentIndex === -1) {
                            newChecked.push(1);
                          } else {
                            newChecked.splice(currentIndex, 1);
                          }
                          setModalValidatedDocuments(newChecked);
                        }}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot,
                        }}
                        checked={modalValidatedDocuments.length > 0}
                        disabled={showModalProgress}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot,
                    }}
                    label={t("validated-documents")}
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("charge-organization")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6} style={{ float: "left" }}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => {
                          const currentIndex =
                            modalChargeOrganization.indexOf(1);
                          const newChecked = [...modalChargeOrganization];
                          if (currentIndex === -1) {
                            newChecked.push(1);
                          } else {
                            newChecked.splice(currentIndex, 1);
                          }
                          setModalChargeOrganization(newChecked);
                        }}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot,
                        }}
                        checked={modalChargeOrganization.length > 0}
                        disabled={showModalProgress}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot,
                    }}
                    label={t("charge-organization")}
                  />
                </div>
              </GridItem>
            </GridContainer>

            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <Business style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("organizations")}</h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => handleRefresh()}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  round
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {t("add")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={handleReturnData(organizations, userId)}
                filterable
                columns={[
                  {
                    Header: t("id"),
                    accessor: "_id",
                  },
                  {
                    Header: t("parent-org"),
                    accessor: "parentOrg",
                  },
                  {
                    Header: t("type"),
                    accessor: "type",
                  },
                  {
                    Header: t("name"),
                    accessor: "name",
                  },
                  {
                    Header: t("blocked"),
                    accessor: "blockedFormatted",
                  },
                  {
                    Header: t("validated-email"),
                    accessor: "validatedEmailFormatted",
                  },
                  {
                    Header: t("req-pricing"),
                    accessor: "requestPricingPlanFormatted",
                  },
                  {
                    Header: t("req-validation"),
                    accessor: "requestDocumentsValidationFormatted",
                  },
                  {
                    Header: t("validated-docs"),
                    accessor: "validatedDocumentsFormatted",
                  },
                  {
                    Header: t("users-limit-0"),
                    accessor: "usersLimitFormatted",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                  {
                    Header: t("charge-organization-0"),
                    accessor: "chargeOrganizationFormatted",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                  {
                    Header: t("credits"),
                    accessor: "credits",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
};

AdminOrganizations.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AdminOrganizations;
