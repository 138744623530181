import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Checkbox from "@material-ui/core/Checkbox";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

// @material-ui/icons
import SecurityIcon from "@material-ui/icons/Security";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";
import CardIcon from "components/Card/CardIcon.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbars from "components/Snackbar/Snackbar.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useAlertStyles = makeStyles(sweetAlertStyles);

const checkboxType = {
  capability: "capability",
  role: "role",
};

const AdminRoleConfiguration = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);

  const [roleName, setRoleName] = React.useState("");
  const [roleType, setRoleType] = React.useState("");
  const [roleDescription, setRoleDescription] = React.useState("");
  const [roleFlags, setRoleFlags] = React.useState("[]");
  const [roles, setRoles] = React.useState([]);
  const [capabilities, setCapabilities] = React.useState([]);
  const [counters, setCounters] = React.useState({
    rootCapabilities: 0,
    inheritedCapabilities: 0,
    rootRoles: 0,
    inheritedRoles: 0,
  });
  const [checkboxes, setCheckboxes] = React.useState({});

  const [query, setQuery] = React.useState({});
  const [role, setRole] = React.useState({});

  // Error handling
  const [roleNameError, setRoleNameError] = React.useState("");
  const [roleTypeError, setRoleTypeError] = React.useState("");
  const [roleDescriptionError, setRoleDescriptionError] = React.useState("");
  const [roleFlagsError, setRoleFlagsError] = React.useState("");

  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [alert, setAlert] = React.useState(null);
  const [refreshClicked, setRefreshClicked] = React.useState(false);

  const clearRoleUpdate = () => {
    setRoleName("");
    setRoleType("");
    setRoleDescription("");
    setRoleFlags("[]");
    setRoles([]);
    setCapabilities([]);
    setCounters({
      rootCapabilities: 0,
      inheritedCapabilities: 0,
      rootRoles: 0,
      inheritedRoles: 0,
    });
    setCheckboxes({});
  };

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      roleId: queryParams.get("roleId"),
    };
    const abortController = new AbortController();
    const signal = abortController.signal;

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal, true, true);
        const roleData = await ApiService.getSingleRole(params, signal);
        const roleInheritedCapabilities = await ApiService.getRoleCapabilities(
          {
            type: roleData.type,
            name: roleData.name,
            resolvingType: ApiService.resolvingTypes.inherited,
          },
          signal
        );
        const roleInheritedRoles = await ApiService.getRoleInheritedRoles(
          {
            type: roleData.type,
            name: roleData.name,
            resolvingType: ApiService.resolvingTypes.inherited,
          },
          signal
        );
        const allCapabilities = await ApiService.getCapabilities(
          {
            sortBy: "name:asc",
          },
          signal
        );
        const allRoles = await ApiService.getRoles(
          {
            sortBy: "name:asc",
          },
          signal
        );
        const rootCapabilities = {};
        // eslint-disable-next-line no-unused-vars
        for (const capability of roleData.capabilities) {
          rootCapabilities[capability.name] = capability.type;
        }
        const rootInheritedRoles = {};
        // eslint-disable-next-line no-unused-vars
        for (const inheritedRole of roleData.inheritedRoles) {
          rootInheritedRoles[inheritedRole.name] = inheritedRole.type;
        }

        let rootCapabilitiesCounter = 0;
        let inheritedCapabilitiesCounter = 0;
        const checkboxes_ = {};
        // eslint-disable-next-line no-unused-vars
        for (const capability_ of allCapabilities) {
          if (rootCapabilities.hasOwnProperty(capability_.name)) {
            capability_.resolvingType = ApiService.resolvingTypes.native;
            rootCapabilitiesCounter += 1;
            checkboxes_[capability_._id] = {
              type: checkboxType.capability,
              name: capability_.name,
              disabled: false,
              initChecked: true,
              checked: true,
            };
          } else if (
            roleInheritedCapabilities.hasOwnProperty(capability_.name)
          ) {
            capability_.resolvingType = ApiService.resolvingTypes.inherited;
            inheritedCapabilitiesCounter += 1;
            checkboxes_[capability_._id] = {
              type: checkboxType.capability,
              name: capability_.name,
              disabled: true,
              initChecked: true,
              checked: true,
            };
          } else {
            capability_.resolvingType = ApiService.resolvingTypes.assignable;
            checkboxes_[capability_._id] = {
              type: checkboxType.capability,
              name: capability_.name,
              disabled: false,
              initChecked: false,
              checked: false,
            };
          }
        }

        let rootRolesCounter = 0;
        let inheritedRolesCounter = 0;
        // eslint-disable-next-line no-unused-vars
        for (const role_ of allRoles) {
          if (rootInheritedRoles.hasOwnProperty(role_.name)) {
            role_.resolvingType = ApiService.resolvingTypes.native;
            rootRolesCounter += 1;
            checkboxes_[role_._id] = {
              type: checkboxType.role,
              name: role_.name,
              disabled: false,
              initChecked: true,
              checked: true,
            };
          } else if (roleInheritedRoles.hasOwnProperty(role_.name)) {
            role_.resolvingType = ApiService.resolvingTypes.inherited;
            inheritedRolesCounter += 1;
            checkboxes_[role_._id] = {
              type: checkboxType.role,
              name: role_.name,
              disabled: true,
              initChecked: true,
              checked: true,
            };
          } else {
            role_.resolvingType = ApiService.resolvingTypes.assignable;
            checkboxes_[role_._id] = {
              type: checkboxType.role,
              name: role_.name,
              disabled: false,
              initChecked: false,
              checked: false,
            };
          }
        }
        delete checkboxes_[roleData._id];
        setCheckboxes(checkboxes_);
        setRole(roleData);
        setCapabilities(allCapabilities);
        setRoles(allRoles.filter((entry) => entry.name !== roleData.name));
        setCounters({
          rootCapabilities: rootCapabilitiesCounter,
          inheritedCapabilities: inheritedCapabilitiesCounter,
          rootRoles: rootRolesCounter,
          inheritedRoles: inheritedRolesCounter,
        });
        setQuery(params);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    apiOperations();
    return () => {
      abortController.abort();
    };
  }, [props.location.search, query.roleId, refreshClicked]);

  React.useEffect(() => {
    if (!ApiService.isObjectEmpty(role)) {
      setRoleName(role.name);
      setRoleType(role.type);
      setRoleDescription(role.description);
      setRoleFlags(
        role.flags
          ? JSON.stringify(
              role.flags.map((entry) => {
                return {
                  name: entry.name,
                  value: entry.value,
                };
              })
            )
          : "[]"
      );
    }
  }, [role]);

  const showErrorMessage = (messageContent) => {
    setSuccessMessage("");
    setErrorMessage(messageContent);
    setTimeout(() => {
      setRoleNameError("");
      setRoleTypeError("");
      setRoleDescriptionError("");
      setRoleFlagsError("");
      setErrorMessage("");
    }, ApiService.messageTimeout);
  };
  const showSuccessMessage = (messageContent) => {
    setErrorMessage("");
    setSuccessMessage(messageContent);
    setTimeout(() => {
      setSuccessMessage("");
    }, ApiService.messageTimeout);
  };
  const handleUpdateRoleSubmit = async (event) => {
    event.preventDefault();
    const requestData = {
      roleId: role._id,
    };
    const messageContent = [];
    let checksPassed = true;
    const roleNameParsed = roleName.trim();
    if (roleNameParsed.length > 0 && roleNameParsed !== role.name) {
      requestData.name = roleNameParsed;
    }
    const roleTypeParsed = roleType.trim();
    if (roleTypeParsed.length > 0 && roleTypeParsed !== role.type) {
      if (Object.values(ApiService.roleTypes).includes(roleTypeParsed)) {
        requestData.type = roleTypeParsed;
      } else {
        messageContent.push(t("type-0"));
        checksPassed = false;
      }
    }
    const roleDescriptionParsed = roleDescription.trim();
    if (
      roleDescriptionParsed.length > 0 &&
      roleDescriptionParsed !== role.description
    ) {
      requestData.description = roleDescriptionParsed;
    }
    try {
      const flagsParsed = roleFlags.trim();
      if (flagsParsed.length > 0) {
        const flags = JSON.parse(flagsParsed);
        requestData.flags = flags;
      }
    } catch (e) {
      messageContent.push(t("flags-0"));
      checksPassed = false;
    }

    if (!checksPassed) {
      showErrorMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`
      );
    }
    if (!checksPassed || Object.keys(requestData).length === 1) {
      return;
    }

    try {
      const returnData = await ApiService.updateSingleRole(requestData);
      setRole(returnData);
      showSuccessMessage(t("successfully-updated-role-fields"));
      clearRoleUpdate();
    } catch (e) {
      showErrorMessage(e.message);
    }
    handleRefresh();
  };

  const deleteRoleRedirect = () => {
    setAlert(null);
    props.history.push("/admin/admin-roles");
  };
  const deleteConfirmAlertNext = (result, resultMessage) => {
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("confirmed")}
          onConfirm={deleteRoleRedirect}
          onCancel={deleteRoleRedirect}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          {resultMessage}
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("cancelled")}
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          {resultMessage}
        </SweetAlert>
      );
    }
  };
  const showDeleteRole = () => {
    setAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={t("delete-role")}
        onConfirm={async (e) => {
          if (e === role.name) {
            try {
              await ApiService.deleteRole({
                type: role.type,
                name: role.name,
              });
              deleteConfirmAlertNext(true, t("role-successfully-deleted"));
            } catch (e) {
              deleteConfirmAlertNext(
                false,
                t("error-deleting-role-please-contact-the-support")
              );
            }
          } else {
            deleteConfirmAlertNext(false, t("names-do-not-match"));
          }
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
      >
        <p>
          {t("to-delete-role-confirm")} <b>{t("name")}</b> {t("below")}.
        </p>
      </SweetAlert>
    );
  };

  const handleReturnData = (data) => {
    return data.map((elem) => {
      elem.protectedFormatted = elem.protected.toString();
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("assign-unassign")}>
            <Checkbox
              color="default"
              key={elem._id}
              checked={
                elem.resolvingType === ApiService.resolvingTypes.inherited ||
                (checkboxes[elem._id] && checkboxes[elem._id].checked)
              }
              disabled={
                elem.resolvingType === ApiService.resolvingTypes.inherited ||
                !!role.protected
              }
              className={classes.positionAbsolute}
              tabIndex={-1}
              onClick={() => {
                const checkboxesUpdate = { ...checkboxes };
                checkboxesUpdate[elem._id] = {
                  ...checkboxes[elem._id],
                  checked: !checkboxes[elem._id].checked,
                };
                setCheckboxes(checkboxesUpdate);
              }}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
          </Tooltip>
        </div>
      );
      return elem;
    });
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const filterUpdates = (inputType) => {
    const addObjects = [];
    const deleteObjects = [];

    const objectIds = Object.keys(checkboxes);
    // eslint-disable-next-line no-unused-vars
    for (const objectId of objectIds) {
      const entry = checkboxes[objectId];
      if (entry.type === inputType && !entry.disabled) {
        if (entry.initChecked && !entry.checked) {
          deleteObjects.push(entry.name);
        } else if (!entry.initChecked && entry.checked) {
          addObjects.push(entry.name);
        }
      }
    }

    return {
      addObjects,
      deleteObjects,
    };
  };

  const handleUpdateRoleCapabilities = async () => {
    setAlert(null);
    try {
      const { addObjects, deleteObjects } = filterUpdates(
        checkboxType.capability
      );
      if (addObjects.length > 0) {
        await ApiService.addRoleCapabilities({
          type: role.type,
          name: role.name,
          capabilities: addObjects,
        });
      }
      if (deleteObjects.length > 0) {
        await ApiService.deleteRoleCapabilities({
          type: role.type,
          name: role.name,
          capabilities: deleteObjects,
        });
      }
      operationResult(true, t("successfully-updated-role-capabilities"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };
  const handleUpdateInheritedRoles = async () => {
    setAlert(null);
    try {
      const { addObjects, deleteObjects } = filterUpdates(checkboxType.role);
      if (addObjects.length > 0) {
        await ApiService.addInheritedRoles({
          type: role.type,
          name: role.name,
          inheritedRoles: addObjects,
        });
      }
      if (deleteObjects.length > 0) {
        await ApiService.deleteInheritedRoles({
          type: role.type,
          name: role.name,
          inheritedRoles: deleteObjects,
        });
      }
      operationResult(true, t("successfully-updated-inherited-roles"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const handleUpdateCapabilities = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("update-capabilities")}
        onConfirm={() => {
          handleUpdateRoleCapabilities();
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      />
    );
  };

  const handleUpdateRoles = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("update-inherited-roles")}
        onConfirm={() => {
          handleUpdateInheritedRoles();
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      />
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} lg={8}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <SecurityIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {role.protected ? t("role") : t("edit-role")}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => {
                    handleRefresh();
                  }}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleUpdateRoleSubmit}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={t("name")}
                      id="name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setRoleName(e.target.value);
                        },
                        value: roleName,
                        disabled: !!role.protected,
                      }}
                      error={roleNameError.length > 0}
                      helperText={roleNameError}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={t("type")}
                      id="type"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setRoleType(e.target.value);
                        },
                        value: roleType,
                        disabled: !!role.protected,
                      }}
                      error={roleTypeError.length > 0}
                      helperText={roleTypeError}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={t("description")}
                      id="description"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setRoleDescription(e.target.value);
                        },
                        value: roleDescription,
                        disabled: !!role.protected,
                        multiline: true,
                      }}
                      error={roleDescriptionError.length > 0}
                      helperText={roleDescriptionError}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={t("flags")}
                      id="flags"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setRoleFlags(e.target.value);
                        },
                        value: roleFlags,
                        disabled: !!role.protected,
                      }}
                      error={roleFlagsError.length > 0}
                      helperText={roleFlagsError}
                    />
                  </GridItem>
                </GridContainer>
                {!role.protected && (
                  <Button
                    color="primary"
                    type="submit"
                    className={classes.updateProfileButton}
                  >
                    {t("edit")}
                  </Button>
                )}
              </form>
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={4}>
          <Card profile>
            <CardBody profile>
              <h6 className={classes.cardCategory}>{t("role")}</h6>
              <h4 className={classes.cardTitle}>{role.name}</h4>

              <GridContainer
                className={`${classes.description} ${classes.cardMargin}`}
              >
                <GridItem xs={12} sm={4} className={classes.alignLeft}>
                  {t("type")}
                </GridItem>
                <GridItem xs={12} sm={8} className={classes.alignRight}>
                  {role.type}
                </GridItem>
              </GridContainer>

              <GridContainer
                className={`${classes.description} ${classes.cardMarginBottom} ${classes.marginIn}`}
              >
                <GridItem xs={12} sm={4} className={classes.alignLeft}>
                  {t("protected")}
                </GridItem>
                <GridItem xs={12} sm={8} className={classes.alignRight}>
                  {role.protected ? t("true") : t("false")}
                </GridItem>
              </GridContainer>

              <GridContainer
                className={`${classes.description} ${classes.cardMarginBottom} ${classes.marginIn}`}
              >
                <GridItem xs={12} sm={4} className={classes.alignLeft}>
                  {t("capabilities")}
                </GridItem>
                <GridItem xs={12} sm={8} className={classes.alignRight}>
                  {counters.rootCapabilities + counters.inheritedCapabilities}
                </GridItem>
              </GridContainer>

              <GridContainer
                className={`${classes.description} ${classes.cardMarginBottom} ${classes.marginIn}`}
              >
                <GridItem xs={12} sm={4} className={classes.alignLeft}>
                  {t("inherited-roles")}
                </GridItem>
                <GridItem xs={12} sm={8} className={classes.alignRight}>
                  {counters.rootRoles + counters.inheritedRoles}
                </GridItem>
              </GridContainer>

              <GridContainer
                className={`${classes.description} ${classes.cardMargin}`}
              >
                <GridItem xs={12} sm={4} className={classes.alignLeft}>
                  {t("created-at")}
                </GridItem>
                <GridItem xs={12} sm={8} className={classes.alignRight}>
                  {ApiService.formatDateTime(role.createdAt)}
                </GridItem>
              </GridContainer>

              <GridContainer
                className={`${classes.description} ${classes.cardMarginBottom} ${classes.marginIn}`}
              >
                <GridItem xs={12} sm={4} className={classes.alignLeft}>
                  {t("updated-at")}
                </GridItem>
                <GridItem xs={12} sm={8} className={classes.alignRight}>
                  {ApiService.formatDateTime(role.updatedAt)}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <NavPills
            color="info"
            alignCenter
            tabs={[
              {
                tabButton: t("inherited-roles"),
                tabIcon: GroupWorkIcon,
                tabContent: (
                  <Card>
                    <CardHeader
                      color="turq"
                      icon
                      style={{ display: "inline-flex" }}
                    >
                      <CardIcon color="turq">
                        <GroupWorkIcon style={{ color: roseColor[0] }} />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>
                        {t("inherited-roles")}
                      </h4>
                      <div style={{ marginLeft: "auto" }}>
                        <Button
                          simple
                          color="rose"
                          className="remove"
                          onClick={() => handleRefresh()}
                          style={{ marginTop: "28px" }}
                        >
                          {t("refresh")}
                        </Button>
                        {!role.protected && (
                          <Button
                            color="primary"
                            style={{ marginTop: "20px" }}
                            round
                            onClick={() => {
                              handleUpdateRoles();
                            }}
                          >
                            {t("update")}
                          </Button>
                        )}
                      </div>
                    </CardHeader>
                    <CardBody>
                      <ReactTable
                        data={handleReturnData(roles)}
                        filterable
                        columns={[
                          {
                            Header: t("name"),
                            accessor: "name",
                          },
                          {
                            Header: t("type"),
                            accessor: "type",
                          },
                          {
                            Header: t("protected"),
                            accessor: "protectedFormatted",
                          },
                          {
                            Header: t("status"),
                            accessor: "resolvingType",
                          },
                          {
                            Header: t("actions"),
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={ApiService.defaultPageSize}
                        showPaginationTop={false}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    </CardBody>
                  </Card>
                ),
              },
              {
                tabButton: t("capabilities"),
                tabIcon: VerifiedUserIcon,
                tabContent: (
                  <Card>
                    <CardHeader
                      color="turq"
                      icon
                      style={{ display: "inline-flex" }}
                    >
                      <CardIcon color="turq">
                        <VerifiedUserIcon style={{ color: roseColor[0] }} />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>
                        {t("capabilities")}
                      </h4>
                      <div style={{ marginLeft: "auto" }}>
                        <Button
                          simple
                          color="rose"
                          className="remove"
                          onClick={() => handleRefresh()}
                          style={{ marginTop: "28px" }}
                        >
                          {t("refresh")}
                        </Button>
                        {!role.protected && (
                          <Button
                            color="primary"
                            style={{ marginTop: "20px" }}
                            round
                            onClick={() => {
                              handleUpdateCapabilities();
                            }}
                          >
                            {t("update")}
                          </Button>
                        )}
                      </div>
                    </CardHeader>
                    <CardBody>
                      <ReactTable
                        data={handleReturnData(capabilities)}
                        filterable
                        columns={[
                          {
                            Header: t("name"),
                            accessor: "name",
                          },
                          {
                            Header: t("type"),
                            accessor: "type",
                          },
                          {
                            Header: t("protected"),
                            accessor: "protectedFormatted",
                          },
                          {
                            Header: t("status"),
                            accessor: "resolvingType",
                          },
                          {
                            Header: t("actions"),
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={ApiService.defaultPageSize}
                        showPaginationTop={false}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    </CardBody>
                  </Card>
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <DeleteOutlineIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("delete-role-0")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer
                className={`${classes.description} ${classes.marginIn} ${classes.cardMarginBottom}`}
              >
                <GridItem xs={12}>
                  <h5>{t("permanently-delete-role")}</h5>
                </GridItem>
              </GridContainer>
              <Button
                color="rose"
                simple
                className={classes.updateProfileButton}
                onClick={showDeleteRole}
              >
                {t("delete")}
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbars
        place="bc"
        color="danger"
        icon={AddAlert}
        message={errorMessage}
        open={errorMessage.length > 0}
        closeNotification={() => setErrorMessage("")}
        close
      />
      <Snackbars
        place="bc"
        color="success"
        icon={AddAlert}
        message={successMessage}
        open={successMessage.length > 0}
        closeNotification={() => setSuccessMessage("")}
        close
      />
      {alert}
    </div>
  );
};

AdminRoleConfiguration.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default AdminRoleConfiguration;
