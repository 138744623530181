import React from "react";
import Table from "components/Table/Table.js";
import { Link } from "react-router-dom";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";
import CardFooter from "components/Card/CardFooter.js";

import ListIcon from "@material-ui/icons/List";
import Business from "@material-ui/icons/Business";
import DataUsage from "@material-ui/icons/DataUsage";

// @material-ui/core components
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import { makeStyles } from "@material-ui/core/styles";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);

const AdminSystemOverview = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();

  const [loading, setLoading] = React.useState(true);
  const [machineCheck, setMachineCheck] = React.useState({});
  const [healthCheckResults, setHealthCheckResults] = React.useState({});
  const [healthCheckAll, setHealthCheckAll] = React.useState(false);

  // System overview results
  const [deployment, setDeployment] = React.useState({});
  const [systemOverview, setSystemOverview] = React.useState([]);

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal, true, true);
        const responseData = await ApiService.getSystemOverview(signal);
        setSystemOverview(responseData);
        if (responseData.length > 0) {
          setDeployment(responseData[0]);
        }
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && systemOverview.length === 0 && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [deployment, systemOverview]);

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const checkMachineServices = async (machine, signal) => {
      const checks = {
        valid: 0,
        total: 0,
      };
      // eslint-disable-next-line no-unused-vars
      for (const service of machine.services) {
        if (service.enabled && !!service.healthCheck) {
          try {
            await ApiService.healthCheck(service.healthCheck, signal);
            checks.valid += 1;
          } catch (err) {
            console.error(err);
          }
          checks.total += 1;
        }
      }
      return checks;
    };

    const apiOperations = async () => {
      try {
        const checks = await checkMachineServices(machineCheck, signal);
        const results = { ...healthCheckResults };
        results[machineCheck.name] = checks;
        setMachineCheck({});
        setHealthCheckResults(results);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && !ApiService.isObjectEmpty(machineCheck) && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [machineCheck, healthCheckResults]);

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const checkMachineServices = async (machine, signal) => {
      const checks = {
        valid: 0,
        total: 0,
      };
      // eslint-disable-next-line no-unused-vars
      for (const service of machine.services) {
        if (service.enabled && !!service.healthCheck) {
          try {
            await ApiService.healthCheck(service.healthCheck, signal);
            checks.valid += 1;
          } catch (err) {
            console.error(err);
          }
          checks.total += 1;
        }
      }
      return checks;
    };

    const apiOperations = async () => {
      try {
        const results = { ...healthCheckResults };
        // eslint-disable-next-line no-unused-vars
        for (const machine of deployment.machines) {
          const checks = await checkMachineServices(machine, signal);
          results[machine.name] = checks;
        }
        setHealthCheckAll(false);
        setHealthCheckResults(results);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted &&
      healthCheckAll &&
      !ApiService.isObjectEmpty(deployment) &&
      apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [deployment, healthCheckAll, healthCheckResults]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <legend>{t("filters")}</legend>
        </GridItem>
      </GridContainer>
      <GridContainer
        justifyContent="space-between"
        alignItems="center"
        direction="row"
      >
        <GridItem xs={3}>
          <FormControl fullWidth className={formClasses.selectFormControl}>
            <InputLabel
              htmlFor="deployment-name"
              className={formClasses.selectLabel}
            >
              {t("deployment")}
            </InputLabel>
            <Select
              value={deployment.deployment}
              onChange={(e) => {
                setDeployment(
                  systemOverview.find((entry) => entry.name === e.target.value)
                );
              }}
              MenuProps={{
                className: formClasses.selectMenu,
              }}
              classes={{
                select: formClasses.select,
              }}
              inputProps={{
                name: "deploymentName",
                id: "deployment-name",
              }}
              disabled={
                healthCheckAll || !ApiService.isObjectEmpty(machineCheck)
              }
            >
              <MenuItem
                disabled
                classes={{
                  root: formClasses.selectMenuItem,
                }}
              >
                {t("deployment")}
              </MenuItem>
              {systemOverview.map((entry, index) => {
                return (
                  <MenuItem
                    key={index}
                    classes={{
                      root: formClasses.selectMenuItem,
                      selected: formClasses.selectMenuItemSelectedMultiple,
                    }}
                    value={entry.deployment}
                  >
                    {entry.deployment}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={9} style={{ textAlign: "right" }}>
          <Button
            simple
            color="rose"
            className="remove"
            disabled={healthCheckAll}
            onClick={() => {
              setHealthCheckAll(true);
            }}
            style={{ marginTop: "28px" }}
          >
            {t("health-check")}
          </Button>
        </GridItem>
      </GridContainer>
      {!ApiService.isObjectEmpty(deployment) && (
        <React.Fragment>
          <GridContainer>
            <GridItem xs={4}>
              <Card>
                <CardHeader color="turq" stats icon>
                  <CardIcon color="turq">
                    <Business style={{ color: roseColor[0] }} />
                  </CardIcon>
                  <p className={classes.cardCategory}>{t("deployment")}</p>
                  <h3 className={classes.cardTitle}>{deployment.deployment}</h3>
                </CardHeader>
                <CardFooter stats></CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={4}>
              <Card>
                <CardHeader color="turq" stats icon>
                  <CardIcon color="turq">
                    <DataUsage style={{ color: roseColor[0] }} />
                  </CardIcon>
                  <p className={classes.cardCategory}>{t("provider")}</p>
                  <h3 className={classes.cardTitle}>{deployment.provider}</h3>
                </CardHeader>
                <CardFooter stats></CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={4}>
              <Card>
                <CardHeader color="turq" stats icon>
                  <CardIcon color="turq">
                    <ListIcon style={{ color: roseColor[0] }} />
                  </CardIcon>
                  <p className={classes.cardCategory}>{t("type")}</p>
                  <h3 className={classes.cardTitle}>{deployment.type}</h3>
                </CardHeader>
                <CardFooter stats></CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            {deployment.machines.map((entry, index) => {
              return (
                <GridItem key={index} xs={4}>
                  <Card>
                    <CardBody>
                      <h3>{entry.name}</h3>
                      <br />
                      <Table
                        tableData={[
                          [t("cluster"), entry.cluster],
                          [t("description"), entry.description],
                          [
                            t("total-checks-services"),
                            `${
                              entry.services.filter(
                                (service) =>
                                  service.enabled && !!service.healthCheck
                              ).length
                            } / ${
                              entry.services.filter(
                                (service) => service.enabled
                              ).length
                            }`,
                          ],
                        ]}
                      />
                      <GridContainer
                        justifyContent="space-between"
                        alignItems="center"
                        direction="row"
                      >
                        <GridItem xs={4} style={{ textAlign: "center" }}>
                          {healthCheckAll ||
                          machineCheck.name === entry.name ? (
                            <h4>{t("loading")}</h4>
                          ) : (
                            healthCheckResults[entry.name] && (
                              <h3>
                                {healthCheckResults[entry.name].valid} /{" "}
                                {healthCheckResults[entry.name].total}
                              </h3>
                            )
                          )}
                        </GridItem>
                        <GridItem xs={8} style={{ textAlign: "right" }}>
                          <Button
                            simple
                            color="rose"
                            className="remove"
                            disabled={
                              healthCheckAll || machineCheck.name === entry.name
                            }
                            onClick={() => {
                              setMachineCheck({ ...entry });
                            }}
                            style={{ marginTop: "28px" }}
                          >
                            {t("health-check")}
                          </Button>
                          <Link
                            to={`/admin/admin-deployment-machine?${new URLSearchParams(
                              {
                                deployment: deployment.deployment,
                                machine: entry.name,
                              }
                            ).toString()}`}
                          >
                            <Button
                              color="primary"
                              style={{ marginTop: "20px" }}
                              round
                              onClick={() => {
                                return;
                              }}
                            >
                              {t("details")}
                            </Button>
                          </Link>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              );
            })}
          </GridContainer>
        </React.Fragment>
      )}
    </div>
  );
};

export default AdminSystemOverview;
