import React from "react";
import ReactTable from "react-table";
import isDecimal from "validator/lib/isDecimal";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import jwt from "jwt-decode";
import moment from "moment";

import { Link } from "react-router-dom";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";
import Datetime from "react-datetime";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AppsIcon from "@material-ui/icons/Apps";
import AccountBox from "@material-ui/icons/AccountBox";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import EmailIcon from "@material-ui/icons/Email";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import BlockIcon from "@material-ui/icons/Block";
import CreditCard from "@material-ui/icons/CreditCard";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";

/*#############################
  # Modal view dialog classes #
  #############################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";

import Muted from "components/Typography/Muted.js";
import { Tooltip } from "@material-ui/core";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "35px",
    marginBottom: "0px",
  },
  wordBreak: {
    wordBreak: "break-all",
  },
};

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyle);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const dialogDetailsInputFields = new Set(["token"]);

const AdminUsers = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState([]);
  const [alert, setAlert] = React.useState(null);

  // Modal states
  const [showModalPricingPlan, setShowModalPricingPlan] = React.useState(false);
  const [showAuthTokenModal, setShowAuthTokenModal] = React.useState(false);
  const [authTokenDetails, setAuthTokenDetails] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("add")
  );

  // Dialog fields
  const [modalName, setModalName] = React.useState("");
  const [modalEmail, setModalEmail] = React.useState("");
  const [modalPassword, setModalPassword] = React.useState("");
  const [modalConfirmPassword, setModalConfirmPassword] = React.useState("");
  const [modalTokenExpireTime, setModalTokenExpireTime] = React.useState("");
  const [modalSpecial, setModalSpecial] = React.useState([1]);
  const [modalSuperuser, setModalSuperuser] = React.useState([1]);
  const [modalValidatedEmail, setModalValidatedEmail] = React.useState([1]);
  const [modalValidatedDocuments, setModalValidatedDocuments] = React.useState([
    1,
  ]);
  const [modalPricingPlan, setModalPricingPlan] = React.useState("");
  const [modalPricingUser, setModalPricingUser] = React.useState({});
  const [modalPricingActivationDate, setModalPricingActivationDate] =
    React.useState(moment().utc().startOf("day"));
  const [pricingPlans, setPricingPlans] = React.useState([]);

  const [refreshClicked, setRefreshClicked] = React.useState(false);

  const clearModalState = () => {
    setShowModal(false);
    setShowModalPricingPlan(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("add"));
    setModalName("");
    setModalEmail("");
    setModalPassword("");
    setModalConfirmPassword("");
    setModalTokenExpireTime("");
    setModalSpecial([1]);
    setModalSuperuser([1]);
    setModalValidatedEmail([1]);
    setModalValidatedDocuments([1]);
    setModalPricingPlan("");
    setModalPricingUser({});
    setModalPricingActivationDate(moment().utc().startOf("day"));
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const handleAddUser = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const name = modalName.trim();
    const email = modalEmail.trim();
    const password = modalPassword;
    const confirmPassword = modalConfirmPassword;
    const tokenExpireTime = modalTokenExpireTime.trim();
    const special = modalSpecial.length > 0;
    const superuser = modalSuperuser.length > 0;
    const validatedEmail = modalValidatedEmail.length > 0;
    const validatedDocuments = modalValidatedDocuments.length > 0;

    const validName = name.length > 0;
    const validEmail = isEmail(email);
    const validPasswordLength = password.length === 0 || password.length > 6;
    const passwordsMatch = password === confirmPassword;

    const checksPassed =
      validName && validEmail && validPasswordLength && passwordsMatch;
    if (!checksPassed) {
      const messageContent = [];
      if (!validName) {
        messageContent.push(t("name-length-should-be-greater-than-1"));
      }
      if (!validEmail) {
        messageContent.push(t("email-address"));
      }
      if (!validPasswordLength) {
        messageContent.push(t("minimum-password-length-is-7"));
      }
      if (!passwordsMatch) {
        messageContent.push(t("passwords-do-not-match"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }
    const params = {
      name,
      email,
      special,
      superuser,
      validatedEmail,
      validatedDocuments,
    };
    if (password.length > 0) {
      params.password = password;
    }
    if (tokenExpireTime.length > 0) {
      params.tokenExpireTime = tokenExpireTime;
    }

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("adding"));

    try {
      await ApiService.createUserSuperuser(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("user-successfully-added"));
  };

  const handleReturnData = (data) => {
    return data.map((elem) => {
      const queryParameters = new URLSearchParams({
        userId: elem._id,
      }).toString();
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("user-profile")}>
            <Link to={`/admin/user-profile?${queryParameters}`}>
              <Button justIcon round simple color="rose" className="like">
                <MoreVertIcon />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip title={t("services")}>
            <Link to={`/admin/admin-services?${queryParameters}`}>
              <Button justIcon round simple color="rose" className="like">
                <AppsIcon />
              </Button>
            </Link>
          </Tooltip>
          {elem.activeTokens > 0 && (
            <Tooltip title={t("view-token")}>
              <Button
                justIcon
                round
                simple
                color="rose"
                className="edit"
                onClick={() => {
                  handleGetUserToken(elem);
                }}
              >
                <VisibilityIcon />
              </Button>
            </Tooltip>
          )}
          <Tooltip title={t("generate-token")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                handleGenerateAuthToken(elem);
              }}
            >
              <AutorenewIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("update-user-credits")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                handleUpdateUserCredits(elem);
              }}
            >
              <CreditCard />
            </Button>
          </Tooltip>
          <Tooltip title={t("update-pricing-plan")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                setModalPricingUser(elem);
                if (elem.requestPricingPlan) {
                  setModalPricingPlan(
                    elem.pricingPlanName ? elem.pricingPlanName : ""
                  );
                }
                setConfirmModalButtonText(t("activate"));
                setShowModalPricingPlan(true);
              }}
            >
              <LocalOfferIcon />
            </Button>
          </Tooltip>
          {!elem.validatedDocuments ? (
            <Tooltip title={t("validate-documents")}>
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  handleValidateDocuments(elem);
                }}
                color="rose"
                className="remove"
              >
                <AssignmentTurnedInIcon />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={t("invalidate-documents")}>
              <Button
                justIcon
                simple
                className="remove"
                color="rose"
                onClick={() => {
                  handleInvalidateDocuments(elem);
                }}
              >
                <AssignmentLateIcon />
              </Button>
            </Tooltip>
          )}
          {!elem.validatedEmail ? (
            <Tooltip title={t("validate-email")}>
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  handleValidateEmail(elem);
                }}
                color="rose"
                className="remove"
              >
                <EmailIcon />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={t("invalidate-email")}>
              <Button
                justIcon
                simple
                color="rose"
                className="remove"
                onClick={() => {
                  handleInvalidateEmail(elem);
                }}
              >
                <MailOutlineIcon />
              </Button>
            </Tooltip>
          )}
          {!elem.blocked ? (
            <Tooltip title={t("block-user")}>
              <Button
                justIcon
                simple
                color="rose"
                className="edit"
                onClick={() => {
                  handleBlockUser(elem);
                }}
              >
                <BlockIcon />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={t("unblock-user")}>
              <Button
                justIcon
                round
                simple
                color="rose"
                className="remove"
                onClick={() => {
                  handleUnblockUser(elem);
                }}
              >
                <CheckCircleIcon />
              </Button>
            </Tooltip>
          )}
        </div>
      );
      return elem;
    });
  };

  const handleValidateDocuments = (elem) => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("validate-user-documents")}
        onConfirm={() => {
          updateUserDocumentValidation(elem, true);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${elem.name} (${elem.email})`}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleInvalidateDocuments = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("invalidate-user-documents")}
        onConfirm={() => {
          updateUserDocumentValidation(elem, false);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${elem.name} (${elem.email})`}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleValidateEmail = (elem) => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("validate-user-email")}
        onConfirm={() => {
          updateUserEmailValidation(elem, true);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${elem.name} (${elem.email})`}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleInvalidateEmail = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("invalidate-user-email")}
        onConfirm={() => {
          updateUserEmailValidation(elem, false);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${elem.name} (${elem.email})`}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleBlockUser = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("block-user")}?`}
        onConfirm={() => {
          updateUserBlockedFlag(elem, true);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${elem.name} (${elem.email})`}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleUnblockUser = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("unblock-user")}?`}
        onConfirm={() => {
          updateUserBlockedFlag(elem, false);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${elem.name} (${elem.email})`}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleDenyPricingPlanUpdate = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("deny-pricing-plan-update")}
        onConfirm={() => {
          denyPricingPlanUpdate();
        }}
        onCancel={() => {
          cancelDialog();
          clearModalState();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${modalPricingPlan}`}</b>
          {t("for-user")}
          <b>{`${modalPricingUser.name} (${modalPricingUser.email})`}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleGetUserToken = async (user) => {
    try {
      const tokens = await ApiService.readUserTokensSuperuser({
        userId: user._id,
      });
      if (tokens.length === 0) return;
      const { token } = tokens.pop();
      const payload = jwt(token);
      const header = jwt(token, { header: true });
      const tokenDates = {};
      if (payload.iat) {
        tokenDates.issuedAt = new Date(payload.iat * 1000).toISOString();
      }
      if (payload.exp) {
        tokenDates.expiresAt = new Date(payload.exp * 1000).toISOString();
        tokenDates.valid = Date.now() < payload.exp * 1000;
      } else {
        tokenDates.valid = true;
      }
      const renderData = {
        header,
        payload,
        tokenDates,
        token,
      };
      setAuthTokenDetails(renderData);
      setShowAuthTokenModal(true);
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const updateUserDocumentValidation = async (
    userProfile,
    validatedDocuments
  ) => {
    setAlert(null);
    try {
      await ApiService.updateUserDocumentsValidation({
        userId: userProfile._id,
        validatedDocuments,
      });
      operationResult(
        true,
        t("user-documents-validation-updated-successfully")
      );
    } catch (e) {
      operationResult(
        false,
        t("error-while-updating-user-documents-validation")
      );
    }
  };

  const updateUserEmailValidation = async (userProfile, validatedEmail) => {
    setAlert(null);
    try {
      await ApiService.updateUserEmailValidation({
        userId: userProfile._id,
        validatedEmail,
      });
      operationResult(true, t("user-email-validation-updated-successfully"));
    } catch (e) {
      operationResult(false, t("error-while-updating-user-email-validation"));
    }
  };

  const updateUserBlockedFlag = async (userProfile, blocked) => {
    setAlert(null);
    try {
      await ApiService.updateUserBlockedFlag({
        userId: userProfile._id,
        blocked,
      });
      operationResult(true, t("user-block-flag-updated-successfully"));
    } catch (e) {
      operationResult(false, t("error-while-updating-user-block-flag"));
    }
  };

  const denyPricingPlanUpdate = async () => {
    setAlert(null);
    try {
      await ApiService.requestPricingPlanUpdate({
        userId: modalPricingUser._id,
        requestPricingPlan: false,
      });
      operationResult(
        true,
        t("successfully-denied-pricing-plan-update-request")
      );
    } catch (e) {
      operationResult(
        false,
        t("error-while-denying-pricing-plan-update-request")
      );
    }
    clearModalState();
  };

  const handleGenerateAuthToken = (elem) => {
    setAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={t("generate-auth-token")}
        onConfirm={async (e) => {
          try {
            await ApiService.generateAuthTokenSuperuser({
              userId: elem._id,
              tokenExpireTime: e,
            });
            operationResult(true, t("successfully-generated-auth-token"));
          } catch (e) {
            operationResult(
              false,
              `${t("error-generating-auth-token")}: ${e.message}`
            );
          }
        }}
        onCancel={() => cancelDialog()}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
      >
        <p>{t("set-token-expire-time-for-user")}</p>
        <p>
          <b>{`${elem.name} (${elem.email})`}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleUpdateUserCredits = (elem) => {
    setAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={t("update-user-credits")}
        onConfirm={async (e) => {
          if (isDecimal(e)) {
            try {
              await ApiService.updateUserCredits({
                userId: elem._id,
                credits: e,
              });
              operationResult(
                true,
                t("successfully-updated-user-credits-amount")
              );
            } catch (exc) {
              operationResult(
                false,
                `${t("error-updating-user-credits-e-message")} ${exc.message}`
              );
            }
          } else {
            operationResult(false, t("invalid-credits-amount"));
          }
        }}
        onCancel={() => cancelDialog()}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
      >
        <p>{t("insert-available-amount-of-credits-for-user")}</p>
        <p>
          <b>{`${elem.name} (${elem.email})`}</b>
        </p>
        <p>
          {t("current-credits-amount")} <b>{elem.credits}</b>
        </p>
      </SweetAlert>
    );
  };

  const handlePricingPlanUpdate = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const validPricingPlanName = pricingPlans.find(
      (pricingPlan) => pricingPlan === modalPricingPlan
    );
    const validPricingPlanActivationDate =
      modalPricingActivationDate.isSameOrAfter(moment().utc().startOf("day"));

    const checksPassed = validPricingPlanName && validPricingPlanActivationDate;
    if (!checksPassed) {
      const messageContent = [];
      if (!validPricingPlanName) {
        messageContent.push(t("invalid-name"));
      }
      if (!validPricingPlanActivationDate) {
        messageContent.push(t("invalid-activation-date"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }

    const params = {
      userId: modalPricingUser._id,
      name: modalPricingPlan,
      activationDate: modalPricingActivationDate,
    };

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("activating"));

    try {
      await ApiService.activatePricingPlan(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("pricing-plan-successfully-activated"));
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;

    const abortController = new AbortController();
    const signal = abortController.signal;

    const getAllUsers = async (signal = undefined) => {
      try {
        const userProfile = await ApiService.loginRequired(signal, true, true);
        const params = {};
        const responseData = await ApiService.getAllUsers(params, signal);
        const filteredData = responseData.filter((userData) => {
          if (userData._id === userProfile._id) {
            return false;
          }
          userData.specialFormatted = userData.special.toString();
          userData.validatedEmailFormatted = userData.validatedEmail.toString();
          userData.validatedDocumentsFormatted =
            userData.validatedDocuments.toString();
          userData.blockedFormatted = userData.blocked.toString();
          userData.requestDocumentsValidationFormatted =
            userData.requestDocumentsValidation.toString();
          userData.requestPricingPlanFormatted =
            userData.requestPricingPlan.toString();
          return true;
        });
        const responsePricingPlans = await ApiService.getPricingPlans(
          {
            sortBy: "name:asc",
          },
          signal
        );
        setPricingPlans(
          responsePricingPlans.map((pricingPlan) => pricingPlan.name)
        );
        setUsers(filteredData);
      } catch (e) {
        console.error(e);
      }
    };

    const apiOperations = async () => {
      try {
        await getAllUsers(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [refreshClicked]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModalPricingPlan}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("activate-pricing-plan")}
          </h4>
        </DialogTitle>
        <form onSubmit={handlePricingPlanUpdate}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("user")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="pricing-plan-user"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    disabled: true,
                    value: `${modalPricingUser.name} (${modalPricingUser.email})`,
                  }}
                  helpText={t("pricing-plan-user")}
                />
              </GridItem>
            </GridContainer>
            {modalPricingUser.requestPricingPlan && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    style={{ float: "left" }}
                  >
                    {t("requested-plan")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <CustomInput
                    id="pricing-plan-user"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      disabled: true,
                      value: `${modalPricingUser.pricingPlanName}`,
                    }}
                    helpText={t("requested-pricing-plan")}
                  />
                </GridItem>
              </GridContainer>
            )}
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("pricing-plan")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="pricing-plan"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalPricingPlan}
                    onChange={(e) => {
                      setModalPricingPlan(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "pricingPlan",
                      id: "pricingPlan",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("pricing-plan")}
                    </MenuItem>
                    {pricingPlans.map((pricingPlan) => {
                      return (
                        <MenuItem
                          key={pricingPlan}
                          classes={{
                            root: formClasses.selectMenuItem,
                            selected:
                              formClasses.selectMenuItemSelectedMultiple,
                          }}
                          value={pricingPlan}
                        >
                          {pricingPlan}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("activation-date")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControl fullWidth>
                    <Datetime
                      dateFormat={"YYYY-MM-DD"}
                      timeFormat={false}
                      inputProps={{
                        placeholder: t("select-date"),
                        disabled: showModalProgress,
                      }}
                      value={modalPricingActivationDate}
                      onChange={(e) => {
                        setModalPricingActivationDate(e);
                      }}
                    />
                  </FormControl>
                </div>
              </GridItem>
            </GridContainer>
            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            {modalPricingUser.requestPricingPlan && (
              <Button
                onClick={() => {
                  setShowModalPricingPlan(false);
                  handleDenyPricingPlanUpdate();
                }}
                disabled={confirmModal}
                color="rose"
              >
                {t("deny")}
              </Button>
            )}
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showAuthTokenModal}
        transition={Transition}
        keepMounted
        scroll="body"
        fullWidth
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowAuthTokenModal(false);
            setAuthTokenDetails({});
          }
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>{t("auth-token")}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            {Object.entries(authTokenDetails).map(([fieldName, fieldValue]) => {
              let data;
              if (typeof fieldValue === "object") {
                data = (
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    {JSON.stringify(fieldValue, undefined, 2)}
                  </div>
                );
              } else {
                if (dialogDetailsInputFields.has(fieldName)) {
                  data = (
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: fieldValue.toString(),
                      }}
                    />
                  );
                } else {
                  data = (
                    <React.Fragment>{fieldValue.toString()}</React.Fragment>
                  );
                }
              }
              return (
                <React.Fragment key={uuidv4()}>
                  <GridItem xs={12} sm={4} md={2} style={{ fontWeight: "400" }}>
                    {dialogDetailsInputFields.has(fieldName) ? (
                      <FormLabel
                        className={formClasses.labelHorizontal}
                        style={{ float: "left" }}
                      >
                        {fieldName}
                      </FormLabel>
                    ) : (
                      fieldName
                    )}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    md={10}
                    className={classes.wordBreak}
                  >
                    {data}
                  </GridItem>
                </React.Fragment>
              );
            })}
          </GridContainer>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button
            onClick={() => {
              setShowAuthTokenModal(false);
              setAuthTokenDetails({});
            }}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>{t("add-user")}</h4>
        </DialogTitle>
        <form onSubmit={handleAddUser}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("name")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="user-name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalName,
                    required: true,
                    onChange: (e) => {
                      setModalName(e.target.value);
                    },
                  }}
                  helpText={t("user-name")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("email")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="user-email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "email",
                    value: modalEmail,
                    required: true,
                    onChange: (e) => {
                      setModalEmail(e.target.value);
                    },
                  }}
                  helpText={t("user-email")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("password")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="user-password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "password",
                    value: modalPassword,
                    onChange: (e) => {
                      setModalPassword(e.target.value);
                    },
                  }}
                  helpText={t("user-password")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("confirm-password")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="user-confirm-password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "password",
                    value: modalConfirmPassword,
                    onChange: (e) => {
                      setModalConfirmPassword(e.target.value);
                    },
                  }}
                  helpText={t("confirm-user-password")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("token-expire-time")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="user-token-expire"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalTokenExpireTime,
                    onChange: (e) => {
                      setModalTokenExpireTime(e.target.value);
                    },
                  }}
                  helpText={t("token-expire-time-0")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("special")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6} style={{ float: "left" }}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => {
                          const currentIndex = modalSpecial.indexOf(1);
                          const newChecked = [...modalSpecial];
                          if (currentIndex === -1) {
                            newChecked.push(1);
                          } else {
                            newChecked.splice(currentIndex, 1);
                          }
                          setModalSpecial(newChecked);
                        }}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot,
                        }}
                        checked={modalSpecial.length > 0}
                        disabled={showModalProgress}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot,
                    }}
                    label={t("special")}
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("superuser")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6} style={{ float: "left" }}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => {
                          const currentIndex = modalSuperuser.indexOf(1);
                          const newChecked = [...modalSuperuser];
                          if (currentIndex === -1) {
                            newChecked.push(1);
                          } else {
                            newChecked.splice(currentIndex, 1);
                          }
                          setModalSuperuser(newChecked);
                        }}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot,
                        }}
                        checked={modalSuperuser.length > 0}
                        disabled={showModalProgress}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot,
                    }}
                    label={t("superuser")}
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("validated-email")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6} style={{ float: "left" }}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => {
                          const currentIndex = modalValidatedEmail.indexOf(1);
                          const newChecked = [...modalValidatedEmail];
                          if (currentIndex === -1) {
                            newChecked.push(1);
                          } else {
                            newChecked.splice(currentIndex, 1);
                          }
                          setModalValidatedEmail(newChecked);
                        }}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot,
                        }}
                        checked={modalValidatedEmail.length > 0}
                        disabled={showModalProgress}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot,
                    }}
                    label={t("validated-email")}
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("validated-documents")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6} style={{ float: "left" }}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => {
                          const currentIndex =
                            modalValidatedDocuments.indexOf(1);
                          const newChecked = [...modalValidatedDocuments];
                          if (currentIndex === -1) {
                            newChecked.push(1);
                          } else {
                            newChecked.splice(currentIndex, 1);
                          }
                          setModalValidatedDocuments(newChecked);
                        }}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot,
                        }}
                        checked={modalValidatedDocuments.length > 0}
                        disabled={showModalProgress}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot,
                    }}
                    label={t("validated-documents")}
                  />
                </div>
              </GridItem>
            </GridContainer>

            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <AccountBox style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("users")}</h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => {
                    handleRefresh();
                  }}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  round
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {t("add")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={handleReturnData(users)}
                filterable
                columns={[
                  {
                    Header: t("name"),
                    accessor: "name",
                  },
                  {
                    Header: t("email-address-0"),
                    accessor: "email",
                  },
                  {
                    Header: t("special"),
                    accessor: "specialFormatted",
                  },
                  {
                    Header: t("blocked"),
                    accessor: "blockedFormatted",
                  },
                  {
                    Header: t("validated-email"),
                    accessor: "validatedEmailFormatted",
                  },
                  {
                    Header: t("req-pricing"),
                    accessor: "requestPricingPlanFormatted",
                  },
                  {
                    Header: t("req-validation"),
                    accessor: "requestDocumentsValidationFormatted",
                  },
                  {
                    Header: t("validated-docs"),
                    accessor: "validatedDocumentsFormatted",
                  },
                  {
                    Header: t("credits"),
                    accessor: "credits",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
};

AdminUsers.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AdminUsers;
