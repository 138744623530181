import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";

import FindInPageIcon from "@material-ui/icons/FindInPage";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";

/*#############################
  # Modal view dialog classes #
  #############################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyle);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const dialogDetailsInputFields = new Set(["value"]);

const UserLookups = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
  const [userLookups, setUserLookups] = React.useState([]);
  const [formattedUserName, setFormattedUserName] = React.useState("");

  // Modal states
  const [showModal, setShowModal] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("add")
  );

  // Dialog fields
  const [modalId, setModalId] = React.useState("");
  const [modalType, setModalType] = React.useState(
    ApiService.lookupTypes.anchor
  );
  const [modalAccess, setModalAccess] = React.useState(
    ApiService.lookupPermissionTypes.public
  );
  const [modalValue, setModalValue] = React.useState("");
  const [modalPermissions, setModalPermissions] = React.useState("[]");
  const [modalFlags, setModalFlags] = React.useState("[]");

  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [detailsTitle, setDetailsTitle] = React.useState("");
  const [details, setDetails] = React.useState({});

  const [refreshClicked, setRefreshClicked] = React.useState(false);

  // pagination sorting
  const [count, setCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(ApiService.defaultPageSize);
  const [totalPages, setTotalPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [sortBy, setSortBy] = React.useState("createdAt:desc");

  const [filters, setFilters] = React.useState([]);
  const [filterClicked, setFilterClicked] = React.useState(false);
  const [filterParams, setFilterParams] = React.useState([]);

  const getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    if (queryParams.get("parentId")) {
      params.parentId = queryParams.get("parentId");
    }
    if (queryParams.get("parentType")) {
      params.parentType = queryParams.get("parentType");
    }
    const returnData = await ApiService.loginRequired(signal, false);
    if (
      (returnData.superuser ||
        params.parentType === ApiService.parentTypes.organization) &&
      params.userId
    ) {
      const userProfileData = await ApiService.readUser(params, signal);
      setFormattedUserName(
        ApiService.getFormattedUserName(returnData._id, userProfileData)
      );
      params.userId =
        typeof userProfileData.superuser !== "undefined"
          ? userProfileData._id
          : returnData._id;
    } else {
      params.userId = returnData._id;
    }
    params.superuser = returnData.superuser;
    return params;
  };

  const clearModalState = () => {
    setShowModal(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("add"));
    setModalId("");
    setModalType(ApiService.lookupTypes.anchor);
    setModalAccess(ApiService.lookupPermissionTypes.public);
    setModalValue("");
    setModalPermissions("[]");
    setModalFlags("[]");
    setShowDetailsModal(false);
    setDetailsTitle("");
    setDetails({});
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const handleReturnData = (data) => {
    return data.map((elem) => {
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("view-details")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                handleGetSingleLookup(elem);
              }}
            >
              <VisibilityIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("edit-lookup")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                setModalId(elem._id);
                setModalType(elem.type);
                setModalAccess(elem.access);
                setModalValue(elem.value);
                setModalPermissions(
                  elem.permissions ? JSON.stringify(elem.permissions) : "[]"
                );
                setModalFlags(
                  elem.flags
                    ? JSON.stringify(
                        elem.flags.map((entry) => {
                          return {
                            name: entry.name,
                            value: entry.value,
                          };
                        })
                      )
                    : "[]"
                );
                setShowModal(true);
              }}
            >
              <EditIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("delete-lookup")}>
            <Button
              justIcon
              color="rose"
              simple
              className="remove"
              onClick={() => {
                handleDeleteUserLookup(elem);
              }}
            >
              <ClearIcon />
            </Button>
          </Tooltip>
        </div>
      );
      return elem;
    });
  };

  const handleDeleteUserLookup = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-lookup")}?`}
        onConfirm={() => {
          deleteSingleLookup(elem);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${elem._id}`}</b>
        </p>
      </SweetAlert>
    );
  };

  const deleteSingleLookup = async (lookup) => {
    setAlert(null);
    try {
      const queryParams = await getQueryParams();
      const params = {
        userId: queryParams.userId,
        id: lookup._id,
      };
      if (queryParams.parentId) {
        params.parentId = queryParams.parentId;
      }
      if (queryParams.parentType) {
        params.parentType = queryParams.parentType;
      }
      await ApiService.deleteUserLookup(params);
      operationResult(true, t("lookup-successfully-deleted"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const handleGetSingleLookup = async (elem) => {
    const queryParams = await getQueryParams();
    const params = {
      userId: queryParams.userId,
      id: elem._id,
    };
    if (queryParams.parentId) {
      params.parentId = queryParams.parentId;
    }
    if (queryParams.parentType) {
      params.parentType = queryParams.parentType;
    }
    try {
      const responseData = await ApiService.getSingleUserLookup(params);
      setDetailsTitle(t("lookup-details"));
      setDetails(responseData);
      setShowDetailsModal(true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddSingleLookup = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const id = modalId.trim();
    const validId = id.length > 0;
    const value = modalValue.trim();
    const validValue = value.length > 0;
    let permissions;
    let validPermissions;
    try {
      permissions = JSON.parse(modalPermissions);
      validPermissions = Array.isArray(permissions);
    } catch (e) {
      validPermissions = false;
    }
    let flags;
    let validFlags;
    try {
      flags = JSON.parse(modalFlags);
      validFlags = Array.isArray(flags);
    } catch (e) {
      validFlags = false;
    }

    const checksPassed =
      validId && validValue && validPermissions && validFlags;
    if (!checksPassed) {
      const messageContent = [];
      if (!validId) {
        messageContent.push(t("invalid-id"));
      }
      if (!validValue) {
        messageContent.push(t("invalid-value"));
      }
      if (!validPermissions) {
        messageContent.push(t("invalid-permissions"));
      }
      if (!validFlags) {
        messageContent.push(t("invalid-flags"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }
    const queryParams = await getQueryParams();
    const params = {
      userId: queryParams.userId,
      id,
      type: modalType,
      access: modalAccess,
      value,
      permissions,
      flags,
    };
    if (queryParams.parentId) {
      params.parentId = queryParams.parentId;
    }
    if (queryParams.parentType) {
      params.parentType = queryParams.parentType;
    }

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("adding"));

    try {
      await ApiService.upsertUserLookup(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("lookup-successfully-added"));
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const handleRefresh = () => {
    setFilters([]);
    setFilterParams([]);
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      if (queryParams.get("parentId")) {
        params.parentId = queryParams.get("parentId");
      }
      if (queryParams.get("parentType")) {
        params.parentType = queryParams.get("parentType");
      }
      const returnData = await ApiService.loginRequired(signal, false);
      if (
        (returnData.superuser ||
          params.parentType === ApiService.parentTypes.organization) &&
        params.userId
      ) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId =
          typeof userProfileData.superuser !== "undefined"
            ? userProfileData._id
            : returnData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;
      return params;
    };

    const getUserLookups = async (signal = undefined) => {
      try {
        const queryParams = await getQueryParams(signal);
        const params = {
          userId: queryParams.userId,
          sortBy: sortBy,
          fromEntry: currentPage * pageSize,
          numberOfResults: pageSize,
        };

        if (filterParams.length > 0) {
          const filteredObj = {};
          filterParams.map((filter) => {
            const filteredField = filter.id.replace(/Formatted$/, "");
            if (ApiService.exactMatch.includes(filter.id)) {
              return (filteredObj[filteredField] =
                filter.id === "fileSize"
                  ? parseInt(filter.value)
                  : filter.value);
            } else {
              return (filteredObj[filteredField] = {
                $regex: `^${filter.value}`,
              });
            }
          });

          params.filters = JSON.stringify(filteredObj);
        }

        if (queryParams.parentId) {
          params.parentId = queryParams.parentId;
        }
        if (queryParams.parentType) {
          params.parentType = queryParams.parentType;
        }
        const responseData = await ApiService.getUserLookups(params, signal);
        setUserLookups(responseData);

        delete params.sortBy;
        delete params.fromEntry;
        delete params.numberOfResults;
        params.count = true;
        const { count: userLookupsCount } = await ApiService.getUserLookups(
          params,
          signal
        );
        setCount(userLookupsCount);
      } catch (e) {
        console.error(e);
      } finally {
        setFilterClicked(false);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await getUserLookups(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [
    props.location.search,
    refreshClicked,
    currentPage,
    pageSize,
    sortBy,
    filterParams,
  ]);

  React.useEffect(() => {
    if (filterClicked && filters.length > 0) {
      setFilterParams(filters);
    }
  }, [filters, filterClicked]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && count > 0) {
      setTotalPages(Math.ceil(count / pageSize));
    }

    return () => {
      isMounted = false;
    };
  }, [count, pageSize]);

  const handlePageChange = async (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePageSizeChange = async (newPageSize, pageIndex) => {
    const total = Math.ceil(count / newPageSize);
    setTotalPages(total);

    let currentIndex;
    if (pageSize > newPageSize) {
      currentIndex = Math.ceil(pageIndex / (pageSize / newPageSize));
    } else {
      currentIndex = Math.ceil((pageSize / newPageSize) * pageIndex);
    }
    setCurrentPage(currentIndex);

    setPageSize(newPageSize);
  };

  const handleSortChange = (newSorted) => {
    setSortBy(
      `${newSorted[0].id.replace(/Formatted$/, "")}:${
        newSorted[0].desc === true ? "desc" : "asc"
      }`
    );
  };

  const handleFilterColumn = (filtered, column) => {
    setFilters(filtered);
  };

  const handleFilterClicked = () => {
    setFilterClicked(true);
  };

  const handleExportData = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("export-data")}?`}
        onConfirm={() => {
          exportData();
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      ></SweetAlert>
    );
  };

  const exportData = async () => {
    try {
      const queryParams = await getQueryParams();
      const fromEntry = currentPage * pageSize;
      const params = {
        userId: queryParams.userId,
        sortBy: sortBy,
        fromEntry,
        numberOfResults: pageSize,
        exportJson: "true",
      };
      if (queryParams.parentId) {
        params.parentId = queryParams.parentId;
      }
      if (queryParams.parentType) {
        params.parentType = queryParams.parentType;
      }

      if (filterParams.length > 0) {
        const filteredObj = {};
        filterParams.map((filter) => {
          const filteredField = filter.id.replace(/Formatted$/, "");
          if (ApiService.exactMatch.includes(filter.id)) {
            return (filteredObj[filteredField] =
              filter.id === "fileSize" ? parseInt(filter.value) : filter.value);
          } else {
            return (filteredObj[filteredField] = {
              $regex: `^${filter.value}`,
            });
          }
        });

        params.filters = JSON.stringify(filteredObj);
      }
      const responseData = await ApiService.downloadUserLookups(params);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = `${t(
        "lookups"
      )}_${fromEntry}_${pageSize}.json`.toLowerCase();
      a.click();
      setAlert(null);
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showDetailsModal}
        transition={Transition}
        keepMounted
        scroll="body"
        fullWidth
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowDetailsModal(false);
            setDetailsTitle("");
            setDetails({});
          }
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>{detailsTitle}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            {Object.entries(details).map(([fieldName, fieldValue]) => {
              let data;
              if (typeof fieldValue === "object") {
                data = (
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    {JSON.stringify(fieldValue, undefined, 2)}
                  </div>
                );
              } else {
                if (dialogDetailsInputFields.has(fieldName)) {
                  data = (
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: fieldValue.toString(),
                      }}
                    />
                  );
                } else {
                  data = (
                    <React.Fragment>{fieldValue.toString()}</React.Fragment>
                  );
                }
              }
              return (
                <React.Fragment key={uuidv4()}>
                  <GridItem xs={12} sm={6} md={4} style={{ fontWeight: "400" }}>
                    {dialogDetailsInputFields.has(fieldName) ? (
                      <FormLabel
                        className={formClasses.labelHorizontal}
                        style={{ float: "left" }}
                      >
                        {fieldName}
                      </FormLabel>
                    ) : (
                      fieldName
                    )}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={6}
                    md={8}
                    style={{ wordBreak: "break-all" }}
                  >
                    {data}
                  </GridItem>
                </React.Fragment>
              );
            })}
          </GridContainer>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button
            simple
            color="rose"
            onClick={() => {
              setShowDetailsModal(false);
              setDetailsTitle("");
              setDetails({});
            }}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("add-lookup")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleAddSingleLookup}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("type")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="lookup-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalType}
                    onChange={(e) => {
                      setModalType(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "lookupType",
                      id: "lookup-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("type")}
                    </MenuItem>
                    {Object.values(ApiService.lookupTypes).map((lookupType) => {
                      return (
                        <MenuItem
                          key={lookupType}
                          classes={{
                            root: formClasses.selectMenuItem,
                            selected:
                              formClasses.selectMenuItemSelectedMultiple,
                          }}
                          value={lookupType}
                        >
                          {lookupType}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("id")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="lookup-id"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalId,
                    onChange: (e) => {
                      setModalId(e.target.value);
                    },
                  }}
                  helpText={t("lookup-id")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("value")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="lookup-value"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalValue,
                    onChange: (e) => {
                      setModalValue(e.target.value);
                    },
                  }}
                  helpText={t("value")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("access")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="lookup-access"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalAccess}
                    onChange={(e) => {
                      setModalAccess(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "lookupAccess",
                      id: "lookup-access",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("access")}
                    </MenuItem>
                    {Object.values(ApiService.lookupPermissionTypes).map(
                      (lookupPermissionType) => {
                        return (
                          <MenuItem
                            key={lookupPermissionType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={lookupPermissionType}
                          >
                            {lookupPermissionType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("permissions")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="permissions"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalPermissions,
                    onChange: (e) => {
                      setModalPermissions(e.target.value);
                    },
                  }}
                  helpText={t("permissions")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("flags")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="flags"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalFlags,
                    onChange: (e) => {
                      setModalFlags(e.target.value);
                    },
                  }}
                  helpText={t("flags")}
                />
              </GridItem>
            </GridContainer>

            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <FindInPageIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("lookups")} {formattedUserName}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => handleRefresh()}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => {
                    handleExportData();
                  }}
                  style={{ marginTop: "28px" }}
                >
                  {t("export")}
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  round
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {t("add")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              {filters.length > 0 && (
                <React.Fragment>
                  <Button
                    color="primary"
                    round
                    className="remove"
                    onClick={() => {
                      handleFilterClicked();
                    }}
                  >
                    {t("filter")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    className="remove"
                    onClick={() => {
                      handleRefresh();
                    }}
                  >
                    {t("clear-filters")}
                  </Button>
                </React.Fragment>
              )}
              <ReactTable
                data={handleReturnData(userLookups)}
                sortable={true}
                multiSort={false}
                filterable={true}
                filtered={filters}
                columns={[
                  {
                    Header: t("lookup-id"),
                    accessor: "_id",
                  },
                  {
                    Header: t("type"),
                    accessor: "type",
                  },
                  {
                    Header: t("access"),
                    accessor: "access",
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
                manual
                pages={totalPages}
                page={currentPage}
                pageSize={pageSize}
                onPageChange={(pageIndex) => handlePageChange(pageIndex)}
                onPageSizeChange={(pageSize, pageIndex) =>
                  handlePageSizeChange(pageSize, pageIndex)
                }
                onSortedChange={(newSorted, column, shiftKey) =>
                  handleSortChange(newSorted, column, shiftKey)
                }
                onFilteredChange={(filtered, column) =>
                  handleFilterColumn(filtered, column)
                }
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
};

UserLookups.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default UserLookups;
